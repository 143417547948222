import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react-lite';
import useStores from 'hooks/use-store';
import { SellButton, SellerButtons } from './Styles';
import { LaneContext } from '../../../LaneWrapper';

const SellerTools = ({ viewOnly = false }) => {
  const {
    AuctionStore: { isFullViewLayout },
    BidStore: { auction },
    UIStore: {
      setSellerAction, clearSellerAction, setTempSellerAction, clearTempSellerAction,
    },
  } = useStores();

  const { laneID } = useContext(LaneContext);
  const { seller, isOpenForBidding, currentBid } = auction[laneID];
  const { sellerAction } = seller;

  const TakeButtonText = () => {
    if (seller.tempSellerAction !== 'TAKES' && seller.sellerAction === 'TAKES') {
      return 'TAKES';
    }

    if (seller.tempSellerAction === 'TAKES') {
      return 'Cancel';
    }
    return 'TAKES';
  };

  return (
    <div>
      <SellerButtons isFullViewLayout={isFullViewLayout}>
        <SellButton
          success
          disabled={!isOpenForBidding || viewOnly}
          data-testid="sell-btn"
          onClick={() => {
            if (sellerAction !== 'SELL') {
              setSellerAction(laneID, 'SELL');
              return;
            }
            clearSellerAction(laneID, 'SELL');
          }}
        >
          {sellerAction !== 'SELL' ? 'SELL' : 'Cancel'}
        </SellButton>
        <SellButton
          disabled={!isOpenForBidding || viewOnly}
          takes={seller.tempSellerAction !== 'TAKES' && sellerAction !== 'TAKES'}
          data-testid="takes-btn"
          onClick={() => {
            if (sellerAction === 'TAKES') {
              return null;
            }
            clearSellerAction(laneID, seller.sellerAction);
            if (seller.tempSellerAction === 'TAKES' && seller.tempTakeAmount > 0) {
              return null;
            }
            if (seller.tempSellerAction !== 'TAKES') {
              setTempSellerAction(laneID, 'TAKES', currentBid);
              return null;
            }
            clearTempSellerAction(laneID, 'TAKES');
            return null;
          }}
        >
          {TakeButtonText()}
        </SellButton>
        <SellButton
          disabled={!isOpenForBidding || viewOnly}
          data-testid="pass-btn"
          danger
          medium
          onClick={() => {
            if (sellerAction !== 'PASS') {
              setSellerAction(laneID, 'PASS');
              return;
            }
            clearSellerAction(laneID, 'PASS');
          }}
        >
          {sellerAction !== 'PASS' ? 'PASS' : 'Cancel'}
        </SellButton>
      </SellerButtons>
    </div>
  );
};

SellerTools.propTypes = {
  viewOnly: PropTypes.bool.isRequired,
};

export default observer(SellerTools);
