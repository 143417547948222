import { useState, useEffect, useRef } from 'react';
import { useOnClickOutside } from 'hooks';

export const useMobile = ({
  isMobileView,
  setMobileClosedLanes,
  handleExitLane,
  getLaneIDPlayingAudio,
  openLanes,
}) => {
  const [open, setOpen] = useState(false);
  const mobileNavRef = useRef();

  useOnClickOutside(mobileNavRef, () => setOpen(false));

  useEffect(() => {
    if (isMobileView) {
      const laneIDToActivate = getLaneIDPlayingAudio() || openLanes[0];
      if (laneIDToActivate) {
        openLanes
          .filter((laneID) => laneID !== laneIDToActivate)
          .forEach((laneID) => {
            setMobileClosedLanes(laneID);
            handleExitLane(laneID);
          });
      }
    }
  }, [isMobileView, getLaneIDPlayingAudio, handleExitLane, openLanes, setMobileClosedLanes]);

  return [mobileNavRef, open, setOpen];
};

export default useMobile;
