// import { promoTestData } from 'hooks';

export const usePromo = (attributes) => {
  const getPromoValueIfExists = (item) => (item || null);

  const getProductAttribute = (attrs, key) => attrs.filter((attr) => attr.name === key)[0]?.value;

  const getPromoData = (attrs) => {
    if (!attrs) {
      return {
        image: null,
        url: null,
        text: null,
      };
    }

    const promoImage = getProductAttribute(attrs, 'PromoImage');
    const promoText = getProductAttribute(attrs, 'PromoText');
    const promoUrl = getProductAttribute(attrs, 'PromoURL');

    const promoResult = {
      image: getPromoValueIfExists(promoImage),
      url: getPromoValueIfExists(promoUrl),
      text: getPromoValueIfExists(promoText),
    };

    return promoResult;
  };

  const handlePromoClick = (redirectUrl) => {
    if (!redirectUrl) return null;
    return window.open(redirectUrl, '_blank');
  };

  // TEST CASES
  // TODO: Need to know what to do with the promoText if it exists.
  // Test Cases: (**** great start; MT will get clarification for others)

  // 1. withImage: **** Yes Image, No Url, No Text (Just show image)
  // 2. withImageAndUrl: **** Yes Image, Yes Url, No Text (Link the image to url)
  // 3. withImageAndUrlAndTest: Yes Image, Yes Url, Yes Text (what to do here?)
  // 4. withImageAndText: Yes Image, No Url, Yes Text (same as 3, but no link)
  // 5. withText: **** No Image, No Url, Yes Text (Just show the text)
  // 6. withUrl: No Image, Yes Url, No Text (Is this valid? If so, what is the link text?)
  // 7. withUrlAndText: **** No Image, Yes Url, Yes Text (link the text to Url)
  // 8. withNoData: No Image, No Url, No Text (don't show promo)

  // const {
  //   withImage,
  //   withImageAndUrl,
  //   withImageAndUrlAndText,
  //   withImageAndText,
  //   withText,
  //   withUrl,
  //   withUrlAndScrollText,
  //   withNoData,
  // } = promoTestData;


  const promo = getPromoData(attributes);
  const showPromo = !!promo.image || !!promo.text;

  return { showPromo, promo, handlePromoClick };
};

export default usePromo;
