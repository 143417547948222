import React from 'react';
import PropTypes from 'prop-types';

const Input = (
  {
    type,
    onFocus,
    onKeyUp,
    onKeyPress,
    onChange,
    value,
    disabled,
    placeholderText,
    defaultValue,
    tabIndex,
    maxLength,
    testId,
  },
  ref
) => {
  return (
    <input
      type={type || 'text'}
      autoComplete="off"
      defaultValue={defaultValue}
      tabIndex={tabIndex}
      placeholder={placeholderText}
      disabled={disabled}
      ref={ref}
      value={value}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      onChange={onChange}
      onFocus={onFocus}
      maxLength={maxLength}
      data-testid={testId}
    />
  );
};

const forwardInput = React.forwardRef(Input);

Input.propTypes = {
  type: PropTypes.string,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool.isRequired,
  placeholderText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabIndex: PropTypes.number,
  testId: PropTypes.string,
};

Input.defaultProps = {
  disabled: true,
  onFocus: () => null,
  onKeyUp: () => null,
  onKeyPress: () => null,
  onChange: () => null,
};

export default forwardInput;
