import { useMediaQuery } from 'react-responsive';
import { useWindowDimension } from 'hooks';
import { breakpoints } from 'shared/utils/styles';

const useResponsive = () => {
  const { winWidth, winHeight } = useWindowDimension();

  const isTabletView = useMediaQuery({
    query: `(min-width: ${breakpoints.tablet})`,
  });
  const isSmallView = useMediaQuery({
    query: `(min-width: ${breakpoints.small})`,
  });
  const isLargeView = useMediaQuery({
    query: `(min-width: ${breakpoints.large})`,
  });
  const isLargerView = useMediaQuery({
    query: `(min-width: ${breakpoints.larger})`,
  });
  const isLargestView = useMediaQuery({
    query: `(min-width: ${breakpoints.largest})`,
  });
  const isMobileView =
    !isTabletView &&
    !isSmallView &&
    !isLargeView &&
    !isLargerView &&
    !isLargestView;

  const getViewType = () => {
    if (isMobileView) return 'mobile';
    if (isLargestView) return 'largest';
    if (isLargerView) return 'larger';
    if (isLargeView) return 'large';
    if (isSmallView) return 'small';
    return 'tablet';
  };

  const viewType = getViewType();

  window.localStorage.setItem('viewType', viewType);
  
  if (!viewType) {
    console.log('No viewType yet');
  }

  return {
    isLargestView,
    isLargerView,
    isLargeView,
    isMobileView,
    isSmallView,
    isTabletView,
    winHeight,
    winWidth,
    viewType,
  };
};

export default useResponsive;
