import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/project/shared';
import { ContentWrapper, MessageContainer } from './Styles';

const LaneMessage = ({
  message, bgColor, color, icon,
}) => {
  if (message) {
    return (
      <ContentWrapper className="pin-message" bgColor={bgColor}>
        <MessageContainer color={color}>
          <Icon icon={icon} size={1} iconOnly hoverPointer={false} />
          {message}
        </MessageContainer>
      </ContentWrapper>
    );
  }

  return null;
};

LaneMessage.propTypes = {
  message: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
};

LaneMessage.defaultProps = {
  message: null,
  bgColor: 'var(--pinMsgBgColor)',
  color: 'var(--pinMsgTextColor)',
  icon: 'info-circle',
};

export default LaneMessage;
