import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useToggle, useStores } from 'hooks';
import { Icon } from '../../../shared';
import { AuctionLaneContent } from './AuctionLaneContent';

import {
  IconButton,
  Section,
  SectionContent,
  SectionHeader,
  SectionHeaderLabel,
} from '../Styles';


export const AuctionLanes = observer(() => {
  const {
    AuctionStore: {
      laneList,
    },
  } = useStores();

  const {
    isToggled: laneSectionOpen,
    toggle: toggleLaneSectContent,
  } = useToggle(true);

  const getToggleIcon = (isOpen) => (isOpen ? 'chevron-up' : 'chevron-down');

  const variants = {
    open: {
      opacity: 1,
      height: 'auto',
      minHeight: '100px',
      maxHeight: '250px',
    },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderLabel>
          <Icon icon="road" size={1} hoverPointer={false} />
          Auction Lanes (
          {laneList.length}
          )
        </SectionHeaderLabel>
        <IconButton onClick={toggleLaneSectContent}>
          <Icon
            icon={getToggleIcon(laneSectionOpen)}
            iconOnly
            size={0.8}
          />
        </IconButton>
      </SectionHeader>
      {laneSectionOpen && (
        <AnimatePresence initial={false}>
          <SectionContent
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <AuctionLaneContent />
          </SectionContent>
        </AnimatePresence>
      )}
    </Section>
  );
});

export default React.memo(AuctionLanes);
