import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';


import Routes from './Routes';
import { ThemeProvider } from './hooks/useTheme';

/** This is our entry file. Anything we want to inject globally goes here.
 * We could put things like global error handling or site wide modals here
 * @returns {*}
 * @constructor
 */

const Container = () => (
  <ThemeProvider value="default">
    <Routes />
  </ThemeProvider>
);

/** Inject our React application into the root element of the index.html */
ReactDOM.render(<Container />, document.getElementById('root'));
