const productionDomainsToTrack = [
  'npasimulcastwebbidder.npauctions.com',
  'npasimulcastwebbidder2.npauctions.com',
];

const includesUrl = (href) => productionDomainsToTrack.filter(item => href.includes(item)).length > 0

class Analytics {
  constructor() {
    this.gtag = null;
    this.isProduction = includesUrl(window.location.href);
    this.debug = true;
  }

  initialize = () => {
    window.dataLayer = window.dataLayer || [];
    //function gtag(){dataLayer.push(arguments);}

    this.gtag = function gtag() {
      if (this.isProduction) {
        window.dataLayer.push(arguments);
        return;
      }
      if (this.debug) {
        console.log('--GA DEBUG--');
        console.log(arguments);
      }
    };

    this.gtag('js', new Date());

    this.gtag('config', 'G-YLMX37XFLQ');
  };

  RecordEvent = (event, category, label, value) => {
    this.gtag('event', event, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };

  RecordPageView = (path, title) => {
    this.gtag({
      event: 'pageview',
      page: {
        path: path,
        title: title,
      },
    });
  };
}

export default new Analytics();
