export const color = {
  primary: '#008cba', // blue
  success: '#008000', // green
  danger: '#f44336', // red
  alert: '#e5e500', // yellow
  warning: '#F89C1C', // orange
  secondary: '#e7e7e7', // light gray

  textDarkest: '#000',
  textDark: '#333',
  textMedium: '#444',
  textLight: '#fff',
  textLink: '#0095E8',

  borderLightest: '#dfe1e6',
  borderLight: '#c1c7d0',
  borderMedium: '#808080',
  borderInputFocus: '#4c9aff',

  backgroundMedium: '#303030', // sub-heading-bgcolor
  backgroundLight: '#eee',
  backgroundLightest: '#fff',

  backgroundAccentMedium: '#C80005',
  backgroundAccentDark: '#870505',
  backgroundDarkPrimary: '#262626',

  backgroundLightPrimary: '#D2E5FE',
  backgroundLightSuccess: '#E4FCEF',
};


export const color2 = {
  askingBid: '#BCEAFD',
  bid: '#009E0F',
  clerkItem: '#931C11',
  clerkMessage: '#04A1D3',
  dealerMessage: '#D7D8D8',
  default: '#E7E7E7',
  globalMessage: '#1C6C98',
  myItems: '#BFBCAC',
  noSale: '#DB2828',
  pinned: '#F57A3A',
  preview: '#CE6363',
  privateMessage: '#DFEBE2',
  proxy: '#FBCC08',
  proxyBorder: '#D8B006',
  publicMessage: '#A6B9AA',
  utility: '#0095E8',
  watch: '#32A9D2',
  watching: '#D3EFFC',
  won: '#21BA45',
};

export const color3 = {
  white: '#ffffff',
  gray1: '#eeeeee',
  gray2: '#e7e7e7', 
  gray3: '#D7D8D8',   // dealer message
  gray4: '#d2d1d2',
  gray5: '#cfcfd1',   // disabled button
  gray6: '#808080',
  gray7: '#888888',   // sub-header bg (ie. preview)
  gray8: '#444444',   // header bg
  
  black: '#000000', 
  brown1: '#BFBCAC',  // seller items
  red1: '#ff0000',    // error messages
  red2: '#DB2828',    // no sale
  red3: '#ab0000',    // bidder bar
  blue1: '#D3EFFC',   // watching
  blue2: '#32A9D3',   // NEW watch button
  blue3: '#04A1D3',   // clerk message
  blue4: '#0095E8',   // utility buttons
  blue5: '#1C6C98',   // global message
  yellow1: '#FBCC08', // proxy/pending
  yellow2: '#D8B006', // border
  green1: '#21BA45',  // won message
  green2: '#009E0F',  // bid button
  orange1: '#F57A3A',  // pinned message
};

export const font = {
  regular: 'font-family: "RobotoRegular"; font-weight: normal;',
  medium: 'font-family: "RobotoMedium"; font-weight: normal;',
  bold: 'font-family: "RobotoBold"; font-weight: normal;',
  black: 'font-family: "RobotoBlack"; font-weight: normal;',
  size: (size) => `font-size: ${size}em;`,
};
