import { useReducer, useEffect, useState } from 'react';
// import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import { useSwipeable } from 'react-swipeable';

// defines the time for the animation between slides in milliseconds
// let transitionTime = 400;
// defines the threshold when to accept a swipe
const threshold = 0.3;
// defines the limit for swiping (max. the next full and a bit)
// const limit = 1.2;
const limit = 0;
// animation to be used when bouncing back
// const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
// animation to be used when automatically sliding
// let smooth = `transform ${transitionTime}ms ease-in`;

const previous = (length, current) => (current - 1 + length) % length;

const next = (length, current) => (current + 1) % length;

const initialCarouselState = {
  offset: 0,
  desired: 0,
  active: 0,
};

const carouselReducer = (state, action) => {
  switch (action.type) {
    case 'jump':
      return {
        ...state,
        desired: action.desired,
      };
    case 'next':
      return {
        ...state,
        desired: next(action.length, state.active),
      };
    case 'prev':
      return {
        ...state,
        desired: previous(action.length, state.active),
      };
    case 'done':
      return {
        ...state,
        offset: NaN,
        active: state.desired,
      };
    case 'drag':
      return {
        ...state,
        offset: action.offset,
      };
    default:
      return state;
  }
};

const swiped = (delta, dispatch, length, dir, container) => {
  const t = container.clientWidth * threshold;
  const d = dir * delta;

  if (d >= t) {
    dispatch(dir > 0 ? { type: 'next', length } : { type: 'prev', length });
  } else {
    dispatch({
      type: 'drag',
      offset: 0,
    });
  }
};

// OBSOLETE
// TODO: IF SLIDES PRESENTED IS MORE THAN 1 THEN WE WANT TO RESET WHAT WE'RE SHOWING (if more than what is to be shown????)
const useCarousel = (length, interval, width, options = {}) => {
  const getWidth = () => Number.parseInt(width);
  const getWidthUnit = () => width.replace(/[0-9]/g, '');

  const { slidesPresented, transitionTime = 400 } = options;
  const smooth = `transform ${transitionTime}ms ease-in`;
  const shadowSlides = 2 * slidesPresented;
  const n = Math.max(1, Math.min(slidesPresented, length));
  const totalWidth = getWidthUnit() === '%' ? getWidth() / n : getWidth() * length;
  //FIXME: MERGING/SYNC'ING ACTIVE INDEX
  const [state, dispatch] = useReducer(carouselReducer, { ...initialCarouselState, active: options.activeImage });
  const [container, setContainer] = useState(undefined);
  const { ref, onMouseDown } = useSwipeable({
    onSwiping(e) {
      const sign = e.deltaX > 0 ? -1 : 1;
      dispatch({
        type: 'drag',
        offset:
          sign * Math.min(Math.abs(e.deltaX), limit * container.clientWidth),
      });
    },
    onSwipedLeft(e) {
      swiped(e.deltaX, dispatch, length, 1, container);
    },
    onSwipedRight(e) {
      swiped(e.deltaX, dispatch, length, -1, container);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  const handlers = {
    onMouseDown,
    // eslint-disable-next-line no-shadow
    ref(container) {
      setContainer(container && container.firstElementChild);
      return ref(container);
    },
  };

  useEffect(() => {
    if (interval > 0) {
      const id = setTimeout(() => dispatch({ type: 'next', length }), interval);
      return () => clearTimeout(id);
    }
    return () => null;
  }, [state.offset, state.active, length, interval]);

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
    return () => clearTimeout(id);
  }, [state.desired, transitionTime]);

  const getLeft = (active, unit, w) => {
    const pxWidth = slidesPresented === length ? 0 : w;
    const left = (active + 1) * (unit === '%' ? 100 : pxWidth);
    const leftWithUnit = left < 0 ? `${left}${unit}` : `-${left}${unit}`;
    return leftWithUnit;
  };

  const style = {
    transform: 'translateX(0)',
    width: `${getWidth() * (length + 2)}${getWidthUnit()}`,
    left: getLeft(state.active, getWidthUnit(), getWidth()),
  };

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired);
    const pref = Math.sign(state.offset || 0);
    const dir = (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active);
    const shift = (totalWidth * (pref || dir)) / (length + shadowSlides);

    style.transition = smooth;
    style.transform = `translateX(${shift}${getWidthUnit()})`;
  } else if (!Number.isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`;
    } else {
      style.transition = smooth;
    }
  }

  return [
    state.active,
    // eslint-disable-next-line no-shadow
    (n) => dispatch({ type: 'jump', desired: n }),
    handlers,
    style,
  ];
};

export default useCarousel;
