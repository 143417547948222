import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStores, useDesktopRedirect } from 'hooks';
import { Content, MarginWrapper } from 'assets/ContainerStyles';
import agent from '../../../../agent';
import { getApiHost } from '../../../../endpoints';

import {
  Icon,
  BackButton,
  BackWrapper,
  TitleHeader,
  Title,
  ProductTitle,
  Wrapper,
  Track,
  TrackKey,
  TrackValue,
  ProxyBidPanel,
  ProxyBidInput,
  ProxyBidButton,
} from './Styles';

import MediaGallery from '../../BidPanel/MediaGallery';

const ProductPreviewView = ({ lotNumber, auctionLaneID }) => {
  const history = useHistory();
  const { UIStore } = useStores();
  const { toggleLoading } = UIStore;
  const { Product } = agent;

  const [productItem, setProductItem] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [error, setError] = useState(null);

  const { isSmallView } = useDesktopRedirect();

  useEffect(() => {
    toggleLoading();

    const host = getApiHost();
    Product.getProduct(lotNumber, auctionLaneID, host)
      .then((res) => {
        setProductItem(res.data);
        setAttributes(res.data.attributes);
        toggleLoading();
      })
      .catch(() => {
        toggleLoading();
        setError('Vehicle no longer available');
      });
    // eslint-disable-next-line
  }, []);

  if (!productItem) {
    return null;
  }

  if (error) {
    return <h2>{error}</h2>;
  }

  return !isSmallView ? (
    <Wrapper>
      <TitleHeader>
        <Title>{`AUCTION ITEM PREVIEW (ITEM #${productItem.lotNumber})`}</Title>
      </TitleHeader>
      <BackWrapper>
        <BackButton onClick={() => history.goBack()}>
          <Icon className="fa fa-chevron-left icon-only" />
          Return to auction items
        </BackButton>
      </BackWrapper>
      {productItem && <MediaGallery data={productItem} />}
      <Content>
        <ProxyBidPanel>
          <ProxyBidInput type="text" value="$5,200" onChange={() => null} />
          <ProxyBidButton>
            <Icon className="fa fa-gavel icon-only" />
            {' '}
            MY PROXY BID
          </ProxyBidButton>
        </ProxyBidPanel>

        <MarginWrapper>
          {productItem && (
            <ProductTitle>{productItem.productName}</ProductTitle>
          )}

          {/** We have to be able to better than this */}
          {attributes
            && attributes
              .filter((item) => item.value !== null)
              .filter((item) => item.value !== '')
              .filter((item) => item.name !== 'ConditionReportURL')
              .filter((item) => item.name !== 'Score')
              .filter((item) => item.name !== 'ConditionNotes')
              .filter((item) => item.name !== 'Title')
              .map((item) => (
                <Track key={item.attributeId}>
                  <TrackKey>{item.name}</TrackKey>
                  <TrackValue>{item.value}</TrackValue>
                </Track>
              ))}
        </MarginWrapper>
      </Content>
    </Wrapper>
  ) : null;
};

ProductPreviewView.propTypes = {
  lotNumber: PropTypes.string.isRequired,
  auctionLaneID: PropTypes.string.isRequired,
};

export default observer(ProductPreviewView);
