import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const Wrapper = styled.div`
    width: 100vw;
`;

export const Content = styled.div`
    margin: 20px;
`;

export const Icon = styled.i`
    color: var(--lightTextColor);
    margin: 0 5px 0 0;
`;

export const TitleHeader = styled.div`
    background: var(--myProxyBgColor);
    text-align: center;
    padding: 5px;
`;

export const Title = styled.h2`
    color: black;
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
`;

export const ProductTitle = styled.h3`
    text-align: center;
`;

export const BackWrapper = styled.div`
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const AuctionTitle = styled.p`
    color: var(--lightTextColor);
    display: flex;
    align-self: center;
`;

export const BackButton = styled.button`
    justify-content: start;
    `;

export const Track = styled.div`
  display: flex;
  &:nth-child(odd) > div {
    background-color: var(--white);
  }
`;

export const TrackKey = styled.div`
  width: 40%;
  /* padding: 5px 0px 5px 3px; */
  padding: 5px 0px 5px 3px;
  text-align: left;
  font-weight: bold;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  
  @media (min-width: ${breakpoints.tablet}) {
  
  }
  
`;

export const TrackValue = styled.div`
  text-align: right;
  /* padding: 5px 3px 5px 0px; */
  padding: 2px 3px 2px 0px;
  width: 60%;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  
  @media (min-width: ${breakpoints.tablet}) {
  
  }
`;

export const ProxyBidPanel = styled.div`
    margin: 15px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ProxyBidInput = styled.input`
    border: var(--line);
    line-height: 1.2em;
    font-size: 1em;
    padding: 5px;
    width: 30%;
`;

export const ProxyBidButton = styled.button`
    position: relative;
    margin: 5px 0px;
    min-width: 50%;
    background: none;
    border: none;
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--myProxyBgColor);
    color: var(--myProxyTextColor);
    line-height: 1.2em;
    font-size: 1em;
    font-weight: bold;
    width: 30%;
`;
