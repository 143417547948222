import React from 'react';

import Spinner from 'components/shared/Spinner';

import StyledPageLoader from './Styles';

const PageLoader = () => (
  <StyledPageLoader>
    <Spinner size={70} color="#DB2828" />
  </StyledPageLoader>
);

export default PageLoader;
