import React from 'react';
import PropTypes from 'prop-types';

const Option = ({ value, text }) => {
  return (
    <option key={value} value={value}>
      {text}
    </option>
  );
};

Option.propTypes = {
  value: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
};

export default Option;
