import styled from 'styled-components';

export const Dimmer = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    color: white;
    margin-bottom: 0.4em;
  }
`;

export default Dimmer;
