import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
// import { motion } from 'framer-motion';
import { Button } from '../../shared/utils/styles';

// export const Center = styled(motion.div)`
export const Center = styled.div`
    box-sizing: border-box;
    z-index: 110;
    /* justify-content: center; */
    position: absolute;
    top: 50px;
    bottom: 4em;
    left: 0;
    right: 0;
    background-color: var(--white);
    overflow-y: scroll;

  @media (min-width: ${breakpoints.tablet}) {
    top: 0;
    overflow: hidden;
    position: relative;
    grid-area: prodprev;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    border: var(--line);
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

const withButtonRowCss = css`
  ${({ isFullViewLayout }) => (isFullViewLayout ? css`
      grid-template-areas:
          'prevtext prevbtns'
          'prevmedia prevattrs'
          'prevmedia prevattrs'
          'prevpromo prevattrs';
      ` : `
        grid-template-areas:
          'prevtext prevbtns'
          'prevmedia prevattrs'
          'prevmedia prevattrs'
          'prevpromo prevattrs';
  `)
}
  
`;

const noButtonRowCss = css`
  ${({ isFullViewLayout }) => (isFullViewLayout ? css`
      grid-template-areas:
        'prevtext prevattrs'
        'prevmedia prevattrs'
        'prevmedia prevattrs'
        'prevpromo prevattrs'
      ` : `
      grid-template-areas:
        'prevtext prevattrs'
        'prevmedia prevattrs'
        'prevmedia prevattrs'
        'prevpromo prevattrs';
  `)}
`;

const isFullViewLayoutGridTemplateCss = css`
  font-size: 1rem;
  grid-template-rows:
      35px
      35px
      175px
      50px;
  grid-template-columns: 215px 240px;
  & .image-gallery-thumbnails-container {
    padding-top: 0px;
  }
  & .image-gallery-thumbnails-container button {
    width: 75px
  }
`;

const defaultViewGridTemplateCss = css`
  grid-template-rows:
      35px
      35px
      185px
      50px;
  grid-template-columns: 225px 250px;
`;

export const ProductWrapper = styled.div`
  //display: none;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet}) {
    display: grid;
    padding: 5px 0px;    
    align-self: center;
    position: relative;
    ${({ isFullViewLayout }) => (isFullViewLayout ? isFullViewLayoutGridTemplateCss : defaultViewGridTemplateCss)};
    ${(props) => (props.showButtonRow ? withButtonRowCss : noButtonRowCss)};
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const PreviewTextContainer = styled.div`
  grid-area: prevtext;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--line);
  text-align: center;
  background-color: var(--previewTitleBgColor);
  color: var(--previewTitleTextColor);
  margin: 0px 10px 5px 10px;
  padding: 5px 10px;
`;

export const PreviewText = styled.div`
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px;
`;

export const TitleWrapper = styled.div`
  grid-area: prevtitle;
  align-items: center;
  padding: 10px 0;
  display: flex;
  background-color: var(--subHeaderBgColor);
  color: var(--subHeaderTextColor);
  margin: 0 0 5px 0;
  position: fixed;
  z-index: 200;
  width: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    width: auto;
    position: relative;
    padding: 0px;
    margin: 0 0 3px 0;
    ${({ isFullViewLayout }) => isFullViewLayout && css`
      & > h2 {
        font-size: 0.6em;
        padding: 3px;
      }
      & i {
        font-size: 1em;
      }
    `}
  }
`;

export const TitleLeft = styled.h2`
  margin: 0px 5px 0px 10px;
  padding: 0px;
  text-transform: uppercase;
  width: 100%;
  min-width: 80%;
  font-size: 0.8em;
  
  @media (min-width: ${breakpoints.tablet}) {
  min-width: 90%;
  }
  
  & a {
    text-decoration: none;
    ${(props) => (props.color ? `color: ${props.color}` : '')};
    &:hover {
      cursor: default;
    }
  }
`;

export const DarkBgIcon = styled.a`
  margin: 0px 15px 0 0;
  color: ${(props) => props.color || 'var(--black)'};
  font-size: 0.75em;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin: 0px 5px;
    font-size: ${(props) => `${props.size || 1}em`};
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const TitleRight = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// const bgImageCss = css`
//   background-image: url('${(props) => props.image}');
//   background-position: 50% 50%;
//   background-repeat: no-repeat;
//   background-size: cover;
// `;

export const GalleryContainer = styled.div`
  grid-area: prevmedia;
  display: flex;
  justify-content: center;
`;

export const AttributesContainer = styled.div`
  grid-area: prevattrs;
  margin-right: 10px;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div`
  grid-area: prevbtns;
  margin: 15px 25px;
  @media (min-width: ${breakpoints.tablet}) {
  margin: 0 10px 0 0;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isSeller ? 'space-between' : 'space-evenly')};
`;

const noHoverCss = css`
  &:hover,
  i:hover {
    cursor: default;
  }
`;
export const StyledButton = styled(Button)`
  width: ${(props) => props.width || '100%'};
  height: 30px;
  margin: 1px;
  padding: 4px 2px;
  font-size: 0.7rem;
  margin: 0px 5px;
  ${(props) => props.readOnly && noHoverCss};
  @media (min-width: ${breakpoints.tablet}) {
    font-size: .7rem;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Loading = styled.p`
    text-align: center;
`;
