import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const Audio = styled.audio`
  outline-width: 0;
  font-size: 0.8em;
  padding: 0px;
  & i {
    padding-right: 5px;
    font-size: 1em;
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const AudioContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
`;
