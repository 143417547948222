import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  margin: 20px 20px 15px 20px;
  padding: 5px;
  box-sizing: border-box;
  line-height: 0.8em;
  ${({ isFullViewLayout, noData }) =>
    isFullViewLayout
      ? css`
          > div div {
            padding: 1px 8px;
          }
          & .fmt-title {
            padding: 5px 10px;
            & > a {
              padding: 0px;
            }
          }
          & .cr-wrapper {
            ${noData &&
            css`
              border: 0px;
              height: 250px;
              color: var(--divider);
              font-size: 1.3em;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `};
            padding: 0px;
            margin: 0px;

            & > div {
              padding: 0px;
            }
            & div p {
              width: 50%;
            }
            & .comment {
              font-size: .8em;
            }
          }
        `
      : css`
          > div div {
            padding: 1px 5px;
          }
          & > div div div {
            padding: 0px 2px;
          }
          & .fmt-title {
            padding: 2px 8px;
            & > a {
              padding: 0px;
            }
          }
          & .cr-wrapper {
            padding: 0px;
            margin: 0px;
            
            & > div {
              padding: 0px;
            }
            & > div div {
              border-bottom: 'solid 1px var(--dividerLine)';  
            }
            & .comment {
              padding: 2px 5px;
            }
            & div p {
              width: 50%;
            }
          }
        `};

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 20px 15px 20px;
    ${({ isFullViewLayout }) => !isFullViewLayout && 'font-size: 1rem;'}
    grid-area: attrs;
    border-top: var(--line);
    border-left: var(--line);
    border-right: var(--line);
    border-bottom: none;
    border: var(--line);
    margin: 0;
    padding: 0px;
    overflow-y: auto;
    min-width: 98%;
    height: auto;
  }
  @media (min-width: ${breakpoints.small}) {
    border-right: none;
    border-bottom: var(--line);
    border: var(--line);
  }
`;

export const Track = styled.div`
  font-size: 0.9em;
  display: flex;
  color: var(--darkTextColor);
  border-bottom: ${({ attribute }) => attribute === 'notes' ? '0px' : 'var(--dividerLine)'};
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.65em;
    background-color: ${({ bgColor }) => bgColor ?? 'inherit'};
    &:nth-child(odd) {
      ${({ bgColor1 }) => bgColor1 && `background-color: ${bgColor1}`};
    }
    &:nth-child(even) {
      ${({ bgColor2 }) => bgColor2 && `background-color: ${bgColor2}`};
    }
    & div {
      ${({ color }) => color && `color: ${color}`};
    }
  }
`;

export const CenteredTrack = styled.a`
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  width: 215px;
  min-height: 1.6em;
  height: 35px;
  ${({ isFullViewLayout }) =>
    isFullViewLayout
      ? css`
          font-size: 0.85em;
        `
      : css`
          font-size: 0.7em;
        `};
`;

export const TrackKey = styled.p`
  width: 40%;
  padding: 5px 0px 5px 3px;
  text-align: left;
  margin: 0;
  font-weight: bold;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
`;

export const TrackValue = styled.p`
  text-align: right;
  padding: 5px 3px 5px 0px;
  margin: 0;
  width: 60%;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
`;

export const TrackLink = styled.a`
  color: var(--linkTextColor);
  font-weight: bold;
  text-decoration: underline;
`;

export const ConditionReportWrapper = styled.div`
  border: var(--line);
`;

export const ConditionReport = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 98%;
  ${({ isFullViewLayout }) =>
    isFullViewLayout
      ? css`
          > div div {
            padding: 1px 2px;
          }
          > div div p {
            font-size: 1.2em;
          }
        `
      : css`
          > div {
            padding: 1px 5px;
          }
          > div div p {
          }
        `};
`;

export const CRTrack = styled(Track)`
  flex-direction: column;
  & > div {
    border:none;
    font-size: .9em;
  }
  @media (min-width: ${breakpoints.tablet}) {
    border: none;
    & > div {
      font-size: .65em;
    }
  }
`;

export const CRTrackKey = styled(TrackKey)`
  & i {
    margin-right: 2px;
  }
`;

const getTooltipWidth = (comment, isFullViewLayout, isMobileView) => {
  const len = comment?.length;
  const fullViewMaxChars = 45;
  const standardViewMaxChars = 25;
  const mobileViewMaxChars = 60;
  const noWrap = 'white-space: nowrap;';
  const autoWidth = 'width: auto;';
  if (isMobileView && len < mobileViewMaxChars) return `${autoWidth} ${noWrap}`;
  if (isMobileView && len >= mobileViewMaxChars) return `width: ${mobileViewMaxChars}ch;`;
  if (isFullViewLayout && len < fullViewMaxChars)
    return `${autoWidth} ${noWrap}`;
  if (isFullViewLayout && len >= fullViewMaxChars)
    return `width: ${fullViewMaxChars}ch;`;
  if (!isFullViewLayout && len < standardViewMaxChars)
    return `${autoWidth} ${noWrap}`;
  if (!isFullViewLayout && len >= standardViewMaxChars)
    return `width: ${standardViewMaxChars}ch;`;
};

export const CRTrackKeyWithComments = styled(CRTrackKey)`
  position: relative;
  display: inline;
  pointer-events: none;
  &:hover {
    cursor: pointer;
  }
  &::before {
    font-family: 'Font Awesome 5 Free', sans-serif;
    content: '\f249  ';
    font-size: 1.3em;
    font-weight: normal;
    display: inline;
    width: 1.2em;
    float: left;
    pointer-events: all;
    padding: 0px 3px 0px 0px;
    transform: scale(1);
  }
  &:hover::before {
    border: 2px;
    transform: scale(1.3);
    transition: transform ease-out 150ms;
  }
  &::after {
    ${({ comment }) => `content: '${comment}'`};
    display: block;
    position: absolute;
    text-align: left;
    line-height: 2.5em;
    ${({ comment, isFullViewLayout, isMobileView }) =>
      getTooltipWidth(comment, isFullViewLayout, isMobileView)};
    background-color: var(--headerBgColor);
    padding: 0.5em 1em;
    color: var(--lightTextColor);
    border-radius: 5px;
    bottom: 100%;
    left: 35px;
    transform: scale(0);
    transition: transform ease-out 150ms;
  }
  &:hover::after {
    transform: scale(1);
    bottom: 0;
  }
`;

export const CRTrackValue = styled(TrackValue)``;

export const TrackComments = styled.div`
  padding: 5px;
  font-size: 0.5em;
`;

export const AttributeComments = styled(TrackComments)`
  padding: 0px 3px;
  font-size: 0.9em;
  color: var(--prevAttrsTextColor1);
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.6em;
  }
`;

export const Comments = styled(TrackValue)`
  text-align: left;
  padding: 0px 3px;
  font-size: 0.9em;
`;

export const TrackKeyValueContainer = styled(CRTrack)`
  flex-direction: row;
  font-size: .8em;
`;
export const Comment = styled.div`
  padding: 2px 5px;
`;
