import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { Button } from '../shared/utils/styles';

export const StyledButton = styled(Button)`
  width: ${(props) => props.width || '100%'};
  height: 30px;
  margin: 1px;
  padding: 6px 4px;
  font-size: 0.7em;
  margin: 3px 5px;
  text-transform: uppercase;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.8em;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export default StyledButton;
