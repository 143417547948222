import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: red;
  padding: 5px;
  z-index: 200;
`;

export const StatusText = styled.p`
  color: white;
  text-align: center;
  margin: 10px 0;
`;

export const ReloadText = styled.p`
  color: white;
  text-align: center;
  text-decoration: underline;
  margin: 10px 0;
  cursor: pointer;
`;

export const ErrorText = styled.p`
  color: white;
  text-align: center;
  font-size: 10px;
  margin: 5px 0 10px 0;
`;
