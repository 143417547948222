import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: none;
  box-sizing: border-box;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important; 
  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    grid-area: mprev;
    align-self: stretch;
    margin: 0px;
    background-image: url('${(props) => props.url}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #e7e7e7;
    border-right:1px solid gray;
    text-align: right;  
    width:100%;
    &:hover button {
      opacity: 0.6;
    }
  }
  @media (min-width: ${breakpoints.small}) {
    border-top: 1px solid gray;
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const CloseButton = styled.button`
  position: relative;
  text-align: right;
  padding: 5px;
  margin: 15px 15px 0px 0px;
  color: #fff;
  background-color: #000;
  opacity: 0;
  transition: 0.5s ease;
  font-size: 1.1em;
  & i {
    padding: 3px;
  }
`;
