import React from 'react';
import PropTypes from 'prop-types';
import { Image, RoundAvatar } from './Styles';

const Avatar = ({ url }) => {
  return (
    <RoundAvatar>
      <Image src={url} />
    </RoundAvatar>
  );
};

Avatar.defaultProps = {
  url: null,
};
Avatar.propTypes = {
  avatarUrl: PropTypes.string,
};

export default Avatar;
