import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import {
  useStores, useBidBlockDetails, useFocus, useTheme,
} from 'hooks';

import { LaneContext } from 'components/project/LaneWrapper';
import SellerTools from '../BidDetails/SellerTools';
import { Icon } from '../../shared';

import {
  Container,
  ItemNumber,
  FixedTrack,
  FixedTrackKey,
  FixedTrackValue,
  ImageThumbnail,
  FullTrackValue,
  SwipeWrapper,
  FixedDetailsContainer,
} from './Styles';

import {
  BidButton,
  InputButtonGroup,
  TakeButton,
  ProxyBidButton,
} from '../BidDetails/Styles';
// import NewSwipeButton from '../BidDetails/NewSwipeButton';
import {DoubleTapButton} from "../BidDetails/DoubleTapButton";

export const FixedMobileBlockDetails = ({ show, productItem }) => {
  const {
    AuctionStore: {
      connected, laneStatusMap, auctionInfo, networkConnected,
    },
    BidStore: {
      auction, user, watchList, proxies,
    },
    UIStore: {
      placeLiveBid, scrollPosition, setSellerAction, setTempSellerAction, clearSellerAction,
    },
  } = useStores();
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);
  const { object: theme, isLight } = useTheme();
  const isLightTheme = isLight('white');
  
  useEffect(() => {
    setVisible(scrollPosition > 300);
    setActive(scrollPosition > 360);
  }, [scrollPosition]);


  const { laneID } = useContext(LaneContext);
  const { allowProxyBidding } = auctionInfo;
  const isMobileView = true;

  const lotNumber = `# ${auction[laneID].lotNumber}`;

  const [takeAmountRef, setTakeAmountRefFocus] = useFocus();

  const {
    decisionStatus,
    abbrevBidderName,
    tempSellerAction,
    sellerAction,
    BidButtonLabelLogic,
    disabledButton,
    DisableTakeSubmit,
    hasProxyBid,
    highBidAmtTakeClick,
    isSeller,
    isWatched,
    message,
    myProxyBidAmount,
    myProxyBidIsActive,
    onTakesKeyUp,
    onTakesKeyPress,
    formattedReserve,
    state,
    TakesValidationMessages,
    tempTakeAmount,
    winningBidderName,
  } = useBidBlockDetails({
    allowProxyBidding,
    auctionLane: auction[laneID],
    laneID,
    laneStatusMap,
    proxies,
    setSellerAction,
    user,
    watchList,
    takeAmountRef,
    setTakeAmountRefFocus,
    networkConnected,
  });

  const BID_BLOCK_HEIGHT = '60px';
  const auctionLaneOpenForBidding = auction[laneID]?.isOpenForBidding;

  useEffect(() => {
    if (!auction[laneID]?.isOpenForBidding) {
      if (sellerAction) {
        clearSellerAction(laneID, sellerAction, false);
      }
    }
   // eslint-disable-next-line
  }, [auctionLaneOpenForBidding]);


  if (!visible) {
    return null;
  }

  return (
    <Container
      isWatched={isWatched}
      showProxy={hasProxyBid}
      isSeller={isSeller}
      initial={active ? { opacity: 1 } : { opacity: 0 }}
      animate={active ? { opacity: 1 } : { opacity: 0 }}
      themeCss={theme}
    >
      <ItemNumber>{lotNumber}</ItemNumber>
      <FixedDetailsContainer isMobileView={isMobileView} isLightTheme={isLightTheme}>
        <FixedTrack isSeller={isSeller} isWatched={isWatched} isMobileView={isMobileView} height={BID_BLOCK_HEIGHT}>
          <ImageThumbnail src={auction[laneID].productItem.primaryMediaUrl} />
        </FixedTrack>

        {!isSeller && (
        <FixedTrack isSeller={isSeller} isMobileView={isMobileView} isWatched={isWatched} height={BID_BLOCK_HEIGHT}>
          <FixedTrackKey>High Bidder</FixedTrackKey>
          <FixedTrackValue
            data-testid="high-bidder"
            color={auction[laneID].bidder === state.bidder ? 'var(--red-2)' : !isSeller && 'var(--lightTextColor)'}
            isSeller={isSeller}
          >
            <div style={{
              width: '150px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden',
            }}
            >
              {abbrevBidderName === winningBidderName ? (
                winningBidderName
              ) : (
                <span title={winningBidderName}>
                  {abbrevBidderName}
                </span>
              )}
            </div>
          </FixedTrackValue>
        </FixedTrack>
        )}
        <FixedTrack isSeller={isSeller} isMobileView={isMobileView} isWatched={isWatched} height={BID_BLOCK_HEIGHT}>
          <FixedTrackKey isMobileView={isMobileView}>High Bid</FixedTrackKey>
          <FixedTrackValue isSeller={isSeller} color={isLightTheme ? 'var(--bidBtnBgColor)' : 'var(--lightTextColor)'} data-testid="high-bid" isMobileView={isMobileView}>
            {auction[laneID].currentBid
              ? `$ ${new Intl.NumberFormat().format(
                auction[laneID].currentBid,
              )}`
              : 'N/A'}
          </FixedTrackValue>
        </FixedTrack>
        {/* SELLER SPECIFIC */}
        {isSeller && (
          <FixedTrack isSeller={isSeller} isMobileView={isMobileView} isWatched={isWatched} height={BID_BLOCK_HEIGHT}>
            <FixedTrackKey>
              {(sellerAction === 'TAKES' || tempSellerAction === 'TAKES') ? 'Takes  ' : 'Reserve'}
              {sellerAction === 'TAKES' && DisableTakeSubmit().disabled && (
                <span title={TakesValidationMessages()}>
                  <Icon icon="info-circle" size={1.1} iconOnly />
                </span>
              )}
            </FixedTrackKey>

            {(!tempSellerAction && (sellerAction?.includes('CANCEL') || !sellerAction)) && (
              <FixedTrackValue data-testid="reserve-amt" color="var(--sellerReserveTextColor)" isSeller={isSeller}>
                {formattedReserve}
              </FixedTrackValue>
            )}

            {sellerAction === 'PASS' && (
              <FixedTrackValue data-testid="pass-value" color="var(--sellerPassBgColor)">
                PASS
              </FixedTrackValue>
            )}

            {sellerAction === 'SELL' && (
              <FixedTrackValue data-testid="reserve-amt" color="var(--sellerOkToSellBgColor)">
                OKAY TO SELL
              </FixedTrackValue>
            )}

            {(tempSellerAction === 'TAKES' || sellerAction === 'TAKES') && (
              <FixedTrackValue data-testid="reserve-amt" color="var(--sellerReserveTextColor)">
                {message ? (
                  `$ ${new Intl.NumberFormat().format(highBidAmtTakeClick)}`
                ) : (
                  <InputButtonGroup showInput>
                    <input
                      style={DisableTakeSubmit().disabled ? { border: '1px solid var(--sellerPassBgColor)' } : null}
                      ref={takeAmountRef}
                      autoComplete="off"
                      tabIndex={-1}
                      type="text"
                      disabled={false}
                      value={tempTakeAmount}
                      onChange={(e) => setTempSellerAction(laneID, 'TAKES', e.target.value)}
                      maxLength={10}
                      onKeyUp={onTakesKeyUp}
                      onKeyPress={onTakesKeyPress}
                    />
                    <TakeButton
                      disabled={DisableTakeSubmit().disabled}
                      className="primary"
                      onClick={() => setSellerAction(
                        laneID,
                        'TAKES',
                        `OK TO SELL @ $${new Intl.NumberFormat().format(
                          tempTakeAmount,
                        )}`,
                        tempTakeAmount,
                      )}
                    >
                      OK
                    </TakeButton>

                    <TakeButton
                      className="primary"
                      onClick={() => {
                        setSellerAction(
                          laneID,
                          'TAKES',
                          null,
                          highBidAmtTakeClick,
                          '',
                        );
                        setTakeAmountRefFocus();
                      }}
                    >
                      X
                    </TakeButton>
                  </InputButtonGroup>
                )}
              </FixedTrackValue>
            )}
          </FixedTrack>
        )}
        {/* //SELLER SPECIFIC */}
      </FixedDetailsContainer>

      {!isSeller && decisionStatus?.message
          && (
          <FullTrackValue
            data-testid="bidder-status"
            isMobileView={isMobileView}
            bgColor={decisionStatus.bgColor}
            color={decisionStatus.color}
          >
            {decisionStatus?.message}
          </FullTrackValue>
          )}

      {connected
        && !isSeller
        && (myProxyBidIsActive ? (
          <ProxyBidButton data-testid="proxybid-btn" warning>
            {`MY PROXY $ ${new Intl.NumberFormat().format(myProxyBidAmount)}`}
          </ProxyBidButton>
        ) : isMobileView ? (
          <SwipeWrapper>
            {/*<NewSwipeButton*/}
            {/*    key={Math.random()}*/}
            {/*  action={() => placeLiveBid(*/}
            {/*    laneID,*/}
            {/*    auction[laneID].syncID,*/}
            {/*    auction[laneID].nextBid,*/}
            {/*  )}*/}
            {/*  disabled={disabledButton}*/}
            {/*  text={BidButtonLabelLogic()}*/}
            {/*/>*/}
            <DoubleTapButton
                action={() => placeLiveBid(
                    laneID,
                    auction[laneID].syncID,
                    auction[laneID].nextBid,
                )}
                disabled={disabledButton}
                text={BidButtonLabelLogic()}
                bidPanel
            />
          </SwipeWrapper>
        ) : (
          <BidButton
            data-testid="bid-btn"
            disabled={disabledButton}
            success
            large
            onClick={() => placeLiveBid(
              laneID,
              auction[laneID].syncID,
              auction[laneID].nextBid,
            )}
          >
            {BidButtonLabelLogic()}
          </BidButton>
        ))}

      {connected && isSeller && <SellerTools />}
    </Container>
  );
};

FixedMobileBlockDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  productItem: PropTypes.any,
};

FixedMobileBlockDetails.defaultProps = {
  productItem: null,
};

export default observer(FixedMobileBlockDetails);
