import {
  action,
  configure,
  decorate,
  extendObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  getFormattedMilitaryTime,
} from '../shared/utils/dateTime';

configure({ enforceActions: 'always' });

class MessageStore {
  constructor() {
    this.initialize();
  }

  initialize = () => {
    this.audioPlaying = true;
    this.directMessages = {};
    this.directMessagesMap = new Map();
    this.error = null;
    this.globalPinnedMessage = null;
    this.laneActivity = {};
    this.lanePinnedMessage = new Map();
    this.laneTempPinnedMessage = new Map();
    this.openChatWindow = [];
    this.laneErrorMessage = new Map();
    this.newMessages = {};
    this.unreadMessages = {};
    this.readMessages = {};
    this.connectionStatus = null;
  };

  setLaneErrorMessage = (laneID, data) => {
    try {
      this.laneErrorMessage.set(laneID, data);
    } catch (error) {
      console.error('setLaneErrorMessage', {
        error, laneID, data,
      });
    }
  };

  clearLaneErrorMessage = (laneID) => {
    try {
      this.laneErrorMessage.delete(laneID);
    } catch (error) {
      console.error('clearLaneErrorMessage', { error, laneID });
    }
  };

  addOpenChatProperty = (laneID) => {
    try {
      if (this.openChatWindow.includes(laneID)) {
        return;
      }

      this.openChatWindow.push(laneID);
    } catch (error) {
      console.error('addOpenChatProperty', { error, laneID });
    }
  };

  setOpenChatProperty = (laneID, value) => {
    try {
      if (value) {
        this.addOpenChatProperty(laneID);
      } else {
        this.openChatWindow = this.openChatWindow.filter(
          (lane) => lane !== laneID,
        );
      }
    } catch (error) {
      console.error('setOpenChatProperty', {
        error,
        laneID,
        value,
        openChatWindow: this.openChatWindow,
      });
    }
  };

  unreadMessagesExists = (laneID) => this.unreadMessages.hasOwnProperty(laneID);

  addUnreadMessageProperty = (laneID) => {
    try {
      if (this.unreadMessagesExists(laneID)) {
        return;
      }

      extendObservable(this.unreadMessages, {
        [laneID]: 0,
      });
    } catch (error) {
      console.error('addUnreadMessageProperty', { error, laneID });
    }
  };

  setUnreadMessageProperty = (laneID, value) => {
    try {
      this.addUnreadMessageProperty(laneID);
      runInAction(() => {
        this.unreadMessages[laneID] = value;
      });
    } catch (error) {
      console.error('setUnreadMessageProperty', {
        error,
        laneID,
        value,
        unreadMessages: this.unreadMessages,
        unreadMessagesForLane: this.unreadMessages[laneID],
      });
    }
  };

  readMessagesExists = (laneID) => this.readMessages.hasOwnProperty(laneID);

  addReadMessageProperty = (laneID) => {
    try {
      if (this.readMessagesExists(laneID)) {
        return;
      }

      extendObservable(this.readMessages, {
        [laneID]: 0,
      });
    } catch (error) {
      console.error('addReadMessageProperty', { error, laneID });
    }
  };

  setReadMessageProperty = (laneID, value) => {
    try {
      this.addReadMessageProperty(laneID);
      runInAction(() => {
        this.readMessages[laneID] = value;
      });
    } catch (error) {
      console.error('setReadMessageProperty', {
        error,
        laneID,
        value,
        readMessages: this.readMessages,
        readMessagesForLane: this.readMessages[laneID],
      });
    }
  };


  newMessagesExists = (laneID) => this.newMessages.hasOwnProperty(laneID);

  addNewMessageProperty = (laneID) => {
    try {
      if (this.newMessagesExists(laneID)) {
        return;
      }

      extendObservable(this.newMessages, {
        [laneID]: 0,
      });
    } catch (error) {
      console.error('addNewMessageProperty', { error, laneID });
    }
  };

  setNewMessageProperty = (laneID, value) => {
    try {
      this.addNewMessageProperty(laneID);
      runInAction(() => {
        this.newMessages[laneID] = value;
      });
    } catch (error) {
      console.error('setNewMessageProperty', {
        error,
        laneID,
        value,
        newMessages: this.newMessages,
        newMessagesForLane: this.newMessages[laneID],
      });
    }
  };


  insertLaneActivity = (laneID, payload) => {
    try {
      const newItem = {
        id: new Date().getUTCMilliseconds(),
        message: payload.message,
        lotNumber: payload.lotNumber,
        name: payload.clerkName ? payload.clerkName : 'You',
        fromClerk: !!payload.clerkName,
      };

      extendObservable(this.laneActivity, {
        [laneID]: { messageLog: [] },
      });

      const laneActivity = this.laneActivity[laneID];
      if (laneActivity?.hasOwnProperty('messageLog')) {
        laneActivity.messageLog.push(newItem);
      } else {
        laneActivity.messageLog = [newItem];
      }
    } catch (error) {
      console.error('insertLaneActivity', { error, laneID, payload });
    }
  };

  deleteAuctionLaneMessages = (laneID) => {
    try {
      this.directMessagesMap.delete(laneID);
    } catch (error) {
      console.error('deleteAuctionLaneMessages', { error, laneID });
    }
  };

  insertDirectMessageMap = (laneID, payload) => {
    try {
      const getMessageType = (lot, member) => {
        if (lot || member) return 'indiv';
        return 'global';
      }
      const newItem = {
        id: new Date().getUTCMilliseconds(),
        message: payload.message,
        lotNumber: payload.lotNumber,
        timestamp: getFormattedMilitaryTime(new Date()),
        name: payload.clerkName ? payload.clerkName : 'You',
        fromClerk: !!payload.clerkName,
        type: getMessageType(payload.lotNumber, payload.member),
      };
      if (this.directMessagesMap.has(laneID)) {
        const msgs = Array.from(
          this.directMessagesMap.get(laneID)?.values() || [],
        );
        msgs.push(newItem);
        this.directMessagesMap.set(laneID, msgs);
        const unread = this.unreadMessages[laneID] ? this.unreadMessages[laneID] + 1 : 1;
        this.setUnreadMessageProperty(laneID, unread);
      } else {
        this.directMessagesMap.set(laneID, [newItem]);
        const unread = this.unreadMessages[laneID] ? this.unreadMessages[laneID] + 1 : 1;
        this.setUnreadMessageProperty(laneID, unread);
      }
    } catch (error) {
      console.error('insertDirectMessageMap', { error, laneID, payload });
    }
  };

  clearLanePinMessage = (laneID) => {
    try {
      this.lanePinnedMessage.delete(laneID);
    } catch (error) {
      console.error('clearLanePinMessage', { error, laneID });
    }
  };

  setLanePinMessage = (laneID, message) => {
    try {
      this.lanePinnedMessage.set(laneID, message);
    } catch (error) {
      console.error('setLanePinMessage', { error, laneID, message });
    }
  };

  setLaneTempPinMessage = (laneID, message) => {
    try {
      this.laneTempPinnedMessage.set(laneID, message);
    } catch (error) {
      console.error('setLaneTempPinMessage', { error, laneID, message });
    }
  };

  clearLaneTempPinMessage = (laneID) => {
    try {
      this.laneTempPinnedMessage.delete(laneID);
    } catch (error) {
      console.error('clearLaneTempPinMessage', { error, laneID });
    }
  };

  setGlobalPinnedMessage = (message) => {
    this.globalPinnedMessage = message;
  };

  clearGlobalPinnedMessage = () => {
    this.globalPinnedMessage = null;
  };

  handleError = (error, customMessage = null) => {
    try {
      runInAction(() => {
        console.error('handleError > try block', { error, customMessage });
        this.error = customMessage || error.message;


        setTimeout(function clearError() {
          this.error = null;
        }, 5000);
      });
    } catch (err) {
      console.error('handleError', {
        err,
        errorPassedIn: error,
        customMessage,
      });
    }
  };

  handleConnectionError = (value) => {
    this.connectionStatus = value;
  };
}

decorate(MessageStore, {
  clearGlobalPinnedMessage: action,
  clearLanePinMessage: action,
  clearLaneTempPinMessage: action,
  clearLaneErrorMessage: action,
  connectionStatus: observable,
  deleteAuctionLaneMessages: action,
  directMessages: observable,
  directMessagesMap: observable,
  error: observable,
  globalPinnedMessage: observable,
  handleConnectionError: action,
  handleError: action,
  initialize: action,
  insertDirectMessageMap: action,
  insertLaneActivity: action,
  insertLaneMessage: action,
  insertMessage: action,
  laneActivity: observable,
  lanePinnedMessage: observable,
  laneTempPinnedMessage: observable,
  messageLog: observable,
  open: observable,
  openChatWindow: observable,
  setAudioState: action,
  setGlobalPinnedMessage: action,
  setLanePinMessage: action,
  setLaneTempPinMessage: action,
  setOpenChatProperty: action,
  setLaneErrorMessage: action,
  setUnreadMessageProperty: action,
  setReadMessageProperty: action,
  laneErrorMessage: observable,
  unreadMessages: observable,
  readMessages: observable,
  setNewMessageProperty: action,
  newMessages: observable,
});

export default MessageStore;
