import React from 'react';

import loading from 'assets/images/loading.gif';
import { LoadingImage, Wrapper } from './Styles';

const LoadingModal = () => (
  <Wrapper>
    <LoadingImage src={loading} />
  </Wrapper>
);

export default LoadingModal;
