import {
  ENDPOINT_TYPE,
  DEV_WS_URL,
  DEV_API_URL,
  ROUTES,
} from 'shared/constants';

export const getWindowGlobalEndpoint = (endpointType) => window?.globalConfig[endpointType];

export const getHost = ({ globalHost, endpointType }) => {
  if (globalHost) return globalHost;
  if (endpointType === ENDPOINT_TYPE.WebSocket) return DEV_WS_URL;
  if (endpointType === ENDPOINT_TYPE.Api) return DEV_API_URL;
  throw new Error('Invalid endpoint type');
};

export const getWsHost = () => getHost({
  globalHost: getWindowGlobalEndpoint(ENDPOINT_TYPE.WebSocket),
  endpointType: ENDPOINT_TYPE.WebSocket,
});

export const getApiHost = () => getHost({
  globalHost: getWindowGlobalEndpoint(ENDPOINT_TYPE.Api),
  endpointType: ENDPOINT_TYPE.Api,
});

export const getProductPreviewRoute = (laneID, lotNumber) => ROUTES.prodPreview
  .replace(':laneID', laneID)
  .replace(':lotNumber', lotNumber);
