import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const StyledItem = styled.a`
  margin: 0px 5px;
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  font-size: 0.6em;
  &:hover {
    cursor: pointer;
  }
  &.expand-collapse-lane {
    display: none;
  }
  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) => `${props.size || 1}em`};
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    &.expand-collapse-lane {
      display: inline;
    }
  }
`;

export default StyledItem;
