import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive, usePromo, useSeller } from 'hooks';
import { LaneContext } from '../../project/LaneWrapper';
import {
  BidderBar,
  BidDetails,
  Chat,
  FixedMobileBlockDetails,
  ItemAttributes,
  LaneHeader,
  LaneMessage,
  LaneStop,
  MediaGallery,
  Player,
  ProductPreview,
  Promo,
  SellerDetails,
  VerticalList,
} from '../../project';

import { Layout, CarouselWrapper, ChatLaneStopContainer } from './Styles';
import { abbrevStringValue } from '../../project/shared/utils/common';
import {
  MAX_AUCTION_LANE_AND_PRODUCT_MOBILE_LEN,
  MAX_AUCTION_LANE_LEN,
  DEFAULT_LAYOUT_VIEW,
} from '../../../shared/constants/index';
import BottomMobileNav from 'components/project/BidPanel/BottomMobileNav';
import Analytics from '../../../shared/utils/analytics';

const MiniDefault = observer(({ auction }) => {
  const {
    AuctionStore: {
      auctionName,
      fontSize,
      isNative,
      laneStatusMap,
      layoutViewType,
      isFullViewLayout,
    },
    BidStore: { setAuctionProperty },
    MessageStore: {
      openChatWindow,
      lanePinnedMessage,
      laneTempPinnedMessage,
      laneErrorMessage,
      unreadMessages,
    },
    UIStore: {
      handleExitLane,
      handleSetLaneAudio,
      hasPinnedNav: isPinnedMenu,
      setLayoutViewType,
      setScrollPosition,
    },
  } = useStores();

  const { isMobileView, isSmallView, isTabletView, isLargeView } =
    useResponsive(layoutViewType);

  const productItem = auction?.productItem;
  const { productName } = productItem;
  const { laneID } = useContext(LaneContext);
  const showLane = true;
  const showSeller = false;
  const showWatch = false;
  const { promo, handlePromoClick } = usePromo(productItem?.attributes);
  const { showRunList, showProdPreview, sellerProducts } = auction;

  const { hasSellerProducts } = useSeller({
    sellerProducts,
    lotNumber: productItem.lotNumber,
  });

  const setProductPreview = (value) => {
    if (!value) setAuctionProperty(laneID, 'previewLotNumber', null);
    setAuctionProperty(laneID, 'showProdPreview', value);
  };

  const getLaneErrorMessage = (laneId) => {
    const laneMessage = laneErrorMessage.get(laneId);
    if (laneMessage?.type === -1) return laneMessage.message.toString();
    return null;
  };

  const laneMessageError = getLaneErrorMessage(laneID);
  const openChatUI = openChatWindow.includes(laneID);
  const pinMessage =
    laneMessageError ||
    laneTempPinnedMessage.get(laneID) ||
    lanePinnedMessage.get(laneID);
  const showPin = !!pinMessage;

  const { laneStatusName } = laneStatusMap.get(laneID);
  const isNotStopped = laneStatusName !== 'Ended';

  // if for some reason, the length is longer, let's trim the name
  // (not expecting the name to be 100 chars long) - right now we'll just trim vs. adding ...
  const trimStringForNonMiniViewAfterThisManyMore = 60;

  const doNotAbbrev =
    (!isMobileView && !isTabletView && !isSmallView) ||
    (isLargeView &&
      (layoutViewType === DEFAULT_LAYOUT_VIEW || isFullViewLayout));

  const auctionAndLane = abbrevStringValue(
    `${auction.laneName} | ${auction.auctionName}`,
    isMobileView
      ? MAX_AUCTION_LANE_AND_PRODUCT_MOBILE_LEN
      : MAX_AUCTION_LANE_LEN,
    doNotAbbrev,
  ).substring(
    0,
    MAX_AUCTION_LANE_LEN + trimStringForNonMiniViewAfterThisManyMore,
  );

  const productNameFormatted = abbrevStringValue(
    productName,
    isMobileView
      ? MAX_AUCTION_LANE_AND_PRODUCT_MOBILE_LEN
      : MAX_AUCTION_LANE_LEN,
    doNotAbbrev,
  ).substring(
    0,
    MAX_AUCTION_LANE_LEN + trimStringForNonMiniViewAfterThisManyMore,
  );

  useEffect(() => {
    if (!showRunList) {
      setProductPreview(false);
    }
    // eslint-disable-next-line
  }, [showRunList]);

  useEffect(() => {
    setProductPreview(showProdPreview && showRunList);
    // eslint-disable-next-line
  }, [showProdPreview]);

  useEffect(() => {
    if (isMobileView && !isSmallView) setLayoutViewType(DEFAULT_LAYOUT_VIEW);
    // eslint-disable-next-line
  }, [isMobileView, isSmallView]);

  return (
    <Layout
      key={`${auction[laneID]}_${laneMessageError}`}
      showLane={showLane}
      laneClosed={
        !productItem.productName || (productItem.productName && !isNotStopped)
      }
      isPinnedMenu={isSmallView && isPinnedMenu}
      showPin={showPin}
      showPromo
      hasPlayerError={false}
      showZoom={false}
      showRunList={showRunList}
      showProdPreview={showProdPreview}
      showChat={openChatUI}
      fontSize={fontSize}
      isFullViewLayout={isFullViewLayout}
      isNative={isNative}
    >
      <LaneHeader
        auctionAndLane={auctionAndLane}
        productName={productNameFormatted}
        lotNumber={productItem.lotNumber}
        audioPlaying={auction.audioPlaying}
        setAudioState={handleSetLaneAudio}
        isNative={isNative}
        isMobileView={isMobileView}
        auctionName={auctionName}
        laneName={auction.laneName}
        handleCloseLane={() => {
          handleExitLane(laneID);
        }}
        laneID={laneID}
        isFullViewLayout={isFullViewLayout}
      />

      {showLane && (
        <>
          <Player auctionLane={auction} />
          {showPin && (
            <LaneMessage
              key={Math.random()}
              message={
                laneMessageError ||
                laneTempPinnedMessage.get(laneID) ||
                lanePinnedMessage.get(laneID)
              }
              bgColor={
                laneMessageError
                  ? 'var(--errorMsgBgColor)'
                  : 'var(--pinMsgBgColor)'
              }
              color={
                laneMessageError
                  ? 'var(--errorMsgTextColor)'
                  : 'var(--pinMsgTextColor)'
              }
              icon={laneMessageError ? 'exclamation-triangle' : 'info-circle'}
            />
          )}
          <BidderBar
            lotNumber={laneStatusName !== 'Ended' ? productItem.lotNumber : ''}
            laneID={laneID}
            mediaRoomID={auction.mediaRoomID}
            audioPlaying={auction.audioPlaying}
            isSeller={hasSellerProducts}
          />

          {(!productItem.productName ||
            (productItem.productName && !isNotStopped)) &&
            (openChatUI && !isMobileView ? (
              <ChatLaneStopContainer>
                <Chat key={laneID} laneID={laneID} openChatUI={openChatUI} />
              </ChatLaneStopContainer>
            ) : (
              <LaneStop
                key={laneStatusName}
                status={productItem.error ? 'error' : laneStatusName}
                isFullViewLayout={isFullViewLayout}
              />
            ))}

          {productItem.productName && isNotStopped && (
            <CarouselWrapper className="carousel-container">
              {productItem.productName &&
                isNotStopped &&
                (isFullViewLayout || isMobileView) &&
                (!openChatUI || isMobileView) && (
                  <Promo
                    promo={promo}
                    handlePromoClick={handlePromoClick}
                    overlayImage={isFullViewLayout || isMobileView}
                    isMobileView={isMobileView}
                  />
                )}
              {!isMobileView && (
                <Chat key={laneID} laneID={laneID} openChatUI={openChatUI} />
              )}
              {(!openChatUI || isMobileView) && (
                <MediaGallery
                  key={`${productItem.lotNumber}_${
                    isMobileView ? 'mobile' : 'prodpreview'
                  }_${isFullViewLayout ? 'singleView' : 'defaultView'}`}
                  data={productItem}
                  view={isMobileView ? 'mobile' : null}
                />
              )}
            </CarouselWrapper>
          )}

          {productItem.productName &&
            isNotStopped &&
            !isFullViewLayout &&
            !isMobileView &&
            !openChatUI && (
              <Promo
                promo={promo}
                handlePromoClick={handlePromoClick}
                overlayImage={false}
              />
            )}
          {((productItem.productName && isNotStopped && !isFullViewLayout) ||
            isFullViewLayout) &&
            (showSeller ? (
              <SellerDetails showWatch={showWatch} />
            ) : (
              <BidDetails />
            ))}
          <FixedMobileBlockDetails show productItem={productItem} />
          {((productItem.productName && isNotStopped && !isFullViewLayout) ||
            isFullViewLayout) && (
            <ItemAttributes
              data={productItem}
              isFullViewLayout={isFullViewLayout}
            />
          )}

          {!isMobileView && showRunList && (
            <VerticalList
              key={`filterType:${auction.runListFilter.filterType}_filterValue:${auction.runListFilter.filterValue}_productState:${auction[laneID]?.productState}_lotNumber:${auction[laneID]?.lotNumber}`}
              laneId={laneID}
              onClick={(lotNumber, productID) => {
                setProductPreview(true);
                setAuctionProperty(laneID, 'previewLotNumber', lotNumber);
                setAuctionProperty(laneID, 'previewProductID', productID);

                Analytics.RecordEvent(
                  'Run List Product Click',
                  'Preview',
                  'Lot Number:',
                  lotNumber,
                );
              }}
            />
          )}
          {showProdPreview && (
            <ProductPreview
              mediaHoverEffect={false}
              mediaWrapWidth={isMobileView ? '100vw' : '200px'}
              mediaWrapHeight={isMobileView ? '320px' : '150px'}
              handleClosePreview={() => {
                setProductPreview(false);
                setScrollPosition(0);
              }}
            />
          )}
        </>
      )}

      {isMobileView && (
        <BottomMobileNav
          laneID={laneID}
          key={unreadMessages}
          unreadMessages={unreadMessages}
        />
      )}
    </Layout>
  );
});

MiniDefault.propTypes = {
  auction: PropTypes.object.isRequired,
};

export default MiniDefault;
