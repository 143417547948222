import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const showInputCss = css`
  & select {
    height: 30px;
    width: 100%;
    max-width: 100%;
    font-size: 0.6em;
  }
  @media (min-width: ${breakpoints.large}) {
    & select {
      width: 200px;
      max-width: 200px;
    }
  }
`;

const hideInputCss = css`
  & select {
    height: 30px;
    width: 100%;
    max-width: 100%;
    font-size: 0.6em;
  }
  @media (min-width: ${breakpoints.large}) {
    & select {
      width: 80%;
      max-width: 80%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  background: var(--subHeaderBgColor);
  color: var(--subHeaderTextColor);
  text-transform: none;
  font-size: 0.8em;
  width: 100%;
  ${(props) => (props.showInputCss ? showInputCss : hideInputCss)}
  & select {
      ${({ filterType }) => filterType === 'upcoming' && 'max-width: 100%'};
      ${({ filterType }) => filterType === 'upcoming' && 'width: 100%'};
      @media (min-width: ${breakpoints.large}) {
        ${({ filterType }) => filterType === 'upcoming' && 'max-width: 350px'};
        ${({ filterType }) => filterType === 'upcoming' && 'width: 350px'};
        ${({ isFullViewLayout, filterType }) => (isFullViewLayout && filterType === 'upcoming' && 'max-width: 400px')};
        ${({ isFullViewLayout, filterType }) => (isFullViewLayout && filterType === 'upcoming' && 'width: 400px')};
      }
      font-size: ${({ isFullViewLayout }) => (isFullViewLayout ? '.75em' : '1em')};
    }
    & button { 
      font-size: ${({ isFullViewLayout }) => (isFullViewLayout ? '.5em' : '1em')};
    }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  padding: 0px 5px;
`;

export const FilterButton = styled.button`
  margin-left: 0px;
  margin-top: 0px;
  color: var(--utilityBtnTextColor);
  height: 30px;
  width: 30px;
  z-index: 2;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
  border-top: var(--line);
  border-right: var(--line);
  border-bottom: var(--line);
  background-color: var(--utilityBtnBgColor);
  padding: 0px;
  :disabled,
  :disabled i {
    cursor: not-allowed;
    background-color: var(--disabledBtnBgColor);
    color: var(--disabledBtnTextColor);
  }
  &:focus {
    outline: none;
  }
`;

export const InputButtonGroup = styled.div`
  display: flex;
  height: 30px;
  margin-bottom: 3px;
  padding: 0px;
  & input {
    width: ${(props) => (props.showInput ? '100px' : '0px')}; 
    padding-left: 5px;
    border: var(--line);
    border-right: none;
    border-top-left-radius: var(--radiusBox);
    border-bottom-left-radius: var(--radiusBox);
    &:focus:-webkit-placeholder {
      color: transparent;
    }
    &:focus {
      outline: none;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    & input {
      width: ${(props) => (props.showInput ? '90px' : '0px')};
      font-size: ${({ isFullViewLayout }) => (isFullViewLayout ? '.75em' : '1em')};         
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;
