import { useEffect } from 'react';

export const useNonMobile = ({
  isMobileView,
  mobileClosedLanes,
  deleteMobileClosedLane,
  handleJoinLane,
}) => {
  useEffect(() => {
    if (!isMobileView) {
      if (mobileClosedLanes.length > 0) {
        mobileClosedLanes.forEach((laneID) => {
          deleteMobileClosedLane(laneID);
          handleJoinLane(laneID);
        });
      }
    }
    return () => null;
  }, [isMobileView, mobileClosedLanes, deleteMobileClosedLane, handleJoinLane]);

  return { isMobileView };
};

export default useNonMobile;
