import React from 'react';
import { PropTypes } from 'prop-types';
import { useLoader } from 'hooks';
import laneNotStartedImage from 'assets/images/lane-not-started.png';
import laneEndedImage from 'assets/images/lane-ended.png';
import laneErrorImage from 'assets/images/lane-error.png';
import Spinner from '../../../shared/Spinner';
import { Container } from './Styles';

export const LaneStop = ({ status, isFullViewLayout }) => {
  const [isLoading] = useLoader();

  const getImage = () => {
    if (status === 'Running') return laneNotStartedImage;
    if (status === 'Ended') return laneEndedImage;
    if (status === 'error') return laneErrorImage;
    return laneNotStartedImage;
  };

  const image = getImage();
  if (isLoading) {
    return <Container isFullViewLayout={isFullViewLayout}><p style={{ textAlign: 'center' }}><Spinner size={70} color="var(--gray-6)" /></p></Container>;
  }

  return <Container image={image} isFullViewLayout={isFullViewLayout} />;
};

LaneStop.propTypes = {
  status: PropTypes.string.isRequired,
  isFullViewLayout: PropTypes.bool,
};

LaneStop.defaultProps = {
  isFullViewLayout: false,
};

export default LaneStop;
