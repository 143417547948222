import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 140px);
  position: relative;
`;

export const Content = styled.div`
  margin: 15px 20px;
  padding: 0px 10px;
  height: 100%;
  overflow: hidden scroll;
`;

export const Icon = styled.i`
  color: var(--lightTextColor);
  margin: 0 5px 0 0;
`;

export const BackWrapper = styled.div`
  background: var(--black);
  display: block;
`;

export const BackButton = styled.button``;

export const InputContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 10px 10px;
  flex-direction: row;
  display: flex;
  width: 100vw;
  background: var(--black);
  align-items: center;
  justify-content: center;
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const Input = styled.textarea`
  display: inline-block;
  margin: 0 5px 0 0;
  border: var(--line);
  flex-grow: 1;
  font-size: 1rem;
  box-sizing: border-box;
`;

export const SendButton = styled.button`
  display: inline-block;
  background: var(--sendBtnBgColor);
  font-size: 0.7rem;
  margin: 0 20px 0 0;
  align-self: center;
  font-size: 1rem;
  box-sizing: border-box;
  &:disabled {
    background: var(--cancelBtnBgColor);
    cursor: not-allowed;
  }
`;
