import React, { createContext, useContext, useState } from 'react';
import themes from '../assets/theme';
import { lightOrDark } from '../components/project/shared/utils/styles';
import { toBoolean } from '../stores/utils';
import Analytics from 'shared/utils/analytics';

const ThemeContext = createContext({
  theme: '',
  updateTheme: () => {},
  object: null,
  themeIsLight: null,
  isLight: () => {},
  toggleButtons: () => {},
});

export const ThemeProvider = ({ value, children }) => {
  const initialValue = localStorage.getItem('theme') || value || 'default';
  const showingButtonsInitialValue = toBoolean(
    localStorage.getItem('themeButtons'),
  );
  const [theme, setTheme] = useState(initialValue);
  const [showingButtons, setShowingButtons] = useState(
    showingButtonsInitialValue,
  );

  const object = themes[theme];

  const isLight = (key) => {
    try {
      return lightOrDark(object[key]) === 'light';
    } catch (e) {
      console.error('invalid theme, delete the theme key and try again');
    }
  }

  const updateTheme = (theme) => {
    setTheme(theme);
    Analytics.RecordEvent('Theme Click', 'View', 'Theme:', theme);
    localStorage.setItem('theme', theme);
  };

  const toggleButtons = () => {
    setShowingButtons(!showingButtons);
    localStorage.setItem('themeButtons', !showingButtons);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme,
        object,
        isLight,
        themeIsLight: isLight('white'),
        toggleButtons,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = (key) => useContext(ThemeContext);

export default useTheme;
