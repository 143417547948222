import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & :hover input ~ span {
    background-color: #fff;
  }
  & > input:checked ~ span {
    background-color: #2196f3;
  }
  & > input:checked ~ span:after {
    display: block;
  }
  & > span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 3px;
  &&::after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Checkbox = ({ value, text, name, checked, onChange }) => {
  return (
    <Container>
      {text}
      <input
        type="checkbox"
        checked={checked}
        value={value}
        name={name}
        onChange={onChange}
      />
      <Checkmark />
    </Container>
  );
};

// Checkbox.defaultProps = {};
// Checkbox.propTypes = {};

export default Checkbox;
