import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './Styles';

const Logo = ({ url, alt, width }) => {
  return (
    <Container>
      <img src={url} alt={alt} width={width} />
    </Container>
  );
};

Logo.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Logo.defaultProps = {
  width: '100%',
};

export default Logo;
