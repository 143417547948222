import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/project/shared';
import { StyledItem } from './Styles';

export const PlayerIcon = ({ onClick, playing }) => (
  <StyledItem onClick={onClick} size={1}>
    {playing ? <Icon icon="volume-up" iconOnly size={1.6} /> : <Icon iconPackage="far" icon="volume-slash" iconOnly size={1.5} />}
  </StyledItem>
);

PlayerIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  playing: PropTypes.bool,
};

PlayerIcon.defaultProps = {
  playing: false,
};

export default PlayerIcon;
