import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';

export const RunListWrapper = styled.div`
  grid-area: runlist;
  margin: 0 0 30px 0;
  @media (min-width: ${breakpoints.tablet}) {
    border-bottom: var(--line);
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const LoadingImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const LoadingImage = styled.img`
  width: 20px!important;
  height: 20px!important;
  border: 0px!important;
  margin: 0!important;
  padding: 0!important;
`;

export const Header = styled.h4`
  text-align: center;
  padding: 10px;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  @media (min-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const RunListContainer = styled.div`
  /* grid-area: auction-items; */
  margin-right: 0px;
  background-color: #fff;
  align-items: center;
  overflow-y: hidden;
  font-size: 1em;
  & > div {
    padding: 0px 5px;
    &:hover {
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    & > div {
      padding: 0px;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const ContentWrapper = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    /* grid-area: media; */
    /* display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 0;
    max-width: 390px;
    border-right: var(--line);
    border-left: var(--line); */
    /* overflow: hidden; */
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

// export const VerticalRunListWrapper = styled(motion.div)`
export const VerticalRunListWrapper = styled.div`
  grid-area: runlist;
  /* margin: 0 0 30px 0; */
  @media (min-width: ${breakpoints.tablet}) {
    /* width: calc(100vw - 15px); */
    width: 475px;
    /* border-bottom: var(--line); */
  }
  @media (min-width: ${breakpoints.small}) {
    width: 475px;
  }
  @media (min-width: ${breakpoints.large}) {
    width: 385px;
    /* width: 955px; */
  }
`;

export const VerticalRunListContainer = styled.div`
  background-color: var(--white);
  margin: 0px;
  overflow-y: scroll;
  ${({ isFullViewLayout }) => (isFullViewLayout ? css`
    height: 280px;
    font-size: .75em;
  ` : css`
    font-size: 1em;
    height: 315px;
  `)}
  border: var(--line);
  & > div {
    padding: 0px 5px;
    &:hover {
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    & > div {
      padding: 0px;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const VerticalContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export const Gallery = styled.div`
  position: relative;
  margin-left: 1px;
  margin-right: 2px;
  max-width: 986px;

  @media (min-width: ${breakpoints.tablet}) {
    /* display: flex;
    justify-content: space-between;
    height: 80px;
    min-height: 80px;
    overflow-x: scroll;
    margin: 0; */
    /* overflow: hidden; */
    /* min-width: 78px; */
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const RunListItem = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  height: 75px;
  gap: 15px;
  align-items: center;
  margin: 0 25px;
  padding: 5px;
  background-color: var(--mobileRunListBgColor1);
  border-bottom: var(--line);
  border-radius: 3px;
  & img {
    width: 100%;
    height: auto;
    margin-left: 5px;
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Text = styled.p`
  text-align: left;
  margin: 5px 0;
  font-size: 0.8rem;
`;

export const TextContent = styled.div`
  margin: 0 10px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BadgeLabel = styled.div`
  margin-right: 3px;
  border: none;
  padding: 2px 4px;
  color: ${(props) => props.color};
  ${(props) => !props.noBgColor && `background-color: ${props.bgColor}`};
  font-size: ${(props) => props.size || '0.8em'};
  border-radius: 3px;
  & i {
    padding-right: 3px;
  }
`;

export const VerticalRunListItem = styled(motion.a)`
  width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  height: 65px;
  gap: 5px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: ${({index}) => index % 2 === 0 ? 'var(--runListBgColor1)' : 'var(--runListBgColor2)'};
  border-left: 5px solid ${({index}) => index % 2 === 0 ? 'var(--runListBgColor1)' : 'var(--runListBgColor2)'};
  ${({isWatched}) => isWatched && 'background-color: var(--watchingBgColor)'};
  ${({isWatched, themeCss}) => isWatched && themeCss && themeCss?.watchingBgGradientColor?.gradient};
  ${({isSeller}) => isSeller && 'background-color: var(--sellerItemBgColor)'};
  border-bottom: var(--line);
  border-radius: var(--radiusNone);
  box-sizing: border-box;
  margin: 1px 0px;
  ${({isWatched}) => isWatched && 'border-left: 5px solid var(--watchingBgColor)'};
  ${({isSeller}) => isSeller && 'border-left: 5px solid var(--sellerItemBgColor)'};
  ${({isClicked}) => isClicked && 'border: 2px solid var(--runListHoverBgColor)'};
  ${({isClicked}) => isClicked && 'border-left: 5px solid var(--runListHoverBgColor)'};  
  & img {
    width: 90%;
    height: auto;
    margin-left: 5px;
  }
  &:hover {
    cursor: pointer;
    border-left: 5px solid var(--runListHoverBgColor);
  }
  
  > div {
  ${(props) => props.isWatched && 'color: var(--watchingTextColor)'};
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const VertText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1px 0px;
  font-size: 0.7em;
`;

export const VertTextContent = styled.div`
  margin: 0 5px;
`;

export const NoResults = styled.div`
  margin-top: 30px;
  padding-top: 50px;
  text-align: center;
  & > svg {
    width: 80px;
    height: 73px;
  }
`;

export const NoResultsTitle = styled.div`
  padding-top: 30px;
  font-weight: bold;
  font-size: 1em;
`;

export const NoResultsTip = styled.div`
  padding-top: 10px;
  font-size: 1em;
`;


export const RunlistImageWrapper = styled.div`
  width: 90px;
  height: 68px;
  background-color: #ccc;
`;
