import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import AudioStreamer from 'components/shared/AudioStreamer';
import useStores from 'hooks/use-store';
import ContentWrapper from './Styles';
import { LaneContext } from '../../LaneWrapper';

const Player = ({ auctionLane }) => {
  const { laneID } = useContext(LaneContext);
  const {
    AuctionStore: { openLanes },
    UIStore: { handleSetLaneAudio },
  } = useStores();

  const {
    mediaRoomID, audioVideoUrl, mediaToken, audioPlaying, audioPaused,
  } = auctionLane;

  /**
   * By default, if have an open lane we auto start the audio.
   * If the user previously paused the audio, we disable autostart on that lane.
   *
   */
  useEffect(() => {
    setTimeout(() => {
      if (openLanes.length && laneID === openLanes[0] && !audioPlaying && !audioPaused) {
        handleSetLaneAudio(openLanes[0], !audioPlaying, false);
      }
    }, 3500);
    // eslint-disable-next-line
  }, [openLanes.length]);

  if (!auctionLane) {
    return null;
  }

  return (
    <ContentWrapper>
      <AudioStreamer
        mediaRoomID={mediaRoomID}
        audioVideoUrl={audioVideoUrl}
        mediaToken={mediaToken}
        laneID={laneID}
        setAudioState={handleSetLaneAudio}
        audioPlaying={audioPlaying}
      />
    </ContentWrapper>
  );
};

Player.propTypes = {
  auctionLane: PropTypes.object,
};

Player.defaultProps = {
  auctionLane: null,
};

export default observer(Player);
