import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  margin: 0 0 0 3px;
  padding: 3px;
  background-color: var(--shadedBgColor);
  border: var(--line);
  border-radius: var(--radiusBox);
  font-size: 0.7em;
  box-sizing: border-box;
  color: var(--darkTextColor);
  &:hover {
    border: 2px solid var(--activeLaneTextColor);
    cursor: pointer;
    & button {
      display: block;
    }
  }
  & a {
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
  ${(props) => props.disabled
    && css`
      border: 2px solid var(--activeLaneTextColor);
      background-color: var(--shadedBgColor);
      &:hover {
        border: 2px solid var(--activeLaneTextColor);
        cursor: pointer;
        & button {
          display: none;
        }
      }
    `}
`;

export const LeftLaneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: 0px;
  & div {
    padding: 0px;
    margin: 1px 0px;
  }
`;

export const RightLaneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 0px;
  & div {
    padding: 0px;
    margin: 1px 0px;
  }
`;

export const LaneTextItem = styled.div`
  ${(props) => props.color && `color: ${props.color};`};
  ${(props) => props.uppercase && 'text-transform: uppercase;'};
  ${(props) => props.bold && 'font-weight: bold;'};
  margin: 0px;
  padding: 0px;
`;

export const AudioStatus = styled.div`
  color: var(--activeLaneTextColor);

`;

export const AudioToggle = styled.div`
  display: none;
  height: 50px;
  width: 15%;
  margin: 0px 3px 3px 0;
  padding: 3px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  ${(props) => (props.laneActive ? 'cursor: pointer' : null)};
  
  @media (min-width: ${breakpoints.tablet}) {
    /* background-color: #FBCC08; */
    display: flex;
  }
  @media (min-width: ${breakpoints.small}) {

  }
  @media (min-width: ${breakpoints.large}) {
    /* background-color: magenta; */
  }
  
  
`;
