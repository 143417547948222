import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores, useDesktopRedirect } from 'hooks';
import { useHistory } from 'react-router-dom';
import {
  Content,
  Wrapper,
  BackWrapper,
  BackButton,
  Icon,
  InputContainer,
  Input,
  SendButton,
} from './styles';
import { MessageFromBidder, MessageFromClerk } from '../styles';
import { ROUTES } from '../../../../../shared/constants/index';

const MobileChat = ({ laneID }) => {
  const {
    MessageStore: { directMessagesMap },
    BidStore: { auction },
    UIStore: { sendDirectMessage },
  } = useStores();

  const history = useHistory();
  const messagesMap = Array.from(directMessagesMap.get(laneID)?.values() || []);
  const inputRef = useRef();
  const [message, setMessage] = useState('');

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    sendDirectMessage(laneID, message, auction[laneID].isSeller ? 'Seller' : 'Bidder');
    setMessage('');
    inputRef.current.focus();
  };

  const { isSmallView } = useDesktopRedirect();
  return !isSmallView ? (
    <Wrapper>
      <BackWrapper>
        <BackButton onClick={() => history.push(`${ROUTES.auction}`)}>
          <Icon className="fa fa-chevron-left icon-only" />
          Return to auction
        </BackButton>
      </BackWrapper>

      <Content>
        <h1>My Messages</h1>

        {messagesMap.length === 0 && 'You have no messages'}

        {messagesMap.map((item) => (item.fromClerk ? (
          <MessageFromClerk key={item.id}>{item.message}</MessageFromClerk>
        ) : (
          <MessageFromBidder key={item.id}>{item.message}</MessageFromBidder>
        )))}
      </Content>

      <InputContainer>
        <Input ref={inputRef} onChange={onMessageChange} value={message} />
        <SendButton disabled={!message} onClick={sendMessage}>
          Send
        </SendButton>
      </InputContainer>
    </Wrapper>
  ) : null;
};

MobileChat.propTypes = {
  laneID: PropTypes.string.isRequired,
};

export default observer(MobileChat);
