import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from 'components/project/shared';
import { Icon } from '../../shared';
import {
  TitleWrapper,
  AudioPlayingWrapper,
  ContentWrapper,
  Row,
  ButtonGroup,
  DarkBgIcon,
  LotWrapper,
  LotNumber,
  AuctionLaneTitle,
  BackButton,
  BackWrapper,
  LaneTitleContainer,
} from './Styles';

const LaneHeader = ({
  auctionName,
  productName,
  laneName,
  auctionAndLane,
  lotNumber,
  audioPlaying,
  setAudioState,
  isMobileView,
  handleCloseLane,
  laneID,
  isFullViewLayout,
}) => {
  const history = useHistory();

  const soundColor = 'var(--lightTextColor)';
  return (
    <ContentWrapper
      isFullViewLayout={isFullViewLayout}
      isMobileView={isMobileView}
    >
      <Row>
        <TitleWrapper>
          <AuctionLaneTitle>{auctionAndLane}</AuctionLaneTitle>
          {isMobileView && <AuctionLaneTitle>{productName}</AuctionLaneTitle>}
        </TitleWrapper>
        {isMobileView ? (
          <ButtonGroup>
            <DarkBgIcon
              audioPlaying={audioPlaying}
              onClick={() => {
                setAudioState(laneID, !audioPlaying, true);
              }}
              color={soundColor}
            >
              {audioPlaying ? (
                <AudioPlayingWrapper>
                  <Icon icon="volume-up" iconOnly />
                </AudioPlayingWrapper>
              ) : (
                <Icon iconPackage="far" icon="volume-slash" iconOnly />
              )}
            </DarkBgIcon>
          </ButtonGroup>
        ) : (
          <>
            <ConfirmModal
              title="Confirm Exit Lane"
              message={`Are you sure you would like to exit the '${laneName}' lane?`}
              confirmText="Exit Lane"
              onConfirm={handleCloseLane}
              renderLink={(modal) => (
                <DarkBgIcon
                  onClick={modal.open}
                  size={0.9}
                  title={`Exit ${auctionAndLane}`}
                >
                  <span
                    style={{
                      width: '60px',
                      fontSize: '.9em',
                      padding: '0px',
                      marginRight: '0px',
                      wrap: 'nowrap',
                    }}
                  >
                    Exit Lane
                  </span>
                  <Icon icon="times" iconOnly size={1} />
                </DarkBgIcon>
              )}
            />
          </>
        )}
        {/* <ButtonGroup>
            {!isMobileView && (
              <DarkBgIcon
                onClick={handleCloseLane}
                size={0.9}
                title={`Exit ${auctionAndLane}`}
              >
                <span style={{ fontSize: '.9em' }}>Exit Lane</span>
                {' '}
                <Icon icon="times" iconOnly size={0.9} />
              </DarkBgIcon>
            )}
          </ButtonGroup> */}
      </Row>

      {lotNumber && (
        <Row>
          <LotWrapper>
            <LotNumber>{`#${lotNumber}`}</LotNumber>
          </LotWrapper>
        </Row>
      )}
    </ContentWrapper>
  );
};

LaneHeader.propTypes = {
  auctionName: PropTypes.string,
  laneName: PropTypes.string,
  auctionAndLane: PropTypes.string,
  lotNumber: PropTypes.string,
  audioPlaying: PropTypes.bool,
  setAudioState: PropTypes.func.isRequired,
  isNative: PropTypes.bool,
  isMobileView: PropTypes.bool,
  handleCloseLane: PropTypes.func,
  laneID: PropTypes.string,
};

LaneHeader.defaultProps = {
  auctionName: '',
  laneName: '',
  auctionAndLane: '',
  lotNumber: null,
  audioPlaying: false,
  isNative: false,
  isMobileView: false,
  handleCloseLane: () => null,
  laneID: null,
};

export default LaneHeader;
