import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: var(--myProxyBgColor);
  margin: 10px 20px 0 20px;
  box-sizing: border-box;
  z-index: 30;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: bid;
    flex-direction: column;
    border-right: var(--line);
    border-left: var(--line);
    ${(props) => props.showWatch && 'border: 2px solid var(--noSaleMsgBgColor)'};
    padding: 0px;
    margin: 0px;
  }
  @media (min-width: ${breakpoints.small}) {
    border-right: var(--line);
    ${(props) => props.showWatch && 'border: 2px solid var(--noSaleMsgBgColor)'};
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  padding: 5px;
  font-size: 0.9em;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.7em;
    text-align: left;
    padding: 0;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Track = styled.div`
  display: flex;
  padding: 5px 10px;
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const TrackKey = styled.div`
  padding: 0px;
  width: 50%;
  text-align: left;
  font-weight: bold;
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const TrackValue = styled.div`
  padding: 0px;
  width: 50%;
  text-align: right;
  font-weight: bold;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

const backgroundColor = (state) => {
  if (state.danger) {
    return 'var(--noSaleMsgBgColor)';
  }

  if (state.success) {
    return 'var(--bidBtnBgColor)';
  }

  return null;
};

export const OkayToSellButton = styled.button`
  position: fixed;
  bottom: 0;
  z-index: 100;
  @media (max-width: ${breakpoints.tablet}) {
    -webkit-box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
    box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
  }
  left: 0;
  right: 0;
  width: 100vw;
  padding: 15px 0;
  line-height: 1.5em;
  outline: 0;
  border-radius: var(--radiusNone);
  background-color: ${(props) => backgroundColor(props)};
  color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
  & i {
    color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
  }
  &:disabled {
    background-color: var(--disabledBtnBgColor);
    cursor: not-allowed;
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: inline-block;
    position: relative;
    align-self: center;
    min-width: 50%;
    height: 2em;
    width: 70%;
    margin: 0px 0px 10px 0px;
    background: none;
    border: none;
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${(props) => backgroundColor(props)};
    color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    & i {
      color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    }
    line-height: 1.2em;
    font-size: 1em;
    font-weight: bold;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

// export const LaneIDentifier = styled.div`
//   display: none;
// `;

export const AcceptanceAmtInput = styled.input`
  /* width: 50px;
  font-size: 0.9em;
  line-height: 0.8em; */
  font-size: 1.2em;
  line-height: 1.5em;
  color: var(--bidBtnBgColor);
  width: 60%;
  text-align: right;
  font-weight: bold;
`;

export const SellButton = styled.button`
  margin: 0px 2px;
  @media (min-width: ${breakpoints.tablet}) {
    background: none;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: var(--radiusBox);
    background-color: ${(props) => backgroundColor(props)};
    color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    & i {
      color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor' : 'var(--darkTextColor)')};
    }
    line-height: 0.8em;
    font-size: 0.8em;
    font-weight: bold;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

/** New Layout */

export const HighBidWrapper = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  margin-left: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const HighBidLabel = styled.p`
  margin: 0;
`;

export const HighBid = styled.p`
  font-size: 2.2rem;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  font-weight: bold;
  color: var(--bidBtnBgColor);
  margin: 0;
`;

export const NoBids = styled.p`
  font-size: 1.6rem;
  color: var(--bidBtnBgColor);
  margin: 0;
`;

export const Header = styled.h4`
  text-align: center;
  padding: 10px;
  margin: 0;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  font-size: 1.1rem;
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;
