import styled from 'styled-components';

export const StyledSelect = styled.select`
  border: 1 solid gray;
  border-radius: 3px;
  height: 2em;
  width: 100%;
  font-size: 1.1em;
`;

export default StyledSelect;
