import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../shared';
import { StyledItem, StyledBadge } from './styles';

export const ChatIcon = ({ count, onClick }) => {
  const hasUnreadMessages = count && count > 0;
  return (
    <StyledItem onClick={onClick} badge={count}>
      <Icon icon="comment" iconOnly>
        {hasUnreadMessages ? <StyledBadge>{count}</StyledBadge> : null}
      </Icon>
    </StyledItem>
  );
};

ChatIcon.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

ChatIcon.defaultProps = {
  count: 0,
};

export default ChatIcon;
