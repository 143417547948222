import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';

export const BodyHeader = styled.div`
  grid-area: header;
  display: grid;
  text-align: center;
`;

export const BodyFooter = styled.div`
  grid-area: footer;
  background-color: #FBCC08;
  display: grid;
  margin: 15px;
  position: fixed;
  bottom: 0;
`;

export const ChatContainer = styled.div`
  grid-area: chat;
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    position: fixed;
    z-index: 30;
    bottom: 0px;
    right: 15px;
    width: 100%;
  }
`;

export const LayoutButtons = styled.div`
  grid-area: laybtns;
  padding-left: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  & div:first-child {
    padding: 0px;
    font-size: 1em;
    font-weight: bold;
  }
`;

export const Content = styled(motion.div)`
  grid-area: content;
  width: 100%;

  /* background-color: cyan; */
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

    /* margin: 20px; */
    border-radius: 5px;
    /* width: calc(100vw - 145px); */
    /* margin: 15px 0px 0px 20px; */
    margin: 0px;
  }
  @media (min-width: ${breakpoints.small}) {
    /* background-color: rgba(174, 152, 0, 0.3); */
    width: calc(100vw - ${(props) => (props.isPinned ? '390px' : '90px')});
  }

  @media (min-width: ${breakpoints.medium}) {
    width: calc(100vw - 145px);
  }
  @media (min-width: ${breakpoints.large}) {
    /* width: calc(100vw - ${(props) =>
      props.isPinned ? '390px' : '90px'}); */
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.6s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;

/** Mostly just used for the mobile layouts right now */
export const Wrapper = styled.div`
  width: 100vw;
`;

export const MarginWrapper = styled.div`
  margin: 20px;
`;

export const Icon = styled.i`
  color: white;
  margin: 0 5px 0 0;
`;

export const TitleHeader = styled.div`
  background: #FBCC08;
  text-align: center;
  padding: 5px;
`;

export const Title = styled.h2`
  color: black;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
`;
