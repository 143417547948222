import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoadingGif from 'assets/images/loading.gif';
import { Icon } from '../../shared';
import { formatCurrency } from '../../shared/utils/common';
import {
  BadgeContainer,
  BadgeLabel,
  LeftContent,
  RightContent,
  VerticalRunListItem,
  VertText,
  VertTextContent,
  RunlistImageWrapper,
  LoadingImageWrapper,
  LoadingImage,
} from './Styles';

// REQUIREMENTS:
// When unit has been statused, remove it from the list, but the primary view are all units that have not yet been statused.
// Allow for previously statused units to re-appear (When previous items re-appear, do not clear the filter when the next items is put onto the block)
// Get filters to work (Currently in AWG, we only allow filtering of Watched Items)

const productStateColorMappings = [
  {
    text: 'Not Statused',
    bgColor: 'var(--gray-2)',
    color: 'var(--darkTextColor)',
    bgVariance: -20,
    value: 0,
  },
  {
    text: 'Open',
    bgColor: 'var(--blue-4)',
    color: 'var(--lightTextColor)',
    bgVariance: 20,
    value: 1,
  },
  {
    text: 'Pending',
    bgColor: 'var(--pendingMsgBgColor)',
    color: 'var(--pendingMsgTextColor)',
    bgVariance: 90,
    value: 2,
  },
  {
    text: 'Sold',
    bgColor: 'var(--wonMsgBgColor)',
    color: 'var(--wonMsgTextColor)',
    bgVariance: 20,
    value: 3,
  },
  {
    text: 'No Sale',
    bgColor: 'var(--noSaleMsgBgColor)',
    color: 'var(--noSaleMsgTextColor)',
    bgVariance: 50,
    value: 4,
  },
];

const Loader = () => (
  <LoadingImageWrapper>
    <LoadingImage src={LoadingGif} />
  </LoadingImageWrapper>
);

const getObjectByProductState = (productState) =>
  productStateColorMappings.filter((item) => item.value === productState);

const getToolTip = (product, lotNumber, isSeller, isWatched) => {
  if (isWatched)
    return `You are currently watching lot number ${lotNumber} (${product}). To stop watching this item, please click on the 'Watching' button from the product preview.`;
  if (isSeller)
    return `You are the seller of lot number ${lotNumber} (${product})`;
};

const VerticalListItem = ({
  item,
  index,
  onClick,
  isWatched,
  myProxyBidAmount,
  isClicked,
  isFullViewLayout,
}) => {
  const { object: theme } = useTheme();

  return (
    <VerticalRunListItem
      key={`${index}_${item.lotNumber}_${item.productState}_${myProxyBidAmount}_${item.isWatched}`}
      index={index}
      onClick={(e) => {
        e.preventDefault();
        onClick(item.lotNumber, item.productID);
      }}
      themeCss={theme}
      isClicked={isClicked}
      isWatched={isWatched}
      isSeller={item.isSeller}
      title={getToolTip(item.product, item.lotNumber, item.isSeller, isWatched)}
    >
      <RunlistImageWrapper>
        <LazyLoadImage
          alt={item.product}
          height={68}
          src={item.url.replace('width=1024', 'width=90')} // use normal <img> attributes as props
          width={90}
          placeholder={<Loader />}
        />
      </RunlistImageWrapper>
      <VertTextContent
        key={`VertTextContent_${index}`}
        className="auction-item-details"
      >
        {isFullViewLayout ? (
          <VertText
            key={`VertText1_${index}`}
          >{`#${item.lotNumber} - ${item.product}`}</VertText>
        ) : (
          <>
            <VertText key={`VertText1_${index}`}>{item.product}</VertText>
            <VertText key={`VertText2_${index}`}>
              Item #:
              {item.lotNumber}
            </VertText>
          </>
        )}
        <BadgeContainer key={`BadgeContainer_${index}`}>
          <LeftContent>
            {myProxyBidAmount > 0 && (
              <BadgeLabel
                key={`BadgeLabel2_${index}`}
                bgColor="var(--myProxyBgColor)"
                size="0.7em"
                color="var(--myProxyTextColor)"
              >
                <Icon key={`Icon2_${index}`} icon="gavel" size={0.8} iconOnly />
                {formatCurrency(myProxyBidAmount)}
              </BadgeLabel>
            )}
          </LeftContent>
          <RightContent>
            {item.productState > 0 && (
              <BadgeLabel
                key={`BadgeLabel4_${index}`}
                size="0.7em"
                color={getObjectByProductState(item.productState)[0]?.bgColor}
                noBgColor
              >
                {getObjectByProductState(item.productState)[0]?.text}
                {item.winningBidAmount > 0 &&
                  ` - ${formatCurrency(item.winningBidAmount)}`}
              </BadgeLabel>
            )}
          </RightContent>
        </BadgeContainer>
      </VertTextContent>
    </VerticalRunListItem>
  );
};

VerticalListItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isWatched: PropTypes.bool,
  myProxyBidAmount: PropTypes.number,
  isClicked: PropTypes.bool,
  isFullViewLayout: PropTypes.bool,
};

VerticalListItem.defaultProps = {
  isWatched: false,
  myProxyBidAmount: null,
  isClicked: false,
  isFullViewLayout: false,
};

export default VerticalListItem;
