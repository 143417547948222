import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from '../../shared/utils/styles';

export const Container = styled.div`
  ${({ isOpen, chatIsOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--mobileBottomNavBgColor);
    ${!isOpen && css`
      -webkit-box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
      box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
    `}
    width: 100vw;
    z-index: 103;
    position: absolute;
    bottom: 0px;
    & i {
      color: var(--mobileBottomNavTextColor);
      padding-right: 0;
    }
    /* chat icon */
    & span {
      margin: 0px;
      margin-top: ${chatIsOpen ? '-15px' : '-10px'};
      display: flex;
      align-self: center;
      justify-self: center;
      padding: 0px;
      width: 4em;
      & i {
        left: 10px;
        top: -2px;
        font-size: 2.8em;
        & span {
          top: 7px;
          right: 0;
          width: 1.2em;
          height: 1.2em;
          font-size: 0.45em;
          color: var(--mobileBottomNavTextColor);
          background-color: var(--mobileBottomNavBgColor);
        }
      }
      & div {
        margin-top: 5.5em;
        color: var(--mobileBottomNavTextColor);
      }
    }
  `}
`;

export const NavButton = styled(Button)`
  background-color: var(--mobileBottomNavBgColor);
  outline: none;
  border: none;
  padding: 0px;
  width: 2.8rem;
  position: relative;
  
  &:hover {
    background-color: var(--mobileBottomNavBgColor);
  }
  
  & div {
    font-size: .6em;
    color: var(--mobileBottomNavTextColor);
    margin-top: 1px;
    text-transform: none;
  }
  & i {
    font-size: 1.6em;
    color: var(--mobileBottomNavTextColor);
    padding-right: 0;
  }
`;

export const PopupView = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--white);
  position: absolute;
  bottom: 45px;
  z-index: 103;
  padding: 0px;
  ${(props) => (props.viewSize === 'small'
    ? css`
      height: 250px;
    `
    : css`
      height: calc(100vh - 95px);
    `)};
  -webkit-box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
  box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
  & .vertical-runlist {
    height: calc(100vh - 150px);
    overflow-y: auto;
    > div:nth-child(2) {
      height: calc(100vh - 165px);
    }
  }
`;

export const ContentContainer = styled.div`
  width: 95vw;
  padding: 10px;
  height: calc(100vh - 5em);
  overflow-y: auto;
`;

export const CloseContainer = styled.div`
  display: flex; 
  width: 100vw;
  height: 100px;
  align-items: center;
  justify-content: center;
  height: 2em;
  & button {
    padding: 0px;
    height: 1.5em;
  }
  & i {
    color: var(--gray-2);
  }
`;


export const StyledBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  line-height: 1.5em;
  width: 1em;
  height: 1em;
  text-align: center;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  position: absolute;
  right: 8px;
  top: 6px;
  background-color: var(--white);
  border: var(--line);
  
  & > span {
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    margin: 0;
  }
`;
