import styled from 'styled-components';

export const lightOrDark = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};
export const lightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    // eslint-disable-next-line no-param-reassign
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  const newColor =
    (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  return newColor;
};

export const Button = styled.button`
  display: inline-block;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'var(--gray-2)'};
  color: ${(props) => (props.color ? props.color : 'var(--darkTextColor)')};
  height: 45px;
  width: ${(props) => props.width};
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 7px 12px;
  border: var(--line);
  border-radius: var(--radiusBox);
  outline: none;
  cursor: pointer;
  :disabled,
  :disabled:hover {
    cursor: not-allowed;
    background-color: var(--gray-2);
    color: var(--gray-8);
  }
  :focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) =>
      lightenDarkenColor(
        props.bgColor ? props.bgColor : 'var(--gray-2)',
        props.bgVariance ? props.bgVariance : 20,
      )};
    color: ${(props) => (props.color ? props.color : 'var(--darkTextColor)')};
  }
  & i {
    padding-right: 5px;
    font-size: 1.2em;
  }
`;

export const GreenButton = styled(Button)`
  background-color: var(--bidBtnBgColor);
  &:hover {
    background-color: ${lightenDarkenColor('var(--bidBtnBgColor)', 20)};
    color: var(--bidBtnTextColor);
  }
`;

export const RedButton = styled(Button)`
  background-color: #db2828;
  &:hover {
    background-color: ${lightenDarkenColor('#DB2828', 50)};
    color: #ffffff;
  }
`;
