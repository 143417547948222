import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { ChatIcon, PlayerIcon } from '..';
import {
  BidderBarContainer,
  LeftContent,
  MiddleContent,
  RightContent,
} from './Styles';

export const BidderBar = ({
  laneID, lotNumber, mediaRoomID, audioPlaying, isSeller,
}) => {
  const {
    AuctionStore: { auctionInfo },
    BidStore: { user },
    MessageStore: { unreadMessages, setOpenChatProperty, openChatWindow },
    UIStore: { handleSetLaneAudio },
  } = useStores();

  const allowChat = isSeller || (auctionInfo.allowChat === 1 && !user.viewOnly);
  const openChatUI = openChatWindow.includes(laneID);

  const hasMediaRoomID = !!mediaRoomID && parseInt(mediaRoomID) > 0;
  const lotNumberValue = lotNumber ? `# ${lotNumber}` : '';

  return (
    <BidderBarContainer>
      <LeftContent>
        {allowChat && (
          <ChatIcon
            key={unreadMessages[laneID]}
            count={unreadMessages[laneID]}
            onClick={() => setOpenChatProperty(laneID, !openChatUI)}
          />
        )}
      </LeftContent>
      <MiddleContent>{lotNumberValue}</MiddleContent>
      <RightContent>
        {hasMediaRoomID && (
          <PlayerIcon
            onClick={() => handleSetLaneAudio(laneID, !audioPlaying, true)}
            playing={audioPlaying}
          />
        )}
      </RightContent>
    </BidderBarContainer>
  );
};

BidderBar.propTypes = {
  audioPlaying: PropTypes.bool.isRequired,
  laneID: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  mediaRoomID: PropTypes.string,
};

BidderBar.defaultProps = {
  lotNumber: null,
  mediaRoomID: '',
};

export default observer(BidderBar);
