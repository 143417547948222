import React from 'react';
import PropTypes from 'prop-types';
import { Dot } from './Styles';

export const ThemeButton = ({
  onClick, isActive, color
}) => (
  <Dot
    onClick={onClick}
    isActive={isActive}
    color={color} 
  />
//   <StyledButton
//   onClick={onClick}
//   isActive={isActive}
//   color={isActive ? 'var(--lightTextColor)' : 'var(--darkTextColor)'}
//   bgColor={isActive ? 'var(--utilityBtnBgColor)' : 'var(--cancelBtnBgColor)'}
//   bgVariance={20}
//   width="95px"
// >
//   {text}
// </StyledButton>
);

ThemeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  // text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ThemeButton;
