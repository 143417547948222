import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Select, Option } from '../Styles';

export const BidActivityLanes = observer(({ activitySectionOpen, setLaneID, laneId }) => {
  const {
    AuctionStore: {
      laneList, openLanes, activeBidActivityLaneId, bidActivityLanes, setActiveBidActivityLaneId,
    },
  } = useStores();

  const [lanes, setLanes] = useState(bidActivityLanes);

  const setBidActivityLaneId = (id) => {
    setActiveBidActivityLaneId(id);
    setLaneID(id);
  };

  const setBidActivityLanes = (bidActivityOpenLanes) => {
    setLanes(bidActivityOpenLanes);
  };
  // FIXME: trying to do too much in this useEffect hook (gets warning b/c we are not adding some objects to array dependency)
  useEffect(() => {
    /** If a lane is removed it won't fire an onChange event, so we catch it manually */
    if (openLanes.length < lanes.length) {
      const newLaneId = openLanes[openLanes.length - 1];
      setBidActivityLaneId(newLaneId);
    }
    /**/
    const openLaneList = laneList.filter((item) => openLanes.includes(item.laneID));
    setBidActivityLanes(openLaneList);

    /** If no audio has been joined and no filter selected, then set the activity to show the first lane in the list */
    if (!laneId) {
      setBidActivityLaneId(openLaneList[0]?.laneID);
    }
    // eslint-disable-next-line
  }, [openLanes, openLanes.length, lanes.length, laneId]);

  if (!activitySectionOpen) return null;

  return (
    <Select
      key={bidActivityLanes.length}
      data-testid="bidactivity-select"
      onChange={(e) => setBidActivityLaneId(e.target.value)}
      value={activeBidActivityLaneId || ''}
    >
      {lanes.length && lanes.map((item) => (
        <Option
          key={`bid-activity_${item?.laneID || Math.random() * 6}`}
          defaultValue={activeBidActivityLaneId}
          value={item?.laneID}
        >
          {item.lane}
        </Option>
      ))}
    </Select>
  );
});

BidActivityLanes.propTypes = {
  activitySectionOpen: PropTypes.bool.isRequired,
  setLaneID: PropTypes.func.isRequired,
  laneId: PropTypes.string,
};

BidActivityLanes.defaultProps = {
  laneId: null,
};

export default BidActivityLanes;
