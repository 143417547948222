import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/*
Original codebase from https://github.com/CagriAldemir/react-cache-buster
The process works as follows:
- When you run the build script, the "generate-meta-tag" script writes the current package.json version into meta.txt and then the build process continues.
- When the client opens the website, the CacheBuster component makes a request to the "/meta.txt" file located in the root.
- Although the website is initially loaded via cache, the updated version data is obtained through the request since XHR requests are not kept in the cache.
- Then, the current version of the cached project is compared with the version received over the request.
- If it is understood that a new version has been published as a result of this comparison, the whole cache is deleted and the project is reloaded.

Code changes for NPA:
- The npm package had a dependency on React v17.0.2, which we're not ready to upgrade to at this moment, so we abstracted the code from the NPM package and localized it.
- Changed meta.json to meta.txt since we found that IIS/asp.net core protects .json files from being requested
- Add timestamp to meta.txt api call to prevent any sort of caching
- Change where we check the cached project version. We write to cacheVersion localStorage variable. Allows user to force a re-cache if necessary instead of checking against the package.json version.
- Change the comparison of versions to see any difference, not just a higher version.
- Hard reload page regardless of whether there are caches to delete. The cacheVersion variable may have been deleted, so theoretically, no caches exist, but yet we need a fresh load
- Also enable checks against localhost
- Added some additional logging/comments

NOTE: Make sure to change the version (match the version of the CI version file) in the package.json file since this is where the value is pulled from when writing to the meta.txt file
*/

export const CacheBuster = ({
  children = null,
  currentVersion,
  isEnabled = false,
  isVerboseMode = false,
  loadingComponent = null,
}) => {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  });

  const VERSION_FILE = 'meta.txt';

  const log = (message, isError) => {
    isVerboseMode && (isError ? console.error(message) : console.log(message));
  };

  useEffect(() => {
    isEnabled ? checkCacheStatus() : log('Cache Buster is disabled.');
  }, []);

  // get the version file endpoint to call via rest call
  const getMetaUrl = () => {
    const isLocal =
      window.location.href.toLowerCase().indexOf('localhost') > -1;
    const timestamp = new Date().getTime();

    if (isLocal) return `/${VERSION_FILE}?v=${timestamp}`;
    return `${process.env.PUBLIC_URL}/${VERSION_FILE}?v=${timestamp}`;
  };

  const checkCacheStatus = async () => {
    try {
      // get latest version from rest call
      const res = await fetch(getMetaUrl(), { cache: 'no-cache' });
      const { version: metaVersion } = await res.json();

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);

      // update cache version in storage
      window.localStorage.setItem('cacheVersion', metaVersion);

      if (shouldForceRefresh) {
        // let's see if the current codebase is cached
        log(`1️⃣ PREVIOUS VERSION: ${currentVersion} (cacheVersion -- See Application tab in localStorage); 
2️⃣ NEW VERSION: ${metaVersion} (${VERSION_FILE} -- See Network tab XHR requests)`);

        log(
          `🆕 HEY, THERE IS A NEW VERSION (v${metaVersion}). Triggering a browser cache delete and hard refresh in a moment, but first checking service worker cache.`,
        );
        setCacheStatus({
          loading: false,
          isLatestVersion: false,
        });
      } else {
        // nothing to do, since we are on the latest version
        log(`%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
VERSIONS MATCH!!!! 🤸 We didn't need to force a reload, so let's carry on...

1️⃣ PREVIOUS VERSION: ${currentVersion} (cacheVersion -- See Application tab in localStorage);
2️⃣ NEW VERSION: ${metaVersion} (${VERSION_FILE} -- See Network tab XHR requests)

NOTE: There is no newer version found, so we don't think a cache refresh is necessary. If you find this to be an issue and believe what
you are looking at to not be the latest version, then we may need to update the package.json file and redeploy so that the latest
version is updated in the ${VERSION_FILE} file. 

💰 So you know, a rest call is made to get the latest version from ${VERSION_FILE} (see Network tab) and compared with against the cacheVersion
in localStorage (see Application tab). Also, versions should match those listed in Octopus 🐙.
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%`);
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
      }
    } catch (error) {
      log('🤦 An error occurred while checking cache status.', true);
      log(error, true);

      //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
    }
  };

  const isThereNewVersion = (metaVersion, currentVersion) => {
    // if cacheVersion is not set, then we need to get latest so we can load it.
    if (!currentVersion) {
      return true;
    }

    // will generate an array of versions to compare (1.2.3 => [1,2,3])
    const metaVersions = metaVersion.split(/\./g);
    const currentVersions = currentVersion.split(/\./g);

    // iterate each build number to check if current different from prev
    while (metaVersions.length || currentVersions.length) {
      
      // get the next version number to compare
      const a = Number(metaVersions.shift());
      const b = Number(currentVersions.shift());

      if (a === b) {
        continue;
      }

      // if versions are different no matter whether old or new version
      return a !== b || isNaN(b);
    }
    return false;
  };

  const refreshCacheAndReload = async () => {
    try {
      if (caches) {
        const cacheNames = await caches.keys();
        if (cacheNames?.length > 0) {
          for (const cacheName of cacheNames) {
            log(`🗡️ DELETING CACHE: ${cacheName}`);

            // Service worker cache will be cleared
            caches.delete(cacheName);
          }
          log('😎 KEEP CALM, SERVICE WORKER CACHES DELETED...');
        } else {
          log('⬇️ CHECKED, BUT FOUND NO SERVICE WORKER CACHES TO DELETE, CONTINUING...');
        }
      }
      log('🎯 ATTEMPTING TO DELETE BROWSER CACHE AND FORCE A HARD REFRESH...');
      // hard reload the page regardless. The cacheVersion variable may have been deleted, so we need a fresh load
      // we attempt to delete browser cache and do a hard refresh
      window.location.reload(true);
    } catch (error) {
      log('🤦‍♂️  An error occurred while deleting the cache.', true);
      log(error, true);
    }
  };

  // if we've disabled this, then ignore any possible reload
  if (!isEnabled) {
    return children;
  } else {
    // show loader, if set and we have a loader set.
    if (cacheStatus.loading) {
      return loadingComponent;
    }

    // if we're done loading and we are not on the latest version, then clear the cache and reload the window
    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      refreshCacheAndReload();
      return null;
    }
    return children;
  }
};

CacheBuster.propTypes = {
  children: PropTypes.element.isRequired,
  currentVersion: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isVerboseMode: PropTypes.bool,
  loadingComponent: PropTypes.element,
};

export default CacheBuster;
