import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding: 200px 0;
  text-align: center;
  background-color: black;
  height: 100vh;
  top: 0;
  bottom: 0: 
  left: 0;
  right: 0;
`;
