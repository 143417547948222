import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useStores from 'hooks/use-store';
import { LaneContext } from 'components/project/LaneWrapper';
import { useResponsive, useBidBlockDetails, useFocus, useTheme } from 'hooks';
import { Icon } from '../../shared';
import SellerTools from './SellerTools';
// import NewSwipeButton from './NewSwipeButton';
import { DoubleTapButton } from './DoubleTapButton';

import {
  BidButton,
  ContentWrapper,
  DetailsContainer,
  HighBid,
  HighBidLabel,
  HighBidWrapper,
  InputButtonGroup,
  TakeButton,
  NoBids,
  ProxyBidButton,
  Track,
  TrackKey,
  TrackValue,
  FullTrackValue,
} from './Styles';


const BidDetails = () => {
  const {
    AuctionStore: {
      connected, laneStatusMap, auctionInfo, networkConnected, isFullViewLayout,
    },
    BidStore: {
      auction, user, watchList, proxies,
    },
    UIStore: {
      placeLiveBid, setSellerAction, setTempSellerAction, clearSellerAction, scrollPosition,
    },
  } = useStores();

  const { object: theme, isLight } = useTheme();
  const isLightTheme = isLight('white');

  const { isMobileView } = useResponsive();
  const { laneID } = useContext(LaneContext);
  const { allowProxyBidding } = auctionInfo;
  const [takeAmountRef, setTakeAmountRefFocus] = useFocus();

  const lanePaused = laneStatusMap.get(laneID).laneStatusName === 'Paused';
  const {
    decisionStatus,
    abbrevBidderName,
    tempSellerAction,
    sellerAction,
    BidButtonLabelLogic,
    disabledButton,
    DisableTakeSubmit,
    hasProxyBid,
    highBidAmtTakeClick,
    isSeller,
    isWatched,
    myProxyBidAmount,
    myProxyBidIsActive,
    onTakesKeyUp,
    onTakesKeyPress,
    formattedReserve,
    state,
    TakesValidationMessages,
    tempTakeAmount,
    winningBidderName,
  } = useBidBlockDetails({
    allowProxyBidding,
    auctionLane: auction[laneID],
    laneID,
    laneStatusMap,
    proxies,
    setSellerAction,
    user,
    watchList,
    takeAmountRef,
    setTakeAmountRefFocus,
    networkConnected,
  });

  useEffect(() => {
    if (tempSellerAction === 'TAKES') {
      const highBid = auction[laneID].currentBid;
      if (highBid > 0) {
        takeAmountRef.current.value = highBid;
      }
      takeAmountRef.current.select();
      setTempSellerAction(laneID, 'TAKES', highBid);
    }
    // eslint-disable-next-line
  }, [tempSellerAction]);


  useEffect(() => {
    if (!auction[laneID]?.isOpenForBidding) {
      if (sellerAction) {
        clearSellerAction(laneID, sellerAction, false);
      }
    }
    // eslint-disable-next-line
  }, [auction[laneID]?.isOpenForBidding]);

  const getToolTip = (watched) => {
    if (watched) return 'You are watching this item.';
    return '';
  };

  const sellerStuff = () => !decisionStatus?.message && isSeller && (
  <Track className="reserve-takes" isMobileView={isMobileView} height={isFullViewLayout ? '35px' : '10px'}>
    <TrackKey isMobileView={isMobileView}>
      {(sellerAction === 'TAKES' || tempSellerAction === 'TAKES') ? 'Takes  ' : 'Reserve'}
      {(sellerAction === 'TAKES' || tempSellerAction === 'TAKES') && <span title={TakesValidationMessages()}><Icon icon="info-circle" size={1.1} iconOnly /></span> }
    </TrackKey>

    {(!tempSellerAction && (sellerAction?.includes('CANCEL') || !sellerAction)) && (
    <TrackValue data-testid="reserve-amt" color="var(--sellerReserveTextColor)" isMobileView={isMobileView}>
      {formattedReserve}
    </TrackValue>
    )}

    {sellerAction === 'PASS' && (
    <TrackValue data-testid="pass-value" color="var(--noSaleMsgBgColor)">
      PASS
    </TrackValue>
    )}

    {sellerAction === 'SELL' && (
      <TrackValue data-testid="reserve-amt" color="var(--sellerOkToSellBgColor)">
        OKAY TO SELL
      </TrackValue>
    )}

    {(tempSellerAction === 'TAKES' || sellerAction === 'TAKES') && (
    <TrackValue data-testid="reserve-amt" color="var(--sellerReserveTextColor)">
      {sellerAction === 'TAKES' ? `$ ${new Intl.NumberFormat().format(highBidAmtTakeClick)}` : (
        <InputButtonGroup showInput isFullViewLayout={isFullViewLayout}>
          <input
            style={DisableTakeSubmit().disabled ? { border: '1px solid var(--sellerReserveTextColor)' } : null}
            ref={takeAmountRef}
            autoComplete="off"
            tabIndex={-1}
            type="text"
            disabled={false}
            value={tempTakeAmount}
            onChange={(e) => setTempSellerAction(laneID, 'TAKES', e.target.value)}
            maxLength={10}
            onKeyUp={onTakesKeyUp}
            onKeyPress={onTakesKeyPress}
          />
          <TakeButton
            disabled={DisableTakeSubmit().disabled}
            className="primary"
            onClick={() => setSellerAction(laneID, 'TAKES', tempTakeAmount)}
          >
            OK
          </TakeButton>

          <TakeButton
            className="primary"
            onClick={() => {
              takeAmountRef.current.value = 0;
              setTakeAmountRefFocus();
              takeAmountRef.current.select();
            }}
          >
            X
          </TakeButton>
        </InputButtonGroup>
      )}
    </TrackValue>
    )}
  </Track>
  );


  return (
    <ContentWrapper
      isWatched={isWatched}
      showProxy={hasProxyBid}
      isSeller={isSeller}
      title={getToolTip(isWatched)}
      themeCss={theme}
    >
      <DetailsContainer isMobileView={isMobileView} isSeller={isSeller} isFullViewLayout={isFullViewLayout} isLightTheme={isLightTheme}>

        <Track className="high-bidder-container" isMobileView={isMobileView} isSeller={isSeller}>
          <TrackKey isMobileView={isMobileView} isWatched={isWatched}>High Bidder</TrackKey>
          <TrackValue
            data-testid="high-bidder"
            color={auction[laneID].bidder === state.bidder ? 'var(--red-2)' : 'var(--darkTextColor)'}
            isMobileView={isMobileView}
          >
            <div style={{
              minWidth: '100%', width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden',
            }}
            >
              {abbrevBidderName === winningBidderName ? (
                winningBidderName
              ) : (
                <span title={winningBidderName}>
                  {abbrevBidderName}
                </span>
              )}
            </div>

          </TrackValue>
        </Track>

        <Track className="high-bid-container" isMobileView={isMobileView}>
          <TrackKey isMobileView={isMobileView}>High Bid</TrackKey>
          <TrackValue color="var(--bidBtnBgColor)" data-testid="high-bid" isMobileView={isMobileView}>
            {auction[laneID].currentBid
              ? `$ ${new Intl.NumberFormat().format(
                auction[laneID].currentBid,
              )}`
              : 'N/A'}
          </TrackValue>
        </Track>
        {!isSeller && decisionStatus?.message && !isMobileView
          && (
          <FullTrackValue
            data-testid="bidder-status"
            isMobileView={isMobileView}
            bgColor={decisionStatus.bgColor}
            color={decisionStatus.color}
          >
            {decisionStatus?.message}
          </FullTrackValue>
          )}
        {sellerStuff()}
      </DetailsContainer>

      {/* FIXME: DO WE NEED THIS ASKING BID - DON'T THINK WE'RE USING IT (HIDDEN IN CSS) */}
      {!isMobileView && (
        <HighBidWrapper>
          <HighBidLabel>Asking Bid</HighBidLabel>
          {auction[laneID].nextBid ? (
            <HighBid data-testid="high-bid">
              $
              {new Intl.NumberFormat().format(auction[laneID].nextBid)}
            </HighBid>
          ) : (
            <NoBids data-testid="no-bid">Waiting</NoBids>
          )}
        </HighBidWrapper>
      )}

      {!isSeller && isMobileView && decisionStatus?.message
      && (
          <FullTrackValue
              data-testid="bidder-status"
              isMobileView={isMobileView}
              bgColor={decisionStatus.bgColor}
              color={decisionStatus.color}
          >
            {decisionStatus?.message}
          </FullTrackValue>
      )}

      {connected && !isSeller && !decisionStatus?.message && (
        myProxyBidIsActive ? (
          <ProxyBidButton data-testid="proxybid-btn" disabled={lanePaused} isFullViewLayout={isFullViewLayout} warning>
            {lanePaused ? 'Paused' : `MY PROXY $ ${new Intl.NumberFormat().format(myProxyBidAmount)}`}
          </ProxyBidButton>
        ) : (
          isMobileView ? (
            <div>
              {scrollPosition < 350 && (
                  <DoubleTapButton
                action={(frozenBid) => placeLiveBid(
                laneID,
                auction[laneID].syncID,
                    frozenBid,
                )}
                disabled={disabledButton}
                text={BidButtonLabelLogic()}
                nextBid={auction[laneID].nextBid}
                bidPanel
                />
              )}

              {/*<NewSwipeButton*/}
              {/*    key={Math.random()}*/}
              {/*    action={() => placeLiveBid(*/}
              {/*        laneID,*/}
              {/*        auction[laneID].syncID,*/}
              {/*        auction[laneID].nextBid,*/}
              {/*    )}*/}
              {/*    disabled={disabledButton}*/}
              {/*    text={BidButtonLabelLogic()}*/}
              {/*    bidPanel*/}
              {/*/>*/}
            </div>
          )
            : (
              <BidButton
                isFullViewLayout={isFullViewLayout}
                className="bid-btn"
                data-testid="bid-btn"
                disabled={disabledButton}
                success
                large
                onClick={() => placeLiveBid(
                  laneID,
                  auction[laneID].syncID,
                  auction[laneID].nextBid,
                )}
              >
                {BidButtonLabelLogic()}
              </BidButton>
            )
        )
      )}

      {connected && isSeller && <SellerTools />}

    </ContentWrapper>
  );
};

export default observer(BidDetails);
