// eslint-disable-file semi-colon
import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { MAX_IMGH_THRESHOLD } from 'components/project/BidPanel/MediaGallery/utils';
import { motion } from 'framer-motion';


export const CarouselWrapper = styled.div`
  grid-area: carousel;
  border-left: var(--line);
  position: relative;
`;

export const ChatLaneStopContainer = styled.div`
  grid-area: chatstop;
  margin: 0;
  margin-left: 10;
  border-right: var(--line);
  border-left: var(--line);
  & div:first-child,
  div:last-child {
    width: auto;
    & input {
      width: 85%;
    }
  }
`;

export const GALLERY_HEIGHT = `${MAX_IMGH_THRESHOLD}px`;

const VERT_RUNLIST_COL_PX = '385px';
const BLOCK_COL1_PX = '250px';
const BLOCK_COL2_PX = '225px';
const FULL_VIEW_COL1_PX = '425px';
const FULL_VIEW_COL2_PX = '450px';
const FULL_VIEW_BOTTOM_ROW_PX = '315px';
const FULL_VIEW_PROD_PREV_OFFSET_ROW_PX = '20px';
const runListTemplateColPx = ({ isFullViewLayout, showRunList }) => {
  if (isFullViewLayout) return FULL_VIEW_COL1_PX;
  return showRunList ? VERT_RUNLIST_COL_PX : '0px';
};
const prodPrevTemplateColPx = ({ isFullViewLayout, showProdPreview }) => {
  if (isFullViewLayout) {
    return showProdPreview ? '475px' : '0px';
  }
  return showProdPreview ? '475px' : '0px';
};
const HEADER_TEMPLATE_ROW_PX = '25px';
const headerTemplateRowPx = ({ isFullViewLayout }) => (isFullViewLayout ? '40px' : '25px');
const playerTemplateRowPx = ({ isFullViewLayout, hasPlayerError }) => {
  if (isFullViewLayout) {
    return hasPlayerError ? '75px' : '0px';
  }
  return hasPlayerError ? '50px' : '0px';
};
const pinnedMessageTemplateRowPx = ({ showPin, isFullViewLayout }) => {
  if (isFullViewLayout) return showPin ? '70px' : '0px';
  return showPin ? '45px' : '0px';
};
const BIDDING_BAR_TEMPLATE_ROW_PX = '30px';
const biddingBarTemplateRowPx = ({ isFullViewLayout }) => (isFullViewLayout ? '40px' : '30px');
// const CAROUSEL_BID_TEMPLATE_ROW_PX = '95px';
const CAROUSEL_BID_TEMPLATE_ROW_PX = '75px';
const carouselBidTemplateRowPx = ({ isFullViewLayout }) => (isFullViewLayout ? '150px' : '75px');
// const mediaTemplateRowPx = ({ showPromo, showChat }) => (showChat || !showPromo ? '225px' : '175px');
// const mediaTemplateRowPx = ({ showPromo, showChat }) => (showChat || !showPromo ? '245px' : '195px');
const mediaTemplateRowPx = ({ isFullViewLayout, showPromo, showChat }) => {
  if (isFullViewLayout) return '220px';
  return showChat || !showPromo ? '245px' : '175px';
};
const promoTemplateRowPx = ({ isFullViewLayout, showPromo, showChat }) => {
  if (showChat) return '0px';
  if (isFullViewLayout && showPromo) return '75px';
  if (showPromo) return '70px';
  return '0px';
};
const runListTemplateRowPx = ({ isFullViewLayout, showRunList }) => {
  if (isFullViewLayout) {
    return showRunList ? FULL_VIEW_BOTTOM_ROW_PX : '0px';
  }
  return showRunList ? '350px' : '0px';
};
const prodPreviewTemplateRowPx = ({ isFullViewLayout, showProdPreview }) => {
  if (isFullViewLayout) {
    return showProdPreview ? FULL_VIEW_BOTTOM_ROW_PX : '0px';
  }
  return showProdPreview ? '350px' : '0px';
};

export const LaneClosed = styled.div`
  grid-area: attrs;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5em;
  color: #666;
  font-weight: bold;
  border-right: none;
  border-top: none;
  border-left: var(--line);
  border-bottom: var(--line);
  padding: 20px;
  background-color: var(--gray-1);
`;

const tabletCss = (props) => css`
    grid-template-columns: ${BLOCK_COL1_PX} ${BLOCK_COL2_PX};
    grid-template-rows:
      ${HEADER_TEMPLATE_ROW_PX}
      ${playerTemplateRowPx(props)}
      ${pinnedMessageTemplateRowPx(props)}
      ${BIDDING_BAR_TEMPLATE_ROW_PX}
      ${CAROUSEL_BID_TEMPLATE_ROW_PX}
      ${mediaTemplateRowPx(props)}
      ${promoTemplateRowPx(props)}
      ${runListTemplateRowPx(props)} ${prodPreviewTemplateRowPx(props)};
    grid-template-areas:
      'head head'
      'player player'
      'msg msg'
      'bidbar bid'
      'carousel bid'
      'carousel attrs'
      'promo attrs'
      'runlist runlist'
      'prodprev prodprev';
`;

const withPinned = (props) => css`
  /* 861px */
  /* TODO: DO WE WANT TO MAKE WIDTH AUTO HERE FOR THE SMALL BREAKPOINT? IT REMOVES ALL THE EXTRA SPACE TO THE RIGHT OF THE LANE, FORCING THE 2ND LANE TO WRAP TO THE NEXT LINE, BUT SETTING TO AUTO WILL REMOVE PADDING TO RIGHT AND ALLOW FOR 2ND LANE TO APPEAR ON SAME ROW  */
  /* width: auto; */
  @media (min-width: ${breakpoints.small}) {
    /* background-color: green; */
    grid-template-columns: ${BLOCK_COL1_PX} ${BLOCK_COL2_PX};
    grid-template-rows:
      ${HEADER_TEMPLATE_ROW_PX}
      ${playerTemplateRowPx(props)}
      ${pinnedMessageTemplateRowPx(props)}
      ${BIDDING_BAR_TEMPLATE_ROW_PX}
      ${CAROUSEL_BID_TEMPLATE_ROW_PX}
      ${mediaTemplateRowPx(props)}
      ${promoTemplateRowPx(props)}
      ${runListTemplateRowPx(props)} ${prodPreviewTemplateRowPx(props)};
    grid-template-areas:
      'head head'
      'player player'
      'msg msg'
      'bidbar bid'
      'carousel bid'
      'carousel attrs'
      'promo attrs'
      'runlist runlist'
      'prodprev prodprev';
  }
  @media (min-width: ${breakpoints.large}) {
    /* 1230px */
    /* background-color: beige; */
    & .vertical-runlist {
      width: ${VERT_RUNLIST_COL_PX};
    }
    width: auto;
    grid-template-columns: ${BLOCK_COL1_PX} ${BLOCK_COL2_PX} ${runListTemplateColPx(props)};
    grid-template-rows:
      ${HEADER_TEMPLATE_ROW_PX}
      ${playerTemplateRowPx(props)}
      ${pinnedMessageTemplateRowPx(props)}
      ${BIDDING_BAR_TEMPLATE_ROW_PX}
      ${CAROUSEL_BID_TEMPLATE_ROW_PX}
      ${mediaTemplateRowPx(props)}
      ${promoTemplateRowPx(props)} ${prodPreviewTemplateRowPx(props)};
    grid-template-areas:
      'head head head'
      'player player player'
      'msg msg msg'
      'bidbar bid runlist'
      'carousel bid runlist'
      'carousel attrs runlist'
      'promo attrs runlist'
      'prodprev prodprev prodprev';
  }
`;

const withNoPinned = (props) => css`
  /* background-color: red; */
  & .vertical-runlist {
    width: ${VERT_RUNLIST_COL_PX};
  }
  grid-template-columns: ${BLOCK_COL1_PX} ${BLOCK_COL2_PX} ${runListTemplateColPx(props)};
  grid-template-rows:
    ${HEADER_TEMPLATE_ROW_PX}
    ${playerTemplateRowPx(props)}
    ${pinnedMessageTemplateRowPx(props)}
    ${BIDDING_BAR_TEMPLATE_ROW_PX}
    ${CAROUSEL_BID_TEMPLATE_ROW_PX}
    ${mediaTemplateRowPx(props)}
    ${promoTemplateRowPx(props)} ${prodPreviewTemplateRowPx(props)};
  grid-template-areas:
    'head head head'
    'player player player'
    'msg msg msg'
    'bidbar bid runlist'
    'carousel bid runlist'
    'carousel attrs runlist'
    'promo attrs runlist'
    'prodprev prodprev prodprev';
`;

// const withNoPinned = (props) => css`
//   @media (min-width: ${breakpoints.small}) {
//     /* background-color: orange; */
//     grid-template-columns:
//       ${BLOCK_COL1_PX}
//       ${BLOCK_COL2_PX};
//     grid-template-rows:
//       ${HEADER_TEMPLATE_ROW_PX}
//       ${playerTemplateRowPx(props)}
//       ${pinnedMessageTemplateRowPx(props)}
//       ${BIDDING_BAR_TEMPLATE_ROW_PX}
//       ${CAROUSEL_BID_TEMPLATE_ROW_PX}
//       ${mediaTemplateRowPx(props)}
//       ${promoTemplateRowPx(props)}
//       ${runListTemplateRowPx(props)}
//       ${prodPreviewTemplateRowPx(props)};
//     grid-template-areas:
//       'head head'
//       'player player'
//       'msg msg'
//       'bidbar bid'
//       'carousel bid'
//       'carousel attrs'
//       'promo attrs'
//       'runlist runlist'
//       'prodprev prodprev';
//   }

//   @media (min-width: ${breakpoints.medium}) {
//     background-color: cyan;
//     & .vertical-runlist {
//       width: ${VERT_RUNLIST_COL_PX};
//     }
//     grid-template-columns:
//       ${BLOCK_COL1_PX}
//       ${BLOCK_COL2_PX}
//       ${runListTemplateColPx(props)};
//     grid-template-rows:
//       ${HEADER_TEMPLATE_ROW_PX}
//       ${playerTemplateRowPx(props)}
//       ${pinnedMessageTemplateRowPx(props)}
//       ${BIDDING_BAR_TEMPLATE_ROW_PX}
//       ${CAROUSEL_BID_TEMPLATE_ROW_PX}
//       ${mediaTemplateRowPx(props)}
//       ${promoTemplateRowPx(props)}
//       ${prodPreviewTemplateRowPx(props)};
//     grid-template-areas:
//       'head head head'
//       'player player player'
//       'msg msg msg'
//       'bidbar bid runlist'
//       'carousel bid runlist'
//       'carousel attrs runlist'
//       'promo attrs runlist'
//       'prodprev prodprev prodprev';
//   }
// `;

const fullHorizontalCss = (props) => css`
  /* background-color: yellow; */
  /* width: calc(100vw - 65px); */
  & .vertical-runlist {
    width: ${VERT_RUNLIST_COL_PX};
  }
  margin-right: 10px;
  grid-template-columns:
    ${BLOCK_COL1_PX} ${BLOCK_COL2_PX}
    ${runListTemplateColPx(props)} ${prodPrevTemplateColPx(props)};
  grid-template-rows:
    ${HEADER_TEMPLATE_ROW_PX}
    ${playerTemplateRowPx(props)}
    ${pinnedMessageTemplateRowPx(props)}
    ${BIDDING_BAR_TEMPLATE_ROW_PX}
    ${CAROUSEL_BID_TEMPLATE_ROW_PX}
    ${mediaTemplateRowPx(props)} ${promoTemplateRowPx(props)};
  grid-template-areas:
    'head head head head'
    'player player player player'
    'msg msg msg msg'
    'bidbar bid runlist prodprev'
    'carousel bid runlist prodprev'
    'carousel attrs runlist prodprev'
    'promo attrs runlist prodprev';
`;

const laneStopFullCss = (props) => css`
  & .vertical-runlist {
    width: ${VERT_RUNLIST_COL_PX};
  }
  margin-right: 10px;
  grid-template-columns: 475px ${runListTemplateColPx(props)} ${prodPrevTemplateColPx(props)};
  grid-template-rows:
    ${HEADER_TEMPLATE_ROW_PX}
    ${playerTemplateRowPx(props)}
    ${pinnedMessageTemplateRowPx(props)}
    ${BIDDING_BAR_TEMPLATE_ROW_PX}
    320px;
  grid-template-areas: ${props.showChat
    ? `
    'head head head'
    'player player player'
    'msg msg msg'
    'bidbar runlist prodprev'
    'chatstop runlist prodprev';
    `
    : `
    'head head head'
    'player player player'
    'msg msg msg'
    'bidbar runlist prodprev'
    'stop runlist prodprev';
    `};
`;

const laneStopPinnedCss = (props) => css`
  @media (min-width: ${breakpoints.tablet}) {
    /* background-color: green; */
    grid-template-columns: 475px;
    grid-template-rows:
      ${HEADER_TEMPLATE_ROW_PX}
      ${playerTemplateRowPx(props)}
      ${pinnedMessageTemplateRowPx(props)}
      ${BIDDING_BAR_TEMPLATE_ROW_PX}
      320px
      ${runListTemplateRowPx(props)} ${prodPreviewTemplateRowPx(props)};
    grid-template-areas: ${props.showChat
    ? `
      'head'
      'player'
      'msg'
      'bidbar'
      'chatstop'
      'runlist'
      'prodprev';
    `
    : `
      'head'
      'player'
      'msg'
      'bidbar'
      'stop'
      'runlist'
      'prodprev';
      `};
  }

  /* 1230px */
  @media (min-width: ${breakpoints.large}) {
    /* background-color: beige; */
    & .vertical-runlist {
      width: ${VERT_RUNLIST_COL_PX};
    }
    width: auto;
    grid-template-columns:
      475px
      ${runListTemplateColPx(props)};
    grid-template-rows:
      ${HEADER_TEMPLATE_ROW_PX}
      ${playerTemplateRowPx(props)}
      ${pinnedMessageTemplateRowPx(props)}
      ${BIDDING_BAR_TEMPLATE_ROW_PX}
      320px
      ${prodPreviewTemplateRowPx(props)};
    grid-template-areas: ${props.showChat
    ? `
      'head head'
      'player player'
      'msg msg'
      'bidbar runlist'
      'chatstop runlist'
      'prodprev prodprev';
      `
    : `
      'head head'
      'player player'
      'msg msg'
      'bidbar runlist'
      'stop runlist'
      'prodprev prodprev';
      `};
  }
`;

const getGridTemplateAreas = (laneClosed, showProdPreview, showChat) => {
  if (laneClosed && showChat && showProdPreview) {
    return `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'chatstop bid'
    'chatstop attrs'
    'chatstop prodprev'
    'runlist prodprev';
    `;
  }

  if (laneClosed && showChat && !showProdPreview) {
    return `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'chatstop bid'
    'chatstop attrs'
    'chatstop attrs'
    'runlist attrs';
    `;
  }

  if (laneClosed && showProdPreview) {
    return `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'stop bid'
    'stop attrs'
    'stop prodprev'
    'runlist prodprev';
    `;
  }

  if (laneClosed && !showProdPreview) {
    return `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'stop bid'
    'stop attrs'
    'stop attrs'
    'runlist attrs';
    `;
  }

  return !showProdPreview
    ? `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'carousel bid'
    'carousel attrs'
    'carousel attrs'
    'runlist attrs';
    ` : `
    'head head'
    'player player'
    'msg msg'
    'bidbar bid'
    'carousel bid'
    'carousel attrs'
    'carousel prodprev'
    'runlist prodprev';
    `;
};

// 1000 x 800 (new)
const fullViewLaneCss = (props) => css`
  /* background-color: yellow; */
  /* width: calc(100vw - 65px); */  
  & .vertical-runlist {
    width: ${FULL_VIEW_COL1_PX};
  }
  & .prev-attrs > div:first-child {
    height: 295px;
  }
  & .prev-attrs.with-btns > div:first-child {
    height: 260px;
  }
  & .item-attribs {
    height: 600px;
  }
  /* & .carousel-container {
    position: relative;
  } */
  & .promo-image {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 199;
  }
  & .bid-btn {
    width: 50%;
    max-width: 50%;
  }
  /* & .prod-prev-wrapper {
    margin: 0px;
    padding: 0px;
  } */
  margin-right: 10px;
  grid-template-columns:
    ${FULL_VIEW_COL1_PX} ${FULL_VIEW_COL2_PX};
  grid-template-rows:
    ${headerTemplateRowPx(props)}
    ${playerTemplateRowPx(props)}
    ${pinnedMessageTemplateRowPx(props)}
    ${biddingBarTemplateRowPx(props)}
    ${carouselBidTemplateRowPx(props)}
    ${mediaTemplateRowPx(props)}
    ${FULL_VIEW_PROD_PREV_OFFSET_ROW_PX}
    ${FULL_VIEW_BOTTOM_ROW_PX};
  grid-template-areas: ${getGridTemplateAreas(props.laneClosed, props.showProdPreview, props.showChat)};
`;

export const Layout = styled(motion.div)`
  /* width: calc(100vw - 15px); */
  margin-bottom: 80px;

  /** Added these two lines to prevent the weird ios bounce over-scroll when in native/webview */
  overflow-x: hidden; 

  /**/
  
  @media (min-width: ${breakpoints.tablet}) {
    
    font-size: ${(props) => (props.fontSize > 1 ? `${props.fontSize}rem` : '1rem')};
    ${(props) => props.isFullViewLayout && 'font-size: 1.75em;'};
    margin-bottom: 15px;
    display: grid;
    gap: 0;
    align-items: stretch;
    justify-items: stretch;
    box-sizing: border-box;
    padding: 3px;
    overflow-x: auto;
    height: auto;
    /* width: calc(100vw - 15px); */
  }

  ${({ isPinnedMenu, laneClosed, isFullViewLayout }) => (laneClosed
    ? css`
          @media (min-width: ${breakpoints.tablet}) {
            margin-left: 10px;
            ${isFullViewLayout ? fullViewLaneCss : laneStopPinnedCss}
          }

          @media (min-width: ${breakpoints.small}) {
            margin-left: 10px;
            ${isFullViewLayout ? fullViewLaneCss : laneStopPinnedCss}
          }

          @media (min-width: ${breakpoints.larger}) {
            ${isPinnedMenu && !isFullViewLayout && laneStopPinnedCss};
            ${!isPinnedMenu && !isFullViewLayout && laneStopFullCss};
            ${isFullViewLayout && fullViewLaneCss};
          }

          @media (min-width: ${breakpoints.largest}) {
            ${isFullViewLayout ? fullViewLaneCss : laneStopFullCss}
          }
        `
    : css`
          @media (min-width: ${breakpoints.tablet}) {
            margin-left: 10px;
            ${isFullViewLayout ? fullViewLaneCss : tabletCss}
          }

          @media (min-width: ${breakpoints.small}) {
            margin-left: 10px;
            ${isPinnedMenu && !isFullViewLayout && withPinned};
            ${!isPinnedMenu && !isFullViewLayout && withNoPinned};
            ${isFullViewLayout && fullViewLaneCss};

          }

          @media (min-width: ${breakpoints.larger}) {
            /* background-color: cyan; */
            ${isPinnedMenu && !isFullViewLayout && withPinned};
            ${!isPinnedMenu && !isFullViewLayout && fullHorizontalCss};
            ${isFullViewLayout && fullViewLaneCss};
          }

          @media (min-width: ${breakpoints.largest}) {
            /* background-color: yellow; */
            ${isFullViewLayout ? fullViewLaneCss : fullHorizontalCss}
          }
        `)}
`;
