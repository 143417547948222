import styled from 'styled-components';

export const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100hv;
    z-index: 100;
`;

export const LoadingImage = styled.img`
    border: 0;
    margin: auto;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 40%;
    left: calc(50vw - 40px);
`;
