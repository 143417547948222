import { useEffect } from 'react';

const useDoubleClick = ({
  ref,
  latency = 300,
  onSingleClick = () => null,
  onDoubleClick = () => null,
  nextBid
}) => {

  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;

    const handleClick = (e) => {
      clickCount += 1;

      setTimeout(() => {
        let frozenBid = nextBid;
        if (clickCount === 1) {
          onSingleClick(e);
        }
        else if (clickCount === 2) {
          onDoubleClick(frozenBid);
        }

        clickCount = 0;
      }, latency);
    };

    if (clickRef) {
      // Add event listener for click events
      clickRef.addEventListener('click', handleClick);

      // Remove event listener
      return () => {
        clickRef.removeEventListener('click', handleClick);
      };
    }

    return () => null;
  });
};

export default useDoubleClick;
