import {
  decorate, observable, action, configure,
} from 'mobx';
import { convertArrayToObject } from './utils';

configure({ enforceActions: 'always' });

class ProviderStore {
  constructor() {
    this.initialize();
  }

  setProperty = (key, value) => {
    try {
      this[key] = value;
    } catch (error) {
      console.error('setProperty', { error, key, value });
    }
  };

  initialize = () => {
    this.setProperty('providerSettings', []);
    this.setProperty('providerEvents', []);
    this.setProperty('language', 'en-us');
  };

  setProviderSettings = (data) => {
    this.setProperty('providerSettings', data);
  };

  setProviderEvents = (data) => {
    const obj = convertArrayToObject(data, 'providerEvents');
    this.setProperty('providerEvents', obj);
  };

  getProviderSettingByKey = (key) => this.providerSettings[key];

  getProviderEventsByType = (type) => this.providerEvents.filter((item) => item.eventTypeId === type);
}

decorate(ProviderStore, {
  getProviderEventsByType: action,
  getProviderSettingByKey: action,
  initialize: action,
  language: observable,
  providerEvents: observable,
  providerSettings: observable,
  setProperty: action,
  setProviderEvents: action,
  setProviderSettings: action,
});

export default ProviderStore;
