import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores, useJanus } from 'hooks';
import { Audio, AudioContainer } from './Styles';

const AudioStreamer = ({
  mediaRoomID,
  audioVideoUrl,
  mediaToken,
  laneID,
  setAudioState,
  audioPlaying,
}) => {
  const {
    AuctionStore: { janusIceServers },
    MessageStore: { clearLaneTempPinMessage, setLaneTempPinMessage },
    UIStore: { handleSetLaneAudio },
  } = useStores();

  const { audioTagRef } = useJanus({
    window,
    mediaRoomID,
    audioVideoUrl,
    mediaToken,
    laneID,
    setAudioState,
    audioPlaying,
    clearLaneTempPinMessage,
    showDebugOutput: false,
    janusIceServers,
    handleSetLaneAudio,
    setLaneTempPinMessage,
  });

  if (!mediaRoomID) {
    return null;
  }

  return (
    <AudioContainer>
      <Audio autoplay controls ref={audioTagRef} id="remoteaudio">
        Your browser does not support audio. Consider upgrading.
      </Audio>
    </AudioContainer>
  );
};

AudioStreamer.propTypes = {
  mediaRoomID: PropTypes.string,
  audioVideoUrl: PropTypes.string,
  mediaToken: PropTypes.string,
  laneID: PropTypes.string.isRequired,
  setAudioState: PropTypes.func.isRequired,
  audioPlaying: PropTypes.bool,
};

AudioStreamer.defaultProps = {
  mediaRoomID: null,
  audioPlaying: false,
  mediaToken: null,
  audioVideoUrl: null,
};

export default observer(AudioStreamer);
