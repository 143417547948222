import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';


import {
  Track,
  TrackValue,
  DetailsContainer,
} from '../BidDetails/Styles';


export const FullTrackValue = styled.div`
  ${({ color, bgColor, isMobileView }) => css`
    background-color: ${bgColor};
    color: ${color};
    display: flex;
    ${isMobileView && 'width: 100%'};
    flex-direction: ${isMobileView ? 'column' : 'row'};
    padding: ${isMobileView ? '5px 0px' : '5px 10px'};
    margin-bottom: 3px;
    @media (min-width: ${breakpoints.tablet}) {
    }
    @media (min-width: ${breakpoints.small}) {
    }
    @media (min-width: ${breakpoints.large}) {
    }
  `};
`;

export const Container = styled(motion.div)`
  display: flex;
  text-align: center;
  background-color: var(--black);
  box-sizing: border-box;
  z-index: 101;
  ${({isWatched}) => isWatched && 'background-color: var(--watchingBgColor); color: var(--watchingTextColor)'};
  ${({isWatched, themeCss}) => isWatched && themeCss && themeCss?.watchingBgGradientColor?.gradient};  
  ${({isSeller}) => isSeller && 'background-color: var(--sellerItemBgColor)'};
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 50px;
  width: 100vw;
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const ItemNumber = styled.div`
  height: 1.5em;
  color: var(--lotNumberTextColor);
  padding: 3px 0px;
  background-color: var(--lotNumberBgColor);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FixedDetailsContainer = styled(DetailsContainer)`
  background-color: ${({isLightTheme}) => isLightTheme ? 'var(--black)' : 'var(--white)'};
  & div {
    background-color: ${({isLightTheme}) => isLightTheme ? 'var(--black)' : 'var(--white)'};
  }
`


export const FixedTrack = styled(Track)`
  font-size: 0.8em;
  font-weight: bold;
  color: ${({isSeller}) => (isSeller ? 'var(--darkTextColor)' : 'var(--lightTextColor)')};
  background-color: ${({isSeller, isWatched}) => (isSeller || isWatched ? 'transparent' : 'var(--black)')};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FixedTrackKey = styled.div`
  width: 100%;
  margin: 5px 0;
  font-weight: normal;
`;

export const FixedTrackValue = styled(TrackValue)`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
`;

export const ImageThumbnail = styled.img`
  width: 80px;
  border: 0;
`;

export const SwipeWrapper = styled.div`
  background-color: var(--gray-6);
  align-items: center;
  text-align: center;
  width: 100%;
  flex: 1;
  padding: 0 0;
`;
