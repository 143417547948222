import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { Button } from '../../shared/utils/styles';

export const ContentWrapper = styled.div`
  background-color: ${({ isMobileView }) =>
    isMobileView ? 'var(--navBgColor)' : 'var(--headerBgColor)'};
  color: ${({ isMobileView }) =>
    isMobileView ? 'var(--lightTextColor)' : 'var(--headerTextColor)'};
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: head;
    min-width: 100%;
    border-right: var(--line);
    border-left: var(--line);
    ${(props) => props.isFullViewLayout && 'font-size: .6em'};
    & i {
      font-size: ${(props) => (props.isFullViewLayout ? '1.2em' : '.8em')};
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    border-radius: var(--radiusBox) var(--radiusBox) 0 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const AuctionLaneTitle = styled.div`
  color: white;
  margin: 0 0 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleWrapper = styled.div`
  padding-right: 10px;
`;

export const Title = styled.h3`
  margin: 5px 10px 5px 10px;
  padding: 0px 20px 0px 0px;
  font-size: 0.8em;
`;

export const SubTitle = styled.h5`
  margin: 5px 10px 5px 10px;
  padding: 0px 20px 0px 0px;
  font-size: 0.8em;
`;

export const DisclaimerWrapper = styled.div``;

export const DislaimerButton = styled(Button)`
  width: 100%;
  height: 30px;
  margin: 1px;
  padding: 6px 4px;
  font-size: 0.7em;
  margin: 10px 0px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    font-size: 0.9em;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const ButtonGroup = styled.div`
  & button {
    margin: 0px 3px;
  }
`;

export const DarkBgIcon = styled.a`
  margin: 0px 5px;
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  font-size: 0.8em;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  &.expand-collapse-lane {
    display: none;
  }
  ${(props) =>
    !props.audioPlaying &&
    css`
      i {
        padding-right: 5px;
      }
    `}
  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) => `${props.size || 1}em`};
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    &.expand-collapse-lane {
      display: inline;
    }
  }
`;

// const badgeCss = css`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   border-radius: var(--radiusBadge);
//   top: -1em;
//   right: -0.5em;
//   width: 2em;
//   height: 2em;
//   line-height: 1.5em;
//   font-size: 0.4em;
//   font-weight: bold;
//   text-align: center;
//   color: white;
//   background-color: green;
// `;

// export const StyledBadge = styled.span`
//   ${(props) => (props.badge || props.badge >= 0) && badgeCss}
// `;

// export const DarkBgBadgeIcon = styled(DarkBgIcon)`
//   & i {
//     position: absolute;
//     left: 10px;
//     font-size: 1.8em;
//     span {
//       ${(props) => (props.badge || props.badge >= 0) && badgeCss}
//     }
//   }
// `;

const badgeCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: var(--radiusBadge);
  top: -1em;
  right: -0.35em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  font-size: 0.5em;
  font-weight: bold;
  text-align: center;
  color: var(--lightTextColor);
  background-color: var(--badgeValueTextColor);
  /* z-index: 9999; */
`;

export const StyledBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: var(--radiusBadge);
  top: 1em;
  right: -0.35em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  /* color: red; */
  /* background-color: green; */
  /* z-index: 9999; */
`;

export const StyledItem = styled.span`
  position: relative;
  display: inline-block;
  align-items: center;
  width: 1.5em;
  height: 1em;
  line-height: 1em;
  padding-left: 10px;
  & i {
    position: absolute;
    left: 10px;
    font-size: 1.2em;
    & span {
      ${(props) => (props.badge || props.badge >= 0) && badgeCss}
    }
  }

  /* margin: 0px 5px; */
  color: ${(props) => props.color || 'white'};
  font-size: 0.6em;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) => `${props.size || 1}em`};
  }
`;

export const ToggleLaneButton = styled(DarkBgIcon)`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    display: inline;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const LotWrapper = styled.div`
  background-color: var(--lotNumberBgColor);
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const LotNumber = styled.h1`
  color: var(--lotNumberTextColor);
  font-weight: normal;
  font-size: 1.3em;
  margin: 0;
  text-align: center;
`;

/** Navigate back to native app */

export const BackWrapper = styled.div`
  background: var(--black);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 0;
`;

export const LaneTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BackButton = styled.button`
  justify-content: start;
  align-items: center;
`;

export const AudioPlayingWrapper = styled.span`
  margin: 0 6px 0 0;
  padding: 0;
`;
