import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  box-sizing: border-box;
`;

export const FilterButtonGroup = styled.div``;

export const FilterButton = styled.button`
  background-color: ${(props) => (props.visible ? 'var(--utilityBtnBgColor)' : 'var(--cancelBtnBgColor)')};
  color: ${(props) => (props.visible ? 'var(--utilityBtnTextColor)' : 'var(--cancelBtnTextColor)')};
  border: 1px solid var(--black);
  border-radius: var(--radiusNone);
  margin: 0px 1px;
  font-size: 0.8em;
  outline: none;
`;

export const SectionContainer = styled.div`
  padding: 10px;
  /* width: 100%; */
  width: 260px;
`;

export const Section = styled.div`
  border: var(--line);
  margin-bottom: 5px;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--headerBgColor);
  padding: 3px;
  height: 1.8em;
  border-bottom: var(--line);
`;

export const SectionHeaderLabel = styled.label`
  color: var(--headerTextColor);
  line-height: 1.8em;
  font-size: 0.8em;
  font-weight: bold;
  & i {
    padding-right: 5px;
  }
`;

export const SectionContent = styled(motion.div)`
${({ height, noScroll }) => css`
  height: ${height || '100px'};
  overflow-y: ${noScroll ? 'hidden' : 'scroll'};
  padding: 5px;
  background-color: var(--white);
`}
`;

export const IconButton = styled.button`
  outline: none;
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  &:hover {
    cursor: pointer;
  }
`;

export const IconButtonGroup = styled.div`
  padding: 0px;
  & button {
    padding: 0px 5px;
  }
`;

export const LaneContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  margin: 3px;
  padding: 3px;
  background-color: var(--shadedBgColor);
  border: var(--line);
  border-radius: var(--radiusBox);
  font-size: 0.7em;
  box-sizing: border-box;
  &:hover {
    border: 2px solid var(--activeLaneTextColor);
    cursor: pointer;
    & button {
      display: block;
    }
  }
  & a {
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
  ${(props) => props.disabled
    && css`
      border: 2px solid var(--activeLaneTextColor);
      background-color: var(--gray-1);
      &:hover {
        border: 2px solid var(--activeLaneTextColor);
        cursor: pointer;
        & button {
          display: none;
        }
      }
    `}
`;

export const LaneImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('${(props) => props.url}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--shadedBgColor);
  width: 100%;
  height: 100%;
  & button {
    display: none;
    & i {
      font-size: 2.2em;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const LaneContent = styled.div`
  padding: 0px;
  & div {
    padding: 0px;
    margin: 1px 0px;
  }
`;

export const LeftLaneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  padding: 0px;
  & div {
    padding: 0px;
    margin: 1px 0px;
  }
`;

export const RightLaneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 0px;
  & div {
    padding: 0px;
    margin: 1px 0px;
  }
`;

export const LaneTextItem = styled.div`
  ${(props) => props.color && `color: ${props.color};`};
  ${(props) => props.uppercase && 'text-transform: uppercase;'};
  ${(props) => props.bold && 'font-weight: bold;'};
  margin: 0px;
  padding: 0px;
`;

export const Track = styled.div`
  display: flex;
  font-size: 0.7em;
  &:nth-child(even) > div {
    background-color: var(--gray-1);
  }
`;

export const TrackKey = styled.div`
  width: 30%;
  padding: 10px 5px 10px 5px;
  text-align: left;
`;

export const TrackValue = styled.div`
  text-align: right;
  padding: 10px 5px 10px 10px;
  width: 70%;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & a {
    cursor: auto;
    text-decoration: none;
    ${(props) => (props.color ? `color: ${props.color}` : '')};
  }
`;

export const BadgeLabel = styled.span`
  float: right;
  margin-right: 0px;
  white-space: nowrap;
  border: none;
  padding: 2px 4px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  font-size: ${(props) => props.size || '0.8em'};
  border-radius: 3px;
  & i {
    padding-right: 2px;
  }
`;

export const RowStatContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: .8em;
  color: var(--darkTextColor);
  & div {
    padding: 5px;
    line-height: 1.5em;
  }
  & div:nth-child(1) {
    width: 50%;
  };
  & div:nth-child(2) {
    width: 20%;
  }
  & div:nth-child(3) {
    width: 30%;
  }
`;

const statColor = (type) => {
  if (type === 'Purchased') return { bgColor: 'var(--wonMsgBgColor)', color: 'var(--wonMsgTextColor)' };
  if (type === 'Pending') return { bgColor: 'var(--pendingMsgBgColor)', color: 'var(--pendingMsgTextColor)' };
  return 'inherit';
};

export const StatValue = styled.div`
  ${({ type, color }) => css`
    text-align: center;
    background-color: ${statColor(type).bgColor};
    color: ${statColor(type).color};
    border: 1px solid ${type ? statColor(type).bgColor : 'var(--gray-7)'};
    border-radius: 3px;
    margin: 1px;
    padding: 5px;
  `};
`;
