import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonRow, Container, StyledButton } from './Styles';

export const ProxyBidConfirmation = ({
  onSubmit, onCancel, proxyBid, message, messageType, heading,
}) => {
  const submitRef = useRef(null);
  const [okDisabled, setOkDisabled] = useState(false);
  const getKeyCode = (e) => e.which || e.keyCode;
  const isEnterKey = (e) => getKeyCode(e) === 13;

  const onKeyPress = (e) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      OkAndDisable();
    }
  };

  const OkAndDisable = () => {
    setOkDisabled(true);
    onSubmit(proxyBid, true);
  };

  useEffect(() => {
    if (submitRef.current) submitRef.current.focus();
    return () => null;
  }, [message]);

  return (
    <Container onKeyPress={onKeyPress}>
      {heading && <strong>{heading}</strong>}
      <div style={{
        color: ['success', 'warning'].includes(messageType) ? 'var(--successColor)' : 'var(--errorColor)', fontSize: message.length > 200 ? '.8rem' : '1em', padding: '0px 10px', textAlign: 'center',
      }}
      >
        {message}
      </div>
      <ButtonRow>
        {onCancel && (
          <StyledButton onClick={onCancel} color="var(--cancelBtnTextColor)" bgVariance={20}>
            Cancel
          </StyledButton>
        )}
        {onSubmit && (
          <StyledButton
            ref={submitRef}
            onClick={() => OkAndDisable()}
            color="var(--utilityBtnTextColor)"
            bgColor="var(--utilityBtnBgColor)"
            bgVariance={20}
            onKeyPress={onKeyPress}
            disabled={okDisabled}
          >
            OK
          </StyledButton>
        )}
      </ButtonRow>
    </Container>
  );
};

ProxyBidConfirmation.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  proxyBid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  messageType: PropTypes.string,
  heading: PropTypes.string,
};

ProxyBidConfirmation.defaultProps = {
  onSubmit: null,
  onCancel: null,
  proxyBid: null,
  message: null,
  messageType: null,
  heading: null,
};

export default ProxyBidConfirmation;
