import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useBidderStats } from 'hooks';
import {
  SectionContent,
  RowStatContainer,
  StatValue,
} from '../Styles';

export const BidderStats = observer(() => {
  const { BidStore: { user } } = useStores();
  const {
    totalSubjectTo,
    totalSubjectToFormatted,
    totalPurchased,
    totalPurchasedAmountFormatted,
    totalPurchasedLabel,
    totalSubjectToLabel,
    remainingProxiesLabel,
    remainingProxies,
    remainingProxiesAmountFormatted,
  } = useBidderStats(user.commitments);
  return (
    <SectionContent height="100px" noScroll>
      <RowStatContainer>
        <div>{totalPurchasedLabel}</div>
        <div>{totalPurchased}</div>
        <StatValue type="Purchased">{totalPurchasedAmountFormatted ?? '$ 0'}</StatValue>
      </RowStatContainer>
      <RowStatContainer>
        <div>{totalSubjectToLabel}</div>
        <div>{totalSubjectTo}</div>
        <StatValue>{totalSubjectToFormatted ?? '$ 0'}</StatValue>
      </RowStatContainer>
      <RowStatContainer>
        <div>{remainingProxiesLabel}</div>
        <div>{remainingProxies}</div>
        <StatValue type="Pending">{remainingProxiesAmountFormatted ?? '$ 0'}</StatValue>
      </RowStatContainer>
    </SectionContent>
  );
});

export default BidderStats;
