import { useState, useEffect } from 'react';
import { useToggle } from 'hooks';
import { isFunction } from 'lodash';
import Analytics from "../shared/utils/analytics";

export const useProxyBid = ({
  initialProxyBid = '',
  proxies = [],
  agent = null,
  loadProxies = null,
  lotNumber = null,
  productID = null,
  laneID = null,
  isSeller = false,
  allowProxyBidding = false,
  isOnBlock = false,
  isUpcomingItem = false,
  token = null,
  setAuctionProperty,
  triggerFinancialCommitments,
}) => {
  const defaultBidderMessage = { lotNumber: null, messageType: null, message: null };
  const [_error, setError] = useState([]);
  const [proxyBid, setProxyBid] = useState(initialProxyBid);
  const [tempProxyBid, setTempProxyBid] = useState(initialProxyBid);
  const [bidderMessage, setBidderMessage] = useState(defaultBidderMessage);
  const [isWaitingForProxy, setIsWaitingForProxy] = useState(false);

  const addError = (message) => setError((err) => [...err, message]);

  const {
    isToggled: showProxyBidInput,
    setToggle: setShowProxyBidInput,
    toggle: toggleProxyBidInput,
  } = useToggle(false);

  const {
    isToggled: showProxyBidAmount,
    setToggle: setShowProxyBidAmount,
  } = useToggle(false);

  const getBidderMessage = (data, outOfBoundsCheck) => {
    const { webhookResponse } = data;
    // bidder confirming an out of bounds proxy bid
    if (outOfBoundsCheck) {
      if (data.success) return { lotNumber, messageType: 'success', message: webhookResponse.conditionalProxyBidMsg };
    }

    return { lotNumber, messageType: data.success ? 'success' : 'error', message: data.message };
  };

  const cancelConfirmation = () => {
    setTempProxyBid(proxyBid);
    setBidderMessage(defaultBidderMessage);
    setIsWaitingForProxy(false);
  };

  const hideProxyBidInput = () => {
    cancelConfirmation();
    toggleProxyBidInput();
    setIsWaitingForProxy(false);
    setShowProxyBidAmount(proxyBid > 0 ? allowProxyBidding : false);
  };

  useEffect(() => {
    cancelConfirmation();
    setShowProxyBidInput(false);
    return () => null;
    // eslint-disable-next-line
  }, [lotNumber]);

  const submitProxyBid = async (bid, confirmBid = false) => {
    const { obj, host } = agent;
    try {
      setIsWaitingForProxy(true);
      if (Number(bid) < proxyBid) {
        setBidderMessage({ lotNumber, messageType: 'error', message: 'You are only allowed to increase proxy bids. Please try again.' });
        return;
      }

      const oobResponse = await obj.outOfBoundsCheck(productID, bid, token, host);
      if (!confirmBid && oobResponse.data.isProxyOutsideBoundary) {
        setBidderMessage({ lotNumber, messageType: 'warning', message: 'The amount entered appears to be very low or very excessive. Press "OK" to continue with this value or "Cancel" to go back and make a change.' });
        return;
      }

      const res = await obj.create(productID, bid, host);
      const { data } = res;
      const msg = getBidderMessage(data, false);
      setBidderMessage(msg);

      if (msg.messageType === 'success') {
        setShowProxyBidAmount(allowProxyBidding);
        setProxyBid(bid);
        triggerFinancialCommitments();

        Analytics.RecordEvent('Proxy Bid Button', 'Bidding', 'Lot Number:', lotNumber);

        await loadProxies(laneID);
      }
    } catch (error) {
      setBidderMessage({ lotNumber, messageType: 'error', message: 'Your proxy bid could not be entered. Please contact NPA directly at 888.292.5339 ext. 923308 for assistance.' });
      addError(`Error with submitProxyBid: ${error}`);
    } finally {
      setIsWaitingForProxy(false);
    }
  };

  const generateNewNumber = () => Math.random() * 6;
  /** Checks to see if the current preview item has a proxy bid */
  useEffect(() => {
    setProxyBid('');
    setTempProxyBid('');
    if (isSeller) {
      setShowProxyBidAmount(false);
    } else if (proxies.length) {
      const bids = proxies.filter((item) => item.productID === productID);
      if (bids.length > 0) {
        setShowProxyBidAmount(allowProxyBidding);
        const bid = bids[0].proxyBid;
        setProxyBid(bid);
        setTempProxyBid(bid);
      }
    }

    setAuctionProperty(laneID, 'lastRunListItemUpdate', `proxy-${generateNewNumber()}`);
    // eslint-disable-next-line
  }, [proxies.length, lotNumber]);

  useEffect(() => {
    if (!showProxyBidInput) setBidderMessage(defaultBidderMessage);
    return () => null;
    // eslint-disable-next-line
  }, [showProxyBidInput]);

  useEffect(() => {
    if (!agent) addError('valid agent object required');
    if (!isFunction(loadProxies)) addError('loadProxies required');
    if (!lotNumber) addError('lotNumber required');
    if (!productID) addError('productID required');
    if (!laneID) addError('laneID required');
    // eslint-disable-next-line
  }, []);

  const showProxyBidButton = allowProxyBidding
    && isUpcomingItem
    && !isOnBlock
    && !showProxyBidAmount
    && !isSeller;
  
  return {
    isWaitingForProxy,
    proxyBid,
    toggleProxyBidInput,
    setProxyBid,
    tempProxyBid,
    setTempProxyBid,
    cancelConfirmation,
    submitProxyBid,
    showProxyBidButton,
    setShowProxyBidAmount,
    showProxyBidAmount,
    setShowProxyBidInput,
    showProxyBidInput,
    hideProxyBidInput,
    bidderMessage,
    error: { category: 'Proxy Bids', error: _error },
  };
};

export default useProxyBid;
