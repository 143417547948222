import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useResponsive } from 'hooks';
import { ROUTES } from '../shared/constants/index';

export const useDesktopRedirect = () => {
  const { isSmallView } = useResponsive();
  const history = useHistory();

  useEffect(() => {
    if (isSmallView) {
      history.push(ROUTES.auction);
    }
  }, [isSmallView, history]);

  return { isSmallView };
};

export default useDesktopRedirect;
