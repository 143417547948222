import React from 'react';
import PropTypes from 'prop-types';
import logo from './logo.png';

const propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  className: undefined,
  width: '288px',
  height: '69px',
};

const Logo = ({ className, width, height }) => (
  <div className={className}>
    <img width={width} height={height} src={logo} alt="Logo" />
  </div>
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
