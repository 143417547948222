import styled from 'styled-components';

export const FormWrapper = styled.div`
    background: rgba(0, 0, 0, 0.05);
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    border: 1px black solid;
    text-align: center;
`;

export const FormCont = styled.div`
  justify-content: center;
  margin: 20px;
  text-align: center;
`;

export const FormElement = styled.div`
  max-width: 240px;
  margin: 0 auto;
`;

export const FormHeading = styled.h1`
  padding: 6px 0 15px;
`;
export const FormRow = styled.div`
    margin: 10px 0;
    text-align: center;
`;

export const Select = styled.select`
    border: 1px grey solid;
    padding: 5px;
    text-align: center;
`;

export const Option = styled.option`
    text-align: center;
`;
