import styled from 'styled-components';

import { color, font, breakpoints } from 'shared/utils/styles';

export const Container = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    /* display: block; */
    color: ${color.textMedium};
    ${font.size(15)};
    grid-area: breadcrumb;
    margin: 0 15px 15px 15px;
    justify-content: space-between;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Divider = styled.span`
  position: relative;
  top: 2px;
  margin: 0 10px;
  ${font.size(18)};
`;
