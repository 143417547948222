import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const ContentWrapper = styled.div`
  /* display: none; */
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: player;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--lotNumberBgColor);
    color: var(--lotNumberTextColor);
    margin: 0px;
    min-width: 100%;
    min-height: 100%;
    border-right: var(--line);
    border-left: var(--line);
    border-radius: var(--radiusNone);
    font-size: 0.8em;
    padding: 0px;
    & i {
      padding-right: 5px;
      font-size: 1em;
    }
    & div {
      padding-left: 10px;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export default ContentWrapper;
