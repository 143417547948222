import defaultTheme from './defaultTheme';
import krisUiTheme from './krisUiTheme';
import abyssTheme from './abyssTheme';
import npaDarkTheme from './npaDarkTheme';
import candyHeartTheme from './candyHeartTheme';
import sgaTheme from './sgaTheme';

export const themes = { 
  default: defaultTheme(),
  og1: krisUiTheme(),
  abyss: abyssTheme(),
  npaDark: npaDarkTheme(),
  candyHeart: candyHeartTheme(),
  sga: sgaTheme(),
}

export default themes;