import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const badgeCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: var(--radiusBadge);
  top: -1em;
  right: -0.35em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  font-size: 0.5em;
  font-weight: bold;
  text-align: center;
  color: var(--lightTextColor);
  background-color: var(--badgeValueTextColor);
  /* z-index: 9999; */
`;

export const StyledItem = styled.span`
  position: relative;
  display: inline-block;
  align-items: center;
  width: 1.5em;
  height: 1em;
  line-height: 1em;
  padding-left: 10px;
  & i {
    position: absolute;
    left: 5px;
    font-size: 1.2em;
    & span {
      ${(props) => (props.badge || props.badge >= 0) && badgeCss}
    }
  }

  /* margin: 0px 5px; */
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  font-size: 0.6em;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${(props) => `${props.size || 1}em`};
  }
`;

export const StyledBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: var(--radiusBadge);
  top: 1em;
  right: -0.35em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  /* color: red; */
  /* background-color: green; */
  /* z-index: 9999; */
`;
