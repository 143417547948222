import React, { useState } from 'react';
import useStores from 'hooks/use-store';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Wrapper } from 'assets/ContainerStyles';
import { useResponsive, useDesktopRedirect } from 'hooks';
import { Icon } from 'components/project/shared';
import { Item, IconContainer, ItemText } from './Styles';
import { BackButton, BackWrapper } from '../BidActivity/Mobile/styles';
import { ROUTES } from '../../../../shared/constants/index';

const Lanes = () => {
  const {
    AuctionStore: {
      auctionID,
      auctionList,
      connected,
      laneList,
      openLanes,
    },
    UIStore: { handleJoinAuction, handleJoinLane, handleExitLane },
    BidStore: { getLaneIDPlayingAudio },
  } = useStores();
  const history = useHistory();

  const {
    isMobileView,
    isSmallView,
  } = useResponsive();

  const [selectedAuctionID, setSelectedAuctionID] = useState(null);

  const handleAuctionSelect = (newAuctionID) => {
    if (newAuctionID) {
      setSelectedAuctionID(newAuctionID);
      handleJoinAuction(newAuctionID);
    }
  };

  const exitLaneID = getLaneIDPlayingAudio() || openLanes[0];

  const handleLaneSelect = (newLaneID) => {
    if (newLaneID) {
      if (isMobileView) {
        handleExitLane(exitLaneID);
      }

      handleJoinLane(newLaneID);

      history.push(`${ROUTES.auction}`);
    }
  };

  useDesktopRedirect();

  return !isSmallView ? (
    <Wrapper>
      <BackWrapper>
        {auctionID && (
          <BackButton onClick={() => history.push(`${ROUTES.auction}`)}>
            <Icon className="fa fa-chevron-left icon-only" />
            Return to auction
          </BackButton>
        )}
      </BackWrapper>
      {auctionList.length === 0 && <p>No auctions available</p>}
      {!selectedAuctionID
        && auctionList.length > 0
        && connected
        && auctionList.map((item) => (
          <Item
            key={item.auctionID}
            onClick={() => handleAuctionSelect(item.auctionID)}
            data-testid={`auction-${item.auctionID}`}
          >
            <ItemText>{item.auction}</ItemText>
            <IconContainer>
              <Icon icon="chevron-right" size={1} iconOnly />
            </IconContainer>
          </Item>
        ))}

      {selectedAuctionID
        && laneList.length > 0
        && connected
        && laneList.map((item) => (
          <Item
            key={item.laneID}
            data-testid={`lane-${item.laneID}`}
            onClick={() => handleLaneSelect(item.laneID)}
          >
            <ItemText>{item.lane}</ItemText>
            <IconContainer>
              <Icon icon="chevron-right" size={1} iconOnly />
            </IconContainer>
          </Item>
        ))}
    </Wrapper>
  ) : null;
};

export default observer(Lanes);
