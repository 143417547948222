import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useWindowDimension } from 'hooks';
import { breakpoints } from 'shared/utils/styles';
import CarouselContainer from '../../CarouselContainer';
import { CarouselChild } from '../../CarouselContainer/Styles';
import RunListFilter from './RunListFilter/index';
import { Icon } from '../../shared';
import { getProductPreviewRoute } from '../../../../endpoints';

import {
  BadgeContainer,
  BadgeLabel,
  ContentWrapper,
  Gallery,
  Header,
  RunListContainer,
  RunListItem,
  RunListWrapper,
  Text,
  TextContent,
} from './Styles';

// OBSOLETE
const RunListPreview = ({ laneId, runList }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { winWidth, winHeight } = useWindowDimension();

  const isTabletView = useMediaQuery({
    query: `(min-width: ${breakpoints.tablet})`,
  });

  const isSmallView = useMediaQuery({
    query: `(min-width: ${breakpoints.small})`,
  });
  const isLargeView = useMediaQuery({
    query: `(min-width: ${breakpoints.large})`,
  });

  const isMobileView = !isTabletView && !isSmallView && !isLargeView;

  const getCarouselSettings = () => ({
    width: '100%',
    height: isMobileView ? '400px' : '400px',
    slidesPresented: 1,
    winWidth,
    winHeight,
  });

  const carouselSetting = getCarouselSettings();

  const getViewType = () => {
    if (isMobileView) return 'mobile';
    if (isLargeView) return 'large';
    if (isSmallView) return 'small';
    return 'tablet';
  };
  const viewType = getViewType();

  const mobileSettings = {
    isMobile: true,
    viewType,
    interval: 0,
    slidesPresented: carouselSetting.slidesPresented,
    showNavButtons: true,
    showCaptions: true,
    showIndicators: true,
    height: carouselSetting.height,
    width: carouselSetting.width,
    transitionTime: 400,
  };
  const desktopSettings = {
    isMobile: false,
    viewType,
    interval: 0,
    slidesPresented: carouselSetting.slidesPresented,
    showNavButtons: true,
    showCaptions: false,
    showIndicators: false,
    height: carouselSetting.height,
    width: carouselSetting.width,
    transitionTime: 100,
  };

  const settings = isMobileView ? mobileSettings : desktopSettings;

  const paginate = (array, pageSize, pageNumber) => array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  const getRunListPage = (pageSize = 3, pageNumber = 1) => {
    const items = paginate(runList, pageSize, pageNumber);
    return items.map((item, index) => (
      <RunListItem
        key={`${index}_${item.lotNumber}_${item.productState}`}
        onClick={() => {
          history.push(
            `${match.path}${getProductPreviewRoute(
              item.laneID,
              item.lotNumber,
            )}`,
          );
        }}
      >
        <img loading="lazy" src={item.url} alt={item.product} />
        <TextContent className="auction-item-details">
          <Text key={`Text1_${index}`}>{item.product}</Text>
          <Text key={`Text2_${index}`}>
            Item #:
            {item.lotNumber}
          </Text>

          <BadgeContainer key={`BadgeContainer_${index}`}>
            <BadgeLabel
              key={`BadgeLabel3_${index}`}
              bgColor="#ff0000"
              size="0.7em"
              color="#ffffff"
            >
              <Icon key={`Icon3_${index}`} icon="eye" size={0.8} iconOnly />
            </BadgeLabel>
            <BadgeLabel
              key={`BadgeLabel2_${index}`}
              bgColor="#ffff00"
              size="0.7em"
              color="#000000"
            >
              <Icon key={`Icon2_${index}`} icon="gavel" size={0.8} iconOnly />
              $4,500
            </BadgeLabel>
          </BadgeContainer>
        </TextContent>
      </RunListItem>
    ));
  };

  const RunListItemWrapper = (items) => (
    <CarouselChild key={new Date()}>{items}</CarouselChild>
  );

  const getRunListPages = () => {
    const pageSize = 4;
    const totalPages = Math.ceil(runList.length / pageSize);
    const items = [];

    // eslint-disable-next-line no-plusplus
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      items.push(RunListItemWrapper(getRunListPage(pageSize, pageNumber)));
    }

    return items;
  };

  const items = getRunListPages();

  return (
    <RunListWrapper>
      <Header>Auction Items</Header>
      <RunListFilter laneId={laneId} />
      <RunListContainer>
        <ContentWrapper>
          <Gallery>
            <CarouselContainer settings={settings}>{items}</CarouselContainer>
          </Gallery>
        </ContentWrapper>
      </RunListContainer>
    </RunListWrapper>
  );
};

RunListPreview.propTypes = {
  laneId: PropTypes.string.isRequired,
  runList: PropTypes.object,
};

RunListPreview.defaultProps = {
  runList: {},
};

export default RunListPreview;
