import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme, useStores } from 'hooks';
import { ThemeButton } from '..';
import { STAGING_URL, LOCAL_URL } from '../../../shared/constants';
import styled from 'styled-components';

export const ThemeButtons = observer(() => {
  const { BidStore: { user } } = useStores();
  const { theme, updateTheme } = useTheme();

  const DEFAULT_THEME = 'default';
  const ORIGINAL_THEME = 'og1';
  const ABYSS_THEME = 'abyss';
  const NPA_DARK_THEME = 'npaDark';
  const SGA_THEME = 'sga';

  const checkUrl = (url) => window.location.hostname.indexOf(url) > -1;
  // const isProduction = checkUrl(PROD_URL);
  // const isUAT = checkUrl(UAT_URL);
  const isLocal = checkUrl(LOCAL_URL);
  const isStaging = checkUrl(STAGING_URL);
  const isAMSAccount = user.memberName === 'NPA';

  const showThemeButtons = isAMSAccount || isLocal || isStaging;
  const onlyShowOnLocalOrStage = isLocal || isStaging;

  // console.log('showButtons', { showThemeButtons, isProduction, isStaging, isUAT, isLocal, isAMSAccount, location: window.location })

  if (!showThemeButtons) return null;

  return (
    <Wrapper>
      <ThemeButton
        onClick={() => updateTheme(DEFAULT_THEME)}
        text="Default"
        color="#AC0000"
        isActive={theme === DEFAULT_THEME}
      />
      {onlyShowOnLocalOrStage && (
        <ThemeButton
          onClick={() => updateTheme(ORIGINAL_THEME)}
          text="Original"
          color="#009E0F"
          isActive={theme === ORIGINAL_THEME}
        />
      )}
      <ThemeButton
        onClick={() => updateTheme(NPA_DARK_THEME)}
        text="NPA (Dark)"
        color="#444444"
        isActive={theme === NPA_DARK_THEME}
      />
      <ThemeButton
        onClick={() => updateTheme(ABYSS_THEME)}
        text="Abyss (Dark)"
        color="#465586"
        isActive={theme === ABYSS_THEME}
      />
      <ThemeButton
        onClick={() => updateTheme(SGA_THEME)}
        text="Stargate Atlantis (Dark)"
        color="#47A7C1"
        isActive={theme === ABYSS_THEME}
      />
    </Wrapper>
  );
});

export default ThemeButtons;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  margin-top: -2em;
  & div {
    margin: 0 5px;
  }
  & div:hover {
    cursor: pointer;
  }
`;
