import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const LaneContext = createContext({
  laneID: null,
});

export const LaneWrapper = ({ laneID, children }) => (
  <LaneContext.Provider value={{ laneID }}>{children}</LaneContext.Provider>
);

LaneWrapper.propTypes = {
  laneID: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export const useLaneState = () => useContext(LaneContext);
