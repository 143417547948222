import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import {
  VerticalList, Chat, ChatIcon, AuctionLaneContent,
} from 'components/project';

import { Icon } from '../../shared';

import {
  ContentContainer, Container, NavButton, PopupView, CloseContainer,
} from './Styles';

import PopupNav from './PopupNav';
import MobileNavButton from './MobileNavButton';
import {sortAscending} from "../../../../shared/utils/javascript";
import Analytics from "../../../../shared/utils/analytics";

export const BottomMobileNav = observer(({ laneID, unreadMessages }) => {
  const {
    AuctionStore: { auctionInfo },
    BidStore: { setAuctionProperty },
    MessageStore: {
      directMessagesMap,
      setOpenChatProperty, setUnreadMessageProperty, setReadMessageProperty,
      setNewMessageProperty, readMessages,
      newMessages, },
      UIStore: {toggleMobileNav, mobileNavOpen}
  } = useStores();

  const setProductPreview = (value) => setAuctionProperty(laneID, 'showProdPreview', value);
  const allowChat = auctionInfo.allowChat === 1;

  const [componentToShow, setComponentToShow] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [viewSize, setViewSize] = useState('small');

  const handleNavClick = (comp, size, clickedButton) => {
    setProductPreview(false);
    if (clickedButton === activeButton) {
      setComponentToShow(null);
      toggleMobileNav(false);
      setViewSize('small');
      setActiveButton(null);
      setOpenChatProperty(laneID, false);
      return;
    }

    toggleMobileNav(true);
    setComponentToShow(comp);
    setViewSize(size);
    setActiveButton(clickedButton);
    if (clickedButton === 'Messages') setOpenChatProperty(laneID, true);
  };

  const messagesMap = sortAscending(
      Array.from(directMessagesMap.get(laneID)?.values() || []),
      'timestamp',
  );

  useEffect(() => {
    if (activeButton === 'Messages') {
      setReadMessageProperty(laneID, messagesMap.length);
    } else {
      setNewMessageProperty(laneID, messagesMap.length);
    }
  }, [messagesMap.length, setReadMessageProperty, setNewMessageProperty, laneID, activeButton]);

  const readMessagesCount = readMessages[laneID] || 0;
  const newMessagesCount = newMessages[laneID] || 0;

  const unreadCount = readMessagesCount >= newMessagesCount ? 0 : newMessagesCount - readMessagesCount;

  setUnreadMessageProperty(laneID, unreadCount);

  useEffect(() => {
    if (!mobileNavOpen) {
      setComponentToShow(null);
      toggleMobileNav(false);
      setViewSize('small');
      setActiveButton(null);
      setOpenChatProperty(laneID, false);
    }
    // eslint-disable-next-line
  }, [mobileNavOpen]);

  return (
    <>
      {componentToShow && (
        <PopupView
          viewSize={viewSize}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <CloseContainer>
            <NavButton onClick={() => handleNavClick(null, null, null)}>
              <Icon icon="chevron-down" iconOnly />
            </NavButton>
          </CloseContainer>
          <ContentContainer>
            {componentToShow}
          </ContentContainer>
        </PopupView>
      )}

      {/*<MobileNavButton key={'ChatToggle'} onClick={() => handleNavClick(<Chat key={unreadMessages[laneID]} laneID={laneID} openChatUI />, 'small', 'More')} icon="comment" iconText="Messages" activeButton={activeButton} />*/}

      <Container isOpen={componentToShow !== null} chatIsOpen={activeButton === 'Messages'}>
        {allowChat && (
            <ChatIcon
            key={`chat_${unreadMessages[laneID]}`}
            count={unreadMessages[laneID]}
            onClick={() => handleNavClick(<Chat key={laneID} laneID={laneID} openChatUI />, 'small', 'Messages')}
            iconText="Messages"
            isActive={activeButton === 'Messages'}
        />
        )}

        <MobileNavButton
          onClick={() => handleNavClick(<VerticalList
            laneId={laneID}
            key={Math.random() + laneID}
            onClick={(lotNumber, productID) => {
              setProductPreview(true);
              setAuctionProperty(laneID, 'previewLotNumber', lotNumber);
              setAuctionProperty(laneID, 'previewProductID', productID);

              Analytics.RecordEvent('Run List Product Click', 'Preview', 'Lot Number:', lotNumber);
            }}
          />, 'full', 'Inventory')}
          icon="th-list"
          iconText="Inventory"
          activeButton={activeButton}
        />
        <MobileNavButton key={Math.random() + laneID} onClick={() => handleNavClick(<AuctionLaneContent />, 'small', 'Lanes')} icon="road" iconText="Lanes" activeButton={activeButton} />
        <MobileNavButton key={Math.random() + laneID} onClick={() => handleNavClick(<PopupNav />, 'small', 'More')} icon="ellipsis-h" iconText="More" activeButton={activeButton} />
      </Container>
    </>
  );
});

BottomMobileNav.propTypes = {
  laneID: PropTypes.string.isRequired,
};

export default BottomMobileNav;
