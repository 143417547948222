import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/project/shared';
import { StyledButton } from './Styles';

export const RefreshButton = ({
  onClick, text, icon,
}) => (
  <StyledButton
    onClick={onClick}
    color="var(--utilityBtnTextColor)"
    bgColor="var(--utilityBtnBgColor)"
    bgVariance={20}
    width="150px"
  >
    <Icon icon={icon} />
    {text}
  </StyledButton>
);

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default RefreshButton;
