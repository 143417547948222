import { useState, useEffect } from 'react';
import { isFunction } from 'lodash';
import Analytics from "../shared/utils/analytics";

export const useWatchListItem = ({
  initialValue = false,
  watchList = [],
  agent = null,
  loadWatchlist = null,
  laneID = null,
  productID = null,
  lotNumber = null,
  userIsSeller,
  isOnBlock,
  isUpcomingItem,
  setAuctionProperty,
}) => {
  const [_error, setError] = useState([]);
  const [isWatching, setIsWatching] = useState(initialValue);
  const addError = (message) => setError((err) => [...err, message]);

  /** If we're watching an item, we send a delete request with the productWatchListID,
   * otherwise we send a create request with just the productID
   * @returns {Promise<void>}
   */
  const toggleWatchList = async () => {
    const { obj, host } = agent;
    try {
      if (!userIsSeller && isWatching) {
        const watchlistItem = watchList.filter(
          (item) => item.productID === productID,
        );
        await obj.delete(watchlistItem[0].productWatchListID, host);
        setIsWatching(false);
      }

      if (!isWatching) {
        await obj.create(productID, host);
        setIsWatching(true);
      }

      Analytics.RecordEvent('Watch List Button', 'Preview', 'Is Watching', !isWatching)

      await loadWatchlist(laneID);
    } catch (error) {
      addError(`Error with toggleWatchList: ${error}`);
    }
  };

  const showWatchListButton = !userIsSeller && isUpcomingItem && !isOnBlock;

  const generateNewNumber = () => Math.random() * 6;

  useEffect(() => {
    if (!userIsSeller && watchList) {
      const watching = watchList.filter((item) => item.productID === productID);
      setIsWatching(watching.length > 0);
    }
    setAuctionProperty(laneID, 'lastRunListItemUpdate', `watch-${generateNewNumber()}`);
    // eslint-disable-next-line
  }, [watchList, lotNumber]);

  useEffect(() => {
    if (!agent) addError('valid agent object required');
    if (!isFunction(loadWatchlist)) addError('loadWatchlist required');
    if (!lotNumber) addError('lotNumber required');
    if (!productID) addError('productID required');
    if (!laneID) addError('laneID required');
    // eslint-disable-next-line
  }, []);

  return {
    isWatching,
    setIsWatching,
    showWatchListButton,
    toggleWatchList,
    error: { category: 'Watch List', error: _error },
    lastRunListItemUpdate: null,
  };
};

export default useWatchListItem;
