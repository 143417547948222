export const useBidderStats = (stats) => {
  const totalPurchasedLabel = 'Total Purchased:';
  const totalSubjectToLabel = 'Total Pending:';
  const remainingProxiesLabel = 'Remaining Proxies:';

  if (!stats) {
    return {
      remainingProxiesLabel,
      remainingProxies: 0,
      remainingProxiesAmountFormatted: '$ 0',
      totalPurchased: 0,
      totalPurchasedAmountFormatted: '$ 0',
      totalPurchasedLabel,
      totalSubjectTo: 0,
      totalSubjectToFormatted: '$ 0',
      totalSubjectToLabel,
    };
  }

  const {
    remainingProxies,
    remainingProxiesAmountFormatted,
    totalPurchased,
    totalPurchasedAmountFormatted,
    totalSubjectTo,
    totalSubjectToFormatted,
  } = stats;

  return {
    remainingProxiesLabel,
    remainingProxies,
    remainingProxiesAmountFormatted,
    totalPurchased,
    totalPurchasedAmountFormatted,
    totalPurchasedLabel,
    totalSubjectTo,
    totalSubjectToFormatted,
    totalSubjectToLabel,
  };
};

export default useBidderStats;
