import styled from 'styled-components';
// TODO: ADD TO THEME
export const NavLeft = styled.div`
  background-color: #2e2e2e;
  padding-top: 2vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100vh;
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;

export const NavListWrap = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const NavItem = styled.li`
  padding: 15px;
  margin: 0;
  border-bottom: 1px #565656 solid;
`;

export const Link = styled.button`
  text-align: left;
  width: 100%;
  height: 100%;
  color: white;
  outline: 0;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const LinkText = styled.p`
  margin: 2px 0 0 20px;
`;

export const Icon = styled.i`
  margin: 0 10px 0 0;
  font-size: 1.2rem;
  position: absolute;
  left: 15px;
`;
