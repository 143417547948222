import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';

const isFullViewLayoutWrapperCss = css`
  height: 385px;
  width: 100%; 
  & h2 { 
    font-size: .8em;
  }
  & .chat-body {
    height: 300px;
    & div > div {
      font-size: .85em;
    }
  }
  & .chat-input-container {
    height: 50px;
    & button { 
      width: 100px;
      font-size: .8em;
    }
  }
  
  @media (min-width: ${breakpoints.tablet}) {
    display: block;   
    background: var(--white);
    align-items: center;
    border: var(--line);
    border-right: none;
    border: none;
    box-sizing: border-box;
  }  
`;

const defaultViewWrapperCss = css`
  height: 200px;
  & .chat-body {
    height: 175px;
  }
  & .chat-input-container {
    height: 25px;
    & textarea {
      background-color: var(--white);
      color: var(--darkTextColor);
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    width: 250px;
    background: var(--white);
    height: ${(props) => (props.expanded ? '320px' : '40px')};
    align-items: center;
    border-top: none;
    border-left: ${(props) => (props.laneStatusName === 'Ended' || props.laneStatusName === 'NotRunning' ? 'var(--line)' : 'none')};
    border-right: ${(props) => (props.laneStatusName === 'Ended' || props.laneStatusName === 'NotRunning' ? 'none' : 'var(--line)')};
    border-bottom: var(--line);
    box-sizing: border-box;
    & .chat-body {
      height: 250px;      
    }
    & .chat-input-container {
      height: 30px;
    }
  }
`;

export const Wrapper = styled(motion.div)`
  display: block;
  width: 95vw;
  ${({ isFullViewLayout }) => (isFullViewLayout ? isFullViewLayoutWrapperCss : defaultViewWrapperCss)}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  background-color: var(--subHeaderBgColor);
  height: 30px;
  color: var(--subHeaderTextColor);
`;

export const LaneTitle = styled.h2`
  color: inherit;
  font-size: 0.9rem;
`;

export const Body = styled.div`
  display: block;
  width: 100%;
  height: 250px;
  margin: 0;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
  padding: 5px 5px 0px 5px;
  background-color: var(--white);
`;

export const MessageFromClerk = styled.div`
  display: flex;
  font-size: 0.7rem;
  margin: 0.15em 0;
  text-align: left;
  background: ${(props) => ((props.type === 'indiv') ? 'var(--clerkMsgBgColor)' : 'var(--globalMsgBgColor)')};
  color: ${(props) => ((props.type === 'indiv') ? 'var(--clerkMsgTextColor)' : 'var(--globalMsgTextColor)')};
  width: 85%;
  min-width: 85%;
  max-width: 85%;
  float: left;
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
  justify-content: space-between;
  & div:first-child {
    width: 75%;
    text-align: left;
  }
  & div:last-child {
    width: 25%;
    text-align: right;
    align-self: flex-end;
  }
`;

export const MessageFromBidder = styled.div`
  display: flex;
  font-size: 0.7rem;
  margin: 0.15em 0;
  width: 85%;
  min-width: 85%;
  max-width: 85%;
  text-align: right;
  background: var(--dealerMsgBgColor);
  color: var(--dealerMsgTextColor);
  float: right;
  padding: 5px;
  border-radius: 3px;
  justify-content: space-between;
  & div:first-child {
    width: 25%;
    text-align: left;
    align-self: flex-end;
  }
  & div:last-child {
    width: 75%;
    text-align: right;
  }
`;

export const Message = styled.div``;

export const Timestamp = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  height: 25px;
  padding: 0px 5px;
`;

export const Input = styled.textarea`
  width: ${(props) => (props.isNotStopped || props.isMobile ? '75%' : '90%')};
  font-size: ${(props) => (props.isMobile ? '0.9em' : '0.8em')};
  &:focus {
    outline: none;
  }
`;

export const SendButton = styled.button`
  width: 50px;
  background: var(--sendBtnBgColor);
  font-size: 0.7rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const NoMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 1.5em;
  color: var(--dealerMsgBgColor);
`;
