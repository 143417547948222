import styled from 'styled-components';

export const ClosePinnedNavContainer = styled.div`
  //margin-top: -30px;
  margin-right: -35px;
  padding: 0;
  text-align: right;
  position: absolute;
  right: 40px;
  & button {
    margin-right: -15px;
  }
`;

export const IconButton = styled.button`
  outline: none;
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  &:hover {
    cursor: pointer;
  }
`;
