import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';

import { Container, StatusText, ReloadText } from './styles';

const ConnectionStatus = observer(() => {
  const { AuctionStore: { networkConnected }, BidStore: { apiError }} = useStores();

  if (!networkConnected) {
      return (
          <Container>
              <StatusText>
                  Connection Lost. Reconnecting...
              </StatusText>
          </Container>
      );
  }

  if (apiError) {
      return (
          <Container>
            <StatusText>
              Failed to Load Product ({apiError})
            </StatusText>
            <ReloadText onClick={() => window.location.reload()}>
                Try again?
            </ReloadText>
          </Container>
      )
  }

  return null;
});

export default ConnectionStatus;
