import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from '@microsoft/signalr';
import { SIM_HUB_ENDPOINT } from 'shared/constants';

export const INVALID_QUERYSTRING_MSG = 'A querystring is required to establish a socket connection';
export const INVALID_HOST_MSG = 'A host is required to establish a socket connection';

const calculateIntervals = () => {
  const totalTimeinMins = 10;
  const intervalInSeconds = 5;

  const next = [];

  for (let i = 1; i <= (totalTimeinMins * 60 / intervalInSeconds); i++) {
    next.push(1000 * i * intervalInSeconds);
  }

  return next;
};

class SignalRMiddleware {
  constructor(host, querystring) {
    if (!host) throw new Error(INVALID_HOST_MSG);
    if (!querystring) throw new Error(INVALID_QUERYSTRING_MSG);
    this.url = `${host}${SIM_HUB_ENDPOINT}${querystring}`;
  }

  openSocket = () => new HubConnectionBuilder()
    .withUrl(this.url, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect(calculateIntervals(),)
    .configureLogging(LogLevel.Information)
    .build();
}

export default SignalRMiddleware;
