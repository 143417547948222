/*
NOTE: Using this as a sandbox environment to validate the default theme and 
determine what other elements need css variables to improve theming.
*/
import { css } from 'styled-components';
const BG_ACCENT = '#8093F1';
const ZEBRA1 = '#F7AEF8';
const ZEBRA2 = '#FDC5F5';
const ZEBRA3 = '#B388EB';
const ZEBRA4 = '#F7AEF8';
const ZEBRA5 = '#79B8F4';
const ZEBRA6 = '#76CBF6';
const RED_ALT = '#a62b2b';


export const WHITE = '#72DDF7';
export const GRAY1 = WHITE; //'#EEEEEE';
export const GRAY2 = '#E7E7E7';
export const GRAY3 = '#D7D8D8'; // DEALER MESSAGE
export const GRAY4 = '#D2D1D2';
export const GRAY5 = '#CFCFD1'; // DISABLED BUTTON
export const GRAY6 = '#808080';
export const GRAY7 = '#888888'; // SUBHEADER BG (IE. PREVIEW)
export const GRAY8 = '#8093F1'; // HEADER BG
export const BLACK = 'purple';
export const BROWN1 = '#BFBCAC'; // SELLER ITEMS
export const RED1 = '#FF0000'; // ERROR MESSAGES
export const RED2 = '#DB2828'; // NO SALE
export const RED3 = '#F7AEF8'; // BIDDER BAR
export const BLUE1 = '#D3EFFC'; // WATCHING
export const BLUE2 = '#32A9D2'; // WATCH BUTTON
export const WATCH_GRADIENT = {
  color1: {
    hex: '#32A9D2',
    hsla: 'hsla(209, 85%, 72%, 1)',
  },
  color2: {
    hex: '#344772',
    hsla: 'hsla(299, 84%, 83%, 1)',
  },
  gradient: css`
    background: hsla(209, 85%, 72%, 1);
    background: linear-gradient(
      145deg,
      hsla(209, 85%, 72%, 1) 0%,
      hsla(299, 84%, 83%, 1) 100%
    );
    background: -moz-linear-gradient(
      145deg,
      hsla(209, 85%, 72%, 1) 0%,
      hsla(299, 84%, 83%, 1) 100%
    );
    background: -webkit-linear-gradient(
      145deg,
      hsla(209, 85%, 72%, 1) 0%,
      hsla(299, 84%, 83%, 1) 100%
    );
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#32A9D2", endColorstr="#344772", GradientType=1 );
  `,
};
export const BLUE3 = '#04A1D3'; // CLERK MESSAGE
export const BLUE4 = RED_ALT; // UTILITY BUTTONS
export const BLUE5 = '#1C6C98'; // GLOBAL MESSAGE
export const YELLOW1 = '#EEBF04'; //'#FBCC08'; // PROXY/PENDING
export const YELLOW2 = '#D8B006'; // BORDER
export const GREEN1 = '#21BA45'; // WON MESSAGE
export const GREEN2 = '#009E0F'; // BID BUTTON
export const ORANGE1 = "#FFE100"; // PINNED MESSAGE




const BACKGROUND = ZEBRA2;

const DIVIDER = WHITE;
const DARK_TEXT_COLOR = BLACK;
const LIGHT_TEXT_COLOR = WHITE;

/*
bodyBgColor
formElementBgColor
promoBgColor
activeBtnBgColor
btnBgColor
highBidTextColor
fixedBidDetailsBgColor
*/

export const candyHeartTheme = (overrides = {}) => {
  return Object.assign(
    {
      black: BLACK,
      blue1: BLUE1,
      blue2: BLUE2,
      blue3: BLUE3,
      blue4: BLUE4,
      blue5: BLUE5,
      brown1: BROWN1,
      gray1: GRAY1,
      gray2: GRAY2,
      gray3: GRAY3,
      gray4: GRAY4,
      gray5: GRAY5,
      gray6: GRAY6,
      gray7: GRAY7,
      gray8: GRAY8,
      green1: GREEN1,
      green2: GREEN2,
      orange1: ORANGE1,
      red1: RED1,
      red2: RED2,
      red3: RED3,
      white: WHITE,
      yellow1: YELLOW1,
      yellow2: YELLOW2,
      activeLaneTextColor: DARK_TEXT_COLOR,
      badgeValueTextColor: DARK_TEXT_COLOR,
      bidActivityBgColor1: ZEBRA1,
      bidActivityBgColor2: ZEBRA2,
      bidActivityTextColor: DARK_TEXT_COLOR,
      bidBtnBgColor: ZEBRA3,
      bidBtnTextColor: DARK_TEXT_COLOR,
      blockAttrsBgColor1: ZEBRA3,
      blockAttrsBgColor2: ZEBRA4,
      blockAttrsTextColor1: DARK_TEXT_COLOR,
      blockAttrsTextColor2: DARK_TEXT_COLOR,
      cancelBtnBgColor: ZEBRA1,
      cancelBtnTextColor: DARK_TEXT_COLOR,
      clerkMsgBgColor: ZEBRA1,
      clerkMsgTextColor: DARK_TEXT_COLOR,
      crAttrsBgColor1: ZEBRA3,
      crAttrsBgColor2: ZEBRA4,
      crAttrsTextColor1: DARK_TEXT_COLOR,
      crAttrsTextColor2: DARK_TEXT_COLOR,
      darkTextColor: DARK_TEXT_COLOR,
      dealerMsgBgColor: BG_ACCENT,
      dealerMsgTextColor: DARK_TEXT_COLOR,
      disabledBtnBgColor: ZEBRA1,
      disabledBtnTextColor: DARK_TEXT_COLOR,
      divider: DIVIDER,
      dividerLine: `.15em solid ${DIVIDER}`,
      errorColor: DARK_TEXT_COLOR,
      errorMsgBgColor: BG_ACCENT,
      errorMsgDarkTextColor: DARK_TEXT_COLOR,
      errorMsgLightTextColor: LIGHT_TEXT_COLOR,
      globalMsgBgColor: ZEBRA6,
      globalMsgTextColor: DARK_TEXT_COLOR,
      headerBgColor: BG_ACCENT,
      headerTextColor: DARK_TEXT_COLOR,
      lightTextColor: LIGHT_TEXT_COLOR,
      line: `solid 1px ${BG_ACCENT}`,
      linkTextColor: DARK_TEXT_COLOR,
      lotNumberBgColor: ZEBRA4,
      lotNumberTextColor: DARK_TEXT_COLOR,
      mobileBlockAttrsBgColor1: ZEBRA1,
      mobileBlockAttrsBgColor2: ZEBRA2,
      mobileBlockAttrsTextColor1: DARK_TEXT_COLOR,
      mobileBlockAttrsTextColor2: DARK_TEXT_COLOR,
      mobileBottomNavBgColor: BACKGROUND,
      mobileBottomNavTextColor: DARK_TEXT_COLOR,
      mobileCrAttrsBgColor1: ZEBRA3,
      mobileCrAttrsBgColor2: ZEBRA4,
      mobileCrAttrsTextColor1: DARK_TEXT_COLOR,
      mobileCrAttrsTextColor2: DARK_TEXT_COLOR,
      mobilePrevAttrsBgColor1: ZEBRA1,
      mobilePrevAttrsBgColor2: ZEBRA2,
      mobilePrevAttrsTextColor1: DARK_TEXT_COLOR,
      mobilePrevAttrsTextColor2: DARK_TEXT_COLOR,
      mobileRunListBgColor1: ZEBRA5,
      mobileRunListBgColor2: ZEBRA6,
      mobileRunListTextColor1: DARK_TEXT_COLOR,
      mobileRunListTextColor2: DARK_TEXT_COLOR,
      myProxyBgColor: YELLOW1,
      myProxyTextColor: DARK_TEXT_COLOR,
      navBgColor: BG_ACCENT,
      navTextColor: DARK_TEXT_COLOR,
      noSaleMsgBgColor: BG_ACCENT,
      noSaleMsgTextColor: DARK_TEXT_COLOR,
      pendingMsgBgColor: BG_ACCENT,
      pendingMsgTextColor: DARK_TEXT_COLOR,
      pinMsgBgColor: ORANGE1,
      pinMsgTextColor: DARK_TEXT_COLOR,
      playerMsgBgColor: ORANGE1,
      playerMsgTextColor: DARK_TEXT_COLOR,
      prevAttrsBgColor1: ZEBRA3,
      prevAttrsBgColor2: ZEBRA4,
      prevAttrsTextColor1: DARK_TEXT_COLOR,
      prevAttrsTextColor2: DARK_TEXT_COLOR,
      previewTitleBgColor: ZEBRA1,
      previewTitleTextColor: DARK_TEXT_COLOR,
      radiusBadge: `50%`,
      radiusBox: `10px`,
      radiusModal: `4px`,
      radiusNone: `0px`,
      runListBgColor1: ZEBRA5,
      runListBgColor2: ZEBRA6,
      runListHoverBgColor: BG_ACCENT,
      runListTextColor1: DARK_TEXT_COLOR,
      runListTextColor2: DARK_TEXT_COLOR,
      sellerItemBgColor: BROWN1,
      sellerItemTextColor: BLACK,
      sellerOkToSellBgColor: GREEN2,
      sellerOkToSellTextColor: LIGHT_TEXT_COLOR,
      sellerPassBgColor: RED2,
      sellerPassTextColor: LIGHT_TEXT_COLOR,
      sellerReserveTextColor: RED2,
      sellerTakesBgColor: BLUE4,
      sellerTakesOkBgColor: BLUE4,
      sellerTakesOkTextColor: LIGHT_TEXT_COLOR,
      sellerTakesTextColor: LIGHT_TEXT_COLOR,
      sendBtnBgColor: BG_ACCENT,
      sendBtnTextColor: DARK_TEXT_COLOR,
      shadedBgColor: BACKGROUND,
      subHeaderBgColor: BG_ACCENT,
      subHeaderTextColor: DARK_TEXT_COLOR,
      successColor: DARK_TEXT_COLOR,
      utilityActionBgColor: BG_ACCENT,
      utilityActionTextColor: DARK_TEXT_COLOR,
      utilityBtnBgColor: BG_ACCENT,
      utilityBtnTextColor: DARK_TEXT_COLOR,
      watchingBgColor: BG_ACCENT,
      watchingTextColor: DARK_TEXT_COLOR,
      wonMsgBgColor: BG_ACCENT,
      wonMsgTextColor: DARK_TEXT_COLOR,
      watchingBgGradientColor: WATCH_GRADIENT,
    },
    overrides,
  );
};

export default candyHeartTheme;
