import { useState, useEffect } from 'react';

const useWindowDimension = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    // window.addEventListener('resize', handleResize);
    return () => {
      // window.removeEventListener('resize', handleResize);
    };
  });

  return { winWidth: width, winHeight: height };
};

export default useWindowDimension;
