import { useState, useEffect } from 'react';
import { formatCurrency } from '../components/project/shared/utils/common';

export const useSeller = ({
  initialValue = false,
  sellerProducts,
  lotNumber,
}) => {
  const NOT_APPLICABLE = 'N/A';
  const [isSeller, setIsSeller] = useState(initialValue);
  const [reserve, setReserve] = useState(null);
  const [formattedReserveAndType, setFormattedReserveAndType] = useState(NOT_APPLICABLE);

  const reserveAsCurrency = (amount) => `$ ${new Intl.NumberFormat().format(amount)}`;

  const hasSellerProducts = sellerProducts?.length > 0;

  useEffect(() => {
    const getFormattedReserve = (amount, reserveType) => {
      if (amount === 0 && reserveType !== 'No Reserve') return NOT_APPLICABLE;
      if (amount === 0 && reserveType === 'No Reserve') return 'No Reserve';
      if (amount > 0 && reserveType !== '-') return `${reserveAsCurrency(amount)} ${reserveType}`;
      return reserveAsCurrency(amount);
    };

    const sellerProduct = sellerProducts.filter(
      (product) => product.lotNumber === lotNumber,
    );
    const hasSellerProduct = sellerProduct.length > 0;
    setIsSeller(hasSellerProduct);

    if (hasSellerProduct) {
      const { reserve: amount, reserveType: type } = sellerProduct[0];
      if (amount) {
        const formattedReserve = `${formatCurrency(amount)}`;
        setReserve({ amount: formattedReserve, type });
        setFormattedReserveAndType(getFormattedReserve(amount, type));
      } else {
        setFormattedReserveAndType(NOT_APPLICABLE);
      }
    }
  }, [sellerProducts, lotNumber]);

  return { isSeller, reserve, formattedReserveAndType, hasSellerProducts };
};

export default useSeller;
