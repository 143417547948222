import * as React from 'react';
import styled from 'styled-components';
import { Modal } from '..';
import { Button, lightenDarkenColor } from '../utils/styles';
import { breakpoints } from '../../../../shared/utils/styles';

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledConfirmModal = styled((props) => <Modal {...props} />)`
  padding: 35px 40px 40px;
  position: absolute;
  top: calc(50vh - (300px / 2));
  left: calc(50vw - (400px / 2));
  @media (min-width: ${breakpoints.tablet}) {
    left: calc(50vw - (800px / 2));
  }
`;

export const Title = styled.div`
  padding-bottom: 25px;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.5;
`;

export const Message = styled.p`
  padding-bottom: 25px;
  white-space: pre-wrap;
  font-size: 1em;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  margin-right: 10px;
  align-items: center;
  padding: 5px 20px;
  height: 30px;
  border: none;
  background-color: #e7e7e7;
  color: #666;
  &:hover {
    background-color: ${lightenDarkenColor('#e7e7e7', -20)};
    color: #666;
  }
`;

export const ConfirmButton = styled(StyledButton)`
  margin-right: 0;
  background-color: #0095E8;
  color: #ffffff;
  &:hover {
    background-color: ${lightenDarkenColor('#0095E8', 20)};
    color: #ffffff;
  }
`;
