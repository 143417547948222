import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import logo from 'assets/images/logo-inverse.png';
import LoadingModal from 'components/shared/LoadingModal';
import {useStores, useResponsive} from 'hooks';
import { Content } from 'assets/ContainerStyles';
import { ROUTES } from '../../shared/constants/index';
import {
  LogoWrapper,
  Logo,
  BodyContent,
} from './Styles';

const Login = () => {
  const {
    AuctionStore: { connected },
    DataStore: { reconnecting, reconnectionErrorMessage },
  } = useStores();
  const history = useHistory();

  useResponsive();

  useEffect(() => {
    if (connected) {
      history.push(`${ROUTES.auction}`);
    }
  });

  if (reconnecting) {
    return <LoadingModal />;
  }

  if (!reconnecting && !connected) {
    return (
      <>
        <Content>
          <BodyContent>
            <h1>Unable to connect</h1>
            <p>{reconnectionErrorMessage || 'Try refreshing your browser or logging in again'}</p>
            <p>
              <button
                type="button"
                style={{
                  color: 'var(--utilityBtnTextColor)',
                  backgroundColor: 'var(--utilityBtnBgColor)',
                }}
                onClick={() => history.go()}
              >
                Refresh
              </button>
            </p>
          </BodyContent>
        </Content>
      </>
    );
  }

  return null;
};

export default observer(Login);
