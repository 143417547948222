export const UAT_WS_URL = 'http://npasimulcastwsuat.npauctions.com';
export const UAT_API_URL = 'http://npasimulcastclientapiuat.npauctions.com';
export const DEV_WS_URL = 'http://npasimulcastwsstage.npauctions.com';
export const DEV_API_URL = 'http://npasimulcastclientapistage.npauctions.com';
export const PROD_URL = 'npasimulcastwebbidder.npauctions.com';
export const UAT_URL = 'npasimulcastwebbidderuat.npauctions.com';
export const STAGING_URL = 'npasimulcastwebbidderstage.npauctions.com';
export const LOCAL_URL = 'localhost';
export const SIM_HUB_ENDPOINT = '/SimulcastHub';
export const ENDPOINT_TYPE = {
  WebSocket: 'ws',
  Api: 'api',
};
export const UAT_WS_HUB_ENDPOINT = `${UAT_WS_URL}${SIM_HUB_ENDPOINT}`;
export const UAT_API_HUB_ENDPOINT = `${UAT_API_URL}${SIM_HUB_ENDPOINT}`;
export const DEV_WS_HUB_ENDPOINT = `${DEV_WS_URL}${SIM_HUB_ENDPOINT}`;
export const DEV_API_HUB_ENDPOINT = `${DEV_API_URL}${SIM_HUB_ENDPOINT}`;

export const EST_MINUTES_PER_AUCTION_ITEM = 0.5;

export const JANUS_STREAMING_PLUGIN = 'janus.plugin.streaming';
export const JANUS_OPAQUE_ID = (rnd) => `npa-attendee-${rnd}`;

export const API_PRODUCT_ENDPOINT = '/api/Product/Read';
export const API_AUTH_LOGIN_ENDPOINT = '/api/Login?MobileApp=1';

export const API_PROXY_ENDPOINT = '/api/ProxyBid';
export const API_WATCHLIST_ENDPOINT = '/api/ProductWatchList';

export const ROUTES = {
  auction: '/auction',
  bidActivity: '/bidActivity',
  chat: '/chat',
  lanes: '/lanes',
  login: '/login',
  prodPreview: '/lane/:laneID/preview/:lotNumber',
  settings: '/settings',
};

export const MAX_PRODUCT_NAME_LEN = 27;
export const MAX_AUCTION_LANE_LEN = 40;
export const MAX_AUCTION_LANE_AND_PRODUCT_MOBILE_LEN = 40;

export const DEFAULT_LAYOUT_VIEW = 'default';
export const MINI_LAYOUT_VIEW = 'mini';
export const FULL_LAYOUT_VIEW = 'full';

export const GOOGLE_ANALYTICS_KEY = 'G-YLMX37XFLQ';