import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStores } from 'hooks';
import {
  Container, Label, FilterButton, InputButtonGroup,
} from './Styles';
import { Icon, Select, Input } from '../../../shared';

const RunListFilter = ({ laneId }) => {
  const {
    AuctionStore: { isFullViewLayout },
    BidStore: { auction },
    UIStore: { handleRunListFilter },
  } = useStores();

  const auctionLane = auction[laneId];
  const runListFilterType = auctionLane.runListFilter.filterType;
  const runListFilterValue = auctionLane.runListFilter.filterValue;

  const filterValueRef = useRef(null);
  const filterTypeRef = useRef(null);
  const [filterValue, setFilterValue] = useState(runListFilterValue);
  const [filterType, setFilterType] = useState(runListFilterType);
  const showInput = filterType === 'itemNumber' || filterType === 'product';

  const filterSelectionData = [
    { value: 'upcoming', text: 'Upcoming Units' },
    { value: 'previous', text: 'Previous Units' },
    { value: 'itemNumber', text: 'Item #' },
    { value: 'product', text: 'Product Name' },
    { value: 'myWatchList', text: 'My Watch List' },
    { value: 'myProxyBids', text: 'My Proxy Bids' },
    { value: 'myPurchases', text: 'My Purchases' },
    { value: 'myPending', text: 'My Pending' },
    { value: 'myInventory', text: 'My Inventory' },
    { value: 'allSold', text: 'All Sold' },
    { value: 'allPending', text: 'All Pending' },
    { value: 'allNoSale', text: 'All No Sale' },
  ];

  const onFilterSelectionChange = (e) => {
    const doShowInput = e.target.value === 'itemNumber' || e.target.value === 'product';
    if (!doShowInput) {
      setFilterType(e.target.value);
      handleRunListFilter(laneId, e.target.value, e.target.text);
    } else {
      setFilterValue('');
      setFilterType(e.target.value);
    }
  };
  const onFilterInputChange = (e) => setFilterValue(e.target.value);
  const onFilterKeyUp = () => {};
  const onFilterClick = () => handleRunListFilter(laneId, filterType, filterValue);
  const getKeyCode = (e) => e.which || e.keyCode;
  const isEnterKey = (e) => getKeyCode(e) === 13;
  const isTabKey = (e) => getKeyCode(e) === 9;
  const onSelectKeyPress = (e) => {
    e.preventDefault();

    if (isEnterKey(e)) {
      onFilterClick();
    }
  };

  const onInputKeyPress = (e) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      onFilterClick();
      return;
    }

    if (isTabKey(e)) {
      return;
    }

    setFilterValue(e.target.value);
  };

  const onFilterClearClick = () => {
    setFilterValue('');
    setFilterType('upcoming');
    handleRunListFilter(laneId, 'upcoming', '');
  };

  const getFilterValueLabelText = () => {
    if (filterType === 'itemNumber') return '#';
    if (filterType === 'product') return 'NAME';
    return 'VALUE';
  }

  useEffect(() => {
    if (showInput) filterValueRef.current.focus();
  }, [filterType, showInput]);

  return (
    <Container showInput={showInput} isFullViewLayout={isFullViewLayout} filterType={filterType}>
      <Label>FILTER</Label>
      <Select
        ref={filterTypeRef}
        tabIndex={1}
        valueProp="value"
        textProp="text"
        value={filterType}
        currentValue={filterType}
        data={filterSelectionData}
        noneSelectedText=""
        onChange={onFilterSelectionChange}
        onKeyPress={onSelectKeyPress}
      />
      {showInput && <Label>{getFilterValueLabelText()}</Label>}
      <InputButtonGroup showInput={showInput} isFullViewLayout={isFullViewLayout}>
        {showInput && (
          <Input
            ref={filterValueRef}
            autoComplete="off"
            tabIndex={2}
            placeholderText=""
            disabled={false}
            value={filterValue}
            onKeyUp={onFilterKeyUp}
            onKeyPress={onInputKeyPress}
            onChange={onFilterInputChange}
            maxLength={50}
            testId="filter-text"
          />
        )}
        {showInput && (
          <FilterButton className="primary" onClick={onFilterClick}>
            <Icon icon="filter" size={1.1} />
          </FilterButton>
        )}
        {filterType !== 'upcoming' && (
          <FilterButton className="primary" onClick={onFilterClearClick}>
            <Icon icon="times" size={1.1} />
          </FilterButton>
        )}
      </InputButtonGroup>
    </Container>
  );
};

RunListFilter.propTypes = {
  laneId: PropTypes.string,
};

RunListFilter.defaultProps = {
  laneId: null,
};

export default RunListFilter;
