import { configure, action, decorate, observable } from 'mobx';
import Analytics from 'shared/utils/analytics';

import {
  DEFAULT_LAYOUT_VIEW,
  FULL_LAYOUT_VIEW,
  MINI_LAYOUT_VIEW,
} from 'shared/constants';
import { stripCurrencySymbols } from '../components/project/shared/utils/common';
import { toBoolean } from './utils';

configure({ enforceActions: 'always' });

class UIStore {
  constructor(DataStore) {
    this.dataStore = DataStore;
    this.initialize();
  }

  initialize = () => {
    this.auctionStore = this.dataStore.auctionStore;
    this.bidStore = this.dataStore.bidStore;
    this.hasPinnedNav = true;
    this.loading = false;
    this.messageStore = this.dataStore.messageStore;
    this.scrollPosition = null;
    this.networkConnected = true;
    this.mobileNavOpen = false;
  };

  handleLogin = async (values) => {
    try {
      this.dataStore.connect(values.bidderNumber, values.name);
    } catch (error) {
      console.error('handleLogin', { error, values });
    }
  };

  handleJoinAuction = (auctionID) => {
    try {
      this.dataStore.sendMessage({
        method: 'JoinAuction',
        payload: [auctionID],
      });
    } catch (error) {
      console.error('handleJoinAuction', { error, auctionID });
    }
  };

  handleJoinLane = (laneID) => {
    try {
      this.dataStore.sendMessage({ method: 'JoinLane', payload: [laneID] });
      this.bidStore.setLaneRequestInProgress(true);
    } catch (error) {
      console.error('handleJoinLane', { error, laneID });
    }
  };

  handleExitLane = (laneID) => {
    try {
      this.dataStore.sendMessage({ method: 'ExitLane', payload: [laneID] });
    } catch (error) {
      console.error('handleExitLane', { error, laneID });
    }
  };

  placeLiveBid = (laneID, syncID, bidAmount) => {
    try {
      this.dataStore.sendMessage({
        method: 'Bid',
        payload: [laneID, syncID, bidAmount],
      });

      const analyticsEventSuffix = toBoolean(this.auctionStore.isNative)
        ? ' - Native'
        : '';
      Analytics.RecordEvent(
        `Bid Button${analyticsEventSuffix}`,
        'Bidding',
        'Bid Amount',
        bidAmount,
      );
    } catch (error) {
      console.error('placeLiveBid', {
        error,
        laneID,
        syncID,
        bidAmount,
      });
    }
  };

  handleDisconnect = () => {
    try {
      this.dataStore.closeConnection();
    } catch (error) {
      console.error('handleDisconnect', error);
    }
  };

  sendDirectMessage = async (laneID, message, userType = 'Bidder') => {
    try {
      if (laneID && message) {
        const payload = {
          message,
          lotNumber: this.bidStore.auction[laneID].lotNumber || null,
          userType,
        };
        this.messageStore.insertDirectMessageMap(laneID, payload);
        await this.dataStore.sendMessage({
          method: 'MessageToClerks',
          payload: [laneID, message, payload.lotNumber, payload.userType],
        });
      }
    } catch (error) {
      console.error('sendDirectMessage', {
        error,
        laneID,
        message,
        userType,
      });
    }
  };

  sendSellerMessage = async (laneID, sellerAction, sellerActionAmount) => {
    try {
      if (laneID && sellerAction) {
        //Analytics.RecordEvent('Seller Actions', 'Seller', 'Button Action', sellerAction);
        Analytics.RecordEvent(
          `Seller Action - ${sellerAction}`,
          'Seller',
          'Button Action',
          sellerAction,
        );

        await this.dataStore.sendMessage({
          method: 'SellerAction',
          payload: [laneID, sellerAction, sellerActionAmount],
        });
      }
    } catch (error) {
      console.error('sendSellerMessage', {
        error,
        laneID,
        sellerAction,
        sellerActionAmount,
      });
    }
  };

  setTempSellerAction = (
    laneID,
    tempSellerAction,
    tempSellerActionAmount = null,
  ) => {
    this.bidStore.auction[laneID].seller.tempSellerAction = tempSellerAction;
    this.bidStore.auction[
      laneID
    ].seller.tempTakeAmount = tempSellerActionAmount;
    if (!this.bidStore.auction[laneID].seller.sellerActionAmount) {
      this.bidStore.auction[
        laneID
      ].seller.sellerActionAmount = tempSellerActionAmount;
    }
  };

  clearTempSellerAction = (laneID) => {
    this.bidStore.auction[laneID].seller.tempSellerAction = null;
    this.bidStore.auction[laneID].seller.tempTakeAmount = null;
  };

  setSellerAction = (
    laneID,
    sellerAction,
    sellerActionAmount = null,
    tempTakeAmount = null,
  ) => {
    this.clearTempSellerAction(laneID);
    if (
      sellerAction !== 'TAKES' ||
      (sellerAction === 'TAKES' && sellerActionAmount)
    ) {
      this.sendSellerMessage(laneID, sellerAction, sellerActionAmount);
    }
  };

  clearSellerAction = (laneID, sellerAction, userInitiated = true) => {
    // console.log('clearSellerAction', sellerAction);
    if (userInitiated) {
      switch (sellerAction) {
        case 'SELL':
          this.sendSellerMessage(laneID, 'CANCEL SELL');
          break;
        case 'TAKES':
          this.sendSellerMessage(laneID, 'CANCEL TAKES');
          break;
        case 'PASS':
          this.sendSellerMessage(laneID, 'CANCEL PASS');
          break;
        default:
        // code block
      }
    }

    this.bidStore.setAuctionProperty(laneID, 'seller', {
      message: null,
      tempTakeAmount: null,
      sellerAction: null,
      sellerActionAmount: null,
      sellerActionAccount: null,
      sellerActionAccountName: null,
      sellerAccount: null,
      sellerAccountName: null,
    });
  };

  setIsPinnedNav = (value) => {
    this.hasPinnedNav = value;
  };

  handleSetLaneAudio = (laneID, value, userInitiated) =>
    this.bidStore.setCurrentLaneAudio(laneID, value, userInitiated);

  toggleLoading = () => {
    this.loading = !this.loading;
  };

  getLaneRemainingUnitCount = (laneId) => {
    const auctionLane = this.bidStore.auction[laneId];
    const { runList } = auctionLane;
    const remainingUnitsCount = [...runList].filter((units) => units.productState === 0).length;
    return remainingUnitsCount;
  }

  getFilteredRunList = (laneId, lotNumber, sequence = 0) => {
    const auctionLane = this.bidStore.auction[laneId];
    const { runList } = auctionLane;

    const { filterType, filterValue } = auctionLane.runListFilter;
    const formattedValue = filterValue?.toLowerCase();
    switch (filterType) {
      case 'upcoming':
        return runList.filter((item) => item.sequence > sequence);
      case 'previous':
        return runList.filter((item) => item.sequence < sequence);
      case 'product':
        return runList.filter(
          (item) => item.product.toLowerCase().indexOf(formattedValue) > -1,
        );
      case 'myWatchList':
        return runList.filter((item) => item.isWatched === true);
      case 'itemNumber':
        return runList.filter(
          (item) => item.lotNumber.toLowerCase().indexOf(formattedValue) > -1,
        );
      case 'myPurchases':
        return runList
          .filter((item) => item?.isWinner === true)
          .filter((item) => item.productState !== 2);
      case 'myPending':
        return runList
          .filter((item) => item?.isWinner === true)
          .filter((item) => item.productState === 2);
      case 'myProxyBids':
        return runList.filter((item) => item.myProxyBidAmount > 0);
      case 'myInventory':
        return runList.filter((item) => item.isSeller === true);
      case 'allSold':
        return runList.filter((item) => item.productState === 3);
      case 'allPending':
        return runList.filter((item) => item.productState === 2);
      case 'allNoSale':
        return runList.filter((item) => item.productState === 4);
      default:
        return runList;
    }
  };

  handleRunListFilter = (laneId, filterType, filterValue) => {
    this.bidStore.setAuctionProperty(laneId, 'runListFilter', {
      filterType,
      filterValue,
    });
  };

  resetRunListFilter = (laneId) =>
    this.handleRunListFilter(laneId, 'upcoming', '');

  clearRunListFilter = (laneId) => this.handleRunListFilter(laneId, '', '');

  setLayoutViewType = (viewType) => {
    const isValidViewType = [
      DEFAULT_LAYOUT_VIEW,
      MINI_LAYOUT_VIEW,
      FULL_LAYOUT_VIEW,
    ].includes(viewType);
    if (!isValidViewType) console.error('Invalid layout view type');
    this.auctionStore.setProperty('layoutViewType', viewType);
    window.localStorage.setItem('layout', viewType);

    for (const [key] of Object.entries(this.bidStore.auction)) {
      if (viewType === DEFAULT_LAYOUT_VIEW || viewType === FULL_LAYOUT_VIEW) {
        this.bidStore.setAuctionProperty(key, 'showRunList', true);
        this.bidStore.setAuctionProperty(key, 'showProdPreview', false);
      } else if (viewType === MINI_LAYOUT_VIEW) {
        this.bidStore.setAuctionProperty(key, 'showRunList', false);
        this.bidStore.setAuctionProperty(key, 'showProdPreview', false);
      }
    }
  };

  setScrollPosition = (value) => {
    this.scrollPosition = value;
  };

  setNetworkConnected = (value) => {
    this.networkConnected = value;
  };

  handleCurrencyKeyUpFormat = (e) => {
    const numericValues = stripCurrencySymbols(e.currentTarget.value);
    e.currentTarget.value = numericValues;
  };

  handleTriggerFinancialCommitments = (auctionID) => {
    try {
      this.dataStore.sendMessage({
        method: 'GetFinancialCommitments',
        payload: [auctionID],
      });
    } catch (error) {
      console.error('handleTriggerFinancialCommitments', { error, auctionID });
    }
  };

  toggleMobileNav = (value) => {
    this.mobileNavOpen = value;
  };
}

decorate(UIStore, {
  clearRunListFilter: action,
  clearSellerAction: action,
  clearTempSellerAction: action,
  getFilteredRunList: action,
  handleCurrencyKeyUpFormat: action,
  handleDisconnect: action,
  handleExitLane: action,
  handleJoinAuction: action,
  handleJoinLane: action,
  handleLogin: action,
  handleRunListFilter: action,
  handleSetLaneAudio: action,
  handleTriggerFinancialCommitments: action,
  hasPinnedNav: observable,
  initialize: action,
  loading: observable,
  mobileNavOpen: observable,
  networkConnected: observable,
  placeLiveBid: action,
  resetRunListFilter: action,
  scrollPosition: observable,
  sendDirectMessage: action,
  sendSellerMessage: action,
  setIsPinnedNav: action,
  setLayoutViewType: action,
  setNetworkConnected: action,
  setScrollPosition: action,
  setSellerAction: action,
  setTempSellerAction: action,
  toggleLoading: action,
  toggleMobileNav: action,
  getLaneRemainingUnitCount: action,
});

export default UIStore;
