import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { PropTypes } from 'prop-types';
import React from 'react';
import LaneNavCountdown from '../../../../BidPanel/LaneNavCountdown';

import { Icon } from '../../../../shared';
import {
  AudioStatus,
  AudioToggle,
  Container,
  LaneTextItem,
  LeftLaneContent,
  RightLaneContent,
  Row,
} from './Styles';

export const LaneContent = observer(({ item, onClick }) => {
  const {
    AuctionStore: {
      auctionInfo,
      laneStatusMap,
      openLanes,
    },
    BidStore: { audioLane },
    UIStore: { handleSetLaneAudio },
  } = useStores();

  const getAuctionStartTime = (value) => {
    if (!value) return null;

    const auctionStartTime = new Date(value);
    const total = Date.parse(auctionStartTime) - Date.parse(new Date());
    if (total < 0) return null;

    return auctionStartTime;
  };

  const auctionStartTime = getAuctionStartTime(auctionInfo?.startTime);

  const getFriendlyLaneStatusName = (status) => {
    if (status === 'NotRunning') return 'Not Running';
    if (status === 'Ended') return 'Ended';
    return 'Running';
  };

  const key = () => 6 * Math.random();

  const laneActive = openLanes.includes(item.laneID);

  return (
    <Row>
      <AudioToggle disabled={laneActive} laneActive={laneActive} onClick={laneActive ? () => handleSetLaneAudio(item.laneID, audioLane !== item.laneID, true) : null}>
        {laneActive && (
        <AudioStatus>
          {audioLane === item.laneID ? <Icon icon="volume-up" iconOnly size={1} /> : <Icon icon="volume-slash" iconOnly iconPackage="far" size={1} />}
        </AudioStatus>
        ) }
      </AudioToggle>
      <Container
        disabled={laneActive}
        key={`Lane_${key}`}
        onClick={() => onClick(item)}
      >

        <LeftLaneContent>
          <LaneTextItem>{item.lane}</LaneTextItem>
          <LaneTextItem>
            {
            laneStatusMap.get(item.laneID)
              .runlistCount
          }
            {' '}
            units
          </LaneTextItem>

          <LaneTextItem color="var(--activeLaneTextColor)">
            {getFriendlyLaneStatusName(
              laneStatusMap.get(item.laneID)
                .laneStatusName,
            )}
          </LaneTextItem>
        </LeftLaneContent>
        <RightLaneContent>
          {getFriendlyLaneStatusName(
            laneStatusMap.get(item.laneID)
              .laneStatusName,
          ) === 'Upcoming' && auctionStartTime ? (
            <LaneTextItem color="var(--noSaleMsgBgColor)">
              <LaneNavCountdown
                date={auctionStartTime}
              />
            </LaneTextItem>
            ) : (
              laneStatusMap.get(item.laneID)
                .lotNumber && (
                  <LaneTextItem color="var(--activeLaneTextColor)">
                    {'#'}
                    {
                  laneStatusMap.get(item.laneID)
                    .lotNumber
                }
                  </LaneTextItem>
              )
            )}

        </RightLaneContent>
      </Container>
    </Row>
  );
});

LaneContent.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

LaneContent.defaultProps = {
  item: null,
};

export default LaneContent;
