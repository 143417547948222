import pubsub from 'sweet-pubsub';
import { get } from 'lodash';

const show = (toast) => pubsub.emit('toast', toast);

const success = (title, durationInSeconds = 5) => show({ title, duration: durationInSeconds });

const error = (err, title = 'Error', durationInSeconds = 5) => {
  show({
    type: 'danger',
    title,
    message: get(err, 'message', err),
    duration: durationInSeconds,
  });
};

export default { show, error, success };
