export const getFormattedDateTime = (d) => {
  const year = d.getFullYear();
  let month = (1 + d.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = d.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;
  return `${month}/${day}/${year} ${strTime}`;
};

export const getSafeString = (value) => value || '';


export const getTrimmedString = (value) => getSafeString(value)
  .toString()
  .replace(/^\s+|\s+$/g, '');

export const stripCurrencySymbols = (value) => getTrimmedString(value).replace(/[^0-9]/gi, '');

export const formatCurrency = (value) => {
  const nums = stripCurrencySymbols(value);
  return `$${new Intl.NumberFormat().format(Number(nums))}`;
};

export const formatCommas = (value) => {
  const nums = stripCurrencySymbols(value);
  return new Intl.NumberFormat().format(Number(nums));
};


export const arrayContainsValue = (arr, value) => arr.indexOf(value) > -1;

export const trimmedStringMatchesExactly = (str1, str2) => (
  getTrimmedString(str1).toLowerCase()
    === getTrimmedString(str2).toLowerCase()
);

export const trimmedStringContainsValue = (source, value) => (
  getTrimmedString(source)
    .toLowerCase()
    .indexOf(getTrimmedString(value).toLowerCase()) > -1
);

export const getKeyCode = (e) => e.which || e.keyCode;

export const getTargetValue = (e) => e.target && getSafeString(e.target.value);

export const isEnterKey = (e) => getKeyCode(e) === 13;

export const getItemById = (list, selector, id) => {
  const obj = list.filter((item) => item[selector] === id);

  if (obj.length === 0) {
    return [];
  }

  return obj;
};

export const listHasStartValue = (list, selector, value) => {
  const obj = list.filter((item) => item[selector]?.startsWith(value));

  if (obj.length === 0) {
    return [];
  }

  return obj;
};

export const abbrevStringValue = (value, len, ignore) => { 
  if (!value) return '';
  if (value.length > len && !ignore) {
    return `${value.substring(0, len).replace('(', '').replace(')', '')}...`;
  }
  return value;
};
