import React, { useEffect } from 'react';
import {
  Route,
  Router,
  Redirect,
  useRouteMatch,
  useHistory,
  Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PageError } from 'components/shared';
import PageLoader from 'components/shared/PageLoader';
import BidClientView from 'views/BidClient';
import ProtectedRoute from './ProtectedRoute';
import useStores from './hooks/use-store';
import { Wrapper, Body } from './assets/Styles';
import Login from './views/Login';
import { ROUTES } from './shared/constants/index';
import { useTheme, useResponsive } from 'hooks';
import NormalizeStyles from 'assets/NormalizeStyles';
import BaseStyles from 'assets/BaseStylesAlt';
import Toast from 'components/shared/Toast';
import themes from './assets/theme';
import Analytics from 'shared/utils/analytics';
import { CacheBuster } from './CacheBuster';

const App = () => {
  const {
    DataStore: { reconnecting },
  } = useStores();
  // useResponsive();
  const { theme } = useTheme();
  const match = useRouteMatch();
  const history = useHistory();
  const error = false;
  const themeToUse = themes[theme];
  Analytics.initialize();

  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    Analytics.RecordPageView(path, path);
  }, []);

  if (reconnecting) return <PageLoader />;
  if (error) return <PageError />;

  return (
    <CacheBuster
      currentVersion={window.localStorage.getItem('cacheVersion')}
      isEnabled={true}
      isVerboseMode={true}
      loadingComponent={<PageLoader />}
    >
      <NormalizeStyles theme={themeToUse} />
      <BaseStyles />
      <Toast />
      <Wrapper>
        <Body>
          <Router
            history={history}
            basename={process.env.PUBLIC_URL}
            key={Math.random()}
          >
            <Switch>
              <Route exact path={ROUTES.login}>
                <Login />
              </Route>
              <Route exact path="/">
                <Redirect to={ROUTES.login} />
              </Route>
              <ProtectedRoute path={ROUTES.auction} component={BidClientView} />
            </Switch>
          </Router>
        </Body>

        {match.isExact && <Redirect to={`${match.url}`} />}
      </Wrapper>
    </CacheBuster>
  );
};

export default observer(App);
