import React from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive, useNonMobile } from 'hooks';
import { Content } from 'assets/ContainerStyles';
import { LaneWrapper } from 'components/project/LaneWrapper';
import { PinnedNav, LayoutViewButtons } from 'components/project';
import BidActivity from 'components/project/Views/BidActivity/Mobile';
import ConnectionStatus from 'components/project/shared/ConnectionStatus';
import Header from 'components/project/Header';
import Lanes from 'components/project/Views/Lanes';
import LoadingModal from 'components/shared/LoadingModal';
import MiniDefault from 'components/layout/MiniDefault';
import MobileNavbarLeft from 'components/project/NavbarLeft/mobile';
import NavbarLeft from 'components/project/NavbarLeft';
import Settings from 'components/project/Views/Settings';
import { Layout, NoLanesSelected } from './Styles';
import { ROUTES } from '../../../shared/constants/index';

const NPAMiniLayout = () => {
  const {
    AuctionStore: {
      connected, isNative, openLanes, deleteMobileClosedLane, mobileClosedLanes,
    },
    BidStore: { auction },
    UIStore: {
      loading, hasPinnedNav: isPinned, setScrollPosition, handleJoinLane,
    },
  } = useStores();

  const { isMobileView } = useResponsive();
  const isPinnedAndNotMobile = isPinned && !isMobileView;
  const history = useHistory();
  const notifications = {};

  if (!connected) {
    history.push(ROUTES.login);
  }

  const match = useRouteMatch();
  useNonMobile({
    isMobileView,
    mobileClosedLanes,
    deleteMobileClosedLane,
    handleJoinLane,
  });

  return (
    <Layout isPinned={isPinnedAndNotMobile} onScroll={(e) => setScrollPosition(e.target.scrollTop)}>
      <Header />
      {!isMobileView && openLanes.length > 0 && <LayoutViewButtons />}
      <Content isPinned={isPinned}>
        <Route path={`${match.path}${ROUTES.lanes}`} render={() => <Lanes />} />
        <Route
          path={`${match.path}${ROUTES.settings}`}
          render={() => <Settings />}
        />
        <Route
          path={`${match.path}${ROUTES.bidActivity}`}
          render={() => <BidActivity />}
        />
        <Route
          path={`${ROUTES.auction}`}
          exact
          render={() => (openLanes.length > 0 ? (
            openLanes.map((laneID) => (
              <LaneWrapper key={laneID} laneID={laneID}>
                <MiniDefault
                  key={laneID}
                  auction={auction[laneID]}
                  notifications={notifications}
                />
              </LaneWrapper>
            ))) : (
              <NoLanesSelected>Click one of the lanes from the Auction Lanes list to join.</NoLanesSelected>
          ))}
        />
      </Content>
      <NavbarLeft />
      {isPinned && <PinnedNav />}
      {isMobileView && <MobileNavbarLeft />}
      {loading && <LoadingModal />}
      <ConnectionStatus />
    </Layout>
  );
};

export default observer(NPAMiniLayout);
