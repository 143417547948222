import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive } from 'hooks';
import { Icon, ScrollToBottom } from 'components/project/shared';
import { sortAscending } from '../../../../shared/utils/javascript';
import {
  Body,
  ContentContainer,
  Header,
  Input,
  InputContainer,
  LaneTitle,
  MessageFromBidder,
  MessageFromClerk,
  SendButton,
  Wrapper,
  Message,
  Timestamp,
  NoMessage,
} from './styles';

export const LONGEST_VIN_LENGTH = 17;
export const messagePrettier = (str, chunkSize = LONGEST_VIN_LENGTH) => {
  const sanitizedMessage = str
    .trim()
    .replace(/[^a-zA-Z0-9\+\-\$\.\?\)\(#! _,]/g, '')
    .trim();
  if (sanitizedMessage.length === 0) return '';
  const parts = sanitizedMessage.split(' ');
  return parts
    .filter((word) => word.length > 0)
    .map((word) =>
      word.match(new RegExp('.{1,' + chunkSize + '}', 'g')).join(' '),
    )
    .join(' ');
};

const Chat = ({ laneID, openChatUI }) => {
  const {
    AuctionStore: { isFullViewLayout, laneStatusMap },
    BidStore: { auction },
    UIStore: { sendDirectMessage },
    MessageStore: {
      directMessagesMap,
      setUnreadMessageProperty,
      setOpenChatProperty,
      setReadMessageProperty,
      setNewMessageProperty,
      readMessages,
      newMessages,
    },
  } = useStores();

  const { laneStatusName } = laneStatusMap.get(laneID);
  const isNotStopped = laneStatusName !== 'NotRunning';

  const readMessagesCount = readMessages[laneID] || 0;
  const newMessagesCount = newMessages[laneID] || 0;

  const messagesMap = sortAscending(
    Array.from(directMessagesMap.get(laneID)?.values() || []),
    'timestamp',
  );
  const { isMobileView } = useResponsive();
  const inputRef = useRef();
  const [message, setMessage] = useState('');

  const directMessagesMapAsString = JSON.stringify(directMessagesMap);
  const messagesMapLength = messagesMap.length;

  const getKeyCode = (e) => e.which || e.keyCode;

  const isEnterKey = (e) => getKeyCode(e) === 13;

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    if (messagePrettier(message).length === 0) {
      inputRef.current.focus();
      return;
    }

    sendDirectMessage(
      laneID,
      messagePrettier(message),
      auction[laneID].isSeller ? 'Seller' : 'Bidder',
    );
    setMessage('');
    inputRef.current.focus();
  };

  const onKeyPress = (e) => {
    if (isEnterKey(e) && inputRef.current.value !== '') {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleToggleChatWindow = (value) => setOpenChatProperty(laneID, value);

  useEffect(() => {
    if (openChatUI) {
      inputRef.current.focus();
      setReadMessageProperty(laneID, messagesMapLength);
    } else {
      setOpenChatProperty(laneID, false);
    }
  }, [
    openChatUI,
    setOpenChatProperty,
    setReadMessageProperty,
    laneID,
    messagesMapLength,
  ]);

  useEffect(() => {
    if (openChatUI) {
      setReadMessageProperty(laneID, messagesMapLength);
    } else {
      setNewMessageProperty(laneID, messagesMapLength);
    }
  }, [
    directMessagesMapAsString,
    messagesMapLength,
    setReadMessageProperty,
    setNewMessageProperty,
    laneID,
    openChatUI,
  ]);

  const unreadCount =
    readMessagesCount >= newMessagesCount
      ? 0
      : newMessagesCount - readMessagesCount;

  setUnreadMessageProperty(laneID, unreadCount);

  if (!openChatUI) {
    return null;
  }

  return (
    <Wrapper
      expanded={openChatUI}
      isFullViewLayout={isFullViewLayout}
      laneStatusName={laneStatusName}
    >
      {!isMobileView && (
        <Header newMessage={unreadCount > 0} expanded={openChatUI}>
          <LaneTitle>
            Messages
            {!openChatUI && `(${unreadCount})`}
          </LaneTitle>
          <Icon
            icon="times"
            size={1}
            iconOnly
            onClick={() => handleToggleChatWindow(!openChatUI)}
          />
        </Header>
      )}
      {openChatUI && (
        <Body className="chat-body">
          <ContentContainer>
            {messagesMap.length === 0 ? (
              <NoMessage>No messages</NoMessage>
            ) : (
              messagesMap.map((item) =>
                item.fromClerk ? (
                  <MessageFromClerk key={item.id} type={item.type}>
                    <Message>
                      {messagePrettier(item.message, LONGEST_VIN_LENGTH)}
                    </Message>
                    <Timestamp>{item.timestamp}</Timestamp>
                  </MessageFromClerk>
                ) : (
                  <MessageFromBidder key={item.id}>
                    <Timestamp>{item.timestamp}</Timestamp>
                    <Message>
                      {messagePrettier(item.message, LONGEST_VIN_LENGTH)}
                    </Message>
                  </MessageFromBidder>
                ),
              )
            )}
            <ScrollToBottom />
          </ContentContainer>
          <InputContainer className="chat-input-container">
            <Input
              isNotStopped={isNotStopped}
              isMobile={isMobileView}
              ref={inputRef}
              onChange={onMessageChange}
              onKeyPress={onKeyPress}
              value={message}
              placeholder="Type message for clerk"
            />
            <SendButton disabled={!message} onClick={sendMessage}>
              Send
            </SendButton>
          </InputContainer>
        </Body>
      )}
    </Wrapper>
  );
};

Chat.propTypes = {
  laneID: PropTypes.string.isRequired,
  openChatUI: PropTypes.bool,
};

Chat.defaultProps = {
  openChatUI: false,
};

export default observer(Chat);
