import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useResponsive } from 'hooks';
import StickyBox from 'react-sticky-box';
import { PinnedNavSections } from './PinnedNavSections';
import { PinnedNavCloser } from './PinnedNavCloser';
import {
  Container,
  SectionContainer,
  StickyContainerPositioner,
} from './Styles';

const PinnedNav = () => {
  const variants = {
    enableSlideOut: true,
    open: { x: 0, opacity: 1, transition: { duration: 0.2 } },
    closed: {
      x: '-100%',
      transition: {
        delay: 0.2,
        duration: 0.5,
      },
      opacity: 0,
    },
  };

  const { isSmallView } = useResponsive();
  if (!isSmallView) return null;

  return (
    <AnimatePresence initial={variants.enableSlideOut}>
      <Container
        style={{ display: 'flex', alignItems: 'flex-start' }}
        variants={variants}
        initial="closed"
        animate="open"
        exit="closed"
      >
        <StickyContainerPositioner>
          <StickyBox>
            <PinnedNavCloser />
            <SectionContainer>
              <PinnedNavSections />
            </SectionContainer>
          </StickyBox>
        </StickyContainerPositioner>
      </Container>
    </AnimatePresence>
  );
};

export default observer(PinnedNav);
