import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import RunListFilter from './RunListFilter/index';
import NoResultsSVG from './NoResultsSvg';

import {
  NoResults,
  NoResultsTip,
  NoResultsTitle,
  VerticalRunListContainer,
  VerticalRunListWrapper,
} from './Styles';

import VerticalListItem from './VerticalListItem';

// REQUIREMENTS:
// When unit has been statused, remove it from the list, but the primary view are all units that have not yet been statused.
// Allow for previously statused units to re-appear (When previous items re-appear, do not clear the filter when the next items is put onto the block)
// Get filters to work (Currently in AWG, we only allow filtering of Watched Items)

export const VerticalList = observer(({ laneId, onClick }) => {
  const {
    AuctionStore: { isFullViewLayout },
    BidStore: { auction },
    UIStore: { getFilteredRunList, getLaneRemainingUnitCount },
  } = useStores();

  const {
    lotNumberSequence, lastRunListItemUpdate, runListFilter, previewLotNumber,
  } = auction[laneId];

  const [localRunlist, setLocalRunlist] = useState([]);


  // const runListItems = getRunListItems();

  useEffect(() => {
    const getRunListItems = () => {
      const remainingCount = getLaneRemainingUnitCount(laneId);
      if (remainingCount === 0 && auction[laneId].runListFilter.filterType === 'upcoming') {
        return (
          <NoResults style={{ padding: '10px' }}>
            <NoResultsTitle>
              Thank you for participating in the auction today!
            </NoResultsTitle>
            <NoResultsTip>You may use the filters above to find information about the units that were auctioned.</NoResultsTip>
          </NoResults>
        );
      }

      const runList = getFilteredRunList(laneId, auction[laneId].lotNumber, auction[laneId].lotNumberSequence);

      if (runList.length === 0) {
        return (
          <NoResults>
            <NoResultsSVG />
            <NoResultsTitle>
              We couldn&apos;t find anything matching your filter
            </NoResultsTitle>
            <NoResultsTip>Try again with a different filter.</NoResultsTip>
          </NoResults>
        );
      }

      return runList.map((item, index) => (
        <VerticalListItem
          key={`runlistitem_lotNumber:${item.lotNumber || 'NONE'}_myProxy:${item.myProxyBidAmount || 'NO'}_watched:${item.isWatched ? 'YES' : 'NO'}`}
          onClick={onClick}
          isClicked={previewLotNumber === item.lotNumber}
          item={item}
          index={index}
          isWatched={item.isWatched}
          myProxyBidAmount={item.myProxyBidAmount}
          isFullViewLayout={isFullViewLayout}
        />
      ));
    };

    setLocalRunlist(getRunListItems());
  }, [lotNumberSequence, lastRunListItemUpdate, runListFilter, previewLotNumber, isFullViewLayout, getFilteredRunList, auction, laneId, onClick]);

  return (
    <VerticalRunListWrapper isFullViewLayout={isFullViewLayout} className="vertical-runlist">
      <RunListFilter laneId={laneId} />
      <VerticalRunListContainer isFullViewLayout={isFullViewLayout}>{localRunlist}</VerticalRunListContainer>
    </VerticalRunListWrapper>
  );
});

VerticalList.propTypes = {
  laneId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default VerticalList;
