export const DEFAULT_SNGL_IMG_HEIGHT_PX = 320;
export const DEFAULT_SNGL_IMG_WIDTH = '100%';
export const DEFAULT_IMG_COUNT = 0;
export const IMG_CONTAINER_WIDTH = 475;
export const REF_IMGW = IMG_CONTAINER_WIDTH / 3; // 200;
export const MIN_IMGH_THRESHOLD = 100; // 107;
export const MAX_IMGH_THRESHOLD = 100; // 130;
export const MIN_SLIDE_THRESHOLD = 4;
export const MAX_SLIDE_THRESHOLD = 6;
export const DEFAULT_WIN_HEIGHT = 400;
export const WIN_HEIGHT_OFFSET = DEFAULT_WIN_HEIGHT;

export const getSlideCountForContainer = (containerWidth, refImageWidth) => Math.floor(containerWidth / refImageWidth);

export const getImageCountContstraint = (images, num) => (images > num ? num : images);

export const getSlideCount = (
  containerWidth,
  images,
  minImgWidth,
  min,
  max,
) => {
  const slideCount = getSlideCountForContainer(containerWidth, minImgWidth);
  const minSlides = getImageCountContstraint(images, min);
  const maxSlides = getImageCountContstraint(images, max);

  if (slideCount < 0) return 0;
  if (slideCount > maxSlides) return maxSlides;
  if (slideCount < minSlides) return minSlides;
  return slideCount;
};

export const getAdjHeight = (height, offset) => height - offset;

export const getImageHeightPx = (height, offset, minHeight, maxHeight) => {
  const calcHeight = getAdjHeight(height, offset);
  if (calcHeight < minHeight) return `${minHeight}px`;
  if (calcHeight > maxHeight) return `${maxHeight}px`;
  return `${calcHeight}px`;
};

export const getSlideWidth = (containerWidth, slides) => Math.floor(containerWidth / slides);

export const getImageWidthPx = (containerWidth, slides, minWidth) => {
  if (slides === 0) return `${minWidth}px`;
  return `${getSlideWidth(containerWidth, slides)}px`;
};

export const getDimensionsAndSlideCount = (
  imageCount = DEFAULT_IMG_COUNT,
  winHeight = DEFAULT_WIN_HEIGHT,
  {
    containerWidth = IMG_CONTAINER_WIDTH,
    minImageHeightThreshold = MIN_IMGH_THRESHOLD,
    maxImageHeightThreshold = MAX_IMGH_THRESHOLD,
    minSlideThreshold = MIN_SLIDE_THRESHOLD,
    maxSlideThreshold = MAX_SLIDE_THRESHOLD,
    refImageWidth = REF_IMGW,
    winHeightOffset = WIN_HEIGHT_OFFSET,
  },
) => {
  const imageHeight = getImageHeightPx(
    winHeight,
    winHeightOffset,
    minImageHeightThreshold,
    maxImageHeightThreshold,
  );

  const slides = getSlideCount(
    containerWidth,
    imageCount,
    refImageWidth,
    minSlideThreshold,
    maxSlideThreshold,
  );

  const imageWidth = getImageWidthPx(containerWidth, slides, refImageWidth);

  return {
    slides,
    width: imageWidth,
    height: imageHeight,
  };
};

export const shallowImmutableMerge = (target, source) => ({ ...target, ...source });

export const getSetting = (config) => {
  const local = {
    isMobile: true,
    viewType: 'mobile',
    interval: 0,
    slidesPresented: 1,
    showNavButtons: true,
    showCaptions: true,
    showIndicators: true,
    height: `${DEFAULT_SNGL_IMG_HEIGHT_PX}px`,
    width: DEFAULT_SNGL_IMG_WIDTH,
    wrapWidth: DEFAULT_SNGL_IMG_WIDTH,
    transitionTime: 400,
  };

  return shallowImmutableMerge(local, config);
};

export const imageSettingsReducer = (state, action) => {
  let dims;
  let showButtons;
  let getSettingObject;
  const {
    imageCount = DEFAULT_IMG_COUNT,
    showNextPrevButtons,
    isFullViewLayout,
    viewType,
    winHeight,
    winWidth,
    wrapWidth,
    wrapHeight,
  } = action;

  const shouldShowNextPrevBtns = (show, images, slides) => (show !== undefined ? show : images > slides);

  if (isFullViewLayout) {
    dims = getDimensionsAndSlideCount(imageCount, winHeight, { minSlideThreshold: 130, maxSlideThreshold: 130 });

    showButtons = shouldShowNextPrevBtns(
      showNextPrevButtons,
      imageCount,
      dims.slides,
    );

    getSettingObject = getSetting({
      isFullViewLayout,
      isMobile: false,
      viewType,
      interval: 0,
      slidesPresented: 1,
      showNavButtons: showButtons,
      showCaptions: true,
      showIndicators: true,
      height: '320px',
      width: '425px',
      wrapWidth,
      wrapHeight,
      transitionTime: 100,
    });

    return getSettingObject;
  }

  switch (viewType) {
    case 'prodpreview':
      return getSetting({
        isFullViewLayout,
        isMobile: true,
        viewType,
        interval: 0,
        slidesPresented: 1,
        showNavButtons: true,
        showCaptions: true,
        showIndicators: true,
        height: wrapHeight,
        width: wrapWidth,
        wrapWidth,
        wrapHeight,
        transitionTime: 400,
      });
    case 'mobile':
      return getSetting({
        isFullViewLayout,
        isMobile: true,
        viewType,
        interval: 0,
        slidesPresented: 1,
        showNavButtons: true,
        showCaptions: true,
        showIndicators: true,
        height: `${DEFAULT_SNGL_IMG_HEIGHT_PX}px`,
        width: DEFAULT_SNGL_IMG_WIDTH,
        wrapWidth,
        wrapHeight,
        transitionTime: 400,
      });
    case 'tablet':
      dims = getDimensionsAndSlideCount(imageCount, winHeight, {
        containerWidth: winWidth,
      });

      showButtons = shouldShowNextPrevBtns(
        showNextPrevButtons,
        imageCount,
        dims.slides,
      );

      return getSetting({
        isFullViewLayout,
        isMobile: false,
        viewType,
        interval: 0,
        slidesPresented: dims.slides,
        showNavButtons: showButtons,
        showCaptions: false,
        showIndicators: false,
        height: '130px', // dims.height,
        width: dims.width,
        wrapWidth: null,
        wrapHeight,
        transitionTime: 100,
      });
    case 'small':
    case 'large':
    case 'larger':
    case 'largest':
      dims = getDimensionsAndSlideCount(imageCount, winHeight, {});
      showButtons = shouldShowNextPrevBtns(
        showNextPrevButtons,
        imageCount,
        dims.slides,
      );

      getSettingObject = getSetting({
        isFullViewLayout,
        isMobile: false,
        viewType,
        interval: 0,
        slidesPresented: dims.slides,
        showNavButtons: showButtons,
        showCaptions: false,
        showIndicators: false,
        height: dims.height,
        width: dims.width,
        wrapWidth: `${IMG_CONTAINER_WIDTH}px`,
        wrapHeight,
        transitionTime: 100,
      });

      return getSettingObject;

    default:
      return null;
  }
};
