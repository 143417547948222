import {
  configure, decorate, observable, action, runInAction,
} from 'mobx';
import Cookies from 'js-cookie';

import history from '../browserHistory';

/**
 * Created for NPA secure. Needs rewriting but thought I'd leave it in as a starting points
 * */

configure({ enforceActions: 'always' });

class AuthStore {
  constructor() {
    runInAction(() => {
      this.user = {
        username: '',
        password: '',
        token: '',
      };
      this.errorMessage = null;
    });
  }

  setProperty = (key, value) => {
    this.user.token = value;
  };

  getSession = () => {
    const jwt = Cookies.get('__session');

    let session;
    try {
      if (jwt) {
        // TODO: We need to check the validity of the session token here
        session = jwt;
      }
    } catch (error) {
      console.error('getSession', { error });
    }

    return session;
  };

  logOut = () => {
    Cookies.remove('__session');
    history.push('/login');
  };
}

decorate(AuthStore, {
  errorMessage: observable,
  getSession: action,
  initialize: action,
  loginAction: action,
  setProperty: action,
  logOut: action,
  user: observable,
});

export default AuthStore;
