import React, { useState } from 'react';
import { Icon } from '../../../shared';
import BidActivity from './BidActivity';
import Stats from './Stats';
import { Container, Item, LinkText } from './styles';
import Version from './../../../shared/Version';
import { BLACK } from 'assets/defaultTheme';

const PopupNav = () => {
  const [componentToShow, setComponentToShow] = useState(false);

  const handleNavClick = (component, state) => {
    if (state === 'show') {
      setComponentToShow(null);
      setComponentToShow(component);
      return;
    }
    setComponentToShow(null);
  };

  return (
    <>
      {componentToShow && <>{componentToShow}</>}
      <Container>
        <Item
          onClick={() =>
            handleNavClick(<BidActivity close={handleNavClick} />, 'show')
          }
        >
          <Icon icon="gavel" size={1.3} />
          <LinkText>Bid Activity</LinkText>
        </Item>
        <Item
          onClick={() =>
            handleNavClick(<Stats close={handleNavClick} />, 'show')
          }
        >
          <Icon icon="dollar-sign" size={1.3} />
          <LinkText>Auction Stats</LinkText>
        </Item>
        {/* <Item>
          <Icon icon="question-circle" size={1.3} />
          <LinkText>Help</LinkText>
        </Item> */}
        <div
          style={{
            position: 'absolute',
            bottom: 5,
            right: 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            color: '#000',
          }}
        >
          <Version color="#ccc" size="0.8em" align="right" />
        </div>
      </Container>
    </>
  );
};

export default PopupNav;
