import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './browserHistory';
import App from './app';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={App} />
    </Switch>
  </Router>
);
export default Routes;
