import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import useStores from 'hooks/use-store';
import useMergeState from 'shared/hooks/mergeState';
import { LaneContext } from 'components/project/LaneWrapper';
import { Icon } from '../../shared';
import {
  ContentWrapper,
  DetailsContainer,
  Track,
  TrackKey,
  TrackValue,
  OkayToSellButton,
  AcceptanceAmtInput,
  HighBidWrapper,
  HighBid,
  HighBidLabel,
  NoBids,
} from './Styles';

const SellerDetails = ({ showWatch }) => {
  const { BidStore, UIStore, AuctionStore } = useStores();

  const { connected, laneStatus } = AuctionStore;
  const { auction, user } = BidStore;
  const { placeLiveBid } = UIStore;

  const { laneID } = useContext(LaneContext);

  const [state] = useMergeState({
    bidder: '',
    acceptanceAmount: '',
  });

  return (
    <ContentWrapper showWatch={showWatch}>
      <DetailsContainer>
        <Track>
          <TrackKey>Status</TrackKey>
          <TrackValue
            color={
              auction[laneID].laneStatus === 'Running'
              && auction[laneID].isOpenForBidding
                ? 'var(--bidBtnBgColor)'
                : 'var(--noSaleMsgBgColor)'
            }
          >
            {auction[laneID].isOpenForBidding
              ? auction[laneID].laneStatus
              : 'Waiting'}
          </TrackValue>
        </Track>
        <Track>
          <TrackKey>High Bidder</TrackKey>
          <TrackValue
            color={auction[laneID].bidder === state.bidder ? 'var(--noSaleMsgBgColor)' : 'var(--darkTextColor)'}
          >
            {auction[laneID].winningBidderName
              ? auction[laneID].winningBidderName
              : 'No bidders'}
          </TrackValue>
        </Track>
        <Track>
          <TrackKey>High Bid</TrackKey>
          <TrackValue color="var(--bidBtnBgColor)">
            {auction[laneID].currentBid
              ? `$${new Intl.NumberFormat().format(auction[laneID].currentBid)}`
              : 'NO BIDS'}
          </TrackValue>
        </Track>
        <Track>
          <TrackKey>
            <Icon icon="question-circle" size={1} />
            Acceptance Amt
          </TrackKey>
          <TrackValue color="var(--bidBtnBgColor)">
            <AcceptanceAmtInput value="" />
          </TrackValue>
        </Track>
      </DetailsContainer>

      <HighBidWrapper>
        <HighBidLabel>Asking Bid</HighBidLabel>
        {auction[laneID].nextBid ? (
          <HighBid>
            $
            {new Intl.NumberFormat().format(auction[laneID].nextBid)}
          </HighBid>
        ) : (
          <NoBids>Waiting</NoBids>
        )}
      </HighBidWrapper>

      {connected && (
        <OkayToSellButton
          disabled={
            !auction[laneID].isOpenForBidding
            || user.name === auction[laneID].winningBidderName
            || laneStatus !== 'Running'
          }
          success
          onClick={() => placeLiveBid(
            laneID,
            auction[laneID].syncID,
            auction[laneID].nextBid,
          )}
        >
          OKAY TO SELL
        </OkayToSellButton>
      )}
    </ContentWrapper>
  );
};

SellerDetails.propTypes = {
  showWatch: PropTypes.bool,
};

SellerDetails.defaultProps = {
  showWatch: false,
};

export default observer(SellerDetails);
