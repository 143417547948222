import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

// export const StyledSlide = styled.div`
//   height: ${(props) => props.height || '80px'};
//   ${(props) => props.width && `width: ${props.width}`};
//   background-image: ${(props) => `url('${props.backgroundImage}')`};
//   background-color: black;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: flex-end;
//   padding: 0;
//   @media (min-width: ${breakpoints.tablet}) {
//     cursor: pointer;
//     transition: transform 0.1s;
//     margin: 0 auto;
//     ${(props) => props.hoverEffect
//       && css`
//         :hover {
//           -webkit-transform: scale(1.05); /* Safari 3-8 */
//           transform: scale(1.05);
//           -webkit-box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
//           box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
//         }
//       `}
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const LeftCaption = styled.div`
//   margin: 0px 1px 10px 10px;
//   padding: 0.01em 5px;
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
//   color: #fff !important;
//   background-color: #000 !important;
//   opacity: 0.6;
//   @media (min-width: ${breakpoints.tablet}) {
//     font-size: 0.7em;
//     /* display: none; */
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const RightCaption = styled.div`
//   margin: 0px 10px 10px 0px;
//   padding: 0.01em 5px;
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
//   color: #fff !important;
//   background-color: #000 !important;
//   opacity: 0.6;

//   @media (min-width: ${breakpoints.tablet}) {
//     /* display: none; */
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

export const ContentWrapper = styled.div`
  text-align: center;
  ${(props) => (props.productPreview ? 'width: 200px' : null)};
  ${(props) => (props.isMobileView && 'width: 100%')};

  .image-gallery-slides {min-height: 187px}
  
  ${(props) => (props.productPreview && '.image-gallery-slides {min-height: 150px}')};

  & .image-gallery-slide .image-gallery-image {
    max-height: 425px;
  }

  @media (min-width: ${breakpoints.tablet}) {
 
    /* grid-area: media; */
    /* display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 0;
    max-width: 390px;
    border-right: 1px solid gray;
    border-left: 1px solid gray; */
    /* overflow: hidden; */
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

// export const Gallery = styled.div`
//   position: relative;
//   max-width: 986px;
//   width: 100vw;
//   @media (min-width: ${breakpoints.tablet}) {
//     width: calc(100vw - 15px);
//     flex-direction: column;
//     width: ${(props) => props.wrapWidth};
//     box-sizing: border-box;
//     /* border-left: 1px solid gray;
//     border-right: 1px solid gray; */
//     display: flex;
//     /* margin-left: 1px; */
//     /* margin-right: 2px; */
//     justify-content: space-between;
//     min-height: 80px;
//     height: ${(props) => props.wrapHeight || '100%'};
//     max-height: ${(props) => props.wrapHeight || '100%'};
//     margin: 0;
//     overflow: hidden;
//     z-index: 0;
//     /* -webkit-box-shadow: 0px -1px 15px 3px rgba(0, 0, 0, 0.48);
//     box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.48); */
//   }
//   @media (min-width: ${breakpoints.small}) {
//     width: ${(props) => props.wrapWidth || '425px'};
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const GalleryItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   min-width: 78px;
//   /* width: auto; */
//   overflow: hidden;
//   margin: 0;
// `;

// export const GalleryImage = styled.img`
//   @media (min-width: ${breakpoints.tablet}) {
//     height: auto;
//     width: 100%;
//     max-width: 100%;
//     border: 0;
//     border-radius: var(--radiusNone);
//     cursor: pointer;
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const PrevButton = styled.button`
//   border: none;
//   display: inline-block;
//   padding: 8px 16px;
//   vertical-align: middle;
//   overflow: hidden;
//   text-decoration: none;
//   color: inherit;
//   background-color: inherit;
//   text-align: center;
//   cursor: pointer;
//   white-space: nowrap;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   &:hover {
//     color: #000 !important;
//     background-color: #ccc !important;

//     @media (min-width: ${breakpoints.tablet}) {
//       background-color: rgba(0, 0, 0, 0.3) !important;
//     }
//     @media (min-width: ${breakpoints.small}) {
//     }
//     @media (min-width: ${breakpoints.large}) {
//     }
//   }
//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.3;
//   }
//   position: absolute;
//   top: 50%;
//   left: 0%;
//   transform: translate(0%, -50%);
//   -ms-transform: translate(-0%, -50%);
//   background: none;
//   color: black;
//   font-size: 2em;
//   font-weight: bold;
//   opacity: 0.6;

//   @media (min-width: ${breakpoints.tablet}) {
//     outline: none;
//     display: none;
//     font-size: 3em;
//     opacity: 0.9;
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const NextButton = styled.button`
//   border: none;
//   display: inline-block;
//   padding: 8px 16px;
//   vertical-align: middle;
//   overflow: hidden;
//   text-decoration: none;
//   color: inherit;
//   background-color: inherit;
//   text-align: center;
//   cursor: pointer;
//   white-space: nowrap;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   &:hover {
//     color: #000 !important;
//     background-color: #ccc !important;

//     @media (min-width: ${breakpoints.tablet}) {
//       background-color: rgba(0, 0, 0, 0.3) !important;
//     }
//     @media (min-width: ${breakpoints.small}) {
//     }
//     @media (min-width: ${breakpoints.large}) {
//     }
//   }
//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.3;
//   }
//   position: absolute;
//   top: 50%;
//   right: 0%;
//   transform: translate(0%, -50%);
//   -ms-transform: translate(0%, -50%);
//   background: none;
//   color: black;
//   font-size: 2em;
//   font-weight: bold;
//   opacity: 0.6;

//   @media (min-width: ${breakpoints.tablet}) {
//     outline: none;
//     display: none;
//     font-size: 3em;
//     opacity: 0.9;
//   }
//   @media (min-width: ${breakpoints.small}) {
//   }
//   @media (min-width: ${breakpoints.large}) {
//   }
// `;

// export const LeftCaption = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   margin: 0px 1px 10px 5px;
//   padding: 0.01em 5px;
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
//   color: #fff !important;
//   background-color: red !important;
//   opacity: 0.6;

//   @media (min-width: 601px) {
//     display: none;
//   }
// `;

// export const RightCaption = styled.div`
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   margin: 0px 1px 10px 0px;
//   padding: 0.01em 5px;
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
//   color: #fff !important;
//   background-color: #000 !important;
//   opacity: 0.6;

//   @media (min-width: 601px) {
//     display: none;
//   }
// `;

// export const Slide = styled.div`
//   /* max-height: 70vh; */
//   height: ${(props) => props.height || 80}px;
//   @media (min-width: 601px) {
//     /* height: ${(props) => props.desktopImageHeight || 80}px; */
//   }
//   background-image: ${(props) => `url('${props.backgroundImage}')`};
//   background-color: black;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 0;
// `;
