import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDoubleClick } from 'hooks';
import {
  StyledButton, Disabled, DisabledText, ButtonWrapper,
} from './Styles';

export const DoubleTapButton = ({ action, text, disabled, bidPanel, nextBid }) => {
  const buttonRef = useRef();

  useDoubleClick({
    nextBid,
    onDoubleClick: action,
    ref: buttonRef,
    latency: 500,
  });

  if (disabled) {
    return <Disabled bidPanel={bidPanel}><DisabledText>{text}</DisabledText></Disabled>;
  }

  return (
    <ButtonWrapper>
      <StyledButton ref={buttonRef} color={disabled ? 'var(--disabledBtnBgColor)' : 'var(--bidBtnBgColor'}>{`DOUBLE TAP TO ${text}`}</StyledButton>
    </ButtonWrapper>
  );
};


DoubleTapButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  action: PropTypes.func,
};

DoubleTapButton.defaultProps = {
  disabled: false,
  text: null,
  action: null,
};


export default DoubleTapButton;
