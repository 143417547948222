import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { LayoutButtons } from 'assets/ContainerStyles';
import { LayoutViewButton } from '..';
import {
  DEFAULT_LAYOUT_VIEW,
  MINI_LAYOUT_VIEW,
  FULL_LAYOUT_VIEW,
} from '../../../shared/constants/index';
import Analytics from "../../../shared/utils/analytics";

export const LayoutViewButtons = () => {
  const {
    AuctionStore: { layoutViewType },
    UIStore: { setLayoutViewType },
  } = useStores();
  
  const switchView = (viewType) => {
    Analytics.RecordEvent(`Layout Type Click - ${viewType}`, 'View', 'Layout Type:', viewType);

    setLayoutViewType(viewType);
  };

  return (
    <LayoutButtons>
      <div style={{ color: 'var(--darkTextColor)'}}>Lane Views:</div>
      <LayoutViewButton
        onClick={() => switchView(MINI_LAYOUT_VIEW)}
        text="Mini"
        isActive={layoutViewType === MINI_LAYOUT_VIEW}
        icon="th"
      />
      <LayoutViewButton
        onClick={() => switchView(DEFAULT_LAYOUT_VIEW)}
        text="Standard"
        isActive={layoutViewType === DEFAULT_LAYOUT_VIEW}
        icon="bars"
      />
      <LayoutViewButton
        onClick={() => switchView(FULL_LAYOUT_VIEW)}
        text="Full"
        isActive={layoutViewType === FULL_LAYOUT_VIEW}
        icon="stop"
      />
    </LayoutButtons>
  );
};

export default observer(LayoutViewButtons);