import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useStores from './hooks/use-store';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { AuctionStore: { connected } } = useStores();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (connected) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default observer(ProtectedRoute);
