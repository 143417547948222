import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive } from 'hooks';
import { ConfirmModal } from 'components/project/shared';
import { LaneContent } from '../LaneContent';

export const AuctionLaneContent = observer(() => {
  const {
    AuctionStore: {
      connected,
      laneList,
      openLanes,
    },
    UIStore: { handleJoinLane, handleExitLane },
  } = useStores();

  const { isMobileView } = useResponsive();

  const handleClick = (item) => {
    if (isMobileView) {
      openLanes.forEach((laneID) => handleExitLane(laneID));
      if (!openLanes.includes(item.laneID)) {
        handleJoinLane(item.laneID);
      }
      return;
    }

    if (!openLanes.includes(item.laneID)) {
      handleJoinLane(item.laneID);
    } else {
      handleExitLane(item.laneID);
    }
  };

  return laneList.length > 0
    && connected
    && laneList
      .map((item) => (
        openLanes.includes(item.laneID) ? (
          <ConfirmModal
            key={`Confirm_${item.laneID}`}
            title="Confirm Exit Lane"
            message={`Are you sure you would like to exit the '${item.lane}' lane?`}
            confirmText="Exit Lane"
            onConfirm={() => {
              handleClick(item);
            }}
            renderLink={(modal) => <LaneContent item={item} onClick={modal.open} key={item.laneID} />}
          />
        ) : (
          <LaneContent item={item} key={`LaneContent_${item.laneID}`} onClick={() => handleClick(item)} />
        )
      ));
});

export default AuctionLaneContent;
