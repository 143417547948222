import React from 'react';
import { PropTypes } from 'prop-types';
import logo from 'assets/images/logo.png';
import { Container, OverlayImageContainer, Error } from './Styles';

export const Promo = ({
  promo, handlePromoClick, preview, productPreview, overlayImage, isMobileView,
}) => {
  if (!promo || !handlePromoClick) return <Error>Missing prop(s) for the Promo component</Error>;

  const showLinkWithText = !!promo.text && !!promo.url;
  const showJustText = !!promo.text && !promo.url;

  const onFakeItOrMakeItClick = showJustText
    ? () => null
    : () => handlePromoClick(promo.url);

  const imageToUse = promo.image || logo;

  return (
    overlayImage && promo.image ? (
      <OverlayImageContainer
        className="promo-image"
        redirectUrl={promo.url}
        onClick={onFakeItOrMakeItClick}
        image={imageToUse}
        text={promo.text}
        preview={preview}
        isProviderLogo={!!promo.image}
        isMobileView={isMobileView}
      >
        {showLinkWithText && (
        <a target="_blank" href={promo.url} rel="noopener noreferrer">
          {promo.text}
        </a>
        )}

        {showJustText && promo.text}
      </OverlayImageContainer>
    ) : (
      <Container
        className={`promo-image${productPreview ? '-prev' : ''}`}
        redirectUrl={promo.url}
        isNPALogo={!promo.url}
        onClick={onFakeItOrMakeItClick}
        image={imageToUse}
        text={promo.text}
        preview={preview}
        isProviderLogo={!!promo.image}
      >
        {!promo.url && !overlayImage && <img src={imageToUse} alt="" />}
        {showLinkWithText && (
        <a target="_blank" href={promo.url} rel="noopener noreferrer">
          {promo.text}
        </a>
        )}

        {showJustText && promo.text}
      </Container>
    )
  );
};

Promo.propTypes = {
  promo: PropTypes.shape({
    image: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  handlePromoClick: PropTypes.func.isRequired,
  preview: PropTypes.bool,
  productPreview: PropTypes.bool,
  overlayImage: PropTypes.bool,
  isMobileView: PropTypes.bool,
};

Promo.defaultProps = {
  preview: false,
  productPreview: false,
  overlayImage: false,
  isMobileView: false,
};

export default Promo;
