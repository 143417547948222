import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const StyledIcon = styled.i`
  font-size: ${(props) => `${props.size || 1}em`};
  ${(props) => props.iconOnly && 'padding-right: 0px'};
  &:hover {
    cursor: ${(props) => (props.hoverPointer ? 'pointer' : 'default')};
  }
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export default StyledIcon;
