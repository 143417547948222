import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import { useToggle, useResponsive } from 'hooks';
import {
  AttributeComments,
  CenteredTrack,
  ConditionReport,
  ConditionReportWrapper,
  ContentWrapper,
  CRTrack,
  CRTrackKey,
  Comment,
  CRTrackKeyWithComments,
  CRTrackValue,
  Track,
  TrackComments,
  TrackKey,
  TrackKeyValueContainer,
  TrackLink,
  TrackValue,
} from './Styles';
import { abbrevStringValue, formatCurrency } from '../../shared/utils/common';
import { Icon } from 'components/project/shared';

const NPAScoreLink = ({url, isMobileView}) => {
  const NPA_SCORE = 'NPA Score';
  if (!url || isMobileView || isMobileView === undefined) return NPA_SCORE;
  return (
    <>
      {NPA_SCORE}&nbsp;
      <TrackLink href={url} target="_blank" title="View Full Report"><Icon iconPackage="fas" icon="external-link-alt" size={1} iconOnly /></TrackLink>
    </>
  )
}

const GetAttributeItem = (value, attributes) => {
  if (!attributes) {
    return null;
  }
  const attr = attributes.filter((item) => item.name === value);

  if (attr.length === 0) {
    return null;
  }

  return attr[0];
};

const useToggleCrComment = (lotNumber, initialValue = []) => {
  const [visible, setVisible] = useState(initialValue);
  
  const show = (index) => visible.includes(index);
  const add = (index) => setVisible([...visible, index]);
  const remove = (commentIndex) => setVisible(visible?.filter((value) => value !== commentIndex));
  const toggle = (index) => show(index) ? remove(index) : add(index);

  useEffect(() => setVisible([]), [lotNumber]);

  return [show, toggle];
}

const ItemAttributes = ({ data, showProductName, isFullViewLayout }) => {
  const initialShowCrValue = isFullViewLayout ? true : isFullViewLayout;
  const { isToggled: showCr, toggle: toggleCr } = useToggle(initialShowCrValue);
  
  const { isMobileView } = useResponsive();
  const { attributes, productName, lotNumber } = data;
  const [showComment, toggleComment] = useToggleCrComment(lotNumber);

  const isProductPreview = !showProductName;
  const showDivider = isProductPreview || isMobileView;
  const onToggle = (e) => {
    e.preventDefault();
    toggleCr();
  };

  if (!attributes && !isFullViewLayout) {
    return null;
  }

  const vin = GetAttributeItem('VIN', attributes);
  const cr = GetAttributeItem('ConditionReport', attributes);
  const mileage = GetAttributeItem('Mileage', attributes);
  const location = GetAttributeItem('Location', attributes);
  const color = GetAttributeItem('Color', attributes);
  const docs = GetAttributeItem('Documents', attributes);
  const inspectionHeader = GetAttributeItem('InspectionHeader', attributes);

  const formattedTitle = abbrevStringValue(productName, 70, false);

  const getCrJsonProp = (report, key) => report?.value && JSON.parse(report.value)[key];
  const getCrValue = (item) => (item?.Score !== '-' ? `${item.Option} (${item.Score})` : item.Option);

  const crTotalScore = getCrJsonProp(cr, 'TotalScore');
  const crCosmeticScore = getCrJsonProp(cr, 'CosmeticScore');
  const crMechanicalScore = getCrJsonProp(cr, 'MechanicalScore');
  const crComments = getCrJsonProp(cr, 'Comments');
  const crItems = getCrJsonProp(cr, 'Items');
  const conditionReportUrl = getCrJsonProp(cr, 'URL');

  const getCrScore = (total, mechanical, cosmetic) => {
    if (!total && (!mechanical || !cosmetic)) return '--';
    if (total === '') return 'Not Available';
    if (cosmetic === '' || mechanical === '') return `Score: ${total}`;
    return `${total} (Mech: ${mechanical}; Cosm: ${cosmetic})`;
  };

  const crScore = getCrScore(crTotalScore, crMechanicalScore, crCosmeticScore);
  const preventCrToggle = crScore === 'Not Available' || crScore !== 'Not Available' & isFullViewLayout;

  const shade1 = isProductPreview ? 'var(--prevAttrsBgColor1)' : 'var(--blockAttrsBgColor1)';
  const shade2 = isProductPreview ? 'var(--prevAttrsBgColor2)' : 'var(--blockAttrsBgColor2)';
  const flipShade1 = isFullViewLayout ? shade1 : shade2;
  const flipShade2 = isFullViewLayout ? shade2 : shade1;

  return (
    <ContentWrapper noData={crScore === '--'} className={`item-attribs${isFullViewLayout ? '-slv' : ''}`} isFullViewLayout={isFullViewLayout}>
      <div>
        {showProductName && !isMobileView && (
          <div className="fmt-title" style={{ backgroundColor: shade2}}>
            <CenteredTrack isFullViewLayout={isFullViewLayout} title={productName}>{formattedTitle.length ? formattedTitle : <>&nbsp;</>}</CenteredTrack>
          </div>
        )}
        <Track bgColor={shade1} showDivider={showDivider}>
          <TrackKey>Documents</TrackKey>
          <TrackValue>{docs ? docs.value : '--'}</TrackValue>
        </Track>

        <Track bgColor={shade2} showDivider={showDivider}>
          <TrackKey>Miles/Hours</TrackKey>
          <TrackValue>
            {mileage ? mileage.value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '--'}
          </TrackValue>
        </Track>

        <Track bgColor={shade1} showDivider={showDivider}>
          <TrackKey>Clean Book</TrackKey>
          <TrackValue>
            {data.bookValue && data.bookValue > 0
              ? formatCurrency(data.bookValue)
              : '--'}
          </TrackValue>
        </Track>

        <Track bgColor={shade2} showDivider={showDivider}>
          <TrackKey>Location</TrackKey>
          <TrackValue>{location ? location.value : '--'}</TrackValue>
        </Track>
        {!isFullViewLayout && (
          <>
            <Track bgColor={shade1}>
              <TrackKey><NPAScoreLink url={conditionReportUrl} isMobileView={isMobileView} /></TrackKey>
              <TrackValue>
                {preventCrToggle ? (
                  crScore
                ) : (
                  <TrackLink href="#" onClick={onToggle}>
                    {crScore}
                  </TrackLink>
                )}
              </TrackValue>
            </Track>

            {(showCr || isFullViewLayout) && (
            <AnimatePresence>
              <ConditionReportWrapper className="cr-wrapper">
                <ConditionReport isFullViewLayout={isFullViewLayout}>
                  {crItems?.map((item, index) => (
                    <>
                    <CRTrack key={`cr1_${lotNumber}_${item.Item}`} color="var(--bidActivityTextColor)" bgColor1="var(--bidActivityBgColor1)" bgColor2="var(--bidActivityBgColor2)">
                     <TrackKeyValueContainer>
                      {item.Comment ? (
                        <CRTrackKeyWithComments comment={item.Comment} isFullViewLayout={isFullViewLayout} isMobileView={isMobileView} onClick={() => toggleComment(index)}>
                          {item.Item}
                        </CRTrackKeyWithComments>
                      ) : (
                        <CRTrackKey comment={item.comment}>
                          {item.Item}
                        </CRTrackKey>
                      )}
                      
                      <CRTrackValue>{getCrValue(item)}</CRTrackValue>
                      </TrackKeyValueContainer>
                      {showComment(index) && (
                        <Comment className="comment" key={`cr1_comment_${lotNumber}_${item.Item}`}>
                          {item.Comment}
                        </Comment>
                      )}
                    </CRTrack>
                    
                    
                    </>
                  ))}
                  {crComments && (
                  <TrackComments>
                    <b>NOTES:</b>
                    {' '}
                    {crComments}
                  </TrackComments>
                  )}
                </ConditionReport>
              </ConditionReportWrapper>
            </AnimatePresence>
            )}
          </>
        )}

        {/* <Track bgColor={isFullViewLayout ? 'var(--blockAttrsBgColor1)' : 'var(--blockAttrsBgColor2)'}> */}
        <Track bgColor={flipShade1} showDivider={showDivider}>
          <TrackKey>VIN</TrackKey>
          <TrackValue>{vin ? vin.value : '--'}</TrackValue>
        </Track>

        <Track bgColor={flipShade2} showDivider={showDivider}>
          <TrackKey>Color</TrackKey>
          <TrackValue>{color ? color.value : '--'}</TrackValue>
        </Track>
        <Track bgColor={flipShade1} attribute="notes" showDivider={showDivider}>
          <TrackKey>Notes</TrackKey>
          <TrackValue>{inspectionHeader ? inspectionHeader.value : '--'}</TrackValue>
        </Track>
        <AttributeComments>
          {data.publicComments}
        </AttributeComments>
        {isFullViewLayout && (
          <>
            <Track bgColor={shade2} style={{ borderTop: '1px solid var(--gray-1)' }}>
              <TrackKey><NPAScoreLink url={conditionReportUrl} isMobileView={isMobileView} /></TrackKey>
              <TrackValue>
                {preventCrToggle ? (
                  crScore
                ) : (
                  <TrackLink href="#" onClick={onToggle}>
                    {crScore}
                  </TrackLink>
                )}
              </TrackValue>
            </Track>
            {(showCr || isFullViewLayout) && (
              <ConditionReportWrapper className="cr-wrapper">
                {crItems?.length ? (
                  <ConditionReport isFullViewLayout={isFullViewLayout} isMobileView={isMobileView}>
                    {crItems?.map((item, index) => (
                      <CRTrack key={`cr2_${lotNumber}_${item.Item}`} color="var(--crAttrsTextColor)" bgColor1="var(--crAttrsBgColor1)" bgColor2="var(--crAttrsBgColor2)">
                        <TrackKeyValueContainer>
                          {item.Comment ? (
                          <CRTrackKeyWithComments 
                            comment={item.Comment} 
                            isFullViewLayout={isFullViewLayout} 
                            isMobileView={isMobileView} 
                            onClick={() => toggleComment(index)}>
                            {item.Item}
                          </CRTrackKeyWithComments>
                        ) : (
                          <CRTrackKey comment={item.comment}>
                            {item.Item}
                          </CRTrackKey>
                        )}
                        <CRTrackValue>{getCrValue(item)}</CRTrackValue>
                      </TrackKeyValueContainer>
                      {showComment(index) && (
                        <Comment className="comment" key={`cr1_comment_${lotNumber}_${item.Item}`}>
                          {item.Comment}
                        </Comment>
                      )}
                      </CRTrack>
                    ))}
                    {crComments && (
                      <TrackComments>
                        <b>NOTES:</b>
                        {' '}
                        {crComments}
                      </TrackComments>
                    )}
                  </ConditionReport>
               ) : (
                 'No CR Data'
                )}
              </ConditionReportWrapper>
            )}
          </>
        )}
      </div>
    </ContentWrapper>
  );
};

ItemAttributes.propTypes = {
  data: PropTypes.object.isRequired,
  showProductName: PropTypes.bool,
  isFullViewLayout: PropTypes.bool,
};

ItemAttributes.defaultProps = {
  showProductName: true,
  isFullViewLayout: false,
};

export default ItemAttributes;
