import styled from 'styled-components';

export const Disabled = styled.div`
  background-color: var(--disabledBtnBgColor);
  margin: 0;
  padding: 1px;
`;

export const DisabledText = styled.p`
  color: var(--disabledBtnTextColor);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 0;
  /* padding: 10px; */
`;

export const StyledButton = styled.button`
  background-color: var(--bidBtnBgColor);
  color: var(--bidBtnTextColor);
  width: 100%;
  height: 50px;
`;
