import styled from 'styled-components';
import { breakpoints } from '../../../../shared/utils/styles';

export const BidderBarContainer = styled.div`
  display:none;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: bidbar;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--lotNumberBgColor);
    color: var(--lotNumberTextColor);
    font-weight: bold;
    font-size: 0.8em;
    /* height: 30px; */
    height: 100%;
    width: 100%;
    & div {  
      width: 100%;
    }
    & div:first-child {
      padding-left: 5px;
    }
    & div:last-child {
      padding-right: 5px;
    }
  }
  
`;
export const LeftContent = styled.div`
  text-align: left;
`;
export const MiddleContent = styled.div`
  text-align: center;
`;
export const RightContent = styled.div`
  text-align: right;

`;
