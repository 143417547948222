import { useEffect, useState } from 'react';

export const useLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    return () => null;
  }, [isLoading, setIsLoading]);

  return [isLoading];
};

export default useLoader;
