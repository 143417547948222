import styled from 'styled-components';

import { Button, Form } from 'components/shared';

export const LogoWrapper = styled.div`
    text-align: center;
    width: 100%;
    margin: 20px 0;
`;

export const Logo = styled.img`
    max-width: 200px;
    border: 0;
    margin: auto;
    text-align: center;
`;

export const FormWrapper = styled.div`
    background: rgba(0, 0, 0, 0.05);
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    border: var(--line);
    text-align: center;
`;

export const FormCont = styled.div`
  justify-content: center;
  margin: 20px;
  text-align: center;
`;

export const FormElement = styled(Form.Element)`
  max-width: 240px;
  margin: 0 auto;
`;

export const FormHeading = styled.h1`
  padding: 6px 0 15px;
  text-align: center;
`;

export const ActionButton = styled(Button)`
  margin-top: 30px;
`;

export const BodyHeader = styled.div`
  grid-area: header;
  display: grid;
`;

export const BodyFooter = styled.div`
  grid-area: footer;
  display: grid;
`;

export const Content = styled.div`
width: 100%;
`;

export const RightColumn = styled.div`
    grid-area: sidebar;
`;

export const FormRow = styled.div`
    margin: 10px 0;
    text-align: center;
`;

export const Select = styled.select`
    border: var(--line);
    padding: 5px;
    text-align: center;
`;

export const Option = styled.option`
    text-align: center;
`;

export const BodyContent = styled.div`
    background: rgba(0, 0, 0, 0.05);
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    border: var(--line);
    text-align: center;
    `;
