import React, { useEffect, useRef } from 'react';

export const ScrollToBottom = () => {
  const ref = useRef(null);

  const scrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  };

  useEffect(() => {
    scrollToBottom();
  });

  return <div style={{ float: 'left', clear: 'both' }} ref={ref} />;
};

export default ScrollToBottom;
