import toast from './toast';

/* eslint-disable no-console */
export const formattedOutput = ({
  component, method, params, message, messageType, settings, user,
}) => {
  const output = {};
  const shouldIncludeProp = (field) => settings.propsToShowInOutput.includes(field);
  const now = new Date();
  output.utcTime = now.toUTCString();
  output.localTime = now.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'long' });
  output.tzOffsetInMinutes = now.getTimezoneOffset();
  output.tzOffsetInHours = now.getTimezoneOffset() / 60;

  if (shouldIncludeProp('component')) output.component = component;
  if (shouldIncludeProp('method')) output.method = method;
  if (shouldIncludeProp('params')) output.params = params;
  if (shouldIncludeProp('message')) output.message = message;
  if (shouldIncludeProp('messageType')) output.messageType = messageType;
  if (shouldIncludeProp('settings')) output.settings = settings;
  if (shouldIncludeProp('user')) output.user = user;

  if (settings.toString) return JSON.stringify(output);
  return output;
};

export const LOGGER_SETTINGS = {
  messageTypeToWrite: ['error'],
  propsToShowInOutput: ['method', 'params', 'message', 'user'],
  toString: false,
  formatter: formattedOutput,
};

export const LOGGER_SETTINGS_DEBUG = Object.assign(LOGGER_SETTINGS,
  {
    messageTypeToWrite: ['log', 'warn', 'error'],
  });

class SimulcastLogger {
  constructor({
    component = null, method = null, params = null, message = null, messageType = 'error', settings = LOGGER_SETTINGS, user = null,
  }) {
    this.component = component;
    this.method = method;
    this.params = params;
    this.message = message;
    this.messageType = messageType;
    this.settings = settings;
    this.user = user;
  }

  shouldWrite = (msgType) => this.messageType === msgType && this.settings.messageTypeToWrite.includes(msgType);

  shouldNotSkipMessageType = () => this.settings.messageTypeToWrite.includes(this.messageType);

  defaultMessageFormatterOutput = () => this.settings.formatter({
    component: this.component, method: this.method, params: this.params, message: this.message, messageType: this.messageType, settings: this.settings, user: this.user,
  });

  logToConsole = () => {
    if (this.shouldNotSkipMessageType()) {
      const messageOutput = this.defaultMessageFormatterOutput();
      const parsedOutput = this.settings.toString ? JSON.parse(messageOutput) : messageOutput;
      const [WARN, ERROR] = this.settings.messageTypeToWrite;
      if (this.shouldWrite(WARN)) console.warn(parsedOutput);
      if (this.shouldWrite(ERROR)) console.error(parsedOutput);
    }
  };

  showToast = (console = false) => {
    if (this.shouldNotSkipMessageType()) {
      const messageOutput = this.defaultMessageFormatterOutput();
      const parsedOutput = this.settings.toString ? JSON.parse(messageOutput) : messageOutput;
      const { message } = parsedOutput;
      const [LOG, WARN, ERROR] = this.settings.messageTypeToWrite;
      if (this.shouldWrite(LOG)) toast.success(message);
      if (this.shouldWrite(WARN)) toast.success(message);
      if (this.shouldWrite(ERROR)) {
        if (console) this.logToConsole();
        toast.error(message);
      }
    }
  };
}

export default SimulcastLogger;
