import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useToggle } from 'hooks';
import { AuctionLanes } from '../AuctionLanes';
import { BidActivity } from '../BidActivity';
import { Stats } from '../Stats';
import { ThemeButtons } from '../../../ThemeButtons'

export const PinnedNavSections = observer(() => {
  const {
    BidStore: {
      user: { memberName },
    },
  } = useStores();

  const showStats = memberName.indexOf('(') > -1;
  const { isToggled: showLaneSection } = useToggle(true);
  const { isToggled: showStatsSection } = useToggle(showStats);
  const { isToggled: showActivitySection } = useToggle(true);

  return (
    <>
      <ThemeButtons />
      {showLaneSection && <AuctionLanes />}
      {showStatsSection && <Stats />}
      {showActivitySection && <BidActivity />}
    </>
  );
});

export default PinnedNavSections;
