import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const bgImageCss = ({ image }) => !!image
  && css`
    background-image: url('${image}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  `;

export const Container = styled.div`
  display: flex;
  grid-area: stop;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  height: 320px;
  margin: 0;
  ${(props) => bgImageCss(props)};
  background-color: var(--gray-2);
  @media (min-width: ${breakpoints.tablet}) {
    width: ${(props) => (props.isFullViewLayout ? '425px' : '475px')};
    height: ${(props) => (props.isFullViewLayout ? '390px' : '320px')};
  }
  @media (min-width: ${breakpoints.small}) {
    
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export default Container;
