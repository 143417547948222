import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useDesktopRedirect } from 'hooks';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../../shared/constants/index';

import {
  Content,
  Wrapper,
  Icon,
  BackWrapper,
  BackButton,
  Track,
  TrackKey,
  TrackValue,
} from './styles';

const BidActivity = () => {
  const {
    BidStore: { notifications },
  } = useStores();

  const history = useHistory();
  const { isSmallView } = useDesktopRedirect();

  return !isSmallView ? (
    <Wrapper>
      <BackWrapper>
        <BackButton onClick={() => history.push(`${ROUTES.auction}`)}>
          <Icon className="fa fa-chevron-left icon-only" />
          Return to auction
        </BackButton>
      </BackWrapper>
      <Content>
        <h2>Bid Activity</h2>

        {notifications.length === 0 && <p>No notifications</p>}

        {notifications.map((value, index) => (
          <Track key={index}>
            <TrackKey>{value.timestamp}</TrackKey>
            <TrackValue>{value.notification}</TrackValue>
          </Track>
        ))}
      </Content>
    </Wrapper>
  ) : null;
};

export default observer(BidActivity);
