import moment from 'moment';
import { EST_MINUTES_PER_AUCTION_ITEM } from 'shared/constants';

export const formatDate = (date, format = 'MMMM D, YYYY') => (date ? moment(date).format(format) : date);

export const formatDateTime = (date, format = 'MMMM D, YYYY, h:mm A') => (date ? moment(date).format(format) : date);

export const formatDateTimeForAPI = (date) => (date ? moment(date).utc().format() : date);

export const formatDateTimeConversational = (date) => (date ? moment(date).fromNow() : date);

export const calculateEstAuctionTime = (index) => {
  const totalMinutes = Math.ceil((index + 1) * EST_MINUTES_PER_AUCTION_ITEM);
  const totalHours = parseInt(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const getFormattedHours = () => {
    if (totalHours > 1) return `${totalHours}hrs`;
    if (totalHours === 1) return `${totalHours}hr`;
    return '';
  };

  const getFormattedMinutes = () => {
    if (minutes > 1) return `${minutes}mins`;
    if (minutes === 1) return `${minutes}min`;
    return '';
  };

  const formattedHours = getFormattedHours();
  const formattedMinutes = getFormattedMinutes();
  const formattedTime = `${formattedHours} ${formattedMinutes}`;
  return formattedTime.trim();
};

export const getFormattedMilitaryTime = (d) => {
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  const strTime = `${hours}:${minutes}:${seconds}`;
  return strTime;
};

export const addHoursToDate = (dt, hrs) => formatDateTime(moment(dt).add(hrs, 'hours'), 'MMMM D, YYYY, h:mm:ss A').toString();

export const isExpired = (dt) => {
  const now = formatDateTime(new Date(), 'MMMM D, YYYY, h:mm:ss A');
  if (!!dt) return false;
  return now >= dt ?? now;
};
