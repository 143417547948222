import React from 'react';
import { observer } from 'mobx-react-lite';
import mobileLogo from 'assets/images/NPAMobileLogo.png';
import logo from 'assets/images/logo.png';
import { useResponsive, useStores, useTheme } from 'hooks';
import { RefreshButton } from '../RefreshButton';

import {
  BodyHeader,
  Logo,
  NativeBackButton,
  LeftContainer,
  CenterContainer,
  RightContainer,
} from './Styles';
import { Icon } from '../shared/Icon';

const Header = () => {
  const {
    AuctionStore: { openLanes },
    BidStore: { user, auction, setAuctionProperty },
    UIStore: { toggleMobileNav, mobileNavOpen },
  } = useStores();
  const { toggleButtons: toggleThemeButtons } = useTheme();
  const { isMobileView } = useResponsive();


  // const checkUrl = (url) => window.location.hostname.indexOf(url) > -1;
  // const isProduction = checkUrl(PROD_URL);
  // const isUAT = checkUrl(UAT_URL);
  // const isLocal = checkUrl(LOCAL_URL);
  // const isStaging = checkUrl(STAGING_URL);
  const isAMSAccount = user.memberName === 'NPA';


  const BackButtonLogic = () => {
    let backToApp = true;

      // eslint-disable-next-line
    openLanes.map((laneID) => {
      if (auction[laneID].showProdPreview) {
        setAuctionProperty(laneID, 'previewLotNumber', null);
        setAuctionProperty(laneID, 'showProdPreview', false);
        backToApp = false;
        // eslint-disable-next-line
        return;
      }

      if (mobileNavOpen) {
        toggleMobileNav(false);
        backToApp = false;
      }
    });

    if (backToApp) window.ReactNativeWebView.postMessage('Go Back');
  };

  return (
    <BodyHeader>
      <LeftContainer>
        <RefreshButton
          onClick={() => window.location.reload()}
          text="Refresh Window"
          icon="redo"
        />
      </LeftContainer>
      <CenterContainer>
        {isMobileView ? (
          <>
            {window.ReactNativeWebView && (
              <NativeBackButton
                className="fal fa-chevron-left"
                onClick={() => BackButtonLogic()}
              />
            )}
            <Logo src={mobileLogo} width="50" />
          </>
        ) : (
          <>
            <Icon icon="user" size={1} hoverPointer={false} />
            <div>{user.memberName}</div>
          </>
        )}
      </CenterContainer>
      <RightContainer>
        {!isMobileView && (
          <>
            {isAMSAccount && (
              <div
                onClick={toggleThemeButtons}
                style={{ color: 'var(--navBgColor)', cursor: 'pointer' }}
              >
                Themes
              </div>
            )}
            <Logo src={logo} width="150" />
          </>
        )}
      </RightContainer>
    </BodyHeader>
  );
};

export default observer(Header);
