import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  useMobile, useNonMobile, useResponsive, useStores,
} from 'hooks';
import {
  NavLeft, NavListWrap, NavItem, Link, LinkText, Icon,
} from './Styles';

import { ROUTES } from '../../../../shared/constants/index';
import { Overlay } from '../../../../assets/ContainerStyles';

const MobileNavbarLeft = () => {
  const {
    AuctionStore: {
      auctionInfo,
      deleteMobileClosedLane,
      mobileClosedLanes,
      openLanes,
      setMobileClosedLanes,
    },
    BidStore: { getLaneIDPlayingAudio },
    UIStore: {
      handleExitLane,
      handleJoinLane,
    },
  } = useStores();

  const { isMobileView } = useResponsive();
  const [mobileNavRef, open] = useMobile({
    isMobileView,
    setMobileClosedLanes,
    handleExitLane,
    getLaneIDPlayingAudio,
    openLanes,
  });

  useNonMobile({
    isMobileView,
    mobileClosedLanes,
    deleteMobileClosedLane,
    handleJoinLane,
  });

  const history = useHistory();
  const match = useRouteMatch();


  return (isMobileView)
  && (
    <>
      <div ref={mobileNavRef}>
        <NavLeft open={open}>
          <NavListWrap>
            <NavItem>
              <Link onClick={() => history.push(`${ROUTES.auction}`)}>
                <Icon className="fa fa-home icon-only" />
                <LinkText>Home</LinkText>
              </Link>
            </NavItem>
            <NavItem>
              <Link onClick={() => history.push(`${match.path}${ROUTES.lanes}`)}>
                <Icon className="fa fa-motorcycle icon-only" />
                <LinkText>Lanes</LinkText>
              </Link>
            </NavItem>
            {auctionInfo.allowChat === 1 && (
              <NavItem>
                <Link onClick={() => history.push(`${match.path}${ROUTES.chat}`)}>
                  <Icon className="fa fa-inbox icon-only" />
                  <LinkText>Messages</LinkText>
                </Link>
              </NavItem>
            )}
            <NavItem>
              <Link
                onClick={() => history.push(`${match.path}${ROUTES.bidActivity}`)}
              >
                <Icon className="fa fa-gavel icon-only" />
                <LinkText>Bid Activity</LinkText>
              </Link>
            </NavItem>
          </NavListWrap>
        </NavLeft>
      </div>
      <Overlay open={open} />
    </>
  );
};

export default observer(MobileNavbarLeft);
