import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100vw;
`;

export const Content = styled.div`
    margin: 20px;
`;

export const Icon = styled.i`
    color: white;
    margin: 0 5px 0 0;
`;

export const BackWrapper = styled.div`
    background: black;
    display: block;  
`;

export const BackButton = styled.button`
    
    `;

export const Track = styled.div`
  display: flex;
  font-size: 0.7em;
  &:nth-child(even) > div {
    background-color: #eee;
  }
`;

export const TrackKey = styled.div`
  width: 30%;
  padding: 10px 5px 10px 5px;
  text-align: left;
`;

export const TrackValue = styled.div`
  text-align: right;
  padding: 10px 5px 10px 10px;
  width: 70%;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
