import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores, useFocus } from 'hooks';
import Input from 'components/project/shared/Input';
import { ButtonRow, Container, StyledButton } from './Styles';
import { formatCurrency } from '../../../shared/utils/common';
import Spinner from '../../../../shared/Spinner';


export const ProxyBid = ({
  onSubmit,
  onCancel,
  tempProxyBid,
  setTempProxyBid,
  message,
}) => {
  const {
    UIStore: { handleCurrencyKeyUpFormat },
  } = useStores();
  const [proxyBidRef, setProxyBidRefFocus] = useFocus();
  const getKeyCode = (e) => e.which || e.keyCode;
  const isEnterKey = (e) => getKeyCode(e) === 13;
  const [tempMessage, setTempMessage] = useState(message);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [amISure, setAmISure] = useState(false);
  const [loadYouSureBox, setLoadYouSureBox] = useState(false);
  const [updatingProxyValue, setUpdatingProxyValue] = useState(false);

  const SubmitAndDisable = (cb) => {
    setSubmitDisabled(true);
    onSubmit(tempProxyBid);
    if (cb) cb();
  };

  const setAmISureBox = (value) => {
    setAmISure(value);
    setLoadYouSureBox(false);
  };

  const handleAmISure = (value) => {
    if (value) {
      SubmitAndDisable(() => setAmISureBox(value));
      return;
    }
    setAmISureBox(value);
  };

  const canContinueSubmittingProxy = () =>
    proxyBidRef.current.value !== '' && !submitDisabled;

  const onKeyPress = (e) => {
    if (isEnterKey(e) && canContinueSubmittingProxy()) {
      e.preventDefault();
      //SubmitAndDisable();
      setLoadYouSureBox(true);
    }
  };

  useEffect(() => {
    if (proxyBidRef && !updatingProxyValue) {
      setUpdatingProxyValue(true);
      setProxyBidRefFocus();
      proxyBidRef.current.select();
    }
    return () => null;
  }, [
    proxyBidRef,
    setProxyBidRefFocus,
    updatingProxyValue,
    setUpdatingProxyValue,
  ]);

  useEffect(() => {
    if (amISure) {
      if (message.messageType === 'warning') {
        setSubmitDisabled(false);
        setTimeout(() =>
          setTempMessage({ messageType: 'success', message: null }, 2000),
        );
      }

      if (message.messageType === 'error') {
        setSubmitDisabled(false);
      }
    }
    return () => null;
  }, [message, amISure]);

  return loadYouSureBox ? (
    <AmISure onClick={handleAmISure} value={tempProxyBid} />
  ) : (
    <Container>
      {!amISure && (
        <>
          <strong>Enter Proxy Bid</strong>
          <Input
            ref={proxyBidRef}
            onFocus={() => null}
            onKeyPress={onKeyPress}
            onKeyUp={handleCurrencyKeyUpFormat}
            disabled={false}
            placeholderText="Enter proxy bid"
            tabIndex={1}
            maxLength={5}
            testId="proxybid-input"
            value={tempProxyBid}
            onChange={(e) => setTempProxyBid(e.target.value)}
          />
        </>
      )}
      {['warning', 'error'].includes(tempMessage?.messageType) && (
        <div
          style={{
            color:
              tempMessage.messageType === 'error'
                ? 'var(--errorColor)'
                : 'var(--successColor)',
            fontSize: '1em',
            padding: '0px 10px',
            textAlign: 'center',
          }}
        >
          {tempMessage.message}
        </div>
      )}
      {!amISure && (
        <ButtonRow>
          <StyledButton
            onClick={onCancel}
            color="var(--cancelBtnTextColor)"
            bgVariance={20}
          >
            Cancel
          </StyledButton>
          <StyledButton
            // onClick={() => SubmitAndDisable()}
            onClick={() => {
              if (canContinueSubmittingProxy()) {
                setLoadYouSureBox(true);
              }
            }}
            disabled={submitDisabled}
            color="var(--utilityBtnTextColor)"
            bgColor="var(--utilityBtnBgColor)"
            bgVariance={20}
          >
            Submit
          </StyledButton>
        </ButtonRow>
      )}
    </Container>
  );
};

ProxyBid.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tempProxyBid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setTempProxyBid: PropTypes.func.isRequired,
  message: PropTypes.object,
};

ProxyBid.defaultProps = {
  tempProxyBid: 0,
  message: null,
};

export default observer(ProxyBid);

export const AmISure = ({ onClick, value }) => {
  const yesConfirmBtnRef = useRef();

  useEffect(() => {
    yesConfirmBtnRef.current.focus();
  }, []);

  return (
    <Container>
      <strong>Confirm Proxy Bid</strong>
      <div
        style={{ maxWidth: '150px', textAlign: 'center' }}
      >{`Are you sure you would like to place a proxy bid of ${formatCurrency(
        value,
      )}?`}</div>
      <ButtonRow>
        <StyledButton
          color="var(--cancelBtnTextColor)"
          bgVariance={20}
          onClick={() => onClick(false)}
        >
          No
        </StyledButton>
        <StyledButton
          ref={yesConfirmBtnRef}
          color="var(--utilityBtnTextColor)"
          bgColor="var(--utilityBtnBgColor)"
          bgVariance={20}
          onClick={() => onClick(true)}
        >
          Yes
        </StyledButton>
      </ButtonRow>
    </Container>
  );
};

AmISure.propTypes = {
  onClick: PropTypes.func.isRequired,
};
