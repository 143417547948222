import styled from 'styled-components';

export const Container = styled.div``;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4em;
  width: 100vw;
  overflow: hidden;
  margin: 0px;
  padding: 0px 10px;
  background: var(--shadedBgColor);
  box-sizing: border-box;
  border-bottom: var(--line);
  &:hover {
    cursor: pointer;
  }
`;

export const ItemText = styled.div`
  font-size: 1.2rem;
`;

export const IconContainer = styled.div`
  font-size: 1.2rem;
  margin-right: 10px;
  color: var(--gray-8);
`;
