import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useResponsive } from 'hooks';
import ImageGallery from 'react-image-gallery';
import 'assets/gallery.css';
import { ContentWrapper } from './Styles';

const MediaGallery = observer(({
                                   data,
                                   view,
                               }) => {
    const { isMobileView } = useResponsive();
    const { mediaGallery } = data;

    const images = mediaGallery.map((item) => ({
        original: item.mediaUrl,
        thumbnail: item.mediaUrl,
    }));

    // FIXME: MEMORY LEAK ON IMAGEGALLERY (RESIZE FROM DESKTOP TO MOBILE OR BACK TO SEE ERROR)
    return (
        <ContentWrapper key={data.length} productPreview={view === 'prodpreview'} isMobileView={isMobileView}>
            <ImageGallery
                showFullscreenButton={false}
                items={images}
                showBullets={images.length > 1}
                showIndex={images.length > 1}
                showPlayButton={false}
                slideOnThumbnailOver={false}
                lazyLoad
                showThumbnails={!isMobileView && images.length > 1}
            />
        </ContentWrapper>
    );
});

MediaGallery.propTypes = {
    data: PropTypes.object,
    view: PropTypes.string,
};

MediaGallery.defaultProps = {
    data: null,
    view: null,
};

export default MediaGallery;
