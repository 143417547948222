import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive } from 'hooks';
import { Icon } from '../../../shared';

import {
  ClosePinnedNavContainer,
  IconButton,
} from './Styles';


export const PinnedNavCloser = () => {
  const { UIStore: { setIsPinnedNav } } = useStores();
  const { isSmallView } = useResponsive();

  if (!isSmallView) return null;

  return (
    <ClosePinnedNavContainer>
      <IconButton
        style={{ color: 'var(--darkTextColor)' }}
        onClick={() => setIsPinnedNav(false)}
      >
        <Icon icon="times" size={1} />
      </IconButton>
    </ClosePinnedNavContainer>
  );
};


export default observer(PinnedNavCloser);
