import { action, configure, decorate, observable, runInAction } from 'mobx';
import queryString from 'query-string';
import LZString from 'lz-string';
import SignalRMiddleware from 'shared/utils/SignalRHelpers';
import SimulcastLogger, { LOGGER_SETTINGS } from 'shared/utils/SimulcastLogger';
import agent from '../agent';
import { getWsHost } from '../endpoints';
import {
  DEFAULT_LAYOUT_VIEW,
  FULL_LAYOUT_VIEW,
  MINI_LAYOUT_VIEW,
} from '../shared/constants/index';
import { breakpoints } from 'shared/utils/styles';

configure({ enforceActions: 'always' });

export const logIf = (label, message, hash) => {
  if (window.location.hash === `#${hash}`) {
    if (message) {
      console.log(label, message);
      return;
    }
    console.log(label);
  }
};

export const getViewType = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );

  const dimensions = `${vw}x${vh}`;
  const isTablet = vw >= parseInt(breakpoints.tablet);
  const isSmall = vw >= parseInt(breakpoints.small);
  const isLarge = vw >= parseInt(breakpoints.large);
  const isLarger = vw >= parseInt(breakpoints.larger);
  const isLargest = vw >= parseInt(breakpoints.largest);
  const isMobile = !isTablet && !isSmall && !isLarge && !isLarger && !isLargest;

  logIf(
    'sim1202 - browser size',
    {
      dimensions,
      isMobile,
      isTablet,
      isSmall,
      isLarge,
      isLarger,
      isLargest,
    },
    'debug1202',
  );

  let viewType = 'largest';
  if (isMobile) viewType = 'mobile';
  if (isTablet) viewType = 'tablet';
  if (isSmall) viewType = 'small';
  if (isLarge) viewType = 'large';
  if (isLarger) viewType = 'larger';

  return { viewType, vh, vw, dimensions };
};

class DataStore {
  constructor(AuthStore, AuctionStore, BidStore, MessageStore, ProviderStore) {
    this.auctionStore = AuctionStore;
    this.authStore = AuthStore;
    this.bidStore = BidStore;
    this.messageStore = MessageStore;
    this.providerStore = ProviderStore;
    this.initialize();
  }

  initialize = () => {
    this.connection = null;
    this.auctionStore.connected = false;
    this.error = null;
    this.reconnecting = false;
    this.token = null;
    this.lanereferenceId = null;
    this.pingInterval = null;

    logIf('sim1202 - initialize > handleReconnect()', null, 'debug1202');
    this.handleReconnect();
    this.reconnectionErrorMessage = null;
    this.invalidAuctionError = false;
  };

  toggleReconnecting = (state = true) => {
    logIf(
      'sim1202 - toggleReconnecting: ',
      {
        state,
        reconnecting: this.reconnecting,
        connection: this.connection,
      },
      'debug1202',
    );

    if (state === false) {
      runInAction(() => {
        this.reconnecting = false;
      });
      return;
    }

    runInAction(() => {
      if (!this.reconnecting) {
        this.reconnecting = true;
        setTimeout(() => {
          runInAction(() => {
            this.reconnecting = false;
          });
        }, 30000);
      }
    });
  };

  setReconnectionErrorMessage = (message) => {
    runInAction(() => {
      this.reconnectionErrorMessage = message;
      console.error(this.reconnectionErrorMessage);
    });
  };

  handleReconnect = async () => {
    logIf('sim1202 - handleReconnect', null, 'debug1202');
    this.toggleReconnecting();
    const qs = queryString.parse(window.location.search);
    const qsToken = qs?.token;
    const lsToken = window.localStorage.getItem('token');
    const tokensAreDiff = qsToken && lsToken && qsToken !== lsToken;
    const token = qsToken || lsToken;

    const qsLaneRefId = qs?.lanereferenceId;
    this.lanereferenceId = qsLaneRefId;
    const lsLaneRefId = window.localStorage.getItem('lanereferenceId');
    const laneRefsAreDiff =
      qsLaneRefId && lsLaneRefId && qsLaneRefId !== lsLaneRefId;
    const lanereferenceId = qsLaneRefId || lsLaneRefId;

    if (tokensAreDiff || laneRefsAreDiff) {
      localStorage.clear();
    }

    if (!token) {
      this.setReconnectionErrorMessage(
        'Missing session token. Please login again',
      );
      this.toggleReconnecting(false);
      return;
    }

    const auctionId = window.localStorage.getItem('AuctionID');
    if (!auctionId && !lanereferenceId) {
      this.setReconnectionErrorMessage(
        'Missing auction id. Please login again',
      );
      this.toggleReconnecting(false);
      return;
    }

    try {
      if (this.connection?.connectionState === 'Connected') {
        logIf(
          `sim1202 - handleReconnect > connect: Looks like we're already connected, but will attempt to connect anyways???`,
          null,
          'debug1202',
        );
      }

      logIf(
        'sim1202 - handleReconnect > connect: ',
        {
          token,
          lanereferenceId,
          connection: this.connection,
          connectionState: this.connection?.connectionState,
        },
        'debug1202',
      );
      await this.connect(null, null, token, lanereferenceId);

      const AuctionID = window.localStorage.getItem('AuctionID');
      if (AuctionID) {
        await this.sendMessage({ method: 'JoinAuction', payload: [AuctionID] });
      }
    } catch (error) {
      this.setReconnectionErrorMessage(error.message);
      this.messageStore.handleConnectionError('reconnecting');
      this.toggleReconnecting(false);

      // const reconnectLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'handleReconnect',
      //   params: {
      //     qs,
      //     qsToken,
      //     lsToken,
      //     qsLaneRefId,
      //     lsLaneRefId,
      //     tokensAreDiff,
      //     laneRefsAreDiff,
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // reconnectLogger.showToast(true);
    }
  };

  handleRejoin = async (bidderNumber, name) => {
    try {
      const AuctionID = window.localStorage.getItem('AuctionID');
      if (AuctionID) {
        await this.sendMessage({ method: 'JoinAuction', payload: [AuctionID] });
      }
    } catch (error) {
      console.error('handleRejoin', { bidderNumber, error, name });
      // const rejoinLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'handleRejoin',
      //   params: {
      //     bidderNumber,
      //     name,
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // rejoinLogger.showToast(true);
    }
  };

  connect = async (
    bidderNumber,
    name,
    queryToken = null,
    lanereferenceId = null,
  ) => {
    try {
      if (this.invalidAuctionError)
        window.location.href = 'https://www.npauctions.com';

      let url = `?Name=${name}&Username=Hello&Password=World&MemberType=BuyerSeller&BidderNumber=${bidderNumber}`;

      if (queryToken) {
        url = `?Name=&Username=&Password=&MemberType=BuyerSeller&BidderToken=${queryToken}&LaneReferenceID=${lanereferenceId}&BidderNumber=1234`;
        if (!lanereferenceId) {
          url = `?Name=&Username=&Password=&MemberType=BuyerSeller&BidderToken=${queryToken}&BidderNumber=1234`;
        }
        window.localStorage.setItem('token', queryToken);
      } else {
        this.reconnecting = false;
        this.connected = false;
        return;
      }

      const version = window.localStorage.getItem('cacheVersion');
      url += `&uiVersion=${version}`;

      //const viewType = window.localStorage.getItem('viewType');

      // get screen sizes for the login process
      const { viewType, vh, vw, dimensions } = getViewType();

      window.localStorage.setItem('dim', dimensions);
      window.localStorage.setItem('vw', vw);
      window.localStorage.setItem('vh', vh);

      url += `&viewType=${viewType}`;
      url += `&host=${window.location.hostname}`;
      url += `&size=${dimensions}`;
      url += `&vw=${vw}`;
      url += `&vh=${vh}`;

      logIf(
        'sim1202 - connect: ',
        { url, connected: this.connected },
        'debug1202',
      );
      const host = getWsHost();

      // Prevent connecting if the bidder needs to have their bid client refreshed. We'll connect after the reload.
      // A different or NULL cacheVersion means that we need to force a refresh of the bid client. This would
      // close the current connection and create a new one; thus a double connect.
      if (!version) {
        this.reconnecting = false;
        return;
      }

      /** open the socket */
      this.connection = new SignalRMiddleware(host, url).openSocket();

      logIf(
        'sim1202 - connect > connection',
        {
          connection: this.connection,
          connectionState: this.connection?.connectionState,
        },
        'debug1202',
      );

      /** On disconnect */
      this.connection.onclose(() => {
        logIf('sim1202 - connection.onclose', null, 'debug1202');
        this.auctionStore.setProperty('connected', false);
        this.messageStore.handleConnectionError('disconnected');
      });

      this.connection.onreconnecting((callback) => {
        logIf('sim1202 - connection.onreconnecting', null, 'debug1202');
        this.messageStore.handleConnectionError('reconnecting');
        if (callback && typeof callback === 'function') callback();
      });

      this.connection.onreconnected(() => {
        logIf('sim1202 - connection.onreconnected', null, 'debug1202');
        this.messageStore.handleConnectionError('reestablished');
        window.location.reload();
      });

      /** If the socket was opened successfully... */
      this.connection
        .start()
        .then(async () => {
          this.listeners();
          if (!this.invalidAuctionError) {
            this.auctionStore.setProperty('connected', true);
            const auctionId = window.localStorage.getItem('AuctionID');
            if (!lanereferenceId) {
              this.sendMessage({
                method: 'JoinAuction',
                payload: [auctionId],
              });
            }

            if (!this.pingInterval) {
              this.pingInterval = setInterval(() => {
                this.sendMessage({
                  method: 'ping',
                  payload: [],
                });

                this.networkCheckTimeout = setTimeout(() => {
                  this.auctionStore.setProperty('networkConnected', false);
                }, 2000);
              }, 4000);
            }

            logIf(
              'sim1202 - connect > start()',
              {
                connection: this.connection,
                connectionState: this.connection?.connectionState,
              },
              'debug1202',
            );
            return true;
          }
        })
        .catch((error) => {
          runInAction('connect error', () => {
            this.reconnecting = false;
            this.auctionStore.setProperty('connected', false);
            // window.localStorage.removeItem('AuctionID');
            // window.localStorage.removeItem('lanes');

            // const connectStartLogger = new SimulcastLogger({
            //   component: 'DataStore',
            //   method: 'connection.start',
            //   params: {
            //     localStorage: window.localStorage,
            //     stores: {
            //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
            //     },
            //   },
            //   message: error,
            //   messageType: 'error',
            //   settings: LOGGER_SETTINGS,
            //   user: this.bidStore.user,
            // });
            // connectStartLogger.showToast(true);
          });
          return false;
        });
    } catch (error) {
      this.reconnecting = false;

      // const connectStartLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'connect',
      //   params: {
      //     bidderNumber,
      //     name,
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // connectStartLogger.showToast(true);
    }
  };

  closeConnection = () => {
    logIf('sim1202 - closeConnection', null, 'debug1202');
    try {
      this.connection
        .stop()
        .then(() => {
          runInAction(() => {
            this.auctionStore.initializeAuction();
            this.auctionStore.setProperty('connected', false);
            window.localStorage.removeItem('AuctionID');
            window.localStorage.removeItem('lanes');
          });
          logIf('sim1202 - closeConnection.stop()', null, 'debug1202');
        })
        .catch((e) => {
          console.error(e);
          // const closeConnectionLogger = new SimulcastLogger({
          //   component: 'DataStore',
          //   method: 'connection.stop',
          //   params: {
          //     localStorage: window.localStorage,
          //     stores: {
          //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
          //     },
          //   },
          //   message: e,
          //   messageType: 'error',
          //   settings: LOGGER_SETTINGS,
          //   user: this.bidStore.user,
          // });
          // closeConnectionLogger.showToast(true);
        });
    } catch (error) {
      // const closeConnectionLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'closeConnection',
      //   params: {
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // closeConnectionLogger.showToast(true);
    }
  };

  sendMessage = async ({ method = null, payload = [] }) => {
    if (!method) throw new Error('Method required');

    const modPayload = [...payload.map((parm) => parm?.toString())];
    modPayload.unshift(method);
    this.connection.invoke(...modPayload).catch((error) => {
      runInAction(() => {
        this.error = error;
      });
      // const sendMessageLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'sendMessage',
      //   params: {
      //     method,
      //     payload,
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // sendMessageLogger.showToast(true);
    });
  };

  emit = async (object) => {
    // const logger = new SimulcastLogger({
    //   component: 'DataStore',
    //   method: 'emit',
    //   params: object,
    //   message: `emit > ${object.method}`,
    //   messageType: 'log',
    //   settings: LOGGER_SETTINGS_DEBUG,
    //   user: this.bidStore.user,
    // });
    // logger.logToConsole();

    try {
      switch (object.method) {
        case 'AuctionChanged': {
          const [AuctionID] = object.payload;
          /** Temp fix to resolve the issue of loading saved lanes that aren't associated with the current auction */
          if (AuctionID !== window.localStorage.getItem('AuctionID')) {
            window.localStorage.setItem('AuctionID', AuctionID);
            window.localStorage.removeItem('lanes');
          }

          this.auctionStore.setProperty('AuctionID', AuctionID);
          break;
        }
        case 'AuctionInfo': {
          const [auctionId, data] = object.payload;
          this.auctionStore.setAuctionInfo({ ...data, auctionId });
          break;
        }
        case 'AuctionList': {
          const [list] = object.payload;
          this.auctionStore.setProperty('auctionList', list);
          break;
        }
        case 'AuctionMessage': {
          const messageData = object.payload[1];
          this.bidStore.updateNotifications(messageData);
          if (messageData.type === -1) {
            const auctionMessageLogger = new SimulcastLogger({
              component: 'DataStore',
              message: messageData?.message,
              messageType: 'error',
              method: 'emit > AuctionMessage',
              params: {
                localStorage: window.localStorage,
                object,
                stores: {
                  auctionStore: this.auctionStore,
                  authStore: this.authStore,
                  bidStore: this.bidStore,
                  dataStore: this.dataStore,
                  messageStore: this.messageStore,
                  providerStore: this.providerStore,
                },
              },
              settings: LOGGER_SETTINGS,
              user: this.bidStore.user,
            });
            auctionMessageLogger.logToConsole();
          }

          break;
        }
        case 'AuctionSettings': {
          const data = object.payload[1];
          this.auctionStore.setAuctionSettings(data);
          break;
        }
        case 'BidAmountChanged': {
          const [LaneID, data] = object.payload;
          this.bidStore.updateBlock(LaneID, data);
          break;
        }
        case 'BiddingClosed': {
          const LaneID = object.payload;
          this.bidStore.BiddingClosed(LaneID);
          break;
        }
        case 'BiddingOpened': {
          const [LaneID, data] = object.payload;
          this.bidStore.updateBlock(LaneID, data);
          break;
        }
        case 'OnBlockChanged': {
          const [LaneID, blockData] = object.payload;

          if (!blockData) return;
          this.bidStore.updateBlock(LaneID, blockData);
          break;
        }
        case 'OnBlockChangedSummary': {
          const [laneID, productInfo] = object.payload;
          this.auctionStore.setLaneStatus(laneID, productInfo);
          break;
        }

        case 'OnBlockChangedReload': {
          const [laneID, productInfo] = object.payload;

          if (!productInfo.lotNumber) {
            break;
          }

          try {
            await this.bidStore.loadProduct(laneID, productInfo.lotNumber);
          } catch (error) {
            console.error('OnBlockChange', { error, object });

            /** If we have a bad session, refresh the connection and attempt to renew */
            if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              this.sendMessage({
                method: 'RefreshSession',
                payload: [],
              });
            }
          }

          break;
        }

        case 'ConnectionInfo': {
          const {
            account,
            accountName,
            bidderNumber,
            ipAddress,
            member,
            memberName,
            name,
            providerID,
            queryString: qs,
            sessionToken,
            viewOnly,
          } = object.payload[0];

          this.bidStore.setUser(
            account,
            accountName,
            member,
            memberName,
            bidderNumber,
            name,
            ipAddress,
            providerID,
            qs,
            viewOnly,
            sessionToken,
          );

          /** Appends the session token to any outbound REST requests */
          agent.initInterceptor(sessionToken);

          break;
        }
        case 'FinancialCommitments': {
          const data = object.payload[1];
          this.bidStore.setUserCommitments(data);
          break;
        }
        case 'GlobalPinCleared': {
          this.messageStore.clearGlobalPinnedMessage();
          break;
        }
        case 'GlobalPinSet': {
          const message = object.payload;
          this.messageStore.setGlobalPinnedMessage(message);
          break;
        }
        case 'LaneExited': {
          const [LaneID] = object.payload;
          this.bidStore.deleteAuctionLane(LaneID);
          this.messageStore.deleteAuctionLaneMessages(LaneID);
          this.bidStore.setCloseLane(LaneID);
          break;
        }
        case 'LaneJoined': {
          const { laneID, sellerProducts } = object.payload[1];

          this.bidStore.addAuctionLane(laneID);
          this.bidStore.setAuctionProperty(
            laneID,
            'sellerProducts',
            sellerProducts,
          );

          const viewType = this.auctionStore.layoutViewType;
          if (
            viewType === DEFAULT_LAYOUT_VIEW ||
            viewType === FULL_LAYOUT_VIEW
          ) {
            this.bidStore.setAuctionProperty(laneID, 'showRunList', true);
            this.bidStore.setAuctionProperty(laneID, 'showProdPreview', false);
          } else if (viewType === MINI_LAYOUT_VIEW) {
            this.bidStore.setAuctionProperty(laneID, 'showRunList', false);
            this.bidStore.setAuctionProperty(laneID, 'showProdPreview', false);
          }

          this.bidStore.setLaneRequestInProgress(false);

          runInAction(() => {
            this.reconnecting = false;
          });
          break;
        }
        case 'LaneList': {
          const laneList = object.payload[1];
          this.auctionStore.setProperty('laneList', laneList);
          this.bidStore.setLaneNames();

          const lanesFromStorage = window.localStorage.getItem('lanes');
          if (lanesFromStorage) {
            const lanes = JSON.parse(lanesFromStorage) || [];

            /** If we're on mobile and we're not already in a lane, only join one */
            if (window.innerWidth < 570) {
              if (!this.lanereferenceId) {
                this.sendMessage({ method: 'JoinLane', payload: [lanes[0]] });
              }
              return;
            }

            // If we're already in a lane, don't join it again
            lanes.forEach((lane) => {
              if (!this.auctionStore.openLanes.includes(lane)) {
                this.sendMessage({ method: 'JoinLane', payload: [lane] });
              }
            });
          }
          break;
        }
        case 'LaneMediaSettings': {
          const [LaneID, data] = object.payload;
          // TODO: CHECK MEDIA ROOM ID

          this.auctionStore.setJanusServers(data);

          this.bidStore.setAuctionProperty(
            LaneID,
            'mediaRoomID',
            data.mediaRoomID,
          );

          this.bidStore.setAuctionProperty(
            LaneID,
            'audioVideoUrl',
            data.audioVideoUrl,
          );

          this.bidStore.setAuctionProperty(
            LaneID,
            'mediaToken',
            data.mediaToken,
          );

          break;
        }
        case 'LaneMessage': {
          const [LaneID, data] = object.payload;
          this.messageStore.insertLaneActivity(LaneID, data);
          // no sale, sold, or S/T
          if ([5, 6, 7].includes(data.type)) {
            this.bidStore.setAuctionProperty(LaneID, 'decisionStatus', data);
          }
          this.bidStore.updateNotifications(data, LaneID);
          if (data.type === -1) {
            this.messageStore.setLaneErrorMessage(LaneID, {
              message: new Date(),
              type: -1,
            });
            setTimeout(
              () => this.messageStore.clearLaneErrorMessage(LaneID),
              5000,
            );

            const laneMessageLogger = new SimulcastLogger({
              component: 'DataStore',
              message: data?.message,
              messageType: 'error',
              method: 'emit > LaneMessage',
              params: {
                localStorage: window.localStorage,
                object,
                stores: {
                  auctionStore: this.auctionStore,
                  authStore: this.authStore,
                  bidStore: this.bidStore,
                  dataStore: this.dataStore,
                  messageStore: this.messageStore,
                  providerStore: this.providerStore,
                },
              },
              settings: LOGGER_SETTINGS,
              user: this.bidStore.user,
            });
            laneMessageLogger.logToConsole();
          }

          break;
        }
        case 'LanePinCleared': {
          const [LaneID] = object.payload;
          this.messageStore.clearLanePinMessage(LaneID);
          break;
        }
        case 'LanePinSet': {
          const [LaneID, message] = object.payload;
          this.messageStore.setLanePinMessage(LaneID, message);
          break;
        }
        case 'LaneStatusChangedDetail': {
          const [LaneID, status] = object.payload;
          this.auctionStore.setLaneStatus(LaneID, status);
          break;
        }
        case 'MediaStatusChanged': {
          const [LaneID, status] = object.payload;
          this.bidStore.setAuctionProperty(LaneID, 'mediaStatus', status);
          break;
        }
        case 'MessageFromClerks': {
          const [LaneID, data] = object.payload;
          this.messageStore.insertDirectMessageMap(LaneID, data);
          break;
        }
        case 'MessageFromClerksToSeller': {
          const [LaneID, data] = object.payload;
          this.messageStore.insertDirectMessageMap(LaneID, data);
          break;
        }
        case 'ProviderEvents': {
          const [events] = object.payload;
          this.providerStore.setProviderEvents(events);
          break;
        }
        case 'ProviderSettings': {
          const [settings] = object.payload;
          this.providerStore.setProviderSettings(settings);
          this.sendMessage({ method: 'GetAuctionList' });
          break;
        }
        case 'RunList': {
          const [LaneID, data] = object.payload;
          //console.log('RunList', LaneID, data);
          this.bidStore.setRunList(LaneID, data);
          break;
        }
        case 'RunListComp': {
          const [LaneID, data] = object.payload;
          const decompressedData = JSON.parse(
            LZString.decompressFromEncodedURIComponent(data),
          );
          this.bidStore.setRunList(LaneID, decompressedData);
          break;
        }
        case 'SellerAction': {
          const [laneID, sellerActionInfo] = object.payload;
          const {
            sellerAccount,
            sellerAccountName,
            sellerAction,
            sellerActionAccount,
            sellerActionAccountName,
            sellerActionAmount,
            sellerActionMessage,
          } = sellerActionInfo;

          this.bidStore.mergeAuctionProperty(laneID, 'seller', {
            sellerAccount,
            sellerAccountName,
            sellerAction,
            sellerActionAccount,
            sellerActionAccountName,
            sellerActionAmount,
            sellerActionMessage,
          });

          break;
        }
        case 'SystemMessage': {
          const [data] = object.payload;

          const message = data?.message.toLowerCase();
          const { type } = data;

          if (type === -1) {
            if (message.indexOf('(force_reload)') > -1) {
              window.location.reload(true);
            }

            if (
              message.indexOf('(invalid_auction)') > -1 ||
              message.indexOf('(ip_blocked)') > -1 ||
              message.indexOf('(kicked_by_admin)') > -1
            ) {
              this.invalidAuctionError = true;
              this.connection
                .stop()
                .then(() => {
                  clearTimeout(this.networkCheckTimeout);
                  localStorage.clear();
                  this.connection = null;
                  window.location.href = 'https://www.npauctions.com';
                })
                .catch((error) => console.error('SystemMessage', error));
            }
          }

          if (
            message.includes('login error:') &&
            !message.includes('session token is invalid')
          ) {
            localStorage.clear();
            window.reload();
          }

          if (data.type === -1) {
            const systemMessageLogger = new SimulcastLogger({
              component: 'DataStore',
              message: data?.message,
              messageType: 'error',
              method: 'emit > SystemMessage',
              params: {
                localStorage: window.localStorage,
                object,
                stores: {
                  auctionStore: this.auctionStore,
                  authStore: this.authStore,
                  bidStore: this.bidStore,
                  dataStore: this.dataStore,
                  messageStore: this.messageStore,
                  providerStore: this.providerStore,
                },
              },
              settings: LOGGER_SETTINGS,
              user: this.bidStore.user,
            });
            systemMessageLogger.logToConsole();
          }
          break;
        }
        case 'pong': {
          if (!this.auctionStore.networkConnected) {
            this.auctionStore.setProperty('networkConnected', true);
          }
          if (this.networkCheckTimeout || this.invalidAuctionError) {
            clearTimeout(this.networkCheckTimeout);
          }
          break;
        }
        default: {
          console.warn(
            `Unhandled WebSocket message received, method: ${object.method}, payload: ${object.payload}`,
          );
          break;
        }
      }
    } catch (error) {
      // const sendMessageLogger = new SimulcastLogger({
      //   component: 'DataStore',
      //   method: 'sendMessage',
      //   params: {
      //     localStorage: window.localStorage,
      //     stores: {
      //       auctionStore: this.auctionStore, bidStore: this.bidStore, authStore: this.authStore, dataStore: this.dataStore, providerStore: this.providerStore, messageStore: this.messageStore,
      //     },
      //   },
      //   message: error,
      //   messageType: 'error',
      //   settings: LOGGER_SETTINGS,
      //   user: this.bidStore.user,
      // });
      // sendMessageLogger.showToast(true);

      // if (object.method === 'SystemMessage' && object.payload[0]?.indexOf('INVALID_AUCTION') > -1) {
      // window.location.href = 'https://www.npauctions.com';
      // return;

      // }

      //window.location.href = 'https://npauctions.com';
      //Error: Connection disconnected with error 'Error: WebSocket closed with status code: 1011 ().'.
      this.messageStore.handleError({
        auction: this.bidStore.auction,
        caller: '_emit',
        error,
        messages: this.messageStore.lanePinMessage,
        method: object.method,
        payload: object.payload,
      });
    }
  };

  listeners = () => {
    const events = [
      'AuctionChanged',
      'AuctionInfo',
      'AuctionList',
      'AuctionMessage',
      'AuctionSettings',
      'BidAmountChanged',
      'BiddingClosed',
      'BiddingOpened',
      'ConnectionInfo',
      'FinancialCommitments',
      'GlobalPinCleared',
      'GlobalPinSet',
      'LaneBidders',
      'LaneExited',
      'LaneJoined',
      'LaneList',
      'LaneMediaSettings',
      'LaneMessage',
      'LaneMetrics',
      'LanePinCleared',
      'LanePinSet',
      'LaneStatusChangedDetail',
      'MediaStatusChanged',
      'MessageFromClerks',
      'MessageFromClerksToSeller',
      'OnBlockChanged',
      'OnBlockChangedSummary',
      'OnBlockChangedReload',
      'pong',
      'ProviderEvents',
      'ProviderSettings',
      'RunList',
      'RunListComp',
      'SellerAction',
      'SystemMessage',
    ];

    events.forEach((name) => {
      // eslint-disable-next-line no-underscore-dangle
      this.connection.on(name, (...payload) =>
        this.emit({ method: name, payload }),
      );
    });
  };
}

decorate(DataStore, {
  closeConnection: action,
  connect: action,
  initialize: action,
  reconnecting: observable,
  reconnectionErrorMessage: observable,
  sendMessage: action,
});

export default DataStore;
