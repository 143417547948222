import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from '../../../../shared/utils/styles';

export const Container = styled(motion.div)`
  grid-area: pnav;
  /* display: none; */
  /* @media (min-width: ${breakpoints.tablet}) {
    display: absolute;
    width: 280px;
    height: calc(100vh - 129px);
    overflow-x: scroll;
    overflow-y: visible;
    z-index:9999;
  } */
  /* @media (min-width: ${breakpoints.small}) { */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  height: calc(100vh - 129px);
  overflow-x: scroll;
  overflow-y: visible;
  padding: 30px 5px;
  border: var(--line);
  background-color: var(--shadedBgColor);
  height: 100%;
  /* } */
`;

export const SectionContainer = styled.div`
  padding: 30px 10px 10px 10px;
  /* width: 100%; */
  width: 260px;
`;

export const Section = styled.div`
  border: var(--line);
  margin-bottom: 5px;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--headerBgColor);
  padding: 3px;
  height: 1.8em;
  border-bottom: var(--line);
`;

export const SectionHeaderLabel = styled.label`
  color: var(--headerTextColor);
  line-height: 1.8em;
  font-size: 0.8em;
  font-weight: bold;
  & i {
    padding-right: 5px;
  }
`;

export const SectionContent = styled(motion.div)`
  height: ${(props) => props.height || '100px'};
  overflow-y: scroll;
  padding: 5px 0 5px 5px;
  background-color: var(--white);
  width: 100%;
  box-sizing: border-box;
`;

export const IconButton = styled.button`
  outline: none;
  color: ${(props) => props.color || 'var(--lightTextColor)'};
  &:hover {
    cursor: pointer;
  }
`;

export const IconButtonGroup = styled.div`
  padding: 0px;
  & button {
    padding: 0px 5px;
  }
`;

export const Track = styled.div`
  display: flex;
  font-size: 0.7em;
  &:nth-child(odd) > div {
    ${({bgColor1}) => bgColor1 && `background-color: ${bgColor1}`};
  }
  &:nth-child(even) > div {
    ${({bgColor2}) => bgColor2 && `background-color: ${bgColor2}`};
  }
  & div {
    ${({color}) => color && `color: ${color}`};
  }
`;

export const TrackKey = styled.div`
  width: 30%;
  padding: 10px 5px 10px 5px;
  text-align: left;
`;

export const TrackValue = styled.div`
  text-align: right;
  padding: 10px 5px 10px 10px;
  width: 70%;
  ${(props) => (props.color ? `color: ${props.color}` : '')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  & a {
    cursor: auto;
    text-decoration: none;
    ${(props) => (props.color ? `color: ${props.color}` : '')};
  }
`;

export const StickyContainerPositioner = styled.div`
  position: absolute;
  top: 50px;
  left: 65px;
`;
