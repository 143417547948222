import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import {
  useStores,
  usePromo,
  useProxyBid,
  usePreviewProductItem,
  useSeller,
  useWatchListItem,
} from 'hooks';
import SimulcastLogger, { LOGGER_SETTINGS } from 'shared/utils/SimulcastLogger';
import { LaneContext } from 'components/project/LaneWrapper';
import agent from '../../../../agent';
import { getApiHost } from '../../../../endpoints';
import { ItemAttributes, MediaGallery, Promo } from '..';
import { ProxyBid } from './ProxyBid';
import { ProxyBidConfirmation } from './ProxyBidConfirmation';
import Spinner from '../../../shared/Spinner';
import {
  AttributesContainer,
  ButtonContainer,
  ButtonRow,
  Center,
  DarkBgIcon,
  GalleryContainer,
  Loading,
  ProductWrapper,
  StyledButton,
  TitleLeft,
  TitleRight,
  TitleWrapper,
  PreviewTextContainer,
  PreviewText,
} from './Styles';
import { Icon } from '../../shared';
import { formatCommas, abbrevStringValue } from '../../shared/utils/common';

const ProductPreview = ({
  mediaHoverEffect,
  mediaWrapWidth,
  mediaWrapHeight,
  handleClosePreview,
}) => {
  const {
    AuctionStore: { auctionInfo, isFullViewLayout },
    BidStore: {
      auction,
      watchList,
      proxies,
      loadWatchlist,
      loadProxies,
      setAuctionProperty,
      user,
    },
    UIStore: { handleTriggerFinancialCommitments },
  } = useStores();
  const { Product, Proxies, WatchList } = agent;
  const { allowProxyBidding, auctionId } = auctionInfo;
  const { laneID } = useContext(LaneContext);
  const { runList, previewImageIndex } = auction[laneID];
  const host = getApiHost();

  const setPreviewImageIndex = (index) =>
    setAuctionProperty(laneID, 'previewImageIndex', index);

  const {
    previewLotNumber,
    previewProductID,
    sellerProducts,
    lotNumber: onBlockLotNumber,
    productState: onBlockProductState,
  } = auction[laneID];

  const runlistItem = runList.filter(
    (item) => item.productID === previewProductID,
  );

  const clearState = () => {
    setShowProxyBidAmount(false);
    setIsWatching(false);
  };

  const {
    productItem,
    isUpcomingItem,
    isOnBlock,
    isLoading,
    error: productItemError,
  } = usePreviewProductItem({
    agent: { obj: Product, host },
    laneID,
    previewLotNumber,
    onBlockLotNumber,
    runlistItem,
    onBlockProductState,
    clearState,
  });

  const { isSeller, reserve, formattedReserveAndType } = useSeller({
    sellerProducts,
    lotNumber: previewLotNumber,
  });

  const {
    isWatching,
    setIsWatching,
    showWatchListButton,
    toggleWatchList,
    error: watchListError,
  } = useWatchListItem({
    watchList,
    agent: { obj: WatchList, host },
    loadWatchlist,
    laneID,
    productID: previewProductID,
    lotNumber: previewLotNumber,
    userIsSeller: isSeller,
    isOnBlock,
    isUpcomingItem,
    setAuctionProperty,
  });

  const {
    isWaitingForProxy,
    proxyBid,
    setProxyBid,
    tempProxyBid,
    setTempProxyBid,
    cancelConfirmation,
    submitProxyBid,
    showProxyBidButton,
    setShowProxyBidAmount,
    showProxyBidAmount,
    toggleProxyBidInput,
    setShowProxyBidInput,
    showProxyBidInput,
    hideProxyBidInput,
    bidderMessage,
    error: proxyBidError,
  } = useProxyBid({
    proxies,
    agent: { obj: Proxies, host },
    loadProxies,
    lotNumber: previewLotNumber,
    productID: previewProductID,
    laneID,
    isSeller,
    allowProxyBidding:
      allowProxyBidding > 0 && runlistItem[0].canBid && !user.viewOnly,
    isUpcomingItem,
    isOnBlock,
    token: user.sessionToken,
    setAuctionProperty,
    triggerFinancialCommitments: () =>
      handleTriggerFinancialCommitments(auctionId),
  });

  const { showPromo, promo, handlePromoClick } = usePromo(
    productItem?.attributes,
  );

  if (!productItem) {
    return null;
  }

  const allErrors = {
    watchList: watchListError,
    proxyBid: proxyBidError,
    productItem: productItemError,
  };

  const hasErrors =
    allErrors.watchList.error.length > 0 ||
    allErrors.proxyBid.error.length > 0 ||
    allErrors.productItem.error.length > 0;

  function formatErrors(err) {
    const messages = [];
    for (const key in err) {
      if (err.hasOwnProperty(key)) {
        const currentError = err[key];
        if (currentError.error.length > 0) {
          currentError.error.map((msg) =>
            messages.push(`${currentError.category}: ${msg}`),
          );
        }
      }
    }
    return messages;
  }

  // TODO: update store to show a general error message in error container?
  if (hasErrors) {
    const errorMessages = formatErrors(allErrors);
    console.error('ProductPreview', errorMessages, allErrors);

    const productPreviewLogger = new SimulcastLogger({
      component: 'ProductPreview',
      method: 'render',
      params: {
        allErrors,
        localStorage: window.localStorage,
        storeParms: {
          auctionInfo,
          auction,
          watchList,
          proxies,
          user,
        },
        hooks: {
          useProxyBid: {
            proxyBid,
            setProxyBid,
            tempProxyBid,
            setTempProxyBid,
            cancelConfirmation,
            submitProxyBid,
            showProxyBidButton,
            setShowProxyBidAmount,
            showProxyBidAmount,
            toggleProxyBidInput,
            setShowProxyBidInput,
            showProxyBidInput,
            hideProxyBidInput,
            bidderMessage,
            error: proxyBidError,
          },
          useWatchListItem: {
            isWatching,
            setIsWatching,
            showWatchListButton,
            toggleWatchList,
            error: watchListError,
          },
          useSeller: { isSeller, reserve, formattedReserveAndType },
          usePreviewProductItem: {
            productItem,
            isUpcomingItem,
            isOnBlock,
            isLoading,
            error: productItemError,
          },
          usePromo: { showPromo, promo, handlePromoClick },
        },
      },
      message: errorMessages,
      messageType: 'error',
      settings: LOGGER_SETTINGS,
      user,
    });
    productPreviewLogger.logToConsole();
  }

  const formattedProductName = abbrevStringValue(
    productItem.productName,
    59,
    false,
  );

  if (isOnBlock) handleClosePreview();

  if (isLoading) {
    return (
      <Center>
        <Loading>
          <Spinner size={70} color="var(--gray-6)" />
        </Loading>
      </Center>
    );
  }

  const showButtonRow =
    (!showProxyBidInput && isUpcomingItem && !isSeller) || isSeller;
  return (
    <Center className="prod-prev-wrapper" isFullViewLayout={isFullViewLayout}>
      <TitleWrapper isFullViewLayout={isFullViewLayout}>
        <TitleLeft>
          {formattedProductName === productItem.productName ? (
            formattedProductName
          ) : (
            <span title={productItem.productName}>{formattedProductName}</span>
          )}
        </TitleLeft>
        <TitleRight>
          <DarkBgIcon
            color="var(--subHeaderTextColor)"
            onClick={handleClosePreview}
            size={0.6}
            title="Close preview window"
          >
            <Icon icon="times" iconOnly />
          </DarkBgIcon>
        </TitleRight>
      </TitleWrapper>
      <ProductWrapper
        showPromo
        showButtonRow={showButtonRow}
        isFullViewLayout={isFullViewLayout}
      >
        <PreviewTextContainer>
          <PreviewText>PREVIEW #{productItem.lotNumber}</PreviewText>
        </PreviewTextContainer>
        <GalleryContainer>
          {isWaitingForProxy && (
            <strong style={{ maxWidth: '125px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div>Please Wait...</div>
              <Spinner size={50} color="var(--gray-6)" />
            </strong>
          )}
          {!isWaitingForProxy && showProxyBidInput && bidderMessage.messageType === 'warning' && (
            <ProxyBidConfirmation
              onSubmit={() => submitProxyBid(tempProxyBid, true)}
              onCancel={cancelConfirmation}
              proxyBid={tempProxyBid}
              message={bidderMessage.message}
              messageType={bidderMessage.messageType}
              heading="Confirm Proxy Bid"
            />
          )}
          {!isWaitingForProxy && showProxyBidInput &&
            bidderMessage.messageType === 'error' &&
            bidderMessage?.lotNumber === productItem.lotNumber && (
              <ProxyBidConfirmation
                onCancel={cancelConfirmation}
                proxyBid={tempProxyBid}
                message={bidderMessage.message}
                messageType={bidderMessage.messageType}
                heading="Error"
              />
            )}
          {!isWaitingForProxy && showProxyBidInput &&
            bidderMessage.messageType === 'success' &&
            bidderMessage?.lotNumber === productItem.lotNumber && (
              <ProxyBidConfirmation
                message={bidderMessage.message}
                messageType={bidderMessage.messageType}
                onSubmit={() => setShowProxyBidInput(false)}
                heading="Confirm Proxy Bid"
              />
            )}
          {/* {showProxyBidInput && !bidderMessage.messageType && bidderMessage?.lotNumber !== productItem.lotNumber && ( */}
          {!isWaitingForProxy && showProxyBidInput &&
            bidderMessage?.lotNumber !== productItem.lotNumber && (
              <ProxyBid
                key={`proxyinput_${previewLotNumber}`}
                onSubmit={() => submitProxyBid(tempProxyBid)}
                onCancel={hideProxyBidInput}
                tempProxyBid={tempProxyBid}
                setTempProxyBid={setTempProxyBid}
                message={bidderMessage}
              />
            )}
          {!showProxyBidInput && (
            <MediaGallery
              key={previewLotNumber}
              data={productItem}
              viewType="prodpreview"
              view="prodpreview"
              winHeight={0}
              winWidth={0}
              hoverEffect={mediaHoverEffect}
              wrapWidth={mediaWrapWidth}
              wrapHeight={mediaWrapHeight}
              showNextPrevButtons
              source="prodpreview"
              imageIndex={previewImageIndex}
              setImageIndex={setPreviewImageIndex}
              ignoreFullViewLayout
            />
          )}
        </GalleryContainer>
        <Promo
          preview
          promo={promo}
          handlePromoClick={handlePromoClick}
          productPreview
        />
        {!showProxyBidInput && isUpcomingItem ? (
          <ButtonContainer>
            <ButtonRow isSeller={isSeller}>
              {showWatchListButton && (
                <StyledButton
                  onClick={toggleWatchList}
                  color="var(--watchingTextColor)"
                  bgColor="var(--watchingBgColor)"
                  bgVariance={20}
                  width={showProxyBidAmount ? '75%' : '100%'}
                >
                  <Icon icon="eye" />
                  {isWatching ? 'Watching' : 'Watch'}
                </StyledButton>
              )}
              {(showProxyBidButton || showProxyBidAmount) && (
                <StyledButton
                  onClick={() => setShowProxyBidInput(true)}
                  color="var(--myProxyTextColor)"
                  bgColor="var(--myProxyBgColor)"
                  bgVariance={90}
                >
                  <Icon icon="gavel" />
                  {showProxyBidAmount
                    ? `MY PROXY $${formatCommas(proxyBid) || 0}`
                    : 'PROXY BID'}
                </StyledButton>
              )}
              {isSeller && (
                <>
                  <div>My Item</div>
                  <div>{`Reserve: ${formattedReserveAndType}`}</div>
                </>
              )}
            </ButtonRow>
          </ButtonContainer>
        ) : (
          !showProxyBidInput && (
            <ButtonContainer>
              <ButtonRow isSeller={isSeller}>
                {isSeller && (
                  <>
                    <div>My Item</div>
                    <div>{`Reserve: ${formattedReserveAndType}`}</div>
                  </>
                )}
              </ButtonRow>
            </ButtonContainer>
          )
        )}
        <AttributesContainer
          className={`prev-attrs${showButtonRow ? ' with-btns' : ''}`}
        >
          <ItemAttributes
            data={productItem}
            showProductName={false}
            isFullViewLayout={false}
          />
        </AttributesContainer>
      </ProductWrapper>
    </Center>
  );
};

ProductPreview.propTypes = {
  mediaHoverEffect: PropTypes.bool,
  mediaWrapWidth: PropTypes.string,
  mediaWrapHeight: PropTypes.string,
  handleClosePreview: PropTypes.func,
};

ProductPreview.defaultProps = {
  mediaHoverEffect: true,
  mediaWrapWidth: '200px',
  mediaWrapHeight: '180px',
  handleClosePreview: () => null,
};

export default observer(ProductPreview);
