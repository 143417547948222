import React from 'react';
import { observer } from 'mobx-react-lite';
import useStores from 'hooks/use-store';
import { ContentWrapper, Text } from './Styles';

// OBSOLETE
const LaneLocation = () => {
  const { AuctionStore } = useStores();

  const { auctionName, laneName } = AuctionStore;
  return (
    <ContentWrapper>
      <Text>{`Location: ${auctionName}`}</Text>
      <Text>{`Lane: ${laneName}`}</Text>
    </ContentWrapper>
  );
};

export default observer(LaneLocation);
