import React from 'react';
import styled from 'styled-components';

export const Version = ({
  color = 'var(--white)',
  size = '0.7em',
  align = 'center',
}) => {
  const cacheVersion = window.localStorage.getItem('cacheVersion');
  const version = cacheVersion !== null ? `v${cacheVersion}` : '';
  return <Wrapper size={size} color={color} align={align} className="version">{version}</Wrapper>;
};

export default Version;

const Wrapper = styled.div`
  font-size: ${({ size }) => size && size};
  width: 100%;
  color: ${({ color }) => color && color};
  text-align: ${({ align }) => align && align};
`;
