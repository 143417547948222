import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: loc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 245px;
    background-color: #eee;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    padding-left: 5px;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Text = styled.h3`
  margin: 10px 0;
  font-size: 0.9em;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 1;
    text-align: left;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;
