import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  margin: 0 20px 15px 20px;
  padding: 5px;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: attrs;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-bottom: none;
    border: 1px solid gray;
    margin: 0;
    padding: 0px;
    overflow-y: auto;
    min-width: 98%;
  }
  @media (min-width: ${breakpoints.small}) {
    border-right: none;
    border-bottom: 1px solid gray;
    border: 1px solid gray;
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Header = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  height: 1.5em;
  background-color: #FBCC08;
  color: #000;
  text-transform: uppercase;
  text-align: center;
`;

export const Content = styled.div`
  overflow-x: hidden;
  font-size: 1em;
  background-color: #ccc;
  font-family: 'courier';
  height: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.8em;
  }
`;
