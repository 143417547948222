import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import PropTypes from 'prop-types';
import {
  Container, CloseIcon, Heading, ScrollView,
} from './styles';
import {
  Track, TrackKey, TrackValue,
} from '../../../../NavbarLeft/PinnedNav/Styles';
import { Icon } from '../../../../shared';

const BidActivity = ({ close }) => {
  const {
    BidStore: { notifications },
  } = useStores();
  // FIXME: STYLE THE <A/> AS A <BUTTON/> TO REMOVE THE WARNING
  return (
    <Container>
      <Heading>Bid Activity</Heading>

      <CloseIcon onClick={() => close(null, null)}><Icon icon="times" iconOnly /></CloseIcon>

      <ScrollView>
        {notifications.length === 0 && <p>No notifications</p>}
        {notifications
          .reverse()
          .map((value, index) => (
            <Track key={index} color="var(--bidActivityTextColor)" bgColor1="var(--bidActivityBgColor1)" bgColor2="var(--bidActivityBgColor2)">
              <TrackKey>{value.timestamp}</TrackKey>
              <TrackValue>
                {value.notification}
              </TrackValue>
            </Track>
          ))}
      </ScrollView>

    </Container>
  );
};

BidActivity.propTypes = {
  close: PropTypes.func.isRequired,
};

export default observer(BidActivity);
