import {
  action,
  configure,
  decorate,
  extendObservable,
  observable,
  runInAction,
} from 'mobx';
import { formatDateTime } from 'shared/utils/dateTime';
import agent from '../agent';
import { getApiHost } from '../endpoints';

configure({ enforceActions: 'always' });

class BidStore {
  constructor(AuctionStore, ProviderStore, MessageStore) {
    this.auctionStore = AuctionStore;
    this.providerStore = ProviderStore;
    this.messageStore = MessageStore;
    this.initialize();
  }

  initialize = () => {
    this.activeLaneID = null;
    this.laneRequestInProgress = false;
    this.audioLane = null;
    this.apiError = null;
    this.auction = observable.object({});
    this.baseAuctionLane = {
      auctionLaneID: null,
      auctionName: '',
      audioPlaying: false,
      audioPaused: null,
      bidStatus: 'Closed',
      canBid: false,
      connected: false,
      currentBid: null,
      decisionStatus: null,
      isOpenForBidding: false,
      key: new Date(),
      laneName: '',
      laneStatus: null,
      lastRunListItemUpdate: null,
      lotNumber: null,
      lotNumberSequence: 0,
      mediaRoomID: null,
      mediaStatus: null,
      myProxyBidAmount: 0,
      nextBid: null,
      previewImageIndex: 0,
      previewLotNumber: null,
      productId: null,
      productItem: {},
      productState: 0,
      runList: observable.array(null, { deep: true }),
      runListFilter: { filterType: 'upcoming', filterValue: '' },
      seller: {
        sellerAccount: null,
        sellerAccountName: null,
        sellerAction: null,
        sellerActionAccount: null,
        sellerActionAccountName: null,
        sellerActionAmount: null,
        sellerActionMessage: null,
        tempSellerAction: null,
        tempTakeAmount: null,
      },
      sellerProducts: [],
      showProdPreview: false,
      showRunList: true,
      standardBidIncrement: 100,
      startingBidAmount: null,
      syncID: null,
      winningBidAmount: '',
      winningBidderName: '',
    };
    this.messages = {
      biddingInProgress: 'In progress',
      waitingToBid: 'Waiting...',
    };
    this.notifications = [];
    this.productItem = {};
    this.proxies = [];
    this.user = {
      account: '',
      accountName: '',
      bidderNumber: '',
      commitments: {},
      ipAddress: null,
      lastUpdated: null,
      member: '',
      memberName: '',
      name: '',
      providerID: null,
      queryString: null,
      sessionToken: null,
      viewOnly: true,
    };
    this.watchList = [];
  };

  auctionLaneExists = (laneID) => this.auction.hasOwnProperty(laneID);

  addAuctionLane = (laneID) => {
    try {
      if (this.auctionLaneExists(laneID)) return;

      extendObservable(this.auction, {
        [laneID]: this.baseAuctionLane,
      });

      this.setAuctionProperty(
        laneID,
        'auctionName',
        this.auctionStore.auctionInfo.auction,
      );

      this.setAuctionProperty(
        laneID,
        'auctionInfo',
        this.auctionStore.auctionInfo,
      );

      this.auctionStore.setOpenLanes(laneID);
      this.setLaneName(laneID);
    } catch (error) {
      console.error('addAuctionLane', { error, laneID });
    }
  };

  deleteAuctionLane = (laneID) => {
    try {
      this.auctionStore.deleteOpenLane(laneID);
      delete this.auction[laneID];
    } catch (error) {
      console.error('deleteAuctionLane', { error, laneID });
    }
  };

  setAuctionProperty = (laneID, key, value) => {
    try {
      this.addAuctionLane(laneID);
      this.auction[laneID][key] = value;
    } catch (error) {
      console.error('setAuctionProperty', {
        error,
        laneID,
        key,
        value,
        auction: this.auction,
        auctionLane: this.auction[laneID],
      });
    }
  };

  mergeAuctionProperty = (laneID, key, value) => {
    try {
      const oldValue = this.auction[laneID][key];
      this.setAuctionProperty(laneID, key, Object.assign(oldValue, value));
    } catch (error) {
      console.error('mergeAuctionProperty', {
        error,
        laneID,
        key,
        value,
        auction: this.auction,
        auctionLane: this.auction[laneID],
      });
    }
  };

  setLaneNames = () => {
    this.auctionStore.laneList.forEach((item) => {
      if (this.auction[item.laneID]) {
        this.auction[item.laneID].laneName = item.lane;
      }
    });
  };

  setLaneName = (laneID) => {
    try {
      const laneListItem = this.auctionStore.laneList.filter(
        (item) => item.laneID === laneID,
      );

      const lanesFromStorage = window.localStorage.getItem('lanes');
      if (lanesFromStorage) {
        const lanes = JSON.parse(lanesFromStorage);
        if (!lanes.includes(laneID)) {
          const newLanes = JSON.stringify([...lanes, laneID]);
          window.localStorage.setItem('lanes', newLanes);
        }
      } else {
        window.localStorage.setItem('lanes', JSON.stringify([laneID]));
      }

      const [laneItem] = laneListItem;
      this.setAuctionProperty(laneID, 'laneName', laneItem.lane);
    } catch (error) {
      console.error('setLaneName', { error, laneID });
    }
  };

  setCloseLane = (laneID) => {
    try {
      const lanesFromStorage = window.localStorage.getItem('lanes');
      if (lanesFromStorage) {
        const storageLanes = JSON.parse(lanesFromStorage) || [];
        const lanes = storageLanes.filter((id) => id !== laneID);
        window.localStorage.setItem('lanes', JSON.stringify([...lanes]));
      }
    } catch (error) {
      console.error('setCloseLane', { error, laneID });
    }
  };

  setOpenLane = (laneID) => {
    try {
      const lanesFromStorage = window.localStorage.getItem('lanes');

      if (lanesFromStorage) {
        const lanes = JSON.parse(lanesFromStorage) || [];
        if (!lanes.includes(laneID)) {
          const newLanes = JSON.stringify([...lanes, laneID]);
          window.localStorage.setItem('lanes', newLanes);
        }
      } else {
        window.localStorage.setItem('lanes', JSON.stringify([laneID]));
      }
    } catch (error) {
      console.error('setOpenLane', { error, laneID });
    }
  };

  setUserCommitments = (data) => {
    this.user.commitments = data;
    this.user.lastUpdated = new Date().toISOString();
  };

  setUser = (
    account = '',
    accountName = '',
    member = '',
    memberName = '',
    bidderNumber = '',
    name = '',
    ipAddress = null,
    providerID = null,
    queryString = null,
    viewOnly = true,
    sessionToken = null,
  ) => {
    this.user.account = account;
    this.user.accountName = accountName;
    this.user.member = member;
    this.user.memberName = memberName;
    this.user.bidderNumber = bidderNumber;
    this.user.name = name;
    this.user.viewOnly = viewOnly;
    this.user.ipAddress = ipAddress;
    this.user.providerID = providerID;
    this.user.queryString = queryString;
    this.user.sessionToken = sessionToken;
    this.user.lastUpdated = new Date().toISOString();
  };

  BiddingClosed = (laneID) => {
    try {
      this.setAuctionProperty(laneID, 'bidStatus', 'Bidding Closed');
      this.setAuctionProperty(laneID, 'isOpenForBidding', false);
    } catch (error) {
      console.error('BiddingClosed', { error, laneID });
    }
  };

  loadProduct = (laneID, lotNumber) =>
    new Promise(async (resolve, reject) => {
      const { Product } = agent;

      if (!this.auctionStore.getOpenLanes().includes(laneID)) {
        return;
      }

      try {
        const host = getApiHost();
        const res = await Product.getProduct(lotNumber, laneID, host);

        this.setAuctionProperty(laneID, 'productItem', res.data);

        runInAction(() => {
          this.auction[laneID].key = new Date();
          this.error = null;
          this.apiError = null;
        });

        resolve(true);
      } catch (error) {
        this.setAuctionProperty(laneID, 'productItem', { error: true });

        this.setAuctionProperty(laneID, 'lotNumber', null);
        runInAction(() => {
          this.apiError = `${error.message}`;
        });
        console.error('loadProduct', { error, laneID, lotNumber });

        reject(error);
      }
    });

  loadWatchlist = async (laneID) => {
    if (!laneID) {
      return;
    }

    try {
      const res = await agent.WatchList.readAll(getApiHost());
      runInAction(async () => {
        this.watchList = res.data.watchListItems;
        this.auction[laneID].runList.forEach((unit, index) => {
          this.auction[laneID].runList[index].isWatched =
            this.watchList?.filter((item) => item.productID === unit.productID)
              .length > 0 || false;
        });

        this.auction[laneID].key = new Date();
        this.setAuctionProperty(laneID, 'lastRunListItemUpdate', Math.random());
      });
    } catch (e) {
      console.error(e);
    }
  };

  loadProxies = async (laneID) => {
    if (!laneID) {
      return;
    }

    const {
      Proxies: { readAll },
    } = agent;

    try {
      const res = await readAll(getApiHost());

      if (!res.data.proxyBids) {
        return;
      }

      runInAction(async () => {
        this.proxies = res.data.proxyBids;

        this.auction[laneID].runList.forEach((unit, index) => {
          const proxy = this.proxies.filter(
            (item) => item.productID === unit.productID,
          );

          if (proxy[0]) {
            this.auction[laneID].runList[index].myProxyBidAmount =
              proxy[0].proxyBid;
            this.auction[laneID].key = new Date();
            this.setAuctionProperty(
              laneID,
              'lastRunListItemUpdate',
              Math.random(),
            );
          }
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  clearNotifications = () => {
    this.notifications = [];
  };

  updateNotifications = (message, LaneID = null) => {
    if (!message.message) {
      return;
    }
    try {
      const item = {
        timestamp: formatDateTime(new Date(), 'h:mm:ss A'),
        messageType: message.type,
        notification: message.message,
        LaneID,
        user: null,
      };

      this.notifications = [item, ...this.notifications];
    } catch (error) {
      console.error('updateNotifications', { error, message });
    }
  };

  updateBlock = (laneID, onBlock) => {
    try {
      if (laneID && onBlock) {
        if (onBlock.financialCommitments) {
          this.setUserCommitments(onBlock.financialCommitments);
        }

        if (this.auction[laneID].lotNumber !== onBlock.lotNumber) {
          this.auction[laneID].seller = {
            tempSellerAction: null,
            tempTakeAmount: null,
            sellerAction: null,
            sellerActionMessage: null,
            sellerActionAmount: null,
            sellerAccount: null,
            sellerAccountName: null,
            sellerActionAccount: null,
            sellerActionAccountName: null,
          };
        }
        this.auction[laneID].decisionStatus = null;
        this.auction[laneID].auctionLaneID = laneID;
        this.auction[laneID].lotNumber = onBlock.lotNumber;

        if (onBlock.sequence !== undefined) {
          this.auction[laneID].lotNumberSequence = onBlock.sequence;
        }
        if (onBlock.winningBidAmount < onBlock.startingBidAmount) {
          this.auction[laneID].currentBid = null;
          this.auction[laneID].winningBidderBadge = '';
          this.auction[laneID].winningBidderName = '';
        } else {
          this.auction[laneID].currentBid = onBlock.winningBidAmount;
          this.auction[laneID].winningBidderBadge = onBlock.winningBidderBadge;
          this.auction[laneID].winningBidderName = onBlock.winningBidderName;
        }
        this.auction[laneID].startingBidAmount = onBlock.startingBidAmount;
        this.auction[laneID].nextBid = onBlock.nextBidAmount;
        this.auction[laneID].isOpenForBidding = onBlock.isOpenForBidding;
        this.auction[laneID].standardBidIncrement = onBlock.bidIncrement;
        this.auction[laneID].productId = onBlock.productId;
        this.auction[laneID].productState = onBlock.productState;

        if (onBlock.canBid !== undefined) {
          this.auction[laneID].canBid = onBlock.canBid;
        }

        if (onBlock.isSeller !== undefined) {
          this.auction[laneID].isSeller = onBlock.isSeller;
        }

        const { runList } = this.auction[laneID];
        if (runList.length > 0) {
          const updatedRunList = runList.map((item) => {
            const runListItem = item;
            if (
              item.lotNumber === onBlock.lotNumber &&
              item.productState !== onBlock.productState
            ) {
              runListItem.productState = onBlock.productState;
            }
            return runListItem;
          });

          this.auction[laneID].runList = updatedRunList;
        }

        const { biddingInProgress, waitingToBid } = this.messages;
        this.auction[laneID].syncID = onBlock.syncID;
        this.auction[laneID].bidStatus = onBlock.isOpenForBidding
          ? biddingInProgress
          : waitingToBid;
      }
    } catch (error) {
      console.error('updateBlock', { error, laneID, onBlock });
    }
  };

  setCurrentLaneAudio = (activeLaneID, value, userInitiated) => {
    this.activeLaneID = activeLaneID;
    this.auctionStore.setActiveBidActivityLaneId(activeLaneID);

    if (userInitiated) {
      this.setAuctionProperty(
        activeLaneID,
        'audioPaused',
        this.auction[activeLaneID].audioPlaying,
      );
    }

    this.setAuctionProperty(activeLaneID, 'audioPlaying', value);

    this.audioLane = value ? activeLaneID : null;

    for (const [key] of Object.entries(this.auction)) {
      if (key !== activeLaneID) {
        this.setAuctionProperty(key, 'audioPlaying', false);
      }
    }
  };

  getLaneIDPlayingAudio = () => {
    for (const [key] of Object.entries(this.auction)) {
      const auctionLane = this.auction[key];
      if (auctionLane.audioPlaying) return key;
    }
    return null;
  };

  setLaneRequestInProgress = (value) => {
    this.laneRequestInProgress = value;
  };

  setRunList = async (laneId, data) => {
    this.setAuctionProperty(laneId, 'runList', data);
    await this.loadWatchlist(laneId);
    await this.loadProxies(laneId);
  };
}

decorate(BidStore, {
  activeLaneID: observable,
  addAuctionLane: action,
  apiError: observable,
  auction: observable,
  audioLane: observable,
  BiddingClosed: action,
  clearNotifications: action,
  deleteAuctionLane: action,
  getLaneIDPlayingAudio: action,
  getUserCommitmentPropByKey: action,
  setRunList: action,
  initialize: action,
  laneRequestInProgress: observable,
  loadProduct: action,
  loadWatchlist: action,
  mergeAuctionProperty: action,
  message: observable,
  notifications: observable,
  productItem: observable,
  proxies: observable,
  setAuctionProperty: action,
  setCloseLane: action,
  setCurrentLaneAudio: action,
  setLaneName: action,
  setLaneNames: action,
  setLaneRequestInProgress: action,
  setOpenLane: action,
  setUser: action,
  setUserCommitments: action,
  updateBlock: action,
  updateNotifications: action,
  user: observable,
  watchList: observable,
});

export default BidStore;
