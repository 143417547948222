import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
body {
  background: var(--white);
  font-size: var(--baseFontSize);
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  font-weight: normal;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
img {
  border: 1px solid #ddd;
  padding: 1px;
  border-radius: var(--radiusBox);
  height: auto;
  max-width: 100%;
}
a {
  text-decoration: none;
  color: var(--black);
}
button {
  background: none;
  border: none;
  color: var(--white);
  padding: 7px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: var(--radiusBox);
}
h1 {
  font-size: var(--h1);
}
h2 {
  font-size: var(--h2);
}
h3 {
  font-size: var(--h3);
}
h4 {
  font-size: var(--h4);
}
h5 {
  font-size: var(--h5);
}
i {
  font-size: 1.5em;
}`;
