import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { Button } from '../../../shared/utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  & input {
    width: 80%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    height: 200px;
    width: 50%;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const StyledButton = styled(Button)`
  width: ${(props) => props.width || '100%'};
  height: 30px;
  margin: 1px;
  padding: 6px 4px;
  font-size: 0.7em;
  margin: 3px 10px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.8em;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;
