import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/project/shared';
import { StyledButton } from './Styles';

export const LayoutViewButton = ({
  onClick, text, icon, isActive,
}) => (
  <StyledButton
    onClick={onClick}
    isActive={isActive}
    color={isActive ? 'var(--lightTextColor)' : 'var(--darkTextColor)'}
    bgColor={isActive ? 'var(--utilityBtnBgColor)' : 'var(--cancelBtnBgColor)'}
    bgVariance={20}
    width="95px"
  >
    <Icon icon={icon} />
    {text}
  </StyledButton>
);

LayoutViewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default LayoutViewButton;
