import axios from 'axios';

import {
  API_PRODUCT_ENDPOINT,
  API_PROXY_ENDPOINT,
  API_WATCHLIST_ENDPOINT,
} from './shared/constants/index';

const httpClient = axios.create({
  timeout: 25000,
});

const initInterceptor = (sessionToken) => {
  /** REQUEST - appends the sessionToken to all outbound http requests */
  httpClient.interceptors.request.use((request) => {
    const { data } = request;

    if (data && !data.sessionToken && sessionToken) {
      request.data.sessionToken = sessionToken;
    }

    return request;
  });

  /** RESPONSE - handles any errors returned from the API */
  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      console.error('REST API ERROR>', { error, originalRequest });

      /** If there's no usable response in the error, throw it back and handle it further down the chain */
      if (!error.response) {
        return axios(originalRequest);
      }

      /** Any unauthorized requests, run the logout process? */
      if (error.response.status === 401) {
        // TODO We need to figure out how to handle unauthorized requests. Either log the user out or retry the request.
      }

      /** If we got a non 401 server error, lets send it to Bugsnag with the original request */
      if (error.response.status !== 401) {
        // TODO We should send the error response to Central Logging/BugSnag
      }

      return Promise.reject(error);
    },
  );
};

const Product = {
  // TODO: BOMBS WHEN AN AUCTION IS NOT RUNNING YET
  getProduct: (LotNumber, LaneID, host) => httpClient.post(`${host}${API_PRODUCT_ENDPOINT}`, {
    LotNumber,
    LaneID,
  }),

};

const Proxies = {
  readByProduct: (productId, host) => httpClient.post(`${host}${API_PROXY_ENDPOINT}/ReadByProduct`, {
    productId,
  }),

  readAll: (host) => httpClient.post(`${host}${API_PROXY_ENDPOINT}/ReadAll`, {}),

  create: (productId, ProxyBid, host) => httpClient.post(`${host}${API_PROXY_ENDPOINT}/Create`, {
    productId,
    ProxyBid,
  }),

  outOfBoundsCheck: (productId, ProxyBid, token, host) => httpClient.post(`${host}${API_PROXY_ENDPOINT}/IsProxyOutsideBoundary`, {
    productId, ProxyBid, sessionToken: token,
  }),
};

const WatchList = {
  readAll: (host) => httpClient.post(`${host}${API_WATCHLIST_ENDPOINT}/ReadAll`, {}),

  create: (productId, host) => httpClient.post(`${host}${API_WATCHLIST_ENDPOINT}/Create`, {
    productId,
  }),

  delete: (ProductWatchListID, host) => httpClient.post(`${host}${API_WATCHLIST_ENDPOINT}/Delete`, {
    ProductWatchListID,
  }),
};

export default {
  initInterceptor,
  Product,
  Proxies,
  WatchList,
};
