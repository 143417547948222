import React from 'react';
// import PropTypes from 'prop-types';
import { StyledSelect } from './Styles';
import Option from '../Option';

const Select = (
  {
    valueProp, textProp, currentValue, data, noneSelectedText, onChange,
  },
  ref,
) => (
  <StyledSelect ref={ref} onChange={onChange} value={currentValue}>
    {noneSelectedText && <Option key="" value="" text={noneSelectedText} />}
    {data.map((item, index) => (
      <Option
        key={item[valueProp]}
        value={item[valueProp]}
        text={item[textProp]}
      />
    ))}
  </StyledSelect>
);

const forwardSelect = React.forwardRef(Select);

Select.defaultProps = {};
Select.propTypes = {};

export default forwardSelect;
