import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useResponsive } from 'hooks';
import { Modal } from 'components/shared';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { Icon } from '../shared';
import {
  NavLeft,
  StaticItem,
  ItemText,
  PinnedNavModalContentContainer,
} from './Styles';
import Item from './PinnedNav/Item';
import Version from './../shared/Version';

import { PinnedNavSections } from './PinnedNav/PinnedNavSections';

const NavbarLeft = () => {
  const {
    UIStore: { setIsPinnedNav },
  } = useStores();
  const { isTabletView, isSmallView } = useResponsive();

  const pinnedNavModalHelpers = createQueryParamModalHelpers('pinnedNav-view');

  if (!isTabletView) {
    return null;
  }
  return (
    <>
      <NavLeft>
        {!isSmallView ? (
          <Item
            onClick={pinnedNavModalHelpers.open}
            icon="fa-th-list"
            testid="sidepanel-actions"
            text="Show Lane Info"
          />
        ) : (
          <StaticItem onClick={() => setIsPinnedNav(true)}>
            <Icon icon="th-list" iconOnly />
            <ItemText>Show Lane Info</ItemText>
          </StaticItem>
        )}
        <div style={{ position: 'absolute', marginTop: 'calc(100vh - 125px)', width: '100%' }}>
          <Version />
        </div>
      </NavLeft>

      {pinnedNavModalHelpers.isOpen() && (
        <Modal
          isOpen
          testid="modal:pinnedNav-view"
          variant="aside"
          width={350}
          onClose={pinnedNavModalHelpers.close}
          renderContent={() => (
            <PinnedNavModalContentContainer>
              <PinnedNavSections />
            </PinnedNavModalContentContainer>
          )}
        />
      )}
    </>
  );
};

export default observer(NavbarLeft);
