import { useState, useEffect } from 'react';
import { isFunction } from 'lodash';

export const usePreviewProductItem = ({
  agent = null,
  laneID = null,
  previewLotNumber,
  onBlockLotNumber,
  clearState = null,
}) => {
  const [_error, setError] = useState([]);
  const [productItem, setProductItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const addError = (message) => setError((err) => [...err, message]);
  const previewProductState = productItem?.productState;
  const isUpcomingItem = previewProductState === 'None';
  const isOnBlock = previewProductState === 'Open'
    || (onBlockLotNumber === previewLotNumber);

  const getProductPreview = (laneId, lotNum) => {
    const { obj, host } = agent;
    setIsLoading(true);
    obj
      .getProduct(lotNum, laneId, host)
      .then((res) => setProductItem(res.data))
      .catch((error) => addError(`Vehicle no longer available: ${error}`))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (clearState && typeof clearState === 'function') {
      clearState();
    }

    getProductPreview(laneID, previewLotNumber);
  // eslint-disable-next-line
  }, [previewLotNumber]);

  useEffect(() => {
    if (!agent) addError('valid agent object required');
    if (!isFunction(clearState)) addError('clearState function required');
    if (!laneID) addError('laneID required');
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    productItem,
    isUpcomingItem,
    isOnBlock,
    error: { category: 'Preview Product Item', error: _error },
  };
};

export default usePreviewProductItem;
