import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
// import { motion } from 'framer-motion';
import { Button } from '../../../components/project/shared/utils/styles';
// export const Layout = styled.div`
//   display: grid;
//   height: 100vh;
//   grid-template-rows: 0px;
//   grid-template-columns: 0px;
//   grid-template-areas:
//     'snav tbar tbar'
//     'snav pnav content'
//     'snav pnav chat';
//   grid-gap: 10px;
//   overflow-y: scroll;
// `;

export const Layout = styled.div`
  display: grid;
  height: 100vh;
  
  /* background-color: cornflowerblue; */
  grid-template-rows: 40px 1fr;
  grid-template-columns: 300px 1fr;
  grid-gap: 10px;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  grid-template-areas:
    'header header'
    'content content';
  @media (min-width: ${breakpoints.tablet}) {
    /* background-color: #FBCC08; */
    grid-template-rows: 40px 25px 1fr 50px;
    grid-template-columns:
      50px
      0px
      1fr;
    grid-template-areas:
      'snav header header'
      'snav pnav laybtns'
      'snav pnav content'
      'snav pnav chat';
  }
  @media (min-width: ${breakpoints.small}) {
    grid-template-rows: 40px 25px 1fr 50px;
    grid-template-columns:
      50px
      ${(props) => (props.isPinned ? '300px' : '0px')}
      1fr;
    grid-template-areas:
      'snav header header'
      'snav pnav laybtns'
      'snav pnav content'
      'snav pnav chat';
  }
  @media (min-width: ${breakpoints.large}) {
    /* background-color: magenta; */
  }
`;

export const StyledButton = styled(Button)`
  width: ${(props) => props.width || '100%'};
  height: 30px;
  margin: 1px;
  padding: 6px 4px;
  font-size: 0.7em;
  margin: 3px 5px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.8em;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const NoLanesSelected = styled.div`
  display: flex;
  width: 100vw;
  padding-top: 5em;
  align-items: center;
  justify-content: center;
`;
