import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const backgroundColor = (state) => {
  if (state.danger) {
    return 'var(--sellerPassBgColor)';
  }

  if (state.success) {
    return 'var(--sellerOkToSellBgColor)';
  }

  if (state.warning) {
    return 'var(--pendingMsgBgColor)';
  }

  if (state.takes) {
    return 'var(--sellerTakesBgColor)';
  }

  return 'var(--gray-6)';
};

export const Confirmation = styled.div`
  margin: 0 15px;
`;

export const SellButton = styled.button`
  margin: 0px 2px;
  font-size: 1em;
  border: 0;
  background-color: ${(props) => backgroundColor(props)};
  @media (min-width: ${breakpoints.tablet}) {
    //background: none;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    color: ${(props) => (props.danger || props.success || props.takes ? 'var(--lightTextColor)' : 'var(--lightTextColor)')};
    & i {
      color: ${(props) => (props.danger || props.success || props.takes ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    }
    line-height: 0.8em;
    font-size: 0.8em;
    font-weight: bold;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
  &:disabled {
    background-color: var(--disabledBtnBgColor);
    color: var(--disabledBtnTextColor);
    cursor: not-allowed;
  }
  &:focus {
    outline: 0;
  }
`;

export const SellerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-content: center; 
  margin: 15px 0;
  height: 40px;
  & button {
    width: 100px;
    text-transform: uppercase;
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin: 0;
    margin-top: 2px;
    ${({ isFullViewLayout }) => (isFullViewLayout ? css`
    margin-top: 10px;
    height: 40px;
    & button {
      width: 100px;
    }
  ` : css`
    height: 30px;
    & button {
      width: auto;
    }
  `)};
  }
`;
