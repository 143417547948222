import { css } from 'styled-components';
import { breakpoints } from '../shared/utils/styles';

export const defaultElevation = css`
  --level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --level-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --level-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --level-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
`;

export const defaultFontSizes = css`
  --baseFontSize: .9em;
  --h1: 1.4em;
  --h2: 1.2em;
  --h3: 1.1em;
  --h4: 1em;
  --h5: .9em;
`;

export const rootStyles = ({
  black,
  blue1,
  blue2,
  blue3,
  blue4,
  blue5,
  brown1,
  gray1,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  gray7,
  gray8,
  green1,
  green2,
  orange1,
  red1,
  red2,
  red3,
  white,
  yellow1,
  yellow2,
  activeLaneTextColor,
  badgeValueTextColor,
  bidActivityBgColor1,
  bidActivityBgColor2,
  bidActivityTextColor,
  bidBtnBgColor,
  bidBtnTextColor,
  blockAttrsBgColor1,
  blockAttrsBgColor2,
  blockAttrsTextColor1,
  blockAttrsTextColor2,
  cancelBtnBgColor,
  cancelBtnTextColor,
  clerkMsgBgColor,
  clerkMsgTextColor,
  crAttrsBgColor1,
  crAttrsBgColor2,
  crAttrsTextColor1,
  crAttrsTextColor2,
  darkTextColor,
  dealerMsgBgColor,
  dealerMsgTextColor,
  disabledBtnBgColor,
  disabledBtnTextColor,
  divider,
  dividerLine,
  errorColor,
  errorMsgBgColor,
  errorMsgDarkTextColor,
  errorMsgLightTextColor,
  globalMsgBgColor,
  globalMsgTextColor,
  headerBgColor,
  headerTextColor,
  lightTextColor,
  line,
  linkTextColor,
  lotNumberBgColor,
  lotNumberTextColor,
  mobileBlockAttrsBgColor1,
  mobileBlockAttrsBgColor2,
  mobileBlockAttrsTextColor1,
  mobileBlockAttrsTextColor2,
  mobileBottomNavBgColor,
  mobileBottomNavTextColor,
  mobileCrAttrsBgColor1,
  mobileCrAttrsBgColor2,
  mobileCrAttrsTextColor1,
  mobileCrAttrsTextColor2,
  mobilePrevAttrsBgColor1,
  mobilePrevAttrsBgColor2,
  mobilePrevAttrsTextColor1,
  mobilePrevAttrsTextColor2,
  mobileRunListBgColor1,
  mobileRunListBgColor2,
  mobileRunListTextColor1,
  mobileRunListTextColor2,
  myProxyBgColor,
  myProxyTextColor,
  navBgColor,
  navTextColor,
  noSaleMsgBgColor,
  noSaleMsgTextColor,
  pendingMsgBgColor,
  pendingMsgTextColor,
  pinMsgBgColor,
  pinMsgTextColor,
  playerMsgBgColor,
  playerMsgTextColor,
  prevAttrsBgColor1,
  prevAttrsBgColor2,
  prevAttrsTextColor1,
  prevAttrsTextColor2,
  previewTitleBgColor,
  previewTitleTextColor,
  radiusBadge,
  radiusBox,
  radiusModal,
  radiusNone,
  runListBgColor1,
  runListBgColor2,
  runListHoverBgColor,
  runListTextColor1,
  runListTextColor2,
  sellerItemBgColor,
  sellerItemTextColor,
  sellerOkToSellBgColor,
  sellerOkToSellTextColor,
  sellerPassBgColor,
  sellerPassTextColor,
  sellerReserveTextColor,
  sellerTakesBgColor,
  sellerTakesOkBgColor,
  sellerTakesOkTextColor,
  sellerTakesTextColor,
  sendBtnBgColor,
  sendBtnTextColor,
  shadedBgColor,
  subHeaderBgColor,
  subHeaderTextColor,
  successColor,
  utilityActionBgColor,
  utilityActionTextColor,
  utilityBtnBgColor,
  utilityBtnTextColor,
  watchingBgColor,
  watchingTextColor,
  wonMsgBgColor,
  wonMsgTextColor,
}) => css`
  /* color definition */
  --white: ${white};
  --gray-1: ${gray1};
  --gray-2: ${gray2}; 
  --gray-3: ${gray3};
  --gray-4: ${gray4};
  --gray-5: ${gray5};
  --gray-6: ${gray6};
  --gray-7: ${gray7};
  --gray-8: ${gray8};
  --black: ${black};
  --brown-1: ${brown1};
  --red-1: ${red1};
  --red-2: ${red2};
  --red-3: ${red3};
  --blue-1: ${blue1};
  --blue-2: ${blue2};
  --blue-3: ${blue3};
  --blue-4: ${blue4};
  --blue-5: ${blue5};
  --yellow-1: ${yellow1};
  --yellow-2: ${yellow2};
  --green-1: ${green1};
  --green-2: ${green2};
  --orange-1: ${orange1};

  /* intentions */

  /* general color */
  --lightTextColor: ${lightTextColor};
  --darkTextColor: ${darkTextColor};
  --shadedBgColor: ${shadedBgColor};
  --activeLaneTextColor: ${activeLaneTextColor};
  --utilityActionBgColor: ${utilityActionBgColor};
  --utilityActionTextColor: ${utilityActionTextColor};
  --linkTextColor: ${linkTextColor};
  --badgeValueTextColor: ${badgeValueTextColor};
    
  /* button template */
  --disabledBtnBgColor: ${disabledBtnBgColor};
  --disabledBtnTextColor: ${disabledBtnTextColor};
  --bidBtnBgColor: ${bidBtnBgColor};
  --bidBtnTextColor: ${bidBtnTextColor};
  --cancelBtnBgColor: ${cancelBtnBgColor};
  --cancelBtnTextColor: ${cancelBtnTextColor};
  --utilityBtnBgColor: ${utilityBtnBgColor};
  --utilityBtnTextColor: ${utilityBtnTextColor};
  
  /* nav */
  --navBgColor: ${navBgColor};
  --navTextColor: ${navTextColor};
  --mobileBottomNavBgColor: ${mobileBottomNavBgColor};
  --mobileBottomNavTextColor: ${mobileBottomNavTextColor};

  /* headings */
  --headerBgColor: ${headerBgColor};
  --headerTextColor: ${headerTextColor};
  --subHeaderBgColor: ${subHeaderBgColor};
  --subHeaderTextColor: ${subHeaderTextColor};
  --previewTitleBgColor: ${previewTitleBgColor};
  --previewTitleTextColor: ${previewTitleTextColor};

  /* errors */
  --successColor: ${successColor};
  --errorColor: ${errorColor};
  --errorMsgBgColor: ${errorMsgBgColor};
  --errorMsgLightTextColor: ${errorMsgLightTextColor};
  --errorMsgDarkTextColor: ${errorMsgDarkTextColor};

  /* pinned area */
  --pinMsgBgColor: ${pinMsgBgColor};
  --pinMsgTextColor: ${pinMsgTextColor};
  --playerMsgBgColor: ${playerMsgBgColor};
  --playerMsgTextColor: ${playerMsgTextColor};

  /* chat */
  --dealerMsgBgColor: ${dealerMsgBgColor};
  --dealerMsgTextColor: ${dealerMsgTextColor};
  --clerkMsgBgColor: ${clerkMsgBgColor};
  --clerkMsgTextColor: ${clerkMsgTextColor};
  --globalMsgBgColor: ${globalMsgBgColor};
  --globalMsgTextColor: ${globalMsgTextColor};
  --sendBtnBgColor: ${sendBtnBgColor};
  --sendBtnTextColor: ${sendBtnTextColor};

  /* decisions */
  --noSaleMsgBgColor: ${noSaleMsgBgColor};
  --noSaleMsgTextColor: ${noSaleMsgTextColor};
  --pendingMsgBgColor: ${pendingMsgBgColor};
  --pendingMsgTextColor: ${pendingMsgTextColor};
  --wonMsgBgColor: ${wonMsgBgColor};
  --wonMsgTextColor: ${wonMsgTextColor};

  /* run list */
  --runListBgColor1: ${mobileRunListBgColor1};
  --runListTextColor1: ${mobileRunListTextColor1};
  --runListBgColor2: ${mobileRunListBgColor2};
  --runListTextColor2: ${mobileRunListTextColor2};

  /* actions */
  --watchingBgColor: ${watchingBgColor};
  --watchingTextColor: ${watchingTextColor};
  --myProxyBgColor: ${myProxyBgColor};
  --myProxyTextColor: ${myProxyTextColor};

  /* seller units */
  --sellerItemBgColor: ${sellerItemBgColor};
  --sellerItemTextColor: ${sellerItemTextColor};

  /* seller actions */
  --sellerPassBgColor: ${sellerPassBgColor};
  --sellerPassTextColor: ${sellerPassTextColor};
  --sellerTakesBgColor: ${sellerTakesBgColor};
  --sellerTakesTextColor: ${sellerTakesTextColor};
  --sellerOkToSellBgColor: ${sellerOkToSellBgColor};
  --sellerOkToSellTextColor: ${sellerOkToSellTextColor};
  --sellerReserveTextColor: ${sellerReserveTextColor};
  --sellerTakesOkBgColor: ${sellerTakesOkBgColor};
  --sellerTakesOkTextColor: ${sellerTakesOkTextColor};

  /* bid activity */
  --bidActivityBgColor1: ${bidActivityBgColor1};
  --bidActivityBgColor2: ${bidActivityBgColor2};
  --bidActivityTextColor: ${bidActivityTextColor};

  /* attributes */
  --blockAttrsBgColor1: ${mobileBlockAttrsBgColor1};
  --blockAttrsTextColor1: ${mobileBlockAttrsTextColor1}
  --blockAttrsBgColor2: ${mobileBlockAttrsBgColor2};
  --blockAttrsTextColor2: ${mobileBlockAttrsTextColor2}

  --prevAttrsBgColor1: ${mobilePrevAttrsBgColor1};
  --prevAttrsBgColor2: ${mobilePrevAttrsBgColor2};
  --prevAttrsTextColor1: ${mobilePrevAttrsTextColor1};
  --prevAttrsTextColor2: ${mobilePrevAttrsTextColor2};
  
  --crAttrsBgColor1: ${mobileCrAttrsBgColor1};
  --crAttrsBgColor2: ${mobileCrAttrsBgColor2};
  --crAttrsTextColor1: ${mobileCrAttrsTextColor1};
  --crAttrsTextColor2: ${mobileCrAttrsTextColor2};

  /* block */
  --lotNumberBgColor: ${lotNumberBgColor};
  --lotNumberTextColor: ${lotNumberTextColor};

  /* containers */
  --radiusNone: ${radiusNone};
  --radiusBox: ${radiusBox};
  --radiusModal: ${radiusModal};
  --radiusBadge: ${radiusBadge};
  --line: ${line};
  --divider: ${divider};
  --dividerLine: ${dividerLine};

  ${defaultFontSizes};
  ${defaultElevation};

  /* non-mobile specific */
  @media (min-width: ${breakpoints.tablet}) {
    --runListBgColor1: ${runListBgColor1};
    --runListTextColor1: ${runListTextColor1};
    --runListBgColor2: ${runListBgColor2};
    --runListTextColor2: ${runListTextColor2};
    --runListHoverBgColor: ${runListHoverBgColor};

    --blockAttrsBgColor1: ${blockAttrsBgColor1};
    --blockAttrsBgColor2: ${blockAttrsBgColor2};
    --blockAttrsTextColor1: ${blockAttrsTextColor1};
    --blockAttrsTextColor2: ${blockAttrsTextColor2};

    --prevAttrsBgColor1: ${prevAttrsBgColor1};
    --prevAttrsBgColor2: ${prevAttrsBgColor2};
    --prevAttrsTextColor1: ${prevAttrsTextColor1};
    --prevAttrsTextColor2: ${prevAttrsTextColor2};

    --crAttrsBgColor1: ${crAttrsBgColor1};
    --crAttrsBgColor2: ${crAttrsBgColor2};
    --crAttrsTextColor1: ${crAttrsTextColor1};
    --crAttrsTextColor2: ${crAttrsTextColor2};
  }
`;