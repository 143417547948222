import styled from 'styled-components';

export const Container = styled.div`
  grid-area: logo;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  & img,
  img:hover {
    height: auto;
    max-width: 200px;
    border: 0px;
  }
`;

export default Container;
