import { useRef } from 'react';

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    try {
      if (htmlElRef && htmlElRef?.current && htmlElRef.current.focus) {
        htmlElRef.current.focus();
      }
    } catch (error) {
      console.error('useFocus', { htmlElRef, error });
    }
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
