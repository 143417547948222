import {
  action,
  computed,
  configure,
  decorate,
  observable,
  reaction,
  runInAction,
} from 'mobx';
// import queryString from 'query-string';
import {
  DEFAULT_LAYOUT_VIEW,
  FULL_LAYOUT_VIEW,
} from '../shared/constants/index';

configure({ enforceActions: 'always' });

class AuctionStore {
  constructor() {
    this.initialize();

    runInAction(() => {
      this.janusIceServers = null;
    });

    runInAction(() => {
      this.networkConnected = true;
      this.openLanes = observable.array([], { deep: true });
      this.refreshKey = Math.random();
    });

    this.baseLaneStatus = {
      endTime: null,
      startTime: null,
      lane: null,
      laneStatus: null,
      laneStatusName: null,
      laneStatusNameFormatted: null,
      runListCount: null,
      lotNumber: null,
      product: null,
      productState: null,
    };

    reaction(
      () => this.auctionID,
      () => this.setAuctionName(),
    );
  }

  setProperty = (key, value) => {
    try {
      this[key] = value;
    } catch (error) {
      console.error('setProperty', { error, key, value });
    }
  };

  initialize = () => {
    this.initializeAuction();
  };

  initializeAuction = () => {
    // const qs = queryString.parse(window.location.search);
    this.setProperty('fontSize', 1);
    this.setProperty('mobileClosedLanes', []);
    this.setProperty('auctionID', null);
    this.setProperty('auctionList', []);
    this.setProperty('auctionName', '');
    this.setProperty('auctionSettings', []);
    this.setProperty('auctionInfo', null);
    this.setProperty('connected', false);
    // this.setProperty('isNative', qs?.native);
    this.setProperty('isNative', window.ReactNativeWebView);
    this.setProperty('laneEvents', []);
    this.setProperty('laneList', []);
    this.setProperty('openLanes', []);
    this.setProperty('activeBidActivityLaneId', null);
    this.setProperty('bidActivityLanes', []);
    this.setProperty('laneStatusMap', new Map());
    this.setProperty('uiVisibilityState', new Map());
    this.setProperty(
      'layoutViewType',
      window.localStorage.getItem('layout') || DEFAULT_LAYOUT_VIEW,
    );
  };

  get isFullViewLayout() {
    return this.layoutViewType === FULL_LAYOUT_VIEW;
  }

  setFontSize = (fontSize) => this.setProperty('fontSize', fontSize);

  setMobileClosedLanes = (laneID) => {
    try {
      this.setProperty('mobileClosedLanes', [
        ...new Set([...this.mobileClosedLanes, laneID]),
      ]);
    } catch (error) {
      console.error('setMobileClosedLanes', { error, laneID });
    }
  };

  deleteMobileClosedLane = (laneID) => {
    try {
      const filteredLanes = this.mobileClosedLanes.filter(
        (lane) => lane !== laneID,
      );
      this.setProperty('mobileClosedLanes', [...filteredLanes]);
    } catch (error) {
      console.error('deleteMobileClosedLane', { error, laneID });
    }
  };

  setOpenLanes = (laneID) => {
    try {
      this.setProperty('openLanes', [...this.openLanes, laneID]);
      this.setProperty('bidActivityLanes', [...this.openLanes]);
      this.setActiveBidActivityLaneId(
        this.bidActivityLanes.filter(
          (item) => item.laneID === this.activeBidActivityLaneId,
        )[0]?.laneID || this.openLanes[0]?.laneID,
      );
    } catch (error) {
      console.error('setOpenLanes', { error, laneID });
    }
  };

  setActiveBidActivityLaneId = (laneID) => {
    try {
      this.setProperty('activeBidActivityLaneId', laneID);
    } catch (error) {
      console.error('activeBidActivityLaneId', { error, laneID });
    }
  };

  setLaneStatus = (laneID, status) => {
    const existingLaneStatus = this.laneStatusMap.get(laneID);

    if (!existingLaneStatus) {
      this.laneStatusMap.set(laneID, {
        ...this.baseLaneStatus,
        ...status,
      });

      return;
    }

    this.laneStatusMap.set(laneID, { ...existingLaneStatus, ...status });
  };

  deleteOpenLane = (laneID) => {
    try {
      const filteredLanes = this.openLanes.filter((lane) => lane !== laneID);

      this.openLanes.replace(filteredLanes);
      this.refreshKey = Math.random();
      this.deleteLaneUiState(laneID);
    } catch (error) {
      console.error('deleteOpenLane', { error, laneID });
    }
  };

  getOpenLanes = () => {
    try {
      const lanesFromStorage = window.localStorage.getItem('lanes');
      const result = lanesFromStorage ? JSON.parse(lanesFromStorage) : [];
      return result;
    } catch (error) {
      console.error('getOpenLanes', error);
    }
    return [];
  };

  setAuctionName = () => {
    const auction = this.auctionList.filter(
      (item) => item.auctionID === this.auctionID,
    );

    try {
      window.localStorage.setItem('auctionID', this.auctionID);
      if (auction.length > 0) {
        this.auctionName = auction[0].auction;
        window.localStorage.setItem('auctionName', auction[0].auction);
      }
    } catch (error) {
      console.error('setAuctionName', { error, auction });
    }
  };

  setAuctionSettings = (data) => {
    this.setProperty('auctionSettings', data);
  };

  setAuctionInfo = (data) => {
    this.setProperty('auctionInfo', data);
    this.removeInvalidLanesFromStorage(data?.laneIDs || []);
  };

  removeInvalidLanesFromStorage = (validLanes = []) => {
    if (validLanes?.length === 0) return;

    const lanesFromStorage = window.localStorage.getItem('lanes');
    const newLanesInStorage = [];

    if (lanesFromStorage) {
      const lanes = JSON.parse(lanesFromStorage) || [];
      lanes.forEach((lane) => {
        if (validLanes.includes(lane)) {
          newLanesInStorage.push(lane);
        }
      });
      if (newLanesInStorage.length > 0) {
        window.localStorage.setItem('lanes', JSON.stringify(newLanesInStorage));
      }
    }
  };

  setLaneEvents = (data) => {
    this.setProperty('laneEvents', data);
  };

  getLaneUiState = (laneId, prop) => {
    try {
      if (!this.uiVisibilityState.get(laneId)[prop]) {
        return;
      }

      for (const [key, value] of Object.entries(
        this.uiVisibilityState.get(laneId),
      )) {
        if (key === prop) {
          return value;
        }
      }
    } catch (error) {
      console.error('test::getLaneUiState', { error, laneId, prop });
    }
  };

  deleteLaneUiState = (laneId) => {
    try {
      this.uiVisibilityState.delete(laneId);
    } catch (error) {
      console.error('test::deleteLaneUiState', { error, laneId });
    }
  };

  setJanusServers = (payload) => {
    try {
      const JanusServers = [
        {
          urls: payload.mediaStunUrl,
        },
        {
          urls: payload.mediaTurnUrls,
          credential: payload.mediaTurnPassword,
          username: payload.mediaTurnAccount,
        },
      ];
      runInAction(() => {
        this.janusIceServers = JanusServers;
      });
    } catch (error) {
      console.error(error);
    }
  };

  addNewLaneUiState = (laneId) => {
    try {
      if (this.uiVisibilityState.has(laneId)) {
        return;
      }

      const newItem = {
        showLane: true,
        widenLane: false,
        zoomUrl: '',
        showZoom: false,
        showRunList: false,
        showProdPreview: false,
      };

      this.uiVisibilityState.set(laneId, newItem);
    } catch (error) {
      console.error('test::addNewLaneUiState', { error, laneId });
    }
  };

  setLaneUiVisibilityState = (laneId, key, value) => {
    try {
      const values = this.getLaneUiState(laneId);
      this.uiVisibilityState.set(laneId, Object.assign(values, { key: value }));
    } catch (error) {
      console.error('test::setLaneUiVisibilityState', {
        error,
        laneId,
        key,
        value,
        uiVisibilityState: this.uiVisibilityState,
        laneUiVisibilityState: this.uiVisibilityState[laneId],
      });
    }
  };
}

decorate(AuctionStore, {
  activeBidActivityLaneId: observable,
  auctionID: observable,
  auctionInfo: observable,
  removeInvalidLanesFromStorage: action,
  auctionLaneID: observable,
  auctionList: observable,
  auctionName: observable,
  auctionSettings: observable,
  bidActivityLanes: observable,
  connected: observable,
  deleteLaneUiState: action,
  deleteMobileClosedLane: action,
  deleteOpenLane: action,
  fontSize: observable,
  getLaneUiState: action,
  getOpenLanes: action,
  initialize: action,
  initializeAuction: action,
  isFullViewLayout: computed,
  janusIceServers: observable,
  laneEvents: observable,
  laneList: observable,
  laneName: observable,
  laneStatusMap: observable,
  layoutSettings: observable,
  layoutViewType: observable,
  mobileClosedLanes: observable,
  networkConnected: observable,
  openLanes: observable,
  refreshKey: observable,
  runList: observable,
  setActiveBidActivityLaneId: action,
  setAuctionInfo: action,
  setAuctionSettings: action,
  setJanusServers: action,
  setLaneEvents: action,
  setLaneStatus: action,
  setLaneUiVisibilityState: action,
  setMobileClosedLanes: action,
  setOpenLanes: action,
  setProperty: action,
  uiVisibilityState: observable,
});

export default AuctionStore;
