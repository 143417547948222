import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { NavButton } from './Styles';
import { Icon } from '../../shared';

const MobileNavButton = ({
  onClick, icon, iconText, activeButton, iconBadge = null,
}) => (
  <NavButton onClick={onClick}>
    <Icon icon={icon} iconOnly>{iconBadge}</Icon>
    {activeButton === iconText && <div>{iconText}</div>}
  </NavButton>
);

MobileNavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  iconText: PropTypes.string.isRequired,
  activeButton: PropTypes.string,
  iconBadge: PropTypes.object,
};

MobileNavButton.defaultProps = {
  activeButton: null,
  iconBadge: null,
};

export default observer(MobileNavButton);
