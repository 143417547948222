import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const BodyHeader = styled.div`
  grid-area: header;
  display: flex;
  background: var(--navBgColor);
  height: 50px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
  color: var(--lightTextColor);
  @media (min-width: ${breakpoints.tablet}) {
    z-index: 200;
    justify-content: space-between;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    
  }
`;

export const LeftContainer = styled.div`
  display: none;
  font-weight: normal;
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  font-weight: normal;
  padding-right:5px;
  & i {
    padding-right: 5px;
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    
  }
`;

export const RightContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    font-weight: normal;
    padding-right:5px;
    & i {
      padding-right: 5px;
    }
  }
  @media (min-width: ${breakpoints.small}) { }
  @media (min-width: ${breakpoints.large}) { }
`;

export const HeaderTitle = styled.p`
  color: var(--headerTextColor);
  margin: 0 0 0 20px;
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    display: inline;
  }
`;

export const Logo = styled.img`
  border: 0;
  object-fit: contain;
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Bars = styled.i`
  font-size: 30px;
  
`;

export const NativeBackButton = styled.i`
  position: absolute;
  top: 15px;
  left: 10px;
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
    display: none;
  }
`;
