import React from 'react';
import { useToggle } from 'hooks';
import { observer } from 'mobx-react-lite';
import { BidderStats } from './BidderStats';
import { Icon } from '../../../shared';
import {
  IconButton,
  Section,
  SectionHeader,
  SectionHeaderLabel,
} from './Styles';

export const Stats = observer(() => {
  const {
    isToggled: statsSectionOpen,
    toggle: toggleStatsSectContent,
  } = useToggle(true);

  const getToggleIcon = (isOpen) => (isOpen ? 'chevron-up' : 'chevron-down');

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderLabel>
          <Icon icon="dollar-sign" size={1} hoverPointer={false} />
          Auction Stats
        </SectionHeaderLabel>
        <IconButton onClick={toggleStatsSectContent}>
          <Icon
            icon={getToggleIcon(statsSectionOpen)}
            iconOnly
            size={0.8}
          />
        </IconButton>
      </SectionHeader>
      {statsSectionOpen && <BidderStats />}
    </Section>
  );
});

export default Stats;
