import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const getHeight = ({ height, isFullViewLayout, thumbnailCarousel}) => {
  if (thumbnailCarousel) return '80px';
  if (isFullViewLayout) return '320px';
  return height || '80px';
};

export const Carousel = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) => getHeight(props)};
  ${(props) => props.laneClosed
    && css`
      border: 1px solid gray;
      box-sizing: border-box;
      z-index: 15;
    `}
  @media (min-width: ${breakpoints.tablet}) {
    /* width: 390px; */
    width: 100%;
    overflow: hidden;
    /* max-width: 390px; */
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
`;

export const CarouselChild = styled.div`
  ${(props) => props.width && `width: ${props.width || '100%'}`};
`;

export const CarouselContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
`;

// export const Slide = styled.div`
//   /* max-height: 70vh; */
//   height: ${(props) => props.height || '80px'};
//   @media (min-width: ${breakpoints.tablet}) {
//     /* height: ${(props) => props.desktopImageHeight || 80}px; */
//   }
//   background-image: ${(props) => `url('${props.backgroundImage}')`};
//   background-color: black;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 0;
// `;

export const CarouselIndicators = styled.ol`
  position: absolute;
  right: 0;
  bottom: 0.5em;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
`;

export const CarouselIndicator = styled.li`
  position: relative;
  flex: 0 1 auto;
  border-radius: var(--radiusBadge);
  margin: 0.5em 0.3em;
  width: 0.7em;
  height: 0.7em;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  &:hover {
    background: #a62b2b;
  }
  ${(props) => props.active
    && css`
      background: #a62b2b;
      cursor: default;
    `}
`;

export const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 0;
  margin: 0 auto;
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const PrevButton = styled.button`
  display: inline-block;
  overflow: hidden;
  z-index: 15;
  border: none;
  padding: 8px 16px;
  vertical-align: middle;
  text-decoration: none;
  /* color: inherit; */
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translate(0%, -50%);
  -ms-transform: translate(-0%, -50%);
  background: none;
  color: black;
  font-size: 2em;
  font-weight: bold;
  opacity: 0.6;
  &:hover {
    color: #000 !important;
    background-color: #ccc !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  @media (min-width: ${breakpoints.tablet}) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const NextButton = styled.button`
  border: none;
  z-index: 15;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  /* color: inherit; */
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  background: none;
  color: black;
  font-size: 2em;
  font-weight: bold;
  opacity: 0.6;
  &:hover {
    color: #000 !important;
    background-color: #ccc !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  @media (min-width: ${breakpoints.tablet}) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  width: ${({ isFullViewLayout }) => (isFullViewLayout ? '420px' : '240px')};
  height: ${({ isFullViewLayout }) => (isFullViewLayout ? '85px' : '62px')};
`;

export const Thumbnail = styled.div`
    margin: 0;
    padding: 0;
    border: 2px #fff solid;
    width: calc((100% / ${({ thumbsToShow }) => thumbsToShow}) - 4px);
    height: ${(props) => (props.isFullViewLayout ? '75px' : '62px')};
    //height: 75px;
    cursor: pointer;
    ${(props) => props.img && `background-image: url("${props.img}");`};
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    float: left;
`;



export const ThumbnailContainerScollbars = styled.div`
  //width: 100%;
  width: ${({ isFullViewLayout }) => (isFullViewLayout ? '420px' : '240px')};
  height: ${({ isFullViewLayout }) => (isFullViewLayout ? '85px' : '68px')};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

export const ThumbnailScrollbars = styled.div`
    margin: 0;
    padding: 0;
    border: 2px #fff solid;
    width: calc((100% / ${({ thumbsToShow }) => thumbsToShow}) - 4px) ;
    height: ${(props) => (props.isFullViewLayout ? '75px' : '62px')};
    cursor: pointer;
    ${(props) => props.img && `background-image: url("${props.img}");`};
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    float: left;
    flex: 0 0 auto;
`;