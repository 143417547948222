import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';
import { motion } from 'framer-motion';

function TrackPadding(isMobileView, isSeller) {
  if (isMobileView) {
    return '5px 0px'
  }

  if (isSeller) {
    return '0px 10px'
  }

  return '5px 10px'
}


export const ContentWrapper = styled.div`
  display: flex;
  cursor: default;
  text-align: center;
  background-color: var(--white);
  margin: 10px 20px 0 20px;
  padding: 0 0 15px 0;
  box-sizing: border-box;
  z-index: 30;
  ${({isWatched}) => isWatched && 'background-color: var(--watchingBgColor);'}; 
  ${({isWatched, themeCss}) => isWatched && themeCss && themeCss?.watchingBgGradientColor?.gradient};
  ${({isSeller}) => isSeller && 'background-color: var(--sellerItemBgColor)'};
    grid-area: bid;
    flex-direction: column;
    border-right: var(--line);
    border-left: var(--line);
    padding: 0 0 15px 0;
    padding: 0 0 0px 0;
    margin: 0px;
    & div, div > a {
    ${({isWatched}) => isWatched && 'color: var(--watchingTextColor)'};
  }
  @media (min-width: ${breakpoints.small}) {
    border-right: var(--line);
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const FullTrackValue = styled.div`
  ${({ color, bgColor, isMobileView }) => css`
    background-color: ${bgColor};
    color: ${color};
    
    ${isMobileView && 'width: 100%'};
    flex-direction: ${isMobileView ? 'column' : 'row'};
    padding: ${isMobileView ? '5px 0px' : '5px 10px'};
    padding: 5px;
    margin-bottom: 3px;
    @media (min-width: ${breakpoints.tablet}) {
    }
    @media (min-width: ${breakpoints.small}) {
    }
    @media (min-width: ${breakpoints.large}) {
    }
  `};
`;

export const DetailsContainer = styled.div`
  ${({ isMobileView }) => css`
    ${isMobileView && 'display: flex'};
  `};
  flex-direction: ${({isMobileView}) => (isMobileView ? 'row' : 'column')};
  justify-content: ${({isMobileView}) => (isMobileView ? 'space-evenly' : 'flex-start')};
  justify-content: ${({isFullViewLayout}) => (isFullViewLayout && 'flex-start')};  
  flex-basis: ${({isMobileView}) => (isMobileView ? 'auto' : '50%')};
  padding: 10px 15px;
  /* background-color: ${({isLightTheme}) => isLightTheme ? 'var(--white)' : 'var(--black)'}; */
  ${({isFullViewLayout}) => (isFullViewLayout && css`
    & .high-bidder-container, .high-bid-container {
      margin-bottom: 10px;
    }
  `)};   
  font-size: 1.2em;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 0.7em;
    text-align: left;
    padding: 0;
    & .high-bidder-container {
      padding-top: 10px;
    }
    ${(props) => (props.isSeller && css`
      & .high-bid-container {
        padding-top: 0;
      }
    `)};
    & .high-bid-container div:last-child {
      font-size: 1.3em;
    }
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
  ${(props) => (props.isFullViewLayout && css`
    & .high-bidder-container {
      padding-top: 15px;
    }
  `)}; 
`;

export const Track = styled.div`
${({ height, isMobileView, isSeller }) => css`
  ${height ? `height: ${height}` : ''};
  display: flex;
  ${isMobileView && 'width: 175px'};
  flex-direction: ${isMobileView ? 'column' : 'row'};
  padding: ${TrackPadding(isMobileView, isSeller)};
  
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`}
`;

export const TrackKey = styled.div`${({ height, isMobileView, isWatched }) => css`
  padding: 0px;
  width: ${isMobileView ? '100%' : '35%'};
  text-align: ${isMobileView ? 'center' : 'left'};
  font-weight: bold;
  font-size: ${(props) => (props.isMobileView && '1rem')};
  color: ${(props) => (props.color ? `${props.color}` : 'var(--darkTextColor)')};
  ${isMobileView && 'margin: 5px 0'};
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
  `}`;

export const TrackValue = styled(motion.div)`${({ height, isMobileView }) => css`
  padding: 0px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  ${(props) => (props.color ? `color: ${props.color}` : 'color: var(--darkTextColor)')};
  ${(props) => (props.color ? 'font-weight: bold' : '')};
  & a, span {
    text-decoration: none;
    ${(props) => (props.color ? `color: ${props.color}` : '')};
    &:hover {
      cursor: default;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    width: 65%;
    text-align: right;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
  `}`;

const backgroundColor = (state) => {
  if (state.danger) {
    return 'var(--noSaleMsgBgColor)';
  }

  if (state.success) {
    return 'var(--wonMsgBgColor)';
  }

  if (state.warning) {
    return 'var(--pendingMsgBgColor)';
  }

  if (state.takes) {
    return 'var(--sellerTakesBgColor)';
  }

  return null;
};

export const BidButton = styled.button`
  width: 100vw;
  padding: 15px 0;
  line-height: 1.5em;
  outline: 0;
  border-radius: var(--radiusNone);;
  background-color: ${(props) => backgroundColor(props)};
  color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
  & i {
    color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
  }
  &:disabled {
    background-color: var(--disabledBtnBgColor);
    color: var(--disabledBtnTextColor);
    cursor: not-allowed;
  }
  @media (min-width: ${breakpoints.tablet}) {
    display: inline-block;
    position: relative;
    align-self: center;
    min-width: 50%;
    height: 3em;
    width: 70%;
    margin: 0px 0px 10px 0px;
    background: none;
    border: none;
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${(props) => backgroundColor(props)};
    color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    & i {
      color: ${(props) => (props.danger || props.success ? 'var(--lightTextColor)' : 'var(--darkTextColor)')};
    }
    line-height: 1.2em;
    font-size: 1em;
    font-weight: bold;
    ${({ isFullViewLayout }) => !isFullViewLayout && 'height: 35px'};
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const ProxyBidButton = styled(BidButton)`
  cursor: not-allowed;
  background-color: ${({ lanePaused }) => (lanePaused ? 'var(--disabledBtnBgColor)' : 'var(--myProxyBgColor)')};
  ${({ lanePaused }) => (lanePaused && 'color: var(--lightTextColor);')};
  @media (min-width: ${breakpoints.tablet}) {
    cursor: not-allowed;
    padding: 4px 8px;
    font-size: 0.9rem;   
  }
`;

export const AcceptanceAmtInput = styled.input`
  width: 50px;
  font-size: 0.9em;
  line-height: 0.8em;
  text-align: right;
  font-weight: bold;
  background-color: var(--white);
  color: var(--darkTextColor);
`;


/** New Layout */

export const HighBidWrapper = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  margin-left: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const HighBidLabel = styled.p`
  margin: 0;
`;

export const HighBid = styled.p`
  font-size: 2.2rem;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  font-weight: bold;
  color: var(--bidBtnBgColor);
  margin: 0;
`;

export const NoBids = styled.p`
  font-size: 1.6rem;
  color: var(--bidBtnBgColor);
  margin: 0;
`;

export const Header = styled.h4`
  text-align: center;
  padding: 10px;
  margin: 0;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  font-size: 1.1rem;
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const TakeButton = styled.button`
  margin-left: 0px;
  margin-top: 0px;
  color: var(--sellerTakesTextColor);
  height: 20px;
  width: 30px;
  z-index: 2;
  border-top-left-radius: var(--radiusNone);
  border-bottom-left-radius: var(--radiusNone);
  border-left: none;
  border-top: var(--line);
  border-right: var(--line);
  border-bottom: var(--line);
  background-color: var(--sellerTakesBgColor);
  padding: 3px;
  :disabled,
  :disabled i {
    cursor: not-allowed;
    background-color: var(--cancelBtnBgColor);
    color: var(--gray-8);
  }
  &:focus {
    outline: none;
  }
`;

const takesEditCss = ({ showInput, isFullViewLayout }) => css`
  height: 30px;
  & input {
    width: 75px;
    font-size: 1rem;
  }
  & button {
    height: 30px;
  }
  @media (min-width: ${breakpoints.tablet}) { 
    height: ${showInput && isFullViewLayout ? '40px' : '20px'};
    & input {
      width: ${showInput && isFullViewLayout ? '125px' : '75px'};
      ${showInput && isFullViewLayout ? 'height: 30px' : null};
      font-size: ${showInput && isFullViewLayout ? '1.8em' : '1em'};
    }
    & button {
      ${showInput && isFullViewLayout ? 'height: 34px' : 'height: 20px'};
    }
  }
`;

export const InputButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  padding: 0px;
  width: 100%;
  font-size: 0.8rem;
  & input {
    padding-left: 5px;
    border: var(--line);
    border-right: none;
    border-top-left-radius: var(--radiusBox);
    border-bottom-left-radius: var(--radiusBox);
    &:focus:-webkit-placeholder {
      color: transparent;
    }
    &:focus {
      outline: none;
    }
  }
  ${(props) => takesEditCss(props)}
  @media (min-width: ${breakpoints.tablet}) {
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const MobileReserveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  padding: 0;
`;
