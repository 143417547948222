import styled, { css } from 'styled-components';
import { mixin, font } from 'shared/utils/styles';
import { NavLeft } from '../../Styles';

const badgeCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: var(--radiusBadge);
  top: -1em;
  right: -0.35em;
  width: 2em;
  height: 2em;
  line-height: 1.5em;
  font-size: 0.4em;
  font-weight: bold;
  text-align: center;
  color: var(--badgeValueTextColor);
  background-color: var(--white);
`;

export const StyledBadge = styled.span`
  ${(props) => (props.badge || props.badge >= 0) && badgeCss}
`;

export const StyledItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 64px;
  color: var(--navTextColor);
  transition: color 0.1s;
  ${mixin.clickable}
  &:hover {
    /* rgba(255, 255, 255, 0.1); */
    background: var(--navBgColor);
  }
  i {
    position: absolute;
    left: 20px;
    top: 10px;
    font-size: 1.8em;
    span {
      ${(props) => (props.badge || props.badge >= 0) && badgeCss}
    }
  }
`;

export const ItemText = styled.div`
  position: relative;
  right: 12px;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: all 0.1s;
  transition-property: right, visibility, opacity;
  ${font.bold}
  ${font.size(0.8)}
  color: var(--navTextColor);
  ${NavLeft}:hover & {
    right: 0;
    visibility: visible;
    opacity: 1;
  }
`;
