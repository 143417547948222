import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: ${(props) => props.bgColor || 'var(--pinMsgBgColor)'};
  border: ${(props) => props.bgColor || 'var(--pinMsgBgColor)'};
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 var(--radiusBox) var(--radiusBox);
    margin-bottom: 5px;
    grid-area: msg;
    /* background-color: green; */
    /* color: var(--light-text-color); */
    color: ${(props) => props.color};
    min-width: 100%;
    min-height: 100%;
    border-right: var(--line);
    border-left: var(--line);
    border-radius: var(--radiusNone);
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const MessageContainer = styled.div`
  color: ${(props) => props.color || 'var(--darkTextColor)'};
  font-size: 0.8em;
  padding: 10px;
  & i {
    padding-right: 5px;
  }
`;
