import React, { useEffect, useState } from 'react';
import { useToggle, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Icon } from '../../../shared';
import { BidActivityLanes } from './BidActivityLanes';
import {
  IconButton,
  Section,
  SectionContent,
  SectionHeader,
  SectionHeaderLabel,
  Track,
  TrackKey,
  TrackValue,
} from '../Styles';

export const BidActivity = observer(() => {
  const {
    AuctionStore: {
      openLanes, activeBidActivityLaneId, setActiveBidActivityLaneId
    },
    BidStore: { notifications, audioLane },
  } = useStores();

  const getToggleIcon = (isOpen) => (isOpen ? 'chevron-up' : 'chevron-down');
  const {
    isToggled: activitySectionOpen,
    toggle: toggleActivitySectContent,
  } = useToggle(true);

  const [laneId, setLaneID] = useState(activeBidActivityLaneId);

  const laneFilter = (item) => {
    if (!laneId && openLanes[0]) {
      return item.LaneID === openLanes[0];
    }

    return item.LaneID === laneId;
  };

  useEffect(() => {}, [openLanes.length]);

  useEffect(() => {
    setActiveBidActivityLaneId(audioLane);
    setLaneID(audioLane);
    // eslint-disable-next-line
  }, [audioLane]);

  if (openLanes.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderLabel>
          <Icon icon="gavel" size={1} hoverPointer={false} />
          Bidding Activity
        </SectionHeaderLabel>
        <IconButton onClick={toggleActivitySectContent}>
          <Icon
            icon={getToggleIcon(activitySectionOpen)}
            iconOnly
            size={0.8}
          />
        </IconButton>
      </SectionHeader>

      {activitySectionOpen && (
        <>
          <BidActivityLanes
            activitySectionOpen={activitySectionOpen}
            laneId={laneId}
            setLaneID={setLaneID}
          />

          <SectionContent height="430px">
            {notifications
              .filter(laneFilter)
              .slice(0, 50)
              .map((value, index) => (
                <Track color="var(--bidActivityTextColor)" bgColor1="var(--bidActivityBgColor1)" bgColor2="var(--bidActivityBgColor2)" key={index}>
                  <TrackKey>{value.timestamp}</TrackKey>
                  <TrackValue>
                    {value.notification}
                  </TrackValue>
                </Track>
              ))}
          </SectionContent>
        </>
      )}
    </Section>
  );
});

export default BidActivity;
