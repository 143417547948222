import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCountdown } from 'hooks';

const LaneNavCountdown = ({ date }) => {
  const {
    hours,
    minutes,
  } = useCountdown(date);

  const hoursLabel = Math.abs(hours) > 1 ? 'hrs' : 'hr';
  const minsLabel = Math.abs(minutes) > 1 ? 'mins' : 'min';
  const timeRemaining = `${hours}${hoursLabel} ${minutes}${minsLabel}`;

  const isInvalidDate = () => minutes < 0 || hours < 0 || Number.isNaN(hours) || Number.isNaN(minutes);

  if (isInvalidDate()) return null;

  return <Container>{timeRemaining}</Container>;
};

LaneNavCountdown.propTypes = {
  date: PropTypes.string.isRequired,
};

export default LaneNavCountdown;

const Container = styled.span`
  background-color: inherit;
  color: var(--red-2);
  font-size: inherit;
`;
