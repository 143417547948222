import { useState, useEffect } from 'react';
// import TimezoneByLocation from 'shared/constants/timezones';
// TODO: USE TIMEZONES TO HELP DETERMINE COUNTDOWN TO AUCTION START
// 1. Use TimezoneByLocation to get momentjs timezone based on auction location
// 2. Compare UTC offset dates between bidder's locale and the auction locale
// resources
// npm install moment-timezone
// https://momentjs.com/timezone/docs/#/using-timezones/
// https://momentjs.com/downloads/moment-timezone-with-data.js

/*

const startTime = '12/3/2020 12:00:00 PM';
const startTimeAsDate=new Date(startTime);

const a = moment.tz(startTimeAsDate, TimezoneByLocation.SAN);
const b = moment.tz(new Date(), TimezoneByLocation.DEN);

console.log('auction start', a.format()); // 2013-11-18T11:55:00+08:00
console.log('my time', b.format()); // 2013-11-18T11:55:00-05:00
console.log('auction has started', moment(a).isBefore(b));

console.log('guess', moment.tz.guess());
console.log('timezone', moment.tz(moment.tz.guess()).zoneAbbr());
console.log('tz', moment.tz.zonesForCountry('US', true));

*/

const useCountdown = (endTime) => {
  const [isActive, setIsActive] = useState(true);
  // const [currentTime, setCurrentTime] = useState(endtime);
  const [date, setDate] = useState(new Date());

  const total = Date.parse(endTime) - Date.parse(date);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  const toggle = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setDate(new Date());
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, date]);

  return {
    countdownDate: endTime,
    toggle,
    isActive,
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export default useCountdown;
