import styled from 'styled-components';

import { font } from 'shared/utils/styles';

export const Image = styled.img`
  border-radius: 100%;
  width: 100%;
  height: auto;
  margin: -2px -1px;
`;

export const Letter = styled.div`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 100%;
  text-transform: uppercase;
  color: #fff;
  background: ${(props) => props.color};
  ${font.medium}
  ${(props) => font.size(Math.round(props.size / 1.7))}
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export const RoundAvatar = styled.figure`
  background-color: black;
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 95%;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
`;
