import React from 'react';
import AuthStore from '../stores/AuthStore';
import AuctionStore from '../stores/AuctionStore';
import MessageStore from '../stores/MessageStore';
import BidStore from '../stores/BidStore';
import DataStore from '../stores/DataStore';
import UIStore from '../stores/UIStore';
import ProviderStore from '../stores/ProviderStore';

const authStore = new AuthStore();
const auctionStore = new AuctionStore();
const providerStore = new ProviderStore();
const messageStore = new MessageStore();
const bidStore = new BidStore(auctionStore, providerStore, messageStore);

const dataStore = new DataStore(
  authStore,
  auctionStore,
  bidStore,
  messageStore,
  providerStore,
);
const uiStore = new UIStore(dataStore);

const storesContext = React.createContext({
  AuthStore: authStore,
  AuctionStore: auctionStore,
  BidStore: bidStore,
  MessageStore: messageStore,
  DataStore: dataStore,
  UIStore: uiStore,
  ProviderStore: providerStore,
});

export default storesContext;
