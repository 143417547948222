/* eslint-disable max-classes-per-file */
/**
 * Most of this code has been loosely converted from ES15 code supplied by the Janus team.
 * For that reason I had to suppress some low impact linting warnings to get it to build.
 *
 * It's dependent on two external packages, Janus core JS and webrtc-adapter that we load into the DOM in index.html.
 */


class JanusStreamingAnswer {
  constructor({
    showDebugOutput, Janus, audio, stereo, errorCallback,
  }) {
    this.SHOW_DEBUG_OUTPUT = showDebugOutput;
    this.Janus = Janus;
    this.audio = audio;
    this.stereo = stereo;
    this.errorCallback = errorCallback;
    this.debugData('constructor');
  }

  debugData = (message) => {
    if (this.SHOW_DEBUG_OUTPUT) {
      console.log(`JanusStreamingAnswer > ${message ?? 'debug'} >`, {
        janus: this.Janus,
        audio: this.audio,
        stereo: this.stereo,
        errorCallback: this.errorCallback,
      });
    }
  };

  onCustomizeSdpCallback = (jsep) => {
    try {
      this.debugData('onCustomizeSdpCallback');

      // If offer was stereo, make sure that our answer contains stereo too
      if (this.stereo && jsep.sdp.indexOf('stereo=1') === -1) {
        // eslint-disable-next-line no-param-reassign
        jsep.sdp = jsep.sdp.replace(
          'useinbandfec=1',
          'useinbandfec=1;stereo=1',
        );
      }
    } catch (error) {
      console.error('JanusStreamingAnswer > onCustomizeSdpCallback', {
        error,
        jsep,
      });
    }
  };

  onSuccessCallback = (jsep) => {
    try {
      this.debugData('onSuccessCallback');

      if (this.SHOW_DEBUG_OUTPUT) {
        this.Janus.debug('Janus::[Audio] Got SDP!', jsep);
      }

      // The way to provide the answer back to the Streaming plugin is
      // by using the "start" request: no other argument is needed,
      // since we're using a specific handle so the context is clear.
      const start = { request: 'start' };
      this.audio.send({ message: start, jsep });
    } catch (error) {
      console.error('JanusStreamingAnswer > onSuccessCallback', {
        error,
        jsep,
      });
    }
  };

  onErrorCallback = (error) => {
    try {
      this.debugData('onErrorCallback');
      this.Janus.error('WebRTC error:', error);
      this.errorCallback(`WebRTC (audio) error... ${error.message}`);
    } catch (err) {
      console.error('JanusStreamingAnswer > onErrorCallback', {
        err,
        error,
      });
    }
  };

  createAnswer = (jsep) => {
    try {
      this.debugData('createAnswer');
      if (this.SHOW_DEBUG_OUTPUT) {
        this.Janus.debug('Janus::[Audio]Handling SDP as well...', jsep);
      }

      // Offer from the plugin, let's answer
      this.audio.createAnswer({
        jsep,

        // We want recvonly audio/video
        media: { audioSend: false, videoSend: false },

        // eslint-disable-next-line no-shadow
        customizeSdp: this.onCustomizeSdpCallback,

        // eslint-disable-next-line no-shadow
        success: this.onSuccessCallback,
        error: this.onErrorCallback,
      });
    } catch (error) {
      console.error('JanusStreamingAnswer > createAnswer', {
        error,
        jsep,
      });
    }
  };
}


class JanusAudioMount {
  constructor({
    showDebugOutput,
    Janus,
    janusInstance,
    laneID,
    mediaRoomID,
    myRoom,
    audioTag,
    plugin,
    opaqueId,
    messageCallback,
    streamStateCallback,
    streamerCallback,
    streamerDetachedCallback,
    janusErrorCallback,
    audioStateCallback,
  }) {
    this.SHOW_DEBUG_OUTPUT = showDebugOutput;
    this.Janus = Janus;
    this.janusInstance = janusInstance;
    this.streaming = {};
    this.laneID = laneID;
    this.id = mediaRoomID;
    this.myRoom = myRoom;
    this.audiotag = audioTag;
    this.plugin = plugin;
    this.opaqueId = opaqueId;

    this.messageCallback = messageCallback;
    this.streamStateCallback = streamStateCallback;
    this.streamerCallback = streamerCallback;
    this.streamerDetachedCallback = streamerDetachedCallback;
    this.janusErrorCallback = janusErrorCallback;
    this.audioStateCallback = audioStateCallback;

    this.debugData('constructor');
  }

  debugData = (message) => {
    if (this.SHOW_DEBUG_OUTPUT) {
      console.log(`JanusAudioMount > ${message ?? 'debug'} >`, {
        laneID: this.laneID,
        audioTag: this.audiotag,
        messageCallback: this.messageCallback,
        streamStateCallback: this.streamStateCallback,
        streamerCallback: this.streamerCallback,
        streamerDetachedCallback: this.streamerDetachedCallback,
        janusErrorCallback: this.janusErrorCallback,
        audioStateCallback: this.audioStateCallback,
        id: this.id,
        janusInstance: this.janusInstance,
        myRoom: this.myRoom,
        streaming: this.streaming,
        Janus: this.Janus,
        plugin: this.plugin,
        opaqueId: this.opaqueId,
      });
    }
  };

  handleMediaInstanceStarting = () => this.messageCallback('Starting, please wait...');

  handleMediaInstanceStarted = () => {
    // We're just waiting for the PeerConnection to come up, now
    this.messageCallback('Broadcast started');
  };

  handleMediaInstanceStopped = () => {
    try {
      this.debugData('handleMediaInstanceStopped');
      this.streaming.audio.detach();
      this.streamerDetachedCallback(this.id);
    } catch (error) {
      console.error('JanusAudioMount > handleMediaInstanceStopped', { error });
    }
  };

  handleMediaInstanceError = (error) => this.janusErrorCallback(error);

  onJanusInstanceSuccessCallback = (pluginHandle) => {
    try {
      this.debugData('onJanusInstanceSuccessCallback');

      this.streaming.audio = pluginHandle;

      this.Janus.log(
        `Janus::[Audio] Plugin attached! (${this.streaming.audio.getPlugin()}, id=${this.streaming.audio.getId()})`,
      );

      // Now that we're attached to the plugin, we can subscribe
      // to the mountpoint: all we need to do is issue a "watch"
      // request to the unique ID of the mountpoint, which will
      // result in Janus sending us an SDP offer later on, in an
      // asynchronous event, which we'll have to answer to.
      const watch = {
        request: 'watch',
        id: this.myRoom,
      };
      this.streaming.audio.send({ message: watch });
    } catch (error) {
      console.error('JanusAudioMount > onJanusInstanceSuccessCallback', {
        error,
        pluginHandle,
      });
    }
  };

  onJanusInstanceMessageCallback = (msg, jsep) => {
    try {
      if (this.SHOW_DEBUG_OUTPUT) {
        this.Janus.debug('Janus::[Audio] ::: Got a message :::', msg);
      }

      const { result } = msg;

      if (msg.error) {
        this.handleMediaInstanceError(msg.error);
      } else if (result && result.status) {
        const { status } = result;

        if (this.SHOW_DEBUG_OUTPUT) {
          console.log(
            'JanusAudioMount > onJanusInstanceMessageCallback > withStatus',
            {
              status,
            },
          );
        }

        this.streamStateCallback(status);

        if (status === 'starting') this.handleMediaInstanceStarting();
        if (status === 'started') this.handleMediaInstanceStarted();
        if (status === 'stopped') this.handleMediaInstanceStopped();
      } else if (this.SHOW_DEBUG_OUTPUT) {
        console.log(
          'JanusAudioMount > onJanusInstanceMessageCallback > what to do?',
          {
            msg,
            jsep,
          },
        );
      }

      if (jsep) {
        if (this.SHOW_DEBUG_OUTPUT) {
          console.log(
            'JanusAudioMount > onJanusInstanceMessageCallback > withJsep',
            {
              jsep,
            },
          );
        }

        const stereo = jsep.sdp.indexOf('stereo=1') !== -1;

        const answer = new JanusStreamingAnswer({
          showDebugOutput: this.SHOW_DEBUG_OUTPUT,
          Janus: this.Janus,
          audio: this.streaming.audio,
          stereo,
          errorCallback: this.janusErrorCallback,
        });

        answer.createAnswer(jsep);
      }
    } catch (error) {
      console.error('JanusAudioMount > onJanusInstanceMessageCallback', {
        error,
        msg,
        jsep,
      });
    }
  };

  onJanusInstanceRemoteStreamCallback = (stream) => {
    try {
      this.debugData('onJanusInstanceRemoteStreamCallback');
      if (this.SHOW_DEBUG_OUTPUT) {
        this.Janus.debug('Janus::[Audio]  ::: Got a remote stream :::', stream);
      }
      this.Janus.attachMediaStream(this.audiotag, stream);

      if (this.audiotag) {
        this.debugData('onJanusInstanceRemoteStreamCallback > play');

        this.audiotag
          .play()
          .then(() => {})
          .catch(() => {});

        this.audioStateCallback(this.laneID, true);
      }
    } catch (error) {
      console.error('JanusAudioMount > onJanusInstanceRemoteStreamCallback', {
        error,
        stream,
      });
    }
  };

  onJanusInstanceErrorCallback = (error) => {
    try {
      this.debugData('onJanusInstanceErrorCallback');
      this.Janus.error('[Audio]   -- Error attaching plugin... ', error);
      this.janusErrorCallback(`Error starting audio... ${error.message}`);
    } catch (err) {
      console.error('JanusAudioMount > onJanusInstanceRemoteStreamCallback', {
        err,
        error,
      });
    }
  };

  subscribe = () => {
    try {
      this.debugData('subscribe');
      if (this.streaming.audio) return;

      this.debugData('subscribe > attach Instance');

      if (!this.janusInstance) {
        return;
      }

      this.janusInstance.attach({
        plugin: this.plugin,
        opaqueId: this.opaqueId,
        success: this.onJanusInstanceSuccessCallback,
        onmessage: this.onJanusInstanceMessageCallback,
        onremotestream: this.onJanusInstanceRemoteStreamCallback,
        error: this.onJanusInstanceErrorCallback,
      });
    } catch (error) {
      console.error('JanusAudioMount > subscribe', {
        error,
      });
    }
  };
}


export class JanusStreamer {
  constructor({
    mediaRoomID,
    laneID,
    audioTag,
    streamer,
    config,
    messageCallback,
    streamStateCallback,
    streamerCallback,
    streamerDetachedCallback,
    janusErrorCallback,
    audioStateCallback,
    connectionStartedCallback,
    connectionStoppedCallback,
  }) {
    if (!mediaRoomID) return;
    // if (!mediaRoomID) throw new Error('mediaRoomID is required');
    if (!config) throw new Error('config is required');

    this.SHOW_DEBUG_OUTPUT = config.showDebugOutput;
    this.messageCallback = messageCallback;
    this.streamStateCallback = streamStateCallback;
    this.streamerCallback = streamerCallback;
    this.streamerDetachedCallback = streamerDetachedCallback;
    this.janusErrorCallback = janusErrorCallback;
    this.audioStateCallback = audioStateCallback;
    this.connectionStartedCallback = connectionStartedCallback;
    this.connectionStoppedCallback = connectionStoppedCallback;

    this.id = parseInt(mediaRoomID);
    this.laneID = laneID;
    this.audiotag = audioTag;

    this.janusInstance = null;
    this.myRoom = null;
    this.streaming = {};

    this.streamer = streamer;

    this.token = config.token;
    this.Janus = config.window;
    this.plugin = config.plugin;
    this.opaqueId = config.opaqueId;
    this.server = config.server;
    this.iceServers = config.iceServers;

    this.debugData(
      'constructor > =========================================================',
    );
  }

  janusAudioMountParams = () => ({
    showDebugOutput: this.SHOW_DEBUG_OUTPUT,
    Janus: this.Janus,
    janusInstance: this.janusInstance,
    laneID: this.laneID,
    mediaRoomID: this.id,
    myRoom: this.myRoom,
    audioTag: this.audiotag,
    plugin: this.plugin,
    opaqueId: this.opaqueId,
    messageCallback: this.messageCallback,
    streamStateCallback: this.streamStateCallback,
    streamerCallback: this.streamerCallback,
    streamerDetachedCallback: this.streamerDetachedCallback,
    janusErrorCallback: this.janusErrorCallback,
    audioStateCallback: this.audioStateCallback,
  });

  debugData = (message) => {
    if (this.SHOW_DEBUG_OUTPUT) {
      console.log(`JanusStreamer > ${message ?? 'debug'} >`, {
        laneID: this.laneID,
        audioTag: this.audiotag,
        messageCallback: this.messageCallback,
        streamStateCallback: this.streamStateCallback,
        streamerCallback: this.streamerCallback,
        streamerDetachedCallback: this.streamerDetachedCallback,
        janusErrorCallback: this.janusErrorCallback,
        audioStateCallback: this.audioStateCallback,
        id: this.id,
        janusInstance: this.janusInstance,
        myRoom: this.myRoom,
        streaming: this.streaming,
        streamer: this.streamer,
        token: this.token,
        Janus: this.Janus,
        plugin: this.plugin,
        opaqueId: this.opaqueId,
        server: this.server,
        iceServers: this.iceServers,
      });
    }
  };

  initialize = (audioState) => {
    try {
      this.debugData('initialize > start');

      if (!this.Janus.isWebrtcSupported()) {
        this.janusErrorCallback('Your browser does not support audio WebRTC');
        return;
      }

      if (!this.Janus) {
        this.debugData('initialize > no Janus, quit');
        return;
      }

      if (this.SHOW_DEBUG_OUTPUT) {
        console.log('JanusStreamer > initialize > audioState/streamer', {
          audioState,
          streamer: this.streamer,
        });
      }

      if (!audioState && this.streamer) {
        // we are connected, disconnect and cleanup
        this.debugData('initialize > stopConnection');
        this.stopConnection();
        return;
      }
      if (audioState && !this.streamer) {
        // If first load and we're not connected, init Janus and start stream
        this.debugData('initialize > startConnection');
        this.startConnection();
      }
    } catch (error) {
      console.error('JanusStreamer > initialize', { error, audioState });
    }
  };

  onStartConnectionInitCallback = () => {
    try {
      this.debugData('onStartConnectionInitCallback > start');

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.id) || this.id < 1) {
        return;
      }

      this.myRoom = this.id;

      this.janusInstance = new this.Janus({
        server: this.server,
        token: this.token,
        iceServers: this.iceServers,
        success: this.onSuccessCallback,
        error: this.onErrorCallback,
        destroyed: this.onDestroyedCallback,
      });

      this.streamer = this.janusInstance;

      this.streamerCallback(this.streamer);

      this.debugData('onStartConnectionInitCallback > end');
    } catch (error) {
      console.error('JanusStreamer > onStartConnectionInitCallback', {
        error,
      });
    }
  };

  startConnection = () => {
    try {
      this.debugData('startConnection');
      this.Janus.init({
        //debug: 'all',
        callback: this.onStartConnectionInitCallback,
      });
      this.connectionStartedCallback({
        Janus: this.Janus,
        laneID: this.laneID,
      });
    } catch (error) {
      console.error('JanusStreamer > startConnection', { error });
    }
  };

  dispose = () => {
    try {
      this.debugData('dispose');
      if (this.streamer) {
        this.stopConnection();
      }
      this.streamer = null;
      this.janusInstance = null;
    } catch (error) {
      console.error('JanusStreamer > dispose', { error });
    }
  };

  stopConnection = () => {
    try {
      this.debugData('stopConnection > start');
      // If we're connected, disconnect and cleanup
      this.streamer.destroy();
      this.audiotag.src = null;
      this.streamStateCallback(null);
      this.streamerCallback(null);
      this.janusInstance = null;
      this.connectionStoppedCallback({
        Janus: this.Janus,
        streamer: this.streamer,
        laneID: this.laneID,
      });
      this.debugData('stopConnection > end');
    } catch (error) {
      console.error('JanusStreamer > stopConnection', { error });
    }
  };

  onSuccessCallback = () => {
    try {
      this.debugData('StartStopConnection > onSuccessCallback > start');

      const params = this.janusAudioMountParams();
      const audioMount = new JanusAudioMount(params);
      audioMount.subscribe();

      this.debugData('StartStopConnection > onSuccessCallback > end');
    } catch (error) {
      console.error('JanusStreamer > onSuccessCallback', { error });
    }
  };

  onErrorCallback = (error) => {
    try {
      this.debugData('StartStopConnection > onErrorCallback');
      this.Janus.error(error);
      this.janusErrorCallback(error.toString());
    } catch (err) {
      console.error('JanusStreamer > onErrorCallback', { err, error });
    }
  };

  onDestroyedCallback = () => {
    try {
      this.debugData('StartStopConnection > onDestroyedCallback');
      // setStreamState(null);
      // this.streamStateCallback(null);
      this.streamer = null;
      this.janusInstance = null;
      if (this.streaming.audio) {
        this.streaming.audio.detach();
      }
    } catch (error) {
      console.error('JanusStreamer > onDestroyedCallback', { error });
    }
  };
}

export default JanusStreamer;
