import styled, { css } from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

const bgImageCss = ({ isProviderLogo, image }) => css`
  background-image: url('${image}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: ${isProviderLogo ? 'contain' : 'cover'};
  background-color: var(--lightTextColor);
`;

const promoTextContainerCss = css`
  height: 50px;
  overflow-y: auto;
  font-size: 0.7em;
  padding: 3px;
  border-bottom: var(--line);
`;

const promoLinkTextContainerCss = css`
  ${promoTextContainerCss}
  & a:hover, a:active {
    text-decoration: underline;
    color: var(--linkTextColor);
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (!props.preview && 'border-left: var(--line);  border-bottom: var(--line);')};

  ${(props) => !!props.redirectUrl && !props.text && '&:hover {cursor: pointer}'};
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: ${(props) => (props.preview ? 'prevpromo' : 'promo')};
    box-sizing: border-box;
    margin: ${(props) => (props.preview ? '0 15px' : '0')};
    /* margin: ${(props) => (props.preview ? '0 15px' : props.isNPALogo ? '0px' : '0')}; */
    /* border-left: var(--line);
    border-right: var(--line); */
    ${(props) => (!props.preview && props.isNPALogo ? css`
      padding: 0px 2px;
      & img { 
        border: 0;
      }
    ` : css`
      & img { display: none;}
      ${bgImageCss}
    `)}
    /* ${(props) => !!props.image && bgImageCss}; */
    ${(props) => !!props.text && !props.redirectUrl && promoTextContainerCss};
    ${(props) => !!props.text && !!props.redirectUrl && promoLinkTextContainerCss};
  }
  @media (min-width: ${breakpoints.small}) {
    ${(props) => !props.preview && 'border-right: none;'};
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const OverlayImageContainer = styled.div`
${({
    isMobileView, redirectUrl, text, preview, image,
  }) => css`
  position: absolute;
  ${isMobileView && 'z-index: 1'};
  top: ${isMobileView ? '15px' : 0}};
  left: ${isMobileView ? '10px' : 0};
  right: 0;
  height: 25px;
  width: 150px;
  background-color: var(--lightTextColor);
  display: flex;
  justify-content: center;
  align-items: center;
  ${!!redirectUrl && !text && '&:hover {cursor: pointer}'};
  box-sizing: border-box;
  margin: ${preview ? '0 15px' : '0'};
  ${!!image && bgImageCss};
  ${!!text && !redirectUrl && promoTextContainerCss};
  ${!!text && !!redirectUrl && promoLinkTextContainerCss};
`};
  
`;

export const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-color: var(--lightTextColor);
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--errorMsgBgColor);
  color: var(--errorMsgTextColor);
  font-size: 0.8em;
  height: 100%;
`;
