import React from 'react';
import PropTypes from 'prop-types';
import { Container, CloseButton } from './Styles';

// OBSOLETE
const MediaPreview = ({ data, zoomUrl, onClose }) => {
  const { mediaGallery } = data;

  const convertImageToHigherRes = (url, width = 600) => {
    if (!url ?? url.indexOf('?') === -1) {
      console.error({
        message:
          'Invalid url when trying to load a higher image resolution in the media previewer',
        url,
        width,
      });
      return '/assets/noimage.png';
    }
    const queryString = url.split('?');
    const parms = new URLSearchParams(`?${queryString[1]}`);
    parms.set('width', width);
    return `${queryString[0]}?${parms.toString()}`;
  };

  let image = '/assets/noimage.png';
  if (zoomUrl?.length) {
    image = convertImageToHigherRes(zoomUrl, 800);
  } else if (mediaGallery?.length > 0) {
    image = convertImageToHigherRes(mediaGallery[0].mediaUrl, 800);
  }

  return (
    <Container
      url={image}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.65, delay: 0.2 }}
      exit={{ opacity: 0 }}
    >
      <CloseButton onClick={onClose}>
        <i className="fa fa-times" aria-hidden="true" />
      </CloseButton>
    </Container>
  );
};

MediaPreview.propTypes = {
  data: PropTypes.object,
  zoomUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

MediaPreview.defaultProps = {
  data: { mediaGallery: [] },
  zoomUrl: '',
};

export default MediaPreview;
