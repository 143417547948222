import React from 'react';
import useStores from 'hooks/use-store';
import { observer } from 'mobx-react-lite';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Breadcrumbs } from 'components/shared';
import { Content, MarginWrapper, Wrapper } from 'assets/ContainerStyles';
import NavbarLeft from 'components/project/NavbarLeft';
import toast from 'shared/utils/toast';
import {
  FormRow, FormWrapper, Option, Select,
} from './Styles';
import { BackButton, BackWrapper, Icon } from '../BidActivity/Mobile/styles';
import { ROUTES } from '../../../../shared/constants/index';

const Settings = () => {
  const { AuctionStore, UIStore } = useStores();
  const {
    auctionID,
    errorMessage,
    auctionList,
    laneList,
    auctionLaneID,
    connected,
  } = AuctionStore;
  const { handleJoinAuction, handleJoinLane } = UIStore;
  const history = useHistory();
  const match = useRouteMatch();

  /** If errorMessage has value, show the error toast */
  // eslint-disable-next-line no-unused-expressions
  errorMessage && toast.error(errorMessage);

  const handleAuctionSelect = (event) => {
    if (event.target.value) {
      handleJoinAuction(event.target.value);
    }
  };

  const handleLaneSelect = (event) => {
    if (event.target.value) {
      handleJoinLane(event.target.value);
      history.goBack();
    }
  };

  return (
    <Wrapper>
      <BackWrapper>
        <BackButton
          onClick={() => history.push(`${match.path}${ROUTES.lanes}`)}
        >
          <Icon className="fa fa-chevron-left icon-only" />
          Return to auction
        </BackButton>
      </BackWrapper>

      <MarginWrapper>
        <Breadcrumbs items={['Bid Client', 'Browse']} />

        <Content>
          <h1>Settings</h1>

          <FormWrapper>
            <h2>Change Auction and Lane</h2>
            {auctionList.length === 0 && <p>No auctions available</p>}
            {auctionList.length > 0 && connected && (
              <FormRow>
                <Select onChange={handleAuctionSelect} defaultValue={auctionID}>
                  <Option value="">--Select Auction--</Option>
                  {auctionList.map((item) => (
                    <Option key={item.auctionID} value={item.auctionID}>
                      {item.auction}
                    </Option>
                  ))}
                </Select>
              </FormRow>
            )}

            {laneList.length > 0 && connected && (
              <FormRow>
                <Select
                  onChange={handleLaneSelect}
                  defaultValue={auctionLaneID}
                >
                  <Option value="">--Select Lane--</Option>
                  {laneList.map((item) => (
                    <Option key={item.laneID} value={item.laneID}>
                      {item.lane}
                    </Option>
                  ))}
                </Select>
              </FormRow>
            )}
          </FormWrapper>
        </Content>

        <NavbarLeft />
      </MarginWrapper>
    </Wrapper>
  );
};

export default observer(Settings);
