import React from 'react';
import PropTypes from 'prop-types';
import { StyledItem, ItemText, StyledBadge } from './Styles';

const Item = ({
  testid, onClick, icon, text, badge,
}) => (
  <StyledItem data-testid={testid} onClick={onClick} badge={badge}>
    <i className={`fa ${icon}`} aria-hidden="true">
      {(badge || badge === 0) && <StyledBadge>{badge}</StyledBadge>}
    </i>
    <ItemText>{text}</ItemText>
  </StyledItem>
);

Item.defaultProps = {
  text: 'Need Text',
  icon: '',
  badge: null,
};

Item.propTypes = {
  testid: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Item;
