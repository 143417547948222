import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { JanusStreamer } from '../shared/utils/JanusStreamer';
import {
  JANUS_OPAQUE_ID,
  JANUS_STREAMING_PLUGIN,
} from '../shared/constants/index';

const useJanus = ({
  window,
  mediaRoomID,
  audioVideoUrl,
  mediaToken,
  laneID,
  setAudioState,
  audioPlaying,
  clearLaneTempPinMessage,
  showDebugOutput,
  janusIceServers,
  handleSetLaneAudio,
  setLaneTempPinMessage,
}) => {
  const { Janus } = window;
  const [message, setMessage] = useState('');
  const [streamState, setStreamState] = useState(null);
  const [streamer, setStreamer] = useState(null);
  const [janusStarted, setJanusStarted] = useState(false);
  const [janusStopped, setJanusStopped] = useState(true);

  const audioTagRef = useRef();

  const janusConfig = {
    showDebugOutput,
    window: Janus,
    plugin: JANUS_STREAMING_PLUGIN,
    opaqueId: JANUS_OPAQUE_ID(Janus.randomString(12)),
    server: audioVideoUrl,
    token: mediaToken,
    iceServers: janusIceServers,
  };

  const onJanusStreamState = (value) => setStreamState(value);

  const onJanusStreamer = (value) => setStreamer(value);

  const onJanusDetached = (value) => {
    if (janusConfig.showDebugOutput) {
      console.log('Janus::AudioStreamer(Component) > onJanusDetached', value);
    }
  };

  const onJanusError = (value) => {
    setJanusStarted(false);
    setJanusStopped(true);
    console.error('Janus::AudioStreamer(Component) > onJanusError', value);
  };

  const onJanusMessage = (value) => setMessage(value);

  const onAudioStateChange = (laneId, value) => setAudioState(laneId, value, false);

  const onConnectionStarted = ({ Janus: janus, laneID: laneId }) => {
    setJanusStarted(true);
    setJanusStopped(false);
    if (janusConfig.showDebugOutput) {
      console.log('JANUS::AudioStreamer(Component) > onConnectionStarted', {
        janus,
        laneId,
      });
    }
  };

  const onConnectionStopped = ({ Janus: janus, streamer: stream }) => {
    setJanusStarted(false);
    setJanusStopped(true);
    if (janusConfig.showDebugOutput) {
      console.log('JANUS::AudioStreamer(Component) > onConnectionStopped', {
        janus,
        laneID,
        stream,
      });
    }
  };

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log('JANUS::AudioStreamer(Component) > useState > janusStarted', {
        janusStarted,
      });
    }
    // eslint-disable-next-line
  }, [janusStarted]);

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log('JANUS::AudioStreamer(Component) > useState > janusStopped', {
        janusStopped,
      });
    }
    // eslint-disable-next-line
  }, [janusStopped]);

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log('Janus::AudioStreamer(Component) > useState > message', {
        message,
      });
    }

    if (message === 'Broadcast started') {
      clearLaneTempPinMessage(laneID);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log('Janus::AudioStreamer(Component) > useState > streamState', {
        streamState,
      });
    }
    // eslint-disable-next-line
  }, [streamState]);

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log('Janus::AudioStreamer(Component) > useState > streamer', {
        streamer,
      });
    }
    // eslint-disable-next-line
  }, [streamer]);

  useEffect(() => {
    if (janusConfig.showDebugOutput) {
      console.log(
        'Janus::AudioStreamer(Component) > initialize(audioPlaying)',
        {
          audioPlaying, streamer, message, streamState,
        },
      );
    }

    if (!mediaRoomID) {
      if (janusConfig.showDebugOutput) {
        console.error('Janus::AudioStreamer(Component) > No Room ID available');
      }
      return;
    }

    const janusStreamer = new JanusStreamer({
      mediaRoomID,
      laneID,
      audioTag: audioTagRef.current,
      streamer,
      config: janusConfig,
      messageCallback: onJanusMessage,
      streamStateCallback: onJanusStreamState,
      streamerCallback: onJanusStreamer,
      streamerDetachedCallback: onJanusDetached,
      janusErrorCallback: onJanusError,
      audioStateCallback: onAudioStateChange,
      connectionStartedCallback: onConnectionStarted,
      connectionStoppedCallback: onConnectionStopped,
    });
    janusStreamer.initialize(audioPlaying);

    return () => janusStreamer.dispose();
    // eslint-disable-next-line
  }, [audioPlaying]);

  if (!audioPlaying) {
    if (audioTagRef.current) audioTagRef.current.pause();
  }

  useLayoutEffect(() => {
    if (audioTagRef.current) {
      if (audioTagRef.current.paused) {
        const promise = audioTagRef.current.play();
        if (promise !== undefined) {
          promise.then((_) => {
            setLaneTempPinMessage(laneID, null);
          }).catch((error) => {

            /** If we get an error code 1 (autoplay not available) show a message advising the user */
            if (error.code < 1) {
              setJanusStarted(false);
              setJanusStopped(true);
              handleSetLaneAudio(laneID, false);
              setLaneTempPinMessage(laneID, 'Click the speaker icon to play audio. If that does not work, then please check browser settings.');
            }
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [audioPlaying, audioTagRef]);

  return {
    audioTagRef,
    janusConfig,
    Janus,
    message,
    streamState,
    streamer,
    audioPlaying,
  };
};

export default useJanus;
