import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Icon } from '../../../../shared';
import {
  Container, CloseIcon, Heading, ScrollView,
} from './styles';
import { BidderStats } from '../../../../NavbarLeft/PinnedNav/Stats/BidderStats';

const Stats = ({ close }) => (
  <Container>
    <Heading>Auction Stats</Heading>
    <CloseIcon onClick={() => close(null, null)}><Icon icon="times" iconOnly /></CloseIcon>
    <ScrollView>
      <BidderStats />
    </ScrollView>
  </Container>
);


Stats.propTypes = {
  close: PropTypes.func.isRequired,
};

export default observer(Stats);
