import useMergeState from 'shared/hooks/mergeState';
import { abbrevStringValue, stripCurrencySymbols, isEnterKey } from '../components/project/shared/utils/common';

export const useBidBlockDetails = ({
  allowProxyBidding = false,
  auctionLane = null,
  laneID = null,
  laneStatusMap = null,
  proxies = [],
  setSellerAction = null,
  user = null,
  watchList = [],
  takeAmountRef = null,
  setTakeAmountRefFocus = null,
  networkConnected = true,
}) => {
  const [state] = useMergeState({
    bidder: '',
    acceptanceAmount: '',
  });

  const proxy = proxies.filter(
    (item) => item.productID === auctionLane.productItem.productID,
  );
  const hasProxyBid = proxy.length > 0;
  const myProxyBidAmount = hasProxyBid ? proxy[0].proxyBid : 0;
  const myProxyBidIsActive = allowProxyBidding > 0
    && myProxyBidAmount > 0
    && myProxyBidAmount >= auctionLane.nextBid;

  const { isOpenForBidding, canBid } = auctionLane;
  const isHighBidder = user.name === auctionLane.winningBidderName;

  const lotNumberMismatch = auctionLane.productItem.lotNumber !== auctionLane.lotNumber;

  const laneStatus = laneStatusMap.get(laneID).laneStatusName;
  const isRunning = laneStatus === 'Running';
  const { winningBidderName, isSeller, decisionStatus } = auctionLane;

  const getDecisionStatus = () => {
    if (!decisionStatus || ![5, 6, 7].includes(decisionStatus?.type)) return { message: null, bgColor: null, color: null };
    if (decisionStatus.type === 5) return { message: decisionStatus.message, bgColor: 'var(--noSaleMsgBgColor)', color: 'var(--noSaleMsgTextColor)' };
    if (decisionStatus.type === 6) return { message: decisionStatus.message, bgColor: 'var(--wonMsgBgColor)', color: 'var(--wonMsgTextColor)' };
    if (decisionStatus.type === 7) return { message: decisionStatus.message, bgColor: 'var(--pendingMsgBgColor)', color: 'var(--pendingMsgTextColor)' };
    return { message: null, bgColor: 'var(--lightTextColor)', color: 'var(--errorColor' };
  };

  // const isTransitioningToNewBlockItem = !getDecisionStatus().message && !isOpenForBidding;

  const disabledButton = !canBid
    || !isOpenForBidding
    || isHighBidder
    || !isRunning
    || myProxyBidIsActive
    || lotNumberMismatch
    || !networkConnected;

  const isWatched = watchList?.filter(
      (item) => item.productID === auctionLane.productItem.productID
    ).length > 0 || false;

  let sellerProduct = [];
  if (isSeller) {
    sellerProduct = auctionLane.sellerProducts.filter(
      (product) => product.lotNumber === auctionLane.lotNumber,
    );
  }

  const { reserve, reserveType } = sellerProduct.length > 0 && sellerProduct[0];

  const reserveAsCurrency = (amount) => `$ ${new Intl.NumberFormat().format(amount)}`;

  const getFormattedReserve = () => {
    if (reserve === 0 && reserveType !== 'No Reserve') return 'N/A';
    if (reserve === 0 && reserveType === 'No Reserve') return 'No Reserve';
    if (reserve > 0 && reserveType !== '-') return `${reserveAsCurrency(reserve)} ${reserveType}`;
    return reserveAsCurrency(reserve);
  };

  const formattedReserve = getFormattedReserve();

  const abbrevBidderName = abbrevStringValue(
    winningBidderName || 'N/A',
    20,
    false,
  );

  const {
    sellerAction, message, sellerActionAmount: highBidAmtTakeClick, tempTakeAmount, tempSellerAction,
  } = auctionLane.seller;

  const TakesValidationMessages = () => {
    if (!reserve && !highBidAmtTakeClick && tempTakeAmount > 0) {
      return null;
    }

    if (reserve && !highBidAmtTakeClick) {
      return `Value must be less than or equal to the reserve of ${reserveAsCurrency(reserve)}`;
    }

    if (!reserve && highBidAmtTakeClick > 0) {
      return `Value must be greater than or equal to the bid of ${reserveAsCurrency(highBidAmtTakeClick)}`;
    }

    if (reserve && highBidAmtTakeClick > 0) {
      return `Value must be greater than or equal to the bid of ${reserveAsCurrency(highBidAmtTakeClick)} and less than or equal to the reserve of ${reserveAsCurrency(reserve)}`;
    }

    return null;
  };

  const DisableTakeSubmit = () => {
    if (tempTakeAmount > 0) {
      /** If there's no reserve or no highbid, allow any value */
      if (!reserve && !highBidAmtTakeClick && tempTakeAmount > 0) {
        return {
          disabled: false,
          message: null,
        };
      }

      /** If we have a reserve and no HIGH BID, and the entered amount is less than the reserve, allow it */
      if (reserve && tempTakeAmount <= reserve && !highBidAmtTakeClick) {
        return {
          disabled: false,
          message: null,
        };
      }

      /** If we have no reserve, but have a high bid and the value is greater than the high bid, allow it */
      if (!reserve && highBidAmtTakeClick && tempTakeAmount >= highBidAmtTakeClick) {
        return {
          disabled: false,
          message: null,
        };
      }

      /** Amount must be greater than RESERVE and less than the current HIGH BID */
      if (tempTakeAmount <= reserve && tempTakeAmount >= highBidAmtTakeClick) {
        return {
          disabled: false,
          message: null,
        };
      }

      /** Disable it */
      return {
        disabled: true,
        message: 'Value must be great than reserve and less than the high bid',
      };
    }

    return {
      disabled: true,
      message: 'Value must be greater than zero',
    };
  };

  const BidButtonLabelLogic = () => {
    if (window.location.href.indexOf('localhost') > -1) {
      console.log('BidButtonLabelLogic', { networkConnected, laneStatus, isHighBidder, disabledButton, isRunning, isOpenForBidding, isSeller, viewOnly: user.viewOnly, canBid });
    }

    if (!networkConnected) {
      return 'OFFLINE';
    }

    if (laneStatus === 'Paused') {
      return laneStatus;
    }

    if (isHighBidder && disabledButton && !isRunning) {
      if (laneStatus === 'Suspended') return 'High Bidder';
      return `High Bidder (${laneStatus})`;
    }

    if (isHighBidder && isRunning) {
      return 'High Bidder';
    }

    // if isSeller or viewOnly then Bid $**** but disabled.
    if ((isOpenForBidding && !disabledButton) || isSeller || user.viewOnly || !canBid) {

      // FIXME: NOT WORKING??
      if (user.viewOnly && auctionLane.nextBid === 0) return 'Waiting';
      return `BID $ ${new Intl.NumberFormat().format(
        auctionLane.nextBid,
      )}`;
    }

    if (lotNumberMismatch && auctionLane.isOpenForBidding) {
      return 'Error'
    }

    if (isOpenForBidding && disabledButton) {
      return 'Closing...';
    }

    return 'Waiting';
  };

  const onTakesKeyUp = (e) => {
    const numericValues = stripCurrencySymbols(e.currentTarget.value);
    e.currentTarget.value = numericValues > 0 ? `${numericValues}` : '';
  };

  const onTakesKeyPress = (e) => {
    if (isEnterKey(e) && !DisableTakeSubmit().disabled) {
      e.preventDefault();
      setSellerAction(laneID, 'TAKES', tempTakeAmount);
    }
  };


  return {
    decisionStatus: getDecisionStatus(),
    abbrevBidderName,
    tempSellerAction,
    sellerAction,
    BidButtonLabelLogic,
    disabledButton,
    DisableTakeSubmit,
    hasProxyBid,
    highBidAmtTakeClick,
    isSeller,
    isWatched,
    message,
    myProxyBidAmount,
    myProxyBidIsActive,
    onTakesKeyUp,
    onTakesKeyPress,
    reserve,
    reserveType,
    formattedReserve,
    setTakeAmountRefFocus,
    state,
    takeAmountRef,
    TakesValidationMessages,
    tempTakeAmount,
    winningBidderName,
  };
};

export default useBidBlockDetails;
