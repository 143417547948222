import styled from 'styled-components';

import { issuePriorityColors } from 'shared/utils/styles';
import { Icon } from 'components/shared/index';

const PriorityIcon = styled(Icon)`
  color: ${(props) => issuePriorityColors[props.color]};
`;

export default PriorityIcon;
