import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

const useAuctionLaneToggle = (laneID, key, initial) => {
  const {
    BidStore: { setAuctionProperty },
  } = useStores();

  const isToggled = initial;

  const toggle = () => {
    setAuctionProperty(laneID, key, !isToggled);
  };

  const setToggle = (value) => {
    setAuctionProperty(laneID, key, value);
  };

  return { isToggled, setToggle, toggle };
};

export default observer(useAuctionLaneToggle);
