import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  color: var(--darkTextColor);
`;

export const Item = styled.div`
  display: flex;
  margin: 10px 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & i {
    width: 1em;
  }
`;

export const LinkText = styled.div`
  font-size: 0.9em;
  font-family: "Open Sans",Arial,Helvetica,sans-serif!important;
  margin: 0 0 0 25px;
`;
