import styled from 'styled-components';
import { breakpoints } from 'shared/utils/styles';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  @media (min-width: ${breakpoints.tablet}) {
    grid-area: promo;
    box-sizing: border-box;
    margin: 0;
    border-left: var(--line);
    border-right: var(--line);
    background-image: url('${(props) => props.url}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--lightTextColor);
  }
  @media (min-width: ${breakpoints.small}) {
    border-right: none;
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;

export const Audio = styled.audio`
  @media (min-width: ${breakpoints.tablet}) {
    min-width: 140px;
  }
  @media (min-width: ${breakpoints.small}) {
  }
  @media (min-width: ${breakpoints.large}) {
  }
`;
