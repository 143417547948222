import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useCarousel } from 'hooks';
import PropTypes from 'prop-types';

import {
  Carousel,
  CarouselChild,
  CarouselIndicator,
  CarouselIndicators,
  CarouselContent,
  NavButtons,
  PrevButton,
  NextButton,
} from './Styles';

const makeIndices = (start, delta, num) => {
  const indices = [];

  while (indices.length < num) {
    indices.push(start);
    start += delta;
  }

  return indices;
};

const CarouselContainer = ({
  children,
  settings,
  laneClosed,
  mediaGallery,
  lotNumber,
  source,
  imageIndex,
  setImageIndex,
  thumbnailCarousel,
}) => {
  const slides = React.Children.toArray(children);
  const { length } = slides;
  const {
    height,
    width,
    showNavButtons,
    showIndicators,
    slidesPresented,
    interval,
    transitionTime,
    isFullViewLayout,
  } = settings;

  const getNumActive = () => {
    if (thumbnailCarousel && isFullViewLayout) return 5;
    return Math.min(length, slidesPresented);
  };
  const numActive = getNumActive();
  const [active, setActive, handlers, style] = useCarousel(
    length,
    interval,
    width,
    {
      slidesPresented: numActive,
      transitionTime,
      activeImage: imageIndex,
    },
  );
  const beforeIndices = makeIndices(length - 1, -1, numActive);
  const afterIndices = makeIndices(0, 1, numActive);

  useEffect(() => {
    if (source === 'block') {
      setImageIndex(0);
      setActive(0);
    }
    return () => null;
  }, [source, isFullViewLayout, setActive, setImageIndex]);

  useEffect(() => {
    if (imageIndex > 0) {
      setActive(imageIndex);
    }
    return () => null;
  }, [imageIndex, setActive]);

  return (
    length > 0 && (
      <div>
        <Carousel {...handlers} height={height} laneClosed={laneClosed} isFullViewLayout={isFullViewLayout} thumbnailCarousel={thumbnailCarousel}>
          {showNavButtons && length > 1 && (
            <NavButtons>
              <PrevButton
                onClick={() => {
                  const activeIndex = active === 0 ? length - 1 : active - 1;
                  setActive(activeIndex);
                  setImageIndex(activeIndex);
                }}
              >
                &#10094;
              </PrevButton>
              <NextButton
                onClick={() => {
                  const activeIndex = active >= length - 1 ? 0 : active + 1;
                  setActive(activeIndex);
                  setImageIndex(activeIndex);
                }}
              >
                &#10095;
              </NextButton>
            </NavButtons>
          )}
          {showIndicators && (
            <CarouselIndicators>
              {slides.map((_, index) => (
                <CarouselIndicator
                  onClick={() => setActive(index)}
                  key={index}
                  active={active === index}
                />
              ))}
            </CarouselIndicators>
          )}
          <CarouselContent style={style}>
            {beforeIndices.map((_, i) => (
              <CarouselChild width={width} key={i} onClick={() => setActive(i)}>
                {slides[i]}
              </CarouselChild>
            ))}
            {slides.map((slide, index) => (
              <CarouselChild width={width} key={index} onClick={() => setActive(index)}>
                {slide}
              </CarouselChild>
            ))}
            {afterIndices.map((_, i) => (
              <CarouselChild width={width} key={i} onClick={() => setActive(i)}>
                {slides[i]}
              </CarouselChild>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    )
  );
};

CarouselContainer.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    isMobile: PropTypes.bool,
    viewType: PropTypes.string,
    isFullViewLayout: PropTypes.bool,
    interval: PropTypes.number,
    slidesPresented: PropTypes.number,
    showNavButtons: PropTypes.bool,
    showCaptions: PropTypes.bool,
    showIndicators: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    transitionTime: PropTypes.number,
  }).isRequired,
  laneClosed: PropTypes.bool,
  mediaGallery: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  lotNumber: PropTypes.string,
  source: PropTypes.string,
  imageIndex: PropTypes.number,
  setImageIndex: PropTypes.func,
  thumbnailCarousel: PropTypes.bool,
};

CarouselContainer.defaultProps = {
  laneClosed: false,
  mediaGallery: [],
  lotNumber: null,
  source: 'block',
  imageIndex: 0,
  setImageIndex: () => null,
  thumbnailCarousel: false,
};

export default observer(CarouselContainer);
