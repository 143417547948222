export const WHITE = '#ffffff';
export const GRAY1 = '#EEEEEE';
export const GRAY2 = '#E7E7E7';
export const GRAY3 = '#D7D8D8'; // DEALER MESSAGE
export const GRAY4 = '#D2D1D2';
export const GRAY5 = '#CFCFD1'; // DISABLED BUTTON
export const GRAY6 = '#808080';
export const GRAY7 = '#888888'; // SUBHEADER BG (IE. PREVIEW)
export const GRAY8 = '#444444'; // HEADER BG
export const BLACK = '#000000';
export const BROWN1 = '#BFBCAC'; // SELLER ITEMS
export const RED1 = '#FF0000'; // ERROR MESSAGES
export const RED2 = '#DB2828'; // NO SALE
export const RED3 = '#AC0000'; // BIDDER BAR
export const BLUE1 = '#D3EFFC'; // WATCHING
export const BLUE2 = '#32A9D2'; // WATCH BUTTON
export const BLUE3 = '#04A1D3'; // CLERK MESSAGE
export const BLUE4 = '#0095E8'; // UTILITY BUTTONS
export const BLUE5 = '#1C6C98'; // GLOBAL MESSAGE
export const YELLOW1 = '#FBCC08'; // PROXY/PENDING
export const YELLOW2 = '#D8B006'; // BORDER
export const GREEN1 = '#21BA45'; // WON MESSAGE
export const GREEN2 = '#009E0F'; // BID BUTTON
export const ORANGE1 = '#ffeb9c'; // PINNED MESSAGE

export const defaultTheme = (overrides = {}) => {
  return Object.assign(
    {
      black: BLACK,
      blue1: BLUE1,
      blue2: BLUE2,
      blue3: BLUE3,
      blue4: BLUE4,
      blue5: BLUE5,
      brown1: BROWN1,
      gray1: GRAY1,
      gray2: GRAY2,
      gray3: GRAY3,
      gray4: GRAY4,
      gray5: GRAY5,
      gray6: GRAY6,
      gray7: GRAY7,
      gray8: GRAY8,
      green1: GREEN1,
      green2: GREEN2,
      orange1: ORANGE1,
      red1: RED1,
      red2: RED2,
      red3: RED3,
      white: WHITE,
      yellow1: YELLOW1,
      yellow2: YELLOW2,
      activeLaneTextColor: GREEN2,
      badgeValueTextColor: BLUE4,
      bidActivityBgColor1: WHITE,
      bidActivityBgColor2: GRAY2,
      bidActivityTextColor: BLACK,
      bidBtnBgColor: GREEN2,
      bidBtnTextColor: WHITE,
      blockAttrsBgColor1: WHITE,
      blockAttrsBgColor2: GRAY4,
      blockAttrsTextColor1: BLACK,
      blockAttrsTextColor2: BLACK,
      cancelBtnBgColor: GRAY2,
      cancelBtnTextColor: BLACK,
      clerkMsgBgColor: BLUE3,
      clerkMsgTextColor: WHITE,
      crAttrsBgColor1: WHITE,
      crAttrsBgColor2: GRAY2,
      crAttrsTextColor1: BLACK,
      crAttrsTextColor2: BLACK,
      darkTextColor: BLACK,
      dealerMsgBgColor: GRAY2,
      dealerMsgTextColor: BLACK,
      disabledBtnBgColor: GRAY6,
      disabledBtnTextColor: WHITE,
      divider: GRAY2,
      dividerLine: `.15em solid ${GRAY2}`,
      errorColor: RED1,
      errorMsgBgColor: RED1,
      errorMsgDarkTextColor: RED1,
      errorMsgLightTextColor: WHITE,
      globalMsgBgColor: BLUE5,
      globalMsgTextColor: WHITE,
      headerBgColor: GRAY8,
      headerTextColor: WHITE,
      lightTextColor: WHITE,
      line: `solid 1px ${GRAY6}`,
      linkTextColor: BLUE4,
      lotNumberBgColor: RED3,
      lotNumberTextColor: WHITE,
      mobileBlockAttrsBgColor1: WHITE,
      mobileBlockAttrsBgColor2: GRAY2,
      mobileBlockAttrsTextColor1: BLACK,
      mobileBlockAttrsTextColor2: BLACK,
      mobileBottomNavBgColor: WHITE,
      mobileBottomNavTextColor: RED2,
      mobileCrAttrsBgColor1: WHITE,
      mobileCrAttrsBgColor2: WHITE,
      mobileCrAttrsTextColor1: BLACK,
      mobileCrAttrsTextColor2: BLACK,
      mobilePrevAttrsBgColor1: WHITE,
      mobilePrevAttrsBgColor2: WHITE,
      mobilePrevAttrsTextColor1: BLACK,
      mobilePrevAttrsTextColor2: BLACK,
      mobileRunListBgColor1: GRAY1,
      mobileRunListBgColor2: GRAY1,
      mobileRunListTextColor1: BLACK,
      mobileRunListTextColor2: BLACK,
      myProxyBgColor: YELLOW1,
      myProxyTextColor: BLACK,
      navBgColor: BLACK,
      navTextColor: WHITE,
      noSaleMsgBgColor: RED2,
      noSaleMsgTextColor: WHITE,
      pendingMsgBgColor: YELLOW1,
      pendingMsgTextColor: BLACK,
      pinMsgBgColor: ORANGE1,
      pinMsgTextColor: BLACK,
      playerMsgBgColor: ORANGE1,
      playerMsgTextColor: WHITE,
      prevAttrsBgColor1: WHITE,
      prevAttrsBgColor2: WHITE,
      prevAttrsTextColor1: BLACK,
      prevAttrsTextColor2: BLACK,
      previewTitleBgColor: GRAY7,
      previewTitleTextColor: WHITE,
      radiusBadge: `50%`,
      radiusBox: `3px`,
      radiusModal: `4px`,
      radiusNone: `0px`,
      runListBgColor1: GRAY1,
      runListBgColor2: GRAY4,
      runListHoverBgColor: BLUE2,
      runListTextColor1: BLACK,
      runListTextColor2: BLACK,
      sellerItemBgColor: BROWN1,
      sellerItemTextColor: BLACK,
      sellerOkToSellBgColor: GREEN2,
      sellerOkToSellTextColor: WHITE,
      sellerPassBgColor: RED2,
      sellerPassTextColor: WHITE,
      sellerReserveTextColor: RED2,
      sellerTakesBgColor: BLUE4,
      sellerTakesOkBgColor: BLUE4,
      sellerTakesOkTextColor: WHITE,
      sellerTakesTextColor: WHITE,
      sendBtnBgColor: BLUE4,
      sendBtnTextColor: WHITE,
      shadedBgColor: GRAY2,
      subHeaderBgColor: GRAY8,
      subHeaderTextColor: WHITE,
      successColor: GREEN2,
      utilityActionBgColor: BLUE4,
      utilityActionTextColor: WHITE,
      utilityBtnBgColor: BLUE4,
      utilityBtnTextColor: WHITE,
      watchingBgColor: BLUE2,
      watchingTextColor: WHITE,
      wonMsgBgColor: GREEN2,
      wonMsgTextColor: WHITE,
    },
    overrides,
  );
};

export default defaultTheme;
