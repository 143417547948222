import styled from 'styled-components';

export const Heading = styled.h2`
  text-align: center;
  margin: 5px 0 15px 0;
  color: var(--darkTextColor);
`;

export const Container = styled.div`
  background-color: var(--white);
  color: var(--darkTextColor);
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 230px;
  -webkit-box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
      box-shadow: 0px -1px 28px 3px rgba(0, 0, 0, 0.48);
`;

export const ScrollView = styled.div`
  height: 200px;
  width: 100%;
  overflow: auto;
`;

export const CloseIcon = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
`;
