import React from 'react';
import { PropTypes } from 'prop-types';
import { StyledIcon } from './Styles';

export const Icon = ({
  icon, size, onClick, iconOnly, children, hoverPointer, iconPackage = 'fa', title,
}) => (
  <StyledIcon
    size={size}
    className={`${iconPackage} fa-${icon}`}
    aria-hidden
    onClick={onClick}
    iconOnly={iconOnly}
    hoverPointer={hoverPointer}
    title={title}
  >
    {children}
  </StyledIcon>
);

Icon.defaultProps = {
  size: 2,
  onClick: () => null,
  iconOnly: false,
  children: null,
  hoverPointer: true,
  iconPackage: 'fa',
  title: null,
};
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  iconOnly: PropTypes.bool,
  children: PropTypes.object,
  hoverPointer: PropTypes.bool,
  iconPackage: PropTypes.string,
  title: PropTypes.string,
};

export default Icon;
