export const getDataIndexByPropName = (data, propName, value) => {
  try {
    return data.findIndex((item) => item[propName] === value);
  } catch (err) {
    console.error('getDataIndexByPropName', {
      err, data, propName, value,
    });
  }
  return null;
};

export const getListDataById = (data, propName, id) => {
  try {
    return data.filter((item) => item[propName] === id);
  } catch (err) {
    console.error('getListDataById', {
      err, data, propName, id,
    });
  }
  return [];
};

export const hasData = (data) => {
  try {
    return data && !!data;
  } catch (err) {
    console.error('hasData', { err, data });
  }
  return false;
};

export const getProductAttribute = (data, key, noDataPlaceholderText) => {
  try {
    const attr = data && getListDataById(data.attributes || [], 'name', key)[0];
    return attr ? attr.value : noDataPlaceholderText;
  } catch (err) {
    console.error('getProductAttribute', {
      err,
      data,
      key,
      noDataPlaceholderText,
    });
  }
  return null;
};

export const convertArrayToObject = (data, key) => {
  try {
    return data.reduce(
      (obj, item) => Object.assign(obj, { [item[key]]: item.value }),
      {},
    );
  } catch (err) {
    console.error('convertArrayToObject', { err, data, key });
  }
  return null;
};

export const toTitleCase = (val) => {
  const value = val || '';
  try {
    return value.trim().replace(/\w\S*/g, (txt) => {
      if (txt.length === 1) return txt.toUpperCase();
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } catch (err) {
    console.error('toTitleCase', { err, val });
  }
  return val;
};

export const mapToObj = (strMap) => {
  const obj = Object.create(null);
  for (const [key, value] of strMap) {
    obj[key] = value;
  }
  return obj;
};

export const toBoolean = (str) => {
  //https://stackoverflow.com/questions/3263161/cannot-set-boolean-values-in-localstorage
  if (typeof str === 'undefined' || str === null) {
    return false;
  } if (typeof str === 'string') {
    switch (str.toLowerCase()) {
      case 'false':
      case 'no':
      case '0':
      case '':
        return false;
      default:
        return true;
    }
  } else if (typeof str === 'number') {
    return str !== 0;
  } else { return true; }
};